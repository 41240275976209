
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'SelectCheckbox',
})
export default class extends Vue {
  @Prop() itemList!: string[];
  @Prop() defaultCheckedItems!: string[];

  checkedItems: string[] = [];

  created() {
    this.checkedItems = this.defaultCheckedItems;
  }

  handleAllChange() {
    this.checkedItems = ['All'];

    this.$emit('value-changed');
  }

  handleSubChange() {
    let index = this.checkedItems.findIndex((item: string) => item === 'All');

    if (index > -1) {
      this.checkedItems.splice(index, 1);
    }

    this.$emit('value-changed');
  }

  setCheckedItems(checkedItems: string[]): void {
    this.checkedItems = checkedItems;
  }

  getCheckedItems(): string[] {
    return this.checkedItems;
  }
}
