
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseHeader',
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: false }) backIconVisible!: boolean;

  handleGoBack() {
    this.$router.go(-1);
  }
}
