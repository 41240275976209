
import { Component, Vue } from 'vue-property-decorator';
import ReportTemplate from '@/views/report/reportTemplate/ReportTemplate.vue';
import SubscribedReport from '@/views/report/subscribedReport/SubscribedReport.vue';
import { UserModule } from '@/store/modules/user';
import NavPage from '@/components/nav/NavPage.vue';

@Component({
  name: 'report',
  components: {
    NavPage,
    'report-template': ReportTemplate,
    'subscribed-report': SubscribedReport,
  },
})
export default class extends Vue {
  activeName: string = 'subscribedReport';

  hasSubscribedReportsAccess: boolean = false;
  hasReportTemplatesAccess: boolean = false;

  handleTabClick() {
    if (
      this.activeName === 'subscribedReport' &&
      this.$route.query.activeName !== 'subscribedReport' &&
      this.hasSubscribedReportsAccess
    ) {
      this.$router.push('/report/index?activeName=subscribedReport');
    } else if (
      this.activeName === 'reportTemplate' &&
      this.$route.query.activeName !== 'reportTemplate' &&
      this.hasReportTemplatesAccess
    ) {
      this.$router.push('/report/index?activeName=reportTemplate');
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = this.hasSubscribedReportsAccess
        ? 'subscribedReport'
        : 'reportTemplate';
      this.handleTabClick();
    }
  }

  async mounted() {
    this.hasSubscribedReportsAccess = await UserModule.hasMenu(
      'AUTHRSC_PAGE_SUBSCRIBED_REPORTS'
    );
    this.hasReportTemplatesAccess = await UserModule.hasMenu(
      'AUTHRSC_PAGE_REPORT_TEMPLATES'
    );

    this.showTabs();
  }
}
