
import { Component, Vue, Watch } from 'vue-property-decorator';
import NavCardPage from './components/NavCardPage.vue';
import SubscriptionManagementTable from './components/SubscriptionManagementTable.vue';
import { SUBSCRIPTION_MANAGEMENT_COLS } from '@/utils/workData/subscriptionManagement';
import { SUBSCRIPTION_MANAGEMENT_STATUS } from '@/utils/workData/lookuptable';
import SubsMgmtModal from './components/SubsMgmtModal.vue';
import {
  getSubscriptionManagement,
  renewSubscriptionManagement,
  terminateSubscriptionManagement,
} from '@/api/subscriptionManagement';
import { UserModule } from '@/store/modules/user';
import { customSuccessMessage, customFailedMessage } from '@/utils/prompt';
import moment from 'moment';

@Component({
  name: 'SubscriptionManagement',
  components: {
    NavCardPage,
    'subs-mgmt-table': SubscriptionManagementTable,
    'subs-managemet-modal': SubsMgmtModal,
  },
})
export default class extends Vue {
  /** Local variables */
  totalSubscriptions: number = 0;
  subscriptionManagementColumns: any = SUBSCRIPTION_MANAGEMENT_COLS;
  subscriptionStatuses: any = SUBSCRIPTION_MANAGEMENT_STATUS;
  columnSelectionForFiltering: string = '';
  searchInputValue: string = '';
  statusInputValue: string = '';
  tableData: any = [];
  isModalVisible: boolean = false;
  modalTitle: string = '';
  modalTriggerSource: string = '';
  modalContent: string = '';
  selectedSubscription: any = {};
  isSubscriptionManagementTableLoading: boolean = false;
  subscriptionDurationDays: any = '';
  loadingText: string = this.$t('subsMgmt.loadingSubscriptionData') as string;

  created() {
    this.fetchSubscriptionManagement();
    this.setPredefinedValues();
  }

  setPredefinedValues() {
    this.columnSelectionForFiltering = this.columnsForSearching[0].label;
  }

  @Watch('isModalVisible', { deep: true })
  handleModalClose() {
    if (!this.isModalVisible) {
      this.selectedSubscription = {};
      this.modalTriggerSource = '';
      this.modalTitle = '';
    }
  }

  /** Retrieve Subscription management orders */
  async fetchSubscriptionManagement(urlAddress?: string | null) {
    this.tableData = [];
    this.isSubscriptionManagementTableLoading = true;
    let customUrl: string = this.generateCustomUrlAddress();
    await getSubscriptionManagement(urlAddress ? urlAddress : customUrl).then(
      (res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.totalSubscriptions = res.data.total;
          this.tableData = res.data.assignments.map((item: any) => {
            item.assetSubscriptionPackage = this.$t(
              item.assetSubscriptionPackage
            );
            if (customUrl.indexOf('assetSubscriptionPackage') > -1) {
              item.assetSubscriptionPackage = `<span class="highlight-search-element">
                            ${this.$t(item.assetSubscriptionPackage)}
                        </span>`;
            } else if (customUrl.indexOf('subscriptionStatus') === -1) {
              item[this.columnSelectionForFiltering] = item[
                this.columnSelectionForFiltering
              ]
                ? item[this.columnSelectionForFiltering].replace(
                    new RegExp(this.searchInputValue, 'gi'),
                    (match: any) => {
                      return `<span class="highlight-search-element">${match}</span>`;
                    }
                  )
                : item[this.columnSelectionForFiltering];
            }
            item.subscriptionStartDate = moment(
              item.subscriptionStartDate
            ).format('YYYY/MM/DD');
            item.subscriptionEndDate = moment(item.subscriptionEndDate).format(
              'YYYY/MM/DD'
            );
            return item;
          });
        }

        this.isSubscriptionManagementTableLoading = false;
      }
    );
  }

  /** Handle renewing a subscription */
  async renewSubscription(subscriptionId: string) {
    await renewSubscriptionManagement(subscriptionId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        customSuccessMessage(
          this.$t('subsMgmt.subsRenewedSuccessfully') as string
        );
        this.fetchSubscriptionManagement();
      }
    });
  }

  /** Handle renewing a subscription */
  async terminateSubscription(subscriptionId: string) {
    await terminateSubscriptionManagement(subscriptionId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        customSuccessMessage(
          this.$t('subsMgmt.subsTerminatedSuccessfully') as string
        );
        this.fetchSubscriptionManagement();
      }
    });
  }

  /** Manage custom URL address for http request */
  generateCustomUrlAddress(page?: number) {
    let pageNumber = page ? page : 1;
    let baseUrl = '';
    let customUrl =
      baseUrl + '?page=' + pageNumber + '&size=' + UserModule.gridPageSize;

    if (
      this.columnSelectionForFiltering.length > 0 &&
      this.searchInputValue.length > 0
    ) {
      return (customUrl +=
        '&searchFieldName=' +
        this.columnSelectionForFiltering +
        '&searchFieldValue=' +
        this.searchInputValue);
    }

    if (
      this.columnSelectionForFiltering === 'subscriptionStatus' &&
      this.statusInputValue.length > 0
    ) {
      return (customUrl +=
        '&searchFieldName=' +
        this.columnSelectionForFiltering +
        '&searchFieldValue=' +
        this.statusInputValue);
    }

    return customUrl;
  }

  /** Handle page navigation */
  handlePagination(pageNumber: number) {
    let customUrl = this.generateCustomUrlAddress(pageNumber);
    this.fetchSubscriptionManagement(customUrl);
  }

  /** Handle table sorting */
  dealTableSort() {}

  /** Handle trigger from search column dropdown */
  handleSelectColumnSearch() {
    this.searchInputValue || this.statusInputValue
      ? this.fetchSubscriptionManagement(
          `?page=1&size=${UserModule.gridPageSize}`
        )
      : '';
    this.statusInputValue = '';
    this.searchInputValue = '';
  }

  /** Handle filtering process */
  handleFiltering() {
    this.fetchSubscriptionManagement();
  }

  /**
   * Display modal
   */
  showModal(action: string, selectedRowData: any) {
    this.modalTriggerSource = action;
    this.selectedSubscription = selectedRowData;

    if (this.selectedSubscription.renewedTimes === 0)
      this.subscriptionDurationDays = moment(
        selectedRowData.subscriptionEndDate
      ).diff(moment(selectedRowData.subscriptionStartDate), 'days');
    if (this.selectedSubscription.renewedTimes === 1)
      (this.subscriptionDurationDays =
        moment(selectedRowData.subscriptionEndDate).diff(
          moment(selectedRowData.subscriptionStartDate),
          'days'
        ) / 2).toFixed(0);
    if (this.selectedSubscription.renewedTimes > 1)
      (this.subscriptionDurationDays =
        moment(selectedRowData.subscriptionEndDate).diff(
          moment(selectedRowData.subscriptionStartDate),
          'days'
        ) /
        (this.selectedSubscription.renewedTimes + 1)).toFixed(0);
    let subscriptionExtendedPeriod = moment(selectedRowData.subscriptionEndDate)
      .add('days', this.subscriptionDurationDays)
      .format('YYYY/MM/DD');

    let dataForRenewOrder = {
      orderId: `<span class="highlight-modal-info">${selectedRowData.orderId}</span>`,
      extendDate: `<span class="highlight-modal-info">${subscriptionExtendedPeriod}</span>`,
    };

    let dataForTerminateOrder = {
      orderId: `<span class="highlight-modal-info">${selectedRowData.orderId}</span>`,
    };

    switch (action) {
      case 'renew': {
        this.modalTitle = this.$t('subsMgmt.renewOrder') as string;
        this.modalContent = this.$t(
          'subsMgmt.renewOrderModalMessage',
          dataForRenewOrder
        ) as string;
        break;
      }
      default: {
        this.modalTitle = this.$t('subsMgmt.terminateOrder') as string;
        this.modalContent = this.$t(
          'subsMgmt.terminateOrderModalMessage',
          dataForTerminateOrder
        ) as string;
        break;
      }
    }

    this.isModalVisible = true;
  }

  /**
   * Close modal
   */
  closeModal() {
    this.modalTitle = '';
    this.modalTriggerSource = '';
    this.isModalVisible = false;
  }

  /** Renew an order flow */
  handleRenewOrder() {
    this.renewSubscription(this.selectedSubscription.id);
    this.closeModal();
  }

  /** Terminate an order flow */
  handleTerminateOrder() {
    this.terminateSubscription(this.selectedSubscription.id);
    this.closeModal();
  }

  /** Defining Column to search for */
  columnsForSearching: any = [
    { label: 'orderId' },
    { label: 'cadId' },
    { label: 'companyName' },
    { label: 'organizationName' },
    { label: 'customerSubscriptionPackage' },
    { label: 'assetSubscriptionPackage' },
    { label: 'subscriptionStatus' },
  ];
}
