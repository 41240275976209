import { render, staticRenderFns } from "./RegisterAssetEdit.vue?vue&type=template&id=8ce909a2&scoped=true&"
import script from "./RegisterAssetEdit.vue?vue&type=script&lang=ts&"
export * from "./RegisterAssetEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ce909a2",
  null
  
)

export default component.exports