
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AssignTable from '../components/AssignTable.vue';
import { CONNECTED_ASSETS_COLS } from '@/utils/workData/assetsMgmt';

@Component({
  name: 'PrevAssignTable',
  components: {
    AssignTable,
  },
})
export default class extends Vue {
  @Prop({ default: [] }) selectedTableList!: any;
  @Prop({ default: '' }) chosenAssetType!: string;
  @Prop({ default: false }) errorTips!: boolean;

  cols = CONNECTED_ASSETS_COLS;

  mounted() {}
}
