
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { getTemplatesById, setDefaultTemplate } from '@/api/dashboardConf';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import ViewDashTemplate from '@/components/template/ViewDashTemplate.vue';
import { PageModule } from '@/store/modules/page';

@Component({
  name: 'ViewTemplate',
  components: {
    BaseCard,
    ViewDashTemplate,
  },
})
export default class extends Vue {
  @Prop() id!: string;
  @Ref('viewDashTemplate') viewDashTemplateRef!: ViewDashTemplate;

  isDefault: boolean = false;

  title: string = '';

  templateViewForm: any = {};

  async handleSetAsDefault() {
    await setDefaultTemplate(this.id, this.templateViewForm).then((res) => {
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.set') as string);
        this.$router.push('/dashboard-conf/index');
      } else if (res.code === 400) {
        promptFailedBox(this.$t('common.set') as string);
      }
    });
  }

  handleEdit() {
    this.$router.push(`/dashboard-conf/edit/${this.id}`);
  }

  async fetchTemplateById() {
    const res = await getTemplatesById(this.id);
    PageModule.setTitle(res.data.name);
    this.isDefault = res.data.isDefault;

    this.templateViewForm = res.data;

    this.viewDashTemplateRef.fetchModules(this.id, res.data.customerType);
  }

  mounted() {
    this.fetchTemplateById();
  }
}
