
import { Component, Prop, Vue } from 'vue-property-decorator';
import BarChart from '@/components/kpiCharts/BarChart.vue';
import KpiTable from '@/components/widget/kpiInfo/KpiTable.vue';
import moment from 'moment';

@Component({
  name: 'report',
  components: {
    BarChart,
    KpiTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) start!: Date;
  @Prop({ required: true }) end!: Date;
  @Prop({ default: () => [] }) dataRows!: unknown[];
  @Prop({ default: () => [] }) dataCols!: unknown[];
  @Prop({ default: () => [] }) kpis!: unknown[];
  @Prop({ default: () => [] }) charts!: unknown[];

  reportTime = moment(new Date()).format('DD/MM/YYYY hh:mm A');

  /**
   * Get range for generated report
   * End date should be substracted with one day to see exactly of what user was selected from time and date selection
   * - Towards API the end date is +1 day because we need the full day inclusive of the selection
   */
  get range(): string {
    const formattedStart = moment(this.start).format('DD/MM/YYYY');
    const formattedEnd = moment(this.end)
      .subtract(1, 'days')
      .format('DD/MM/YYYY');

    return `${formattedStart} - ${formattedEnd}`;
  }
}
