
import AssetHealthList from '@/components/health/AssetHealthList.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AssetHealthFactorList from '@/components/health/AssetHealthFactorList.vue';
import { AssetType, WarningType } from '@/utils/workData/lookuptable';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';

@Component({
  name: 'health-modal',
  components: {
    AssetHealthFactorList,
    AssetHealthList,
    DefaultDialog,
  },
})
export default class extends Vue {
  @Prop({ default: '1200px' }) popupWidth!: string;
  @Prop({ required: true }) modalVisable!: boolean;
  @Prop({ default: false }) hideFootBtns!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) assetType!: AssetType;
  @Prop({ required: true }) warningType!: WarningType;

  normalWarning = WarningType.Normal;
  minorWarning = WarningType.Warning;
  alarmWarning = WarningType.Alarm;

  selectedAsset: string | null = null;
  selectedAssetName: string = '';
  healthStatus = this.warningType;

  @Watch('warningType')
  onWarningChanged(val: WarningType, oldVal: WarningType) {
    this.healthStatus = val;
  }

  get visible() {
    return this.modalVisable;
  }

  set visible(val) {
    this.$emit('updateVisible', val);
  }

  changeHealthStatus(status: any) {
    this.healthStatus = status;
  }

  created() {}

  mounted() {}

  handleModalCancel() {
    this.visible = false;
  }

  assetSelected(assetId: string, assetName: string) {
    this.selectedAssetName = assetName;
    this.selectedAsset = assetId;
  }

  navigateToAssetsPage() {
    let assetTypeUrl = null;
    switch (this.assetType) {
      case AssetType.MobileCompactor:
        assetTypeUrl = 'mobile-compactors';
        break;

      case AssetType.StaticCompactor:
        assetTypeUrl = 'static-compactors';
        break;

      case AssetType.AlbaStaticCompactor:
        assetTypeUrl = 'alba-static-compactors';
        break;

      case AssetType.TippingVehicle:
        assetTypeUrl = 'tipping-vehicles';
        break;

      case AssetType.TableTopTissector:
        assetTypeUrl = 'table-top-tissectors';
        break;
    }

    if (assetTypeUrl === null) return;
    this.$router.push(`/assets/${assetTypeUrl}/${this.selectedAsset}`);
  }
}
