
import { AssetObjectModel, getAssetById, getKpiData } from '@/api/assets';
import { getKpisForMultipleAssets } from '@/api/assetsAll';
import { CommonResult } from '@/api/commonResult';
import {
  getHealthStatusFactorDefinitions,
  HealthFactorDefinitionResponse,
} from '@/api/healthStatus';
import { getProductModelById, ProductModel } from '@/api/products';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import AssetHealthFactorList from '@/components/health/AssetHealthFactorList.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  ActiveContext,
  LoggedInUserContext,
  LoggedInUserRef,
  useActiveContext,
  useLoggedInUser,
} from '@/composables/context';
import MobileCompactor from '@/icons/svg/mobileCompactor.svg';
import StaticCompactor from '@/icons/svg/staticCompactor.svg';
import TippingVehicle from '@/icons/svg/tippingVehicle.svg';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { findAssetKpiDataValue, findKpiValue } from '@/utils/assets';
import { formatTimer } from '@/utils/misc';
import {
  AssetType,
  COMPANY_TYPE,
  EntityType,
  gpsStatusColorMapping,
  KPI_FIELDS,
  OperationalStatus,
  operationalStatusColorMapping,
  SYSTEM_FEATURES,
  WarningType,
  WARNING_TO_HEALTH_STATUS_COLOR,
} from '@/utils/workData/lookuptable';
import BaseInfos from '@/views/assets/components/BaseInfos.vue';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import {
  getMaintenanceStatusOverview,
  MaintenanceOverview,
  MaintStatus,
} from '../../../api/maintenance';

interface kpiResponse {
  code: string | null;
  v: string | null;
}

const warningOrder = [
  WarningType.Alarm,
  WarningType.Warning,
  WarningType.Normal,
];

const emptyProductModel: ProductModel = {
  id: '',
  code: '',
  modelNumber: '',
  assetTypeId: '',
  assetTypeCode: '',
  lifeCycle: '',
  numberOfParts: '',
  picturePath: '',
  properties: [],
  containedParts: [],
};

@Component({
  name: 'AssetBasicInfo',
  components: {
    WidgetCard,
    BaseInfos,
    DefaultDialog,
    AssetHealthFactorList,
  },
})
export default class extends Vue {
  /** Local variables */
  assetInfos: any = {};
  operationalStatus: string = '';
  isHealthModalVisible: boolean = false;
  healthStatusData: HealthFactorDefinitionResponse[] = [];
  systemReleaseVersion: string | null = '';
  isNewSystemReleaseAvailable: boolean = false;
  isDeployingNewRelease: boolean = false;
  lastCommunicationTime: string | undefined = undefined;
  gpsAccuracy: string | undefined = undefined;
  unknownValue: string = this.$t('unknownValue') as string;
  isLoading: boolean = true;
  serviceStatusIsLoading: boolean = false;
  hasHealthFeature: boolean = false;
  context!: Ref<ActiveContext>;
  productModel: ProductModel = { ...emptyProductModel };
  loggedInUser!: LoggedInUserRef;
  currentUserContext: Readonly<LoggedInUserContext | undefined>;
  customerCanImpersonate: boolean = false;
  companyTypesWithImpersonation: string[] = [
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
    COMPANY_TYPE.Helpdesk,
  ];
  serviceStatus: MaintStatus = MaintStatus.OK;

  async created() {
    this.loggedInUser = useLoggedInUser();
    this.currentUserContext = unref(this.loggedInUser);
    this.customerCanImpersonate = this.companyTypesWithImpersonation.includes(
      UserModule.companyType
    );

    this.isLoading = true;
    this.serviceStatusIsLoading = true;
    this.context = useActiveContext();
    await this.loadAssetData();
    await this.getServiceStatusData();
    await this.prepareDefaultBehavior();
  }

  async prepareDefaultBehavior(): Promise<void> {
    this.hasHealthFeature = await UserModule.hasSystemFeature([
      SYSTEM_FEATURES.HealthScore,
      this.assetInfos.assetType,
    ]);
    if (this.hasHealthFeature) {
      await this.getHealthStatusData();
    }
  }

  formatTime(value: string | undefined, includeTime: boolean = false) {
    if (!value) {
      return undefined;
    }

    return formatTimer(value, includeTime ? 'datetime' : 'date');
  }

  /**
   * Show GPS fix field in the UI
   */
  get showGpsFix(): boolean {
    return (
      this.assetInfos.assetType != AssetType.StaticCompactor &&
      this.assetInfos.assetType != AssetType.TableTopTissector
    );
  }

  get statusClass() {
    switch (this.assetInfos.status) {
      case OperationalStatus.Active:
        return 'status status-yellow';
      case OperationalStatus.Parked:
        return 'status status-red0';
      case OperationalStatus.Installed:
        return 'status status-red';
      case OperationalStatus.Maintenance:
        return 'status status-red1';
    }
  }

  get serviceClass() {
    switch (this.serviceStatus) {
      case MaintStatus.DUE:
        return 'status status-red';
      case MaintStatus.OK:
        return 'status status-green';
      case MaintStatus.CLOSE:
        return 'status status-orange';
    }
  }

  get operationalStatusStyle() {
    let color = operationalStatusColorMapping.get(this.operationalStatus);
    if (!color) return undefined;
    return { backgroundColor: color };
  }

  get gpsConnectStyle() {
    let color = gpsStatusColorMapping.get(this.gpsAccuracy as string);
    if (!color) return undefined;
    return { backgroundColor: color };
  }

  get backgroundColorFromHealthStatus() {
    return this.healthStatusData[0]
      ? WARNING_TO_HEALTH_STATUS_COLOR[
          this.healthStatusData[0].healthCondition!
        ]
      : '#be442d';
  }

  get lastCommunicationTimeFormatted() {
    return (
      this.formatTime(this.lastCommunicationTime, true) || this.unknownValue
    );
  }

  async getHealthStatusData(): Promise<void> {
    try {
      const res = await getHealthStatusFactorDefinitions(
        this.$route.params.id,
        unref(this.context),
        UserModule.i18nCode
      );
      if (res && res.code === 200) {
        res.data.forEach(
          (entry: any) =>
            (entry.lastChangeTimestamp = moment
              .utc(entry.lastChangeTimestamp)
              .format('YYYY/MM/DD HH:mm:ss'))
        );
        res.data.sort(
          (a: any, b: any) =>
            warningOrder.indexOf(a.healthCondition) -
            warningOrder.indexOf(b.healthCondition)
        );
        this.healthStatusData = res.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  maintenanceStatusOrder = [MaintStatus.OK, MaintStatus.CLOSE, MaintStatus.DUE];
  async getServiceStatusData(): Promise<void> {
    try {
      const res = await getMaintenanceStatusOverview({
        filters: [
          {
            name: 'assetId',
            operator: FilterOperator.IN,
            value: [this.assetInfos.id],
          },
        ],
      });

      if (res.code === 200) {
        const maintenanceStatusOverview: MaintenanceOverview[] = res.data;

        this.serviceStatus = this.maintenanceStatusOrder.reduce(
          (returnStatus, status) => {
            if (
              maintenanceStatusOverview.some(
                (overview) =>
                  overview.maintenanceStatus === status &&
                  overview.numberOfMaintenancePlans > 0
              )
            ) {
              returnStatus = status;
            }
            return returnStatus;
          },
          MaintStatus.OK
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.serviceStatusIsLoading = false;
    }
  }

  openHealthModal(): void {
    this.isHealthModalVisible = true;
  }

  handleModalCancel(): void {
    this.isHealthModalVisible = false;
  }

  async loadAssetData(): Promise<void> {
    try {
      const assetId: string = this.$route.params.id;
      const assetByIdResponse = await getAssetById(
        assetId,
        unref(this.context)
      );

      await Promise.all([
        this.loadProductModelById(assetByIdResponse.data?.productModelId),
        this.loadAssetKpiData(assetId, assetByIdResponse),
        this.loadKpiData(assetId),
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadProductModelById(productModelId: string): Promise<void> {
    try {
      const response = await getProductModelById(productModelId);
      this.productModel = response.data;
    } catch (error) {
      console.log(error);
      this.productModel = { ...emptyProductModel };
    }
  }

  private async loadAssetKpiData(
    assetId: string,
    assetByIdResponse: CommonResult<AssetObjectModel>
  ) {
    const requestPayload = {
      metadata: { filter: { assetIds: [assetId] } },
      details: [
        {
          fields: [
            { code: KPI_FIELDS.OperationalStatus },
            { code: KPI_FIELDS.LastCommunicationTime },
          ] as { code: typeof KPI_FIELDS[keyof typeof KPI_FIELDS] }[],
        },
      ],
    };
    if (assetByIdResponse.data?.assetType != AssetType.StaticCompactor) {
      requestPayload.details[0].fields.push({
        code: KPI_FIELDS.GpsStatus,
      });
    }

    const res = await getKpisForMultipleAssets(
      requestPayload,
      unref(this.context)
    );

    if (assetByIdResponse && assetByIdResponse.code === 200) {
      this.assetInfos = assetByIdResponse.data;
      this.operationalStatus = findKpiValue(
        res.data,
        assetId,
        KPI_FIELDS.OperationalStatus
      );
      this.lastCommunicationTime =
        findKpiValue(res.data, assetId, KPI_FIELDS.LastCommunicationTime) ??
        undefined;
      this.gpsAccuracy =
        findKpiValue(res.data, assetId, KPI_FIELDS.GpsStatus) ?? undefined;
    }
  }

  private async loadKpiData(assetId: string) {
    const res = await getKpiData(
      {
        metadata: { filter: { assetId: assetId } },
        details: [
          {
            entity: EntityType.ASSET,
            fields: [
              { code: KPI_FIELDS.SystemReleaseVersion },
              { code: KPI_FIELDS.NewSystemReleaseAvailable },
              { code: KPI_FIELDS.DeployingNewRelease },
            ],
          },
        ],
      },
      unref(this.context)
    );

    this.systemReleaseVersion =
      findAssetKpiDataValue(res.data, KPI_FIELDS.SystemReleaseVersion) ?? null;
    this.isNewSystemReleaseAvailable =
      findAssetKpiDataValue(res.data, KPI_FIELDS.NewSystemReleaseAvailable) ??
      false;
    this.isDeployingNewRelease =
      findAssetKpiDataValue(res.data, KPI_FIELDS.DeployingNewRelease) ?? false;
  }

  /**
   * Slim down the larger asset ids
   * @param text
   * @param max
   */
  truncateTextToMax(text: string, max: number): string {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
  }

  /**
   * Handle product model picture
   */
  get pictureUrlSource(): string {
    if (this.productModel && this.productModel?.picturePath) {
      return this.productModel?.picturePath;
    }
    let tempSvg;
    switch (this.assetInfos?.assetType) {
      case AssetType.MobileCompactor: {
        tempSvg = MobileCompactor;
        break;
      }
      case AssetType.StaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.AlbaStaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.TippingVehicle: {
        tempSvg = TippingVehicle;
        break;
      }
      case AssetType.TableTopTissector: {
        tempSvg = StaticCompactor; // TODO Update to correct picture
        break;
      }
    }
    return tempSvg;
  }
}
