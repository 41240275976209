import { render, staticRenderFns } from "./CreateNewRole.vue?vue&type=template&id=44372a48&scoped=true&"
import script from "./CreateNewRole.vue?vue&type=script&lang=ts&"
export * from "./CreateNewRole.vue?vue&type=script&lang=ts&"
import style0 from "./CreateNewRole.vue?vue&type=style&index=0&id=44372a48&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44372a48",
  null
  
)

export default component.exports