
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import PureTable from '@/components/table/PureTable.vue';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { HEALTH_MANAGEMENT_COLS } from '@/utils/workData/healthMgmt';
import {
  HealthSearchType,
  EnabledStatus,
  AssetTypes,
} from '@/utils/types/health';
import {
  getHealthFactorDefinitions,
  putHealthFactorDefinitions,
} from '@/api/healthStatus';
import {
  GENERAL_QUERY_OPERATORS,
  UserRole,
} from '@/utils/workData/lookuptable';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import Debouncer from '@/utils/debounce';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';

@Component({
  name: 'healthMgmt',
  components: {
    'base-card': BaseCard,
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = HEALTH_MANAGEMENT_COLS;
  pageTotal = UserModule.gridPageSize;
  userRole = UserModule.role;
  data: any[] = [];
  isUserAdmin: boolean = false;

  firstLoad = true; // true until created => getData() is finished
  editing = false;

  searchFieldTypes: HealthSearchType[] = Object.values(HealthSearchType);
  searchField: { name: HealthSearchType; value: string } = {
    name: this.searchFieldTypes[0],
    value: '',
  };

  validSearchValues: string[] | null = null;
  queryOperator: GENERAL_QUERY_OPERATORS = GENERAL_QUERY_OPERATORS.Like;
  isHealthFactorTableLoading: boolean = false;

  created() {
    this.getData();

    // only set firstLoad to false after all data has been collected
    // otherwise the form will create a rerender and extra API calls
    this.firstLoad = false;
    this.isUserAdmin = this.userRole === UserRole.HyvaAdmin;
  }

  /**
   * Parse filter
   */
  parseFilter() {
    let selectedFilters: any = {
      filters: [],
      sorters: [
        {
          field: 'assetType',
          order: SorterOrder.ASC,
        },
      ],
    };

    // search field
    if (this.searchField.value !== '') {
      selectedFilters.filters.push({
        name: this.searchField.name,
        operator: this.queryOperator,
        value: [`${this.searchField.value}`],
      });
    }

    return selectedFilters;
  }

  /**
   * Handle get data remotly from the API
   */
  async getData(): Promise<void> {
    try {
      this.isHealthFactorTableLoading = true;
      const res = await getHealthFactorDefinitions(this.parseFilter());
      this.data = res.data.map((entry: any) => {
        return {
          id: entry.id,
          healthFactor: entry.name,
          factorType: this.$t(
            `healthManagementModule.${
              entry.errorFlag ? 'error' : 'alarmSetting'
            }`
          ),
          assetType: entry.assetType,
          ifEnable: entry.enabled,
          normalCondition: entry?.limitNormal || entry?.flagNormal || '',
          warningCondition:
            [entry?.limitWarningHigh, entry?.limitWarningLow]
              .filter((val) => val)
              .join(' / ') || entry?.flagWarning,
          alarmCondition:
            [entry?.limitAlarmHigh, entry?.limitAlarmLow]
              .filter((val) => val)
              .join(' / ') || entry?.flagAlarm,
          editable: false,
        };
      });
      this.data.forEach((entry: any) => (entry.editable = this.editing));
    } catch (error) {
      console.log(error);
    } finally {
      this.isHealthFactorTableLoading = false;
    }
  }

  /**
   * Handle save event
   */
  async save(): Promise<void> {
    let send = this.data.map((entry: any) => {
      return {
        id: entry.id,
        isEnabled: entry.ifEnable,
      };
    });
    const updateResult = await putHealthFactorDefinitions(send);
    if (updateResult.code == 200) {
      customSuccessMessage(
        this.$t('healthManagementModule.saveSucessful').toString()
      );
    } else {
      customFailedMessage(
        this.$t('healthManagementModule.errorOccured').toString()
      );
    }
  }

  /**
   * Switch from read only to edit mode
   */
  toggleEditFactor(): void {
    this.editing && this.save();
    this.editing = !this.editing;
    this.data.forEach((entry: any) => (entry.editable = !entry.editable));
  }

  // search field name changed (e.g. Organisation)
  handleSearchFieldFilter(): void {
    let oldValidSearchValues = this.validSearchValues;
    switch (this.searchField.name) {
      case HealthSearchType.ifEnable:
        this.queryOperator = GENERAL_QUERY_OPERATORS.Equal;
        this.validSearchValues = Object.values(EnabledStatus);
        break;

      case HealthSearchType.assetType:
        this.queryOperator = GENERAL_QUERY_OPERATORS.Equal;
        this.validSearchValues = Object.values(AssetTypes);
        break;

      default:
        this.queryOperator = GENERAL_QUERY_OPERATORS.Like;
        this.validSearchValues = null;
    }

    if (
      this.validSearchValues == null ||
      oldValidSearchValues == null ||
      this.validSearchValues.join() != oldValidSearchValues.join()
    ) {
      this.searchField.value = '';
    }

    this.handleSearchFieldForm();
  }

  /**
   * Handle search field event
   */
  handleSearchFieldForm(): void {
    if (this.searchField.value.length === 0 && !this.firstLoad) {
      this.getData();
    }
    if (this.searchField.value.length > 2) {
      Debouncer.debounce(this.getData);
    }
  }
}
