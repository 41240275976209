
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';
import { ALARM_CONFIG_TIME_UNITS_list } from '@/utils/workData/lookuptable';
import { Kpi } from '../models/kpi.model';
import { customWarningMessage } from '@/utils/prompt';
import { InvalidAlarm } from '../models/invalidAlarm';
import { AnyKpiLimit } from '@/api/alarmConfig';

@Component({
  name: 'AlarmConfigTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: AnyKpiLimit[];
  @Prop({ default: true }) showPage!: boolean;
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() viewPagePath!: string;
  @Prop() actionTable!: string;
  @Prop() maxHeight!: string;
  @Prop() warningMessageListAndLastCalled!: {
    time: number;
    list: string[];
  }[];

  /** Local variables */
  list: AnyKpiLimit[] = [...this.tableList];
  tableComponentKey: string = new Date().toString();
  dropCols: any = [];
  timeUnitList: any = ALARM_CONFIG_TIME_UNITS_list;
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  routesWithEditModelDisabled: any = [
    'alarmConfigurationViewPage',
    'Administration',
  ];

  changedAlarms: Kpi[] = [];
  invalidAlarms: InvalidAlarm[] = [];
  inputWarningThresholdClass: string = 'input-warning-threshold';
  inputAlarmThresholdClass: string = 'input-alarm-threshold';
  inputWarningIntervalClass: string = 'input-warning-interval';
  inputAlarmIntervalClass: string = 'input-alarm-interval';
  isInvalidAlarmAndWarning: boolean = false;
  invalidColor: string = '#EA8827';
  invalidTextColor: string = `color: ${this.invalidColor}`;
  invalidBorder: string = `border: 1px solid ${this.invalidColor}`;
  isAlreadyWarningMessage: boolean = false;
  maxValueForKg: number = 999;
  maxValueForTonnes: number = 999.0;

  @Watch('tableList')
  onListChange() {
    this.list = this.tableList ? [...this.tableList] : [];
  }

  rowChanged(currentRow: Kpi, event: any, showWarningMessage?: boolean): void {
    let findRow = this.changedAlarms.find((row) => row.id == currentRow.id);
    if (!findRow) {
      this.changedAlarms.push(currentRow);
    }

    this.validateRow(currentRow, event, showWarningMessage);
  }

  validateRow(currentRow: Kpi, event: any, showWarningMessage?: boolean): void {
    const {
      isInvalid: missingWarningInterval,
      isInvalidWithoutTreshold: warningIntervalWithoutWarningTreshold,
    } = this.isInvalidInterval(
      currentRow.warningIntervalValue,
      currentRow.warningIntervalUnit,
      this.inputWarningIntervalClass,
      currentRow.id,
      !!currentRow.warningLow || !!currentRow.warningHigh
    );
    const {
      isInvalid: missingAlarmInterval,
      isInvalidWithoutTreshold: alarmIntervalWithoutAlarmTreshold,
    } = this.isInvalidInterval(
      currentRow.alarmIntervalValue,
      currentRow.alarmIntervalUnit,
      this.inputAlarmIntervalClass,
      currentRow.id,
      !!currentRow.alarmLow || !!currentRow.alarmHigh
    );

    let isInvalidWarningThreshold: boolean = false;
    let isInvalidAlarmThreshold: boolean = false;

    if (currentRow.warningLow && currentRow.warningHigh) {
      isInvalidWarningThreshold = this.checkIsInvalidThreshold(
        currentRow.warningLow,
        currentRow.warningHigh,
        event,
        this.inputWarningThresholdClass,
        currentRow.id
      );
    } else if (!this.isInvalidAlarmAndWarning) {
      this.removeValidateThresholdBorder(
        this.inputWarningThresholdClass,
        currentRow.id
      );
    }

    if (currentRow.alarmLow && currentRow.alarmHigh) {
      isInvalidAlarmThreshold = this.checkIsInvalidThreshold(
        currentRow.alarmLow,
        currentRow.alarmHigh,
        event,
        this.inputAlarmThresholdClass,
        currentRow.id
      );
    } else if (!this.isInvalidAlarmAndWarning) {
      this.removeValidateThresholdBorder(
        this.inputAlarmThresholdClass,
        currentRow.id
      );
    }

    const invalidThresholdValues: boolean =
      isInvalidWarningThreshold || isInvalidAlarmThreshold;
    if (
      currentRow.warningLow ||
      currentRow.warningHigh ||
      currentRow.alarmLow ||
      currentRow.alarmHigh
    ) {
      this.isInvalidAlarmAndWarning =
        this.checkIfInvalidAlarmsLimitsWithWarningValues(
          currentRow,
          event,
          invalidThresholdValues
        );
    } else {
      this.isInvalidAlarmAndWarning = false;
    }

    if (
      missingWarningInterval ||
      missingAlarmInterval ||
      warningIntervalWithoutWarningTreshold ||
      alarmIntervalWithoutAlarmTreshold ||
      invalidThresholdValues ||
      this.isInvalidAlarmAndWarning
    ) {
      const invalidAlarm: InvalidAlarm = {
        id: currentRow.id,
        missingWarningInterval,
        missingAlarmInterval,
        warningIntervalWithoutWarningTreshold,
        alarmIntervalWithoutAlarmTreshold,
        isInvalidWarningThreshold,
        isInvalidAlarmThreshold,
        isInvalidAlarmAndWarning: this.isInvalidAlarmAndWarning,
      };

      this.addInvalidAlarm(invalidAlarm);

      if (
        showWarningMessage &&
        (invalidThresholdValues || this.isInvalidAlarmAndWarning)
      ) {
        const warningValidateMessage: string[] = [];

        if (invalidThresholdValues) {
          warningValidateMessage.push(
            this.$t('alarmConfig.thresholdErrorMessage').toString()
          );
        }

        if (this.isInvalidAlarmAndWarning) {
          warningValidateMessage.push(
            this.$t('alarmConfig.thresholdsRangeErrorMessage').toString()
          );
        }

        if (!!this.warningMessageListAndLastCalled.length) {
          this.warningMessageListAndLastCalled.pop();
        }
        this.warningMessageListAndLastCalled.push({
          time: Date.now(),
          list: warningValidateMessage,
        });

        customWarningMessage(warningValidateMessage.join(' '));
      }
    } else {
      this.removeFromArray(currentRow.id, this.invalidAlarms);
      this.removeValidateThresholdBorder(
        this.inputWarningThresholdClass,
        currentRow.id
      );
      this.removeValidateThresholdBorder(
        this.inputAlarmThresholdClass,
        currentRow.id
      );
    }
  }

  checkIfInvalidAlarmsLimitsWithWarningValues(
    currentRow: Kpi,
    event: any,
    isThresholdInvalid: boolean
  ) {
    let isInvalid: boolean = false;

    const warningLow = parseInt(currentRow.warningLow);
    const warningHigh = parseInt(currentRow.warningHigh);

    const alarmLow = parseInt(currentRow.alarmLow);
    const alarmHigh = parseInt(currentRow.alarmHigh);

    if (
      !isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmLow >= warningLow ||
        alarmHigh <= warningHigh ||
        alarmHigh <= warningLow ||
        alarmLow >= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmHigh <= warningHigh || alarmLow >= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmHigh <= warningLow || alarmLow >= warningLow)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmHigh <= warningLow || alarmHigh <= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      isNaN(alarmHigh) &&
      (alarmLow >= warningLow || alarmLow >= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      isNaN(alarmHigh) &&
      alarmLow >= warningLow
    ) {
      isInvalid = true;
    } else if (
      isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      alarmHigh <= warningHigh
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      isNaN(warningHigh) &&
      isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      alarmHigh <= warningLow
    ) {
      isInvalid = true;
    } else if (
      isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      isNaN(alarmHigh) &&
      alarmLow >= warningHigh
    ) {
      isInvalid = true;
    }

    if (!!event) {
      const cssClass: string = event.target.classList.contains(
        this.inputWarningThresholdClass
      )
        ? this.inputWarningThresholdClass
        : this.inputAlarmThresholdClass;

      if (isInvalid) {
        event.target.setAttribute(
          'style',
          `${this.invalidTextColor}; ${this.invalidBorder};`
        );
      } else if (!isThresholdInvalid) {
        this.removeValidateThresholdBorder(cssClass, currentRow.id);
      }
    }

    return isInvalid;
  }

  isInvalidInterval(
    intervalValue: string,
    unit: string,
    inputClassName: string,
    rowId: string,
    isLowOrHigh: boolean
  ): {
    isInvalid: boolean;
    isInvalidWithoutTreshold: boolean;
  } {
    const isIntervalValueInvalid: boolean =
      !intervalValue && (isLowOrHigh || !!unit);
    const isUnitInvalid: boolean = !unit && (isLowOrHigh || !!intervalValue);

    const isIntervalValueInvalidWithoutTreshold: boolean =
      (isLowOrHigh && !intervalValue) || (!isLowOrHigh && !!intervalValue);
    const isUnitInvalidWithoutTreshold: boolean =
      (isLowOrHigh && !unit) || (!isLowOrHigh && !!unit);

    const intervalValueInput: Element = document.getElementsByClassName(
      `${inputClassName}-value ${rowId}`
    )[0];
    const intervalUnitInput = document.getElementsByClassName(
      `${inputClassName}-unit ${rowId}`
    )[0].firstElementChild?.firstElementChild;

    if (isIntervalValueInvalid || isIntervalValueInvalidWithoutTreshold) {
      intervalValueInput.setAttribute(
        'style',
        `${this.invalidTextColor}; ${this.invalidBorder};`
      );
    }

    if (isUnitInvalid || isUnitInvalidWithoutTreshold) {
      intervalUnitInput?.setAttribute(
        'style',
        `${this.invalidBorder} !important;`
      );
    }

    if (!isUnitInvalid && !isUnitInvalidWithoutTreshold) {
      intervalUnitInput?.removeAttribute('style');
    }

    if (!isIntervalValueInvalid && !isIntervalValueInvalidWithoutTreshold) {
      intervalValueInput.removeAttribute('style');
    }

    return {
      isInvalid: isIntervalValueInvalid || isUnitInvalid,
      isInvalidWithoutTreshold:
        isIntervalValueInvalidWithoutTreshold || isUnitInvalidWithoutTreshold,
    };
  }

  removeIntervalValidationsBorder(inputClassName: string, rowId: string) {
    const intervalValueInput: Element = document.getElementsByClassName(
      `${inputClassName}-value ${rowId}`
    )[0];
    const intervalUnitInput = document.getElementsByClassName(
      `${inputClassName}-unit ${rowId}`
    )[0].firstElementChild?.firstElementChild;

    intervalValueInput.removeAttribute('style');
    intervalUnitInput?.removeAttribute('style');
  }

  checkIsInvalidThreshold(
    low: string,
    high: string,
    event: any,
    inputClassName: string,
    rowId: string
  ): boolean {
    const isValid: boolean = this.isValidLowerWithHigherValue(low, high);

    if (!isValid) {
      if (!!event) {
        event.target.setAttribute(
          'style',
          `${this.invalidTextColor}; ${this.invalidBorder};`
        );
      }

      return true;
    } else if (!this.isInvalidAlarmAndWarning) {
      this.removeValidateThresholdBorder(inputClassName, rowId);
    }

    return false;
  }

  removeValidateThresholdBorder(inputClassName: string, rowId: string) {
    const warningsThresholdInputs: HTMLCollectionOf<Element> =
      document.getElementsByClassName(`${inputClassName} ${rowId}`);
    for (let i = 0; i < warningsThresholdInputs.length; i++) {
      warningsThresholdInputs[i].removeAttribute('style');
    }
  }

  addInvalidAlarm(invalidAlarm: InvalidAlarm) {
    const findRow = this.invalidAlarms.find((i) => i.id == invalidAlarm.id);
    if (!findRow) {
      this.invalidAlarms.push(invalidAlarm);
    } else {
      findRow.missingWarningInterval = invalidAlarm.missingWarningInterval;
      findRow.missingAlarmInterval = invalidAlarm.missingAlarmInterval;
      findRow.isInvalidWarningThreshold =
        invalidAlarm.isInvalidWarningThreshold;
      findRow.isInvalidAlarmThreshold = invalidAlarm.isInvalidAlarmThreshold;
      findRow.isInvalidAlarmAndWarning = invalidAlarm.isInvalidAlarmAndWarning;
    }
  }

  removeFromArray(id: string, arr: any[]) {
    const findRowIndex = arr.findIndex((i) => i.id == id);
    if (findRowIndex >= 0) {
      arr.splice(findRowIndex, 1);
    }
  }

  isValidLowerWithHigherValue(lowValue: string, highValue: string): boolean {
    return parseInt(lowValue) < parseInt(highValue);
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  mounted() {
    this.columnDrop();
  }

  /**
    all the action buttons call the same function, the event name depends on the item param
    * @param item
    * @param row
    */
  handleActionBtnClick(item: string, row: any) {
    this.$emit(item, row);
  }

  handleRowClick(row: any, column: any, event: any) {
    if (
      row.hasOwnProperty('gatewayId') &&
      row.gatewayId &&
      !row.hasOwnProperty('installTime')
    ) {
      this.$router.push('register-connecting/view/' + row.id);
      return;
    }

    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + '/' + row.id);
    }
  }

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  columnDrop() {
    var that = this;
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        let oldMoveItem = this.dropCols[evt.oldIndex];
        let newMoveItem = this.dropCols[evt.newIndex];

        let oldItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === oldMoveItem.prop;
        });
        let newItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === newMoveItem.prop;
        });
        this.cols.splice(newItemIndex, 0, this.cols.splice(oldItemIndex, 1)[0]);
      },
    });
  }

  get checkIfAlarmConfigIsInEditMode(): boolean {
    return !this.routesWithEditModelDisabled.includes(this.$route.name);
  }

  /** Validate input values to be only number + delete key */
  acceptOnlyNumericValues(event: KeyboardEvent) {
    const acceptedFloatChar: string[] = ['.'];
    const acceptedDeleteKeys: string[] = ['Backspace', 'Delete'];
    if (
      (acceptedDeleteKeys.indexOf(event.key) > -1 ||
        acceptedFloatChar.indexOf(event.key)) > -1 &&
      (event.target as HTMLInputElement).value.length > 0
    ) {
      return;
    }

    const regexp = new RegExp(/[0-9]/);
    if (
      !regexp.test(event.key) ||
      parseInt((event.target as HTMLInputElement).value) > 999 ||
      (event.target as HTMLInputElement).value.length > 2
    ) {
      event.preventDefault();
    }
  }
}
