var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BaseCard,{ref:"baseCardRef",staticClass:"maintenance-planner-main-container",attrs:{"title":_vm.$t('AUTHRSC_PAGE_MAINTEN_PLANNER'),"backIconVisible":false,"showDialogBeforeBack":false},on:{"handle-back":false}},[(_setup.maintenanceFilterItems.length === 0)?_c('div',{staticClass:"no-assets"},[_c('span',[_vm._v(_vm._s(_vm.$tc('assetMgmt.noAssetsAvailableInThisOrg')))])]):_c('div',{staticClass:"maintenance-planner-container"},[_c('div',{staticClass:"add-new-maintenance-btn-container",staticStyle:{"display":"none"}},[_c('el-button',{staticClass:"add-new-maintenance-btn",attrs:{"id":"addnewmplanbtn","type":"plain"},on:{"click":function($event){return _setup.showMaintenanceDialog(_setup.MaintenanceModalTriggerSource.New)}}},[_c('i',{staticClass:"el-icon-plus common-icon"}),_vm._v(_vm._s(_vm.$t('maintenance.maintenancePlanner.addNewMaintenanceAppointment'))+" ")])],1),_c('div',{staticClass:"maintenance-calendar-body"},[_c('div',{staticClass:"maintenance-calendar"},[_c('div',{staticClass:"maintenance-calendar-left"},[_c('div',{staticClass:"maintenance-items-label"},[_vm._v(" "+_vm._s(_vm.$t('maintenance.items'))+": ")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.maintenanceFilterItems.length === 0),expression:"maintenanceFilterItems.length === 0"}],staticClass:"maintenance-items"},[_c(_setup.MultiAssetTypeSelect,{attrs:{"assetTypeList":_setup.assetTypeList,"error":_setup.selectedAssetTypeCodes?.length === 0
                  ? 'ApiFieldAssetTypeCode'
                  : undefined},on:{"filterData":_setup.filterDataAssetType}}),_c(_setup.MultiProductModelSelect,{directives:[{name:"show",rawName:"v-show",value:(
                _setup.assetTypeList.data &&
                _setup.assetTypeList.data.every((at) => !at.selected)
              ),expression:"\n                assetTypeList.data &&\n                assetTypeList.data.every((at) => !at.selected)\n              "}],attrs:{"productModelList":{
                data: undefined,
                loading: false,
                error: undefined,
              },"disabled":_setup.assetTypeList.data === undefined ||
                _setup.selectedAssetTypeCodes?.length === 0},on:{"filterData":_setup.filterDataProductModel}}),_c(_setup.MultiProductModelSelect,{directives:[{name:"show",rawName:"v-show",value:(
                _setup.assetTypeList.data &&
                _setup.assetTypeList.data.some((at) => !!at.selected)
              ),expression:"\n                assetTypeList.data &&\n                assetTypeList.data.some((at) => !!at.selected)\n              "}],attrs:{"productModelList":_setup.productModelList,"disabled":_setup.assetTypeList.data === undefined ||
                _setup.selectedAssetTypeCodes?.length === 0},on:{"filterData":_setup.filterDataProductModel}}),_c('div',{staticClass:"maintenance-item-check-boxes"},[(
                  _setup.selectedProductModelIds &&
                  _setup.selectedProductModelIds.length > 0 &&
                  _setup.selectedAssetTypeCodes &&
                  _setup.selectedAssetTypeCodes.length > 0 &&
                  !_setup.maintenanceItemList.loading &&
                  !_setup.maintenanceItemList.error &&
                  _setup.maintenanceItemList.data &&
                  _setup.maintenanceItemList.data.length > 0
                )?_c('div',{staticClass:"maintenance-item-check-boxes-container"},_vm._l((_setup.maintenanceItemList.data),function(maintenanceItem){return _c('el-checkbox',{key:maintenanceItem.id,attrs:{"label":maintenanceItem.name},model:{value:(maintenanceItem.selected),callback:function ($$v) {_vm.$set(maintenanceItem, "selected", $$v)},expression:"maintenanceItem.selected"}},[_vm._v(" "+_vm._s(maintenanceItem.name)+" ")])}),1):_c('div',[_vm._v(" "+_vm._s(_vm.$tc('common.noData'))+" ")]),(_setup.maintenanceItemList.error)?_c('div',[_vm._v(" "+_vm._s(_vm.$tc('common.errorWithFetchingData'))+" ")]):_vm._e()])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(
              _setup.schedulerAppointments?.loading ||
              _setup.maintenanceItemListSelectedCheckboxes?.length === 0 ||
              _setup.selectedAssetTypeCodes?.length === 0 ||
              _setup.selectedProductModelIds?.length === 0
            ),expression:"\n              schedulerAppointments?.loading ||\n              maintenanceItemListSelectedCheckboxes?.length === 0 ||\n              selectedAssetTypeCodes?.length === 0 ||\n              selectedProductModelIds?.length === 0\n            "}],staticClass:"mini-calendar"},[_c(_setup.Calendar,{attrs:{"cell":_setup.customCell},on:{"change":_setup.miniCalendarDaySelection,"hook:mounted":_setup.miniCalendarDefaultDaySelection},scopedSlots:_vm._u([{key:"customMinicalendarTemplate",fn:function({ props }){return [_c(_setup.Cell,{attrs:{"data-item":props.dataItem,"is-focused":props.isFocused,"is-selected":props.isSelected,"is-today":props.isToday,"value":props.value,"formatted-value":props.formattedValue,"appointments-dates":_setup.appointmentDates.data},on:{"click":(e) => props.onClick(e)}})]}}])})],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(
            _setup.schedulerAppointments?.loading ||
            _setup.maintenanceItemListSelectedCheckboxes?.length === 0 ||
            _setup.selectedAssetTypeCodes?.length === 0 ||
            _setup.selectedProductModelIds?.length === 0
          ),expression:"\n            schedulerAppointments?.loading ||\n            maintenanceItemListSelectedCheckboxes?.length === 0 ||\n            selectedAssetTypeCodes?.length === 0 ||\n            selectedProductModelIds?.length === 0\n          "}],staticClass:"maintenance-calendar-right"},[(!_setup.schedulerAppointments.loading && _setup.schedulerData.length > 0)?_c(_setup.CustomMaintenanceScheduler,{attrs:{"currentDate":_setup.miniCalendarSelectedDate,"scheduledAppointments":_setup.schedulerData},on:{"edit":_setup.editSchedulerEvent}}):_vm._e()],1)])]),(_setup.isModalVisible)?_c(_setup.MaintenanceModal,{staticClass:"appointment-modal",attrs:{"isModalVisible":_setup.isModalVisible,"title":_setup.maintenanceModalTitle,"appointment":_setup.selectedAppointment,"modalTriggerSource":_setup.modalTriggerSource},on:{"close":_setup.handleModalCancel,"reload":_setup.handleReloadPlanner}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }