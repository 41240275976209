
import { getKpiData, KpiDataField } from '@/api/assets';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import {
  DEFAULT_DATE_RANGE,
  getDateRangeFromStartAndEndExclusive,
  getPreviousRange,
} from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { EntityType, KPI_FIELDS, KPI_UNIT } from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface Values {
  id: string | null;
  k: string | null;
  lc: string | null;
  q: string | null;
  ts: string | null;
  v: string | null;
}

@Component({
  name: 'AssetOverview',
  components: {
    WidgetCard,
    TimeSelect,
  },
})
export default class extends Vue {
  isLoading: boolean = true;
  arrowList = ['positive', 'negative'];
  firstLoad: boolean = true;
  data: any[] = [];
  fleetData: any[] = [];
  kpi = KPI_FIELDS;
  kpiOrder: string[] = [
    this.kpi.TippingPayload,
    this.kpi.TripCount,
    this.kpi.TippingTime,
    this.kpi.PTOWorkingHours,
  ];
  singleAssetFilter = {
    assetIds: [this.$route.params.id],
  };
  details: any[] = [
    {
      entity: EntityType.ASSET,
      fields: [
        {
          code: this.kpi.TippingPayload,
          unit: KPI_UNIT.MetricTonne,
        },
        {
          code: this.kpi.TripCount,
          unit: KPI_UNIT.UnitCount,
        },
        {
          code: this.kpi.TippingTime,
          unit: KPI_UNIT.Second,
        },
        {
          code: this.kpi.PTOWorkingHours,
          unit: KPI_UNIT.Hour,
        },
        {
          code: this.kpi.PTOWorkingHoursTipping,
          unit: KPI_UNIT.Hour,
        },
        {
          code: this.kpi.PTOWorkingHoursFiltering,
          unit: KPI_UNIT.Hour,
        },
      ],
    },
  ];
  selection = {
    startDate: DEFAULT_DATE_RANGE.start,
    endDate: DEFAULT_DATE_RANGE.endExclusive,
    dataBucketDimension: 'DBDIM_ASSET',
  };

  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
    this.firstLoad = false;
  }

  async handleTimeFilter(dateRange: DateRange) {
    this.selection.startDate = dateRange.start;
    this.selection.endDate = dateRange.endExclusive;

    if (!this.firstLoad) {
      await this.getData();
    }
  }

  private async getData() {
    try {
      this.isLoading = true;
      const [fleetKpis, assetKpis, oldAssetKpis] = await Promise.all([
        this.fetchFleetKpis(),
        this.fetchAssetKpis(),
        this.fetchAssetKpisForPreviousCycle(),
      ]);
      this.fleetData = this.orderData(fleetKpis.data.details[0].fields);
      const assetData = this.orderData(assetKpis.data.details[0].fields);
      const oldAssetData = oldAssetKpis.data.details[0].fields;
      this.data = assetData.map((field) =>
        this.updateGrowthPercentage(field, oldAssetData)
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async fetchAssetKpis() {
    return getKpiData(
      {
        metadata: {
          filter: this.singleAssetFilter,
          selection: this.selection,
        },
        details: this.details,
      },
      unref(this.context)
    );
  }

  private async fetchAssetKpisForPreviousCycle() {
    const currentDateRange = this.getSelectionDateRange();
    const previousDateRange = getPreviousRange(currentDateRange);
    return getKpiData(
      {
        metadata: {
          filter: this.singleAssetFilter,
          selection: {
            ...this.selection,
            startDate: previousDateRange.start,
            endDate: previousDateRange.endExclusive,
          },
        },
        details: this.details,
      },
      unref(this.context)
    );
  }

  private async fetchFleetKpis() {
    const fleetDetails = this.details.map((detail) => {
      return { ...detail, entity: EntityType.FLEET };
    });
    return await getKpiData(
      {
        metadata: {
          filter: {
            assetTypeCode: 'ASSTYP_TIPPING_VEHICLES',
            organizationIds: unref(this.context).organizationIds,
          },
          selection: this.selection,
        },
        details: fleetDetails,
      },
      unref(this.context)
    );
  }

  private getSelectionDateRange() {
    return getDateRangeFromStartAndEndExclusive(
      this.selection.startDate,
      this.selection.endDate
    );
  }

  private orderData(data: KpiDataField[]) {
    const sorted = data.sort((a, b) => {
      return this.kpiOrder.indexOf(a.code) - this.kpiOrder.indexOf(b.code);
    });

    return sorted.filter((a) => this.kpiOrder.includes(a.code));
  }

  private updateGrowthPercentage(field: KpiDataField, oldData: KpiDataField[]) {
    const oldField = oldData.find((oldField) => oldField.code === field.code);
    if (!oldField) return field;

    const fleetValue =
      oldField.values.reduce(
        (acc: number, current: any) => acc + parseFloat(current.v) || 0,
        0
      ) / oldField.values.length;
    const value = field.values[0].v;

    if (value != null) {
      field.growthPercentage =
        (100 * (Number(value) - fleetValue)) / fleetValue;
    }
    return field;
  }

  growthPercentageArrow(growthPercentage: number) {
    return growthPercentage >= 0;
  }

  assetAvgValeueOfSelectedPeriod(values: Values[]) {
    let sum: number = 0;
    for (let value of values) {
      if (value.v) sum = sum + parseFloat(value.v);
    }

    let avrage = sum / values.length;

    if (parseFloat(`${avrage}`) % 1 !== 0) {
      return avrage.toFixed(1);
    } else {
      return parseInt(avrage.toFixed(1));
    }
  }
}
