
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import Treeselect from '@riophae/vue-treeselect';
import CusFormItem from '@/components/form/CusFormItem.vue';
import CommFormInput from '@/components/form/CommFormInput.vue';
import { getOrgById } from '@/api/organizations';
import CommonBtn from '@/components/button/CommonBtn.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { filterDeactivatedOrg } from '@/utils/index';
import { customFailedMessage } from '@/utils/prompt';
import { ErrorType } from '@/api/types';

interface ModuleAccType {
  resource: string;
}

interface ModuleAccessNode {
  children: string[];
  disabled: boolean;
  id: string;
  label: string;
  name: string;
}

@Component({
  name: 'UserManagementInputForm',
  components: {
    Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
    'module-access': ModuleAccess,
    'comm-form-input': CommFormInput,
  },
})
export default class extends Vue {
  @Prop({ default: true }) inputFormIsInEditMode!: boolean;
  @Prop({ required: true }) userForm!: any;
  @Prop() roles!: any;
  @Prop() organizationId!: string;
  @Prop() modulesInfo!: [];
  @Prop() errorInfos!: ErrorType[];
  @Prop() activateOrDeactivate!: string;

  /** Local variables */
  errorCode = ERROR_CODE_LIST;
  orgs: any = [];
  disabledTreeSel: boolean = false;
  organizationIdIsLoading: boolean = false;

  created() {
    this.handleSelectedAccessModules();
    this.fetchOrganizationData();
  }

  /**
   * Get inline message
   */
  get inlineMsg() {
    return false;
  }

  /**
   * Handle selected access modules
   */
  handleSelectedAccessModules() {
    if (!this.inputFormIsInEditMode) return;
    let defaultSelected: string[] = [];
    this.userForm.claims.forEach((item: any) => {
      defaultSelected.push(item.resource);
    });
    this.userForm.claimsFtd = defaultSelected;
  }

  /**
   * Validate claims
   * @param rule
   * @param value
   * @param callback
   */
  validateClaimsFtd = (rule: any, value: any, callback: any) => {
    const checkedNode = (
      this.$refs.userManagementModuleAccess as any
    ).getCheckedNodes();
    if (checkedNode.length < 1) {
      callback(new Error(`${this.$t('userModule.tipSelectModuleAccess')}`));
    } else {
      callback();
    }
  };

  /**
   * Validate organization id
   * @param rule
   * @param value
   * @param callback
   */
  validateOrgId = (rule: any, value: any, callback: any) => {
    if (this.userForm.organizationId) {
      callback();
    } else {
      callback(new Error(`${this.$t('userModule.tipSelectOrg')}`));
    }
  };

  /**
   * Validate organization iduser name field
   * @param rule
   * @param value
   * @param callback
   */
  validateUserName = (rule: any, value: any, callback: any) => {
    if (this.userForm.organizationId) {
      callback();
    } else {
      callback(new Error(`${this.$t('organizationModule.tipSelectName')}`));
    }
  };

  /**
   * Validate phone number field
   * @param rule
   * @param value
   * @param callback
   */
  validatePhoneNumber = (rule: any, value: any, callback: any) => {
    const onlyNumberRegex = /[0-9]|\./;
    if (
      this.userForm.mobilePhone &&
      !onlyNumberRegex.test(this.userForm.mobilePhone)
    ) {
      callback(new Error(`${this.$t('userModule.onlyNumbers')}`));
    }
    callback();
  };

  /**
   * Get rules for input form
   */
  get rules() {
    const tmpRules = {
      username: [{ validator: this.validateUserName, trigger: 'input' }],
      email: [
        { required: true, message: this.$t('userModule.tipInputEmail') },
        {
          type: 'email',
          message: this.$t('userModule.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      role: [
        {
          required: true,
          message: this.$t('userModule.tipSelectRole'),
          trigger: 'change',
        },
      ],
      organizationId: [{ validator: this.validateOrgId, trigger: 'input' }],
      claimsFtd: [{ validator: this.validateClaimsFtd, trigger: 'change' }],
      mobilePhone: [{ validator: this.validatePhoneNumber, trigger: 'change' }],
    };
    return tmpRules;
  }

  /**
   * Send event for input
   */
  handleEmailInput() {
    this.userForm.email = this.userForm.email.trim().replace(/\s+/g, '');
    this.$emit('handle-email-input');
  }

  /**
   * Handle access module selection
   */
  handleAccessModuleSelection() {
    (this.$refs.userForm as any).validateField('organizationId');
  }

  /**
   * Handle tree select expanded/open
   */
  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  /**
   * Handle tree select close/minimize
   */
  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  /**
   * Email validation
   */
  get errEmailInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos?.find((item) => item.field === this.errorCode.email)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userModule.duplicateEmail')}`;
    } else if (
      this.errorInfos?.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  /**
   * Handle change role event
   * @param value
   */
  changeRole(value: any) {
    this.roles.find((item: any) => {
      if (item.code === value) {
        this.$emit('change-role', item.claims);
      }
    });
  }

  /**
   * Tree select custom key for children
   * @param node
   */
  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children: node.children?.length > 0 ? node.children : undefined,
    };
  }

  /**
   * Get organization management info
   */
  async fetchOrganizationData() {
    try {
      this.organizationIdIsLoading = true;
      await getOrgById(this.organizationId).then((res) => {
        if (res.code === 200) {
          let tempResData = res.data;
          this.orgs = filterDeactivatedOrg([tempResData]);
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.organizationIdIsLoading = false;
    }
  }

  /**
   * Validate access modules and send event to the parent with selections
   */
  validateClickAction() {
    (this.$refs.userForm as any).validate((valid: any) => {
      if (valid) {
        return this.$emit('handle-api-event');
      }
    });
  }

  /**
   * Hide user management input form
   */
  cancelUserManagementForm() {
    this.$emit('cancel-user-form');
  }

  /** Remove user remotly */
  async removeUser() {
    this.$emit('remove-user-event');
  }

  /**
   * Show modal window event
   */
  showModalWindow() {
    this.$emit('show-modal-window');
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos?.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldUnique'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }
}
