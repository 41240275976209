var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('NewCard',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isViewModeLoading),expression:"isViewModeLoading"}],attrs:{"id":"view_cust_info_new_card","title":_vm.customerId
      ? `${_vm.$t('customerModule.removeAccess')} ${_vm.title}`
      : _vm.$t('customerModule.removeAccess'),"createdOrEdit":_vm.customerId,"visibleEdit":false,"visibleDeactivate":false,"visible":true,"activateOrDeactivate":_vm.activateOrDeactivate}},[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.$t('customerModule.selectOrgRemove')))]),_c('el-select',{attrs:{"placeholder":"Select"},on:{"change":_vm.organizationChange},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}},_vm._l((_vm.orgList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),(
          this.$router.currentRoute.path.includes(
            '/customer-management/view/'
          )
        )?_c('el-button',{staticClass:"el-button el-button--plain el-button--medium",on:{"click":_vm.handleRemoveAssets}},[_vm._v(" "+_vm._s(_vm.$t('common.removeAccess'))+" ")]):_vm._e()],1),_c('h3',[_vm._v(" "+_vm._s(_vm.$t('customerModule.slectedRemove'))+": "+_vm._s(_vm.selectedAssetes.length)+" ")]),_c('SelectTableHeader',{staticStyle:{"margin":"20px 0"},attrs:{"searchFieldOptions":_vm.assetsSearchFields,"cols":_vm.assetCols,"searchParams":_vm.assetSearchParams,"selectedAssetes":_vm.selectedAssetes,"customerType":_vm.customerType},on:{"search-event":_vm.handleFetchAssets}}),_c('el-row',[_c('PureTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isAssetTableLoading),expression:"isAssetTableLoading"}],attrs:{"element-loading-text":_vm.assetsLoadingText,"tableList":_vm.assetList,"total":_vm.assetTotal,"cols":_vm.assetCols,"selectable":true,"markItemWhenClickOnRow":true},on:{"updateSelectedAssets":_vm.handleUpdateSelectedAssets,"handle-page":_vm.handleTablePagination,"handle-sort-change":_vm.fetchAssignedAssetsDataBySortEvent}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }