
import { Component, Vue } from 'vue-property-decorator';
import { DEPLOYMENT_HISTORY_MANAGEMENT_COLS } from '@/utils/workData/deploymentHistoryManagementCols';
import {
  getFinishedDeployments,
  FinishedDeploymentResponse,
  FinishDeployment,
} from '@/api/systemReleaseManagement';
import PureTable from '@/components/table/PureTable.vue';
import { UserModule } from '@/store/modules/user';
import { PROPER_ASSET_TYPE, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import {
  flattenOrganizations,
  LoggedInUserContext,
  LoggedInUserRef,
  useLoggedInUser,
} from '@/composables/context';
import { unref } from 'vue';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { calculateTimeDifference } from '@/utils/misc';

@Component({
  name: 'History',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = DEPLOYMENT_HISTORY_MANAGEMENT_COLS;
  pageTotal = UserModule.gridPageSize;
  data: FinishDeployment[] = [];
  numberOfReleases = this.data.length;
  searchInput = '';
  historyTableIsLoading: boolean = false;
  filterList: { field: string; label?: string; dropdownEnum?: any }[] = [
    { field: 'systemReleaseId' },
    { field: 'companyName' },
    { field: 'deploymentBy' },
    { field: 'assetType', dropdownEnum: PROPER_ASSET_TYPE },
    { field: 'productModelCode' },
    { field: 'productModelNumber' },
  ];
  filterEnum: any = null;
  tableFilter: { searchFieldName: string; searchFieldValue: string | null } = {
    searchFieldName: this.filterList[0].field,
    searchFieldValue: null,
  };
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  appliedFilters: QueryParameter = {
    filters: [],
    sorters: [],
    pagination: this.pagination,
  };
  loggedInUser!: LoggedInUserRef;
  currentUserContext: Readonly<LoggedInUserContext | undefined>;
  isDifferentUserThanHyvaadmin: boolean = false;

  created(): void {
    this.loggedInUser = useLoggedInUser();
    this.currentUserContext = unref(this.loggedInUser);
    this.isDifferentUserThanHyvaadmin =
      UserModule.companyType != COMPANY_TYPE.Hyva;

    this.prepareDefaultQueryParameters();
    this.getData();
  }

  /**
   * Prepare default query parameters
   */
  prepareDefaultQueryParameters(): void {
    if (this.isDifferentUserThanHyvaadmin && this.currentUserContext) {
      const filterByOrganizations: Filter = {
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: flattenOrganizations(this.currentUserContext.organization).map(
          (org) => org.id
        ),
      };
      this.appliedFilters.filters = [filterByOrganizations];
    }
    this.applyDefaultSorterByDeploymentEndTime();
  }

  /**
   * Handle get history deployments
   */
  async getData(): Promise<void> {
    try {
      this.data = [];
      this.historyTableIsLoading = true;
      const response = await getFinishedDeployments(this.appliedFilters);
      this.data = response.data?.deployments;
      this.pageTotal = response.data.total;
      this.data.map((systemDeployment: FinishDeployment) => {
        const diff = calculateTimeDifference(
          systemDeployment.deploymentStartTime,
          systemDeployment.deploymentEndTime
        );
        systemDeployment.totalTimeSpent = diff;
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.historyTableIsLoading = false;
    }
  }

  /**
   * Handle search input event
   */
  handleSearchInput(): void {
    this.appliedFilters.filters = [];
    if (this.isDifferentUserThanHyvaadmin) this.applyDefaultFilter();
    if (this.tableFilter.searchFieldValue) {
      const searchFilter: Filter = {
        name: this.tableFilter.searchFieldName,
        operator: FilterOperator.LIKE,
        value: [encodeURIComponent(this.tableFilter.searchFieldValue)],
      };
      this.appliedFilters.filters.push(searchFilter);
    }
    this.getData();
  }

  /**
   * Handle search clear event
   */
  async handleSearchClear(): Promise<void> {
    this.tableFilter.searchFieldValue = '';
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.tableFilter.searchFieldName
    )?.dropdownEnum;
    this.handleSearchInput();
  }

  /**
   * Handle page selection event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    this.getData();
  }

  /**
   * Handle sort change event
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    if (sortField === 'totalTimeSpent') return;
    this.appliedFilters.sorters = [];
    if (sortOrder) {
      const currentSorter: Sorter = {
        field: sortField,
        order:
          sortOrder === SorterOrder.ASC ? SorterOrder.ASC : SorterOrder.DESC,
      };
      this.appliedFilters.sorters = [currentSorter];
    } else {
      this.applyDefaultSorterByDeploymentEndTime();
    }
    this.getData();
  }

  /**
   * Apply default sorter by created on
   * - deploymentFinishTime will be retrieved DESC by the API
   */
  applyDefaultSorterByDeploymentEndTime(): void {
    const defaultSorter: Sorter = {
      field: 'deploymentEndTime',
      order: SorterOrder.DESC,
    };
    this.appliedFilters.sorters = [defaultSorter];
  }

  /**
   * Apply default filter from current logged in user:
   * - current org and sub org levels
   */
  applyDefaultFilter(): void {
    if (!this.currentUserContext) return;
    const filterByOrganizations: Filter = {
      name: 'organizationId',
      operator: FilterOperator.IN,
      value: flattenOrganizations(this.currentUserContext.organization).map(
        (org) => org.id
      ),
    };
    this.appliedFilters.filters = [filterByOrganizations];
  }
}
