
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import VerticalDivide from '../components/VerticalDivide.vue';
import { getAssetOnBoardingById } from '@/api/assetsMgmt';
import moment from 'moment-timezone';
import ConnectionProgress from '../components/ConnectionProgress.vue';
import { PageModule } from '@/store/modules/page';
import { formatServerDate } from '../helper';

@Component({
  name: 'RegisteredAssetConnectView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    VerticalDivide,
    'connection-progress': ConnectionProgress,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;

  /** Local variables */
  assetOnBoardingObj: any = {};
  title = '';
  connectAssetViewIsLoading: boolean = false;
  isConnectionProgressLoading: boolean = false;

  mounted() {
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.connectAssetViewIsLoading = true;
    this.isConnectionProgressLoading = true;
    await getAssetOnBoardingById(this.assetId).then((res) => {
      if (res.code === 200) {
        this.assetOnBoardingObj = res.data;
        this.assetOnBoardingObj.manufactureDate = formatServerDate(
          this.assetOnBoardingObj.manufactureDate
        );
        PageModule.setTitle(res.data.serialNumber);
      }

      this.isConnectionProgressLoading = false;
      this.connectAssetViewIsLoading = false;
    });
  }
}
