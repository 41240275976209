
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'RenewSubscriptionModal',
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop() activateOrDeactivate!: string;
  @Prop({ required: true }) modalContent!: string;

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleRenewSubscription() {
    this.$emit('handle-renew-subscription');
  }
}
