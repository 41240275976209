
import { Component, Prop, Vue } from 'vue-property-decorator';
import CusFormItem from '@/components/form/CusFormItem.vue';
import {
  SUBSCRIBE_REPORT_DATE_RANGE_TYPE_LIST,
  SUBSCRIBE_REPORT_ASSET_SCOPES,
  SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import { getAssets } from '@/api/assets';
import { getOrganizations } from '@/api/organizations';
import { UserModule } from '@/store/modules/user';
import {
  fetchReportTemplatesByCustomParams,
  getReportTemplates,
} from '@/api/report';
import { generateRequestPayload } from '@/utils/misc';
import { dateFormat, reportTemplateName } from '../report';
import { disabledPastDate } from '@/utils/date';
import { flattenOrganizations } from '@/composables/context';

@Component({
  name: 'ModifySubscriptionModal',
  components: {
    CusFormItem,
    WidgetDialog,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop() activateOrDeactivate!: string;
  @Prop({ required: true }) subscriptionToModify!: any;

  /** Local variables */
  reportTemplateFrequencyList: any = SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST;
  reportFormatList = [] as { id: string; label: string }[];
  reportFormatIsLoading: boolean = false;
  subscribeReportAssetRangeType: any = SUBSCRIBE_REPORT_ASSET_SCOPES;
  subscribeReportDateRangeList: any = SUBSCRIBE_REPORT_DATE_RANGE_TYPE_LIST;
  assetsForCurrentUser: any = [];
  orgsForCurrentUser: any = [];
  reportTemplateList: any = [];
  generalQueryData: any = {
    pageNumber: 1,
    pageSize: UserModule.gridPageSize,
    searchParams: [
      {
        reference: null,
        operator: null,
        value: null,
      },
    ],
    sortByAndOrder: [
      {
        sortBy: null,
        order: null,
      },
    ],
  };

  canChangeSubscriptionStartDate: boolean = false;

  created() {
    this.initializeDefaultSettings();
    this.checkIfCanChangeSubscriptionStartDate();
  }

  checkIfCanChangeSubscriptionStartDate() {
    const currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);
    const subscriptionStartDate = new Date(this.subscriptionToModify.startDate);

    this.canChangeSubscriptionStartDate = moment(subscriptionStartDate).isAfter(
      currentDate
    );
  }

  async initializeDefaultSettings() {
    try {
      this.reportFormatIsLoading = true;

      let finalUrlParamsForSearch: string = generateRequestPayload(
        this.generalQueryData
      );
      await this.fetchReportTemplates(finalUrlParamsForSearch);
      await this.fetchAssetsForCurrentUser();
      await this.fetchOrgsForCurrentUser();

      const res = await getReportTemplates();
      if (res.code === 200) {
        this.reportFormatList = res.data.map((el) => ({ id: el, label: el }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.reportFormatIsLoading = false;
    }
  }

  /** Retrieve assets that current user can have access */
  async fetchAssetsForCurrentUser() {
    let requestParams: string = `?page=1&size=${UserModule.gridPageSize}&companyId=${UserModule.companyId}`;
    await getAssets(requestParams).then((res) => {
      const response = res.data.assets;
      if (!response) return;

      if (res.code === 200 && response) {
        this.assetsForCurrentUser = response;
      }
    });
  }

  /** Retrieve organizations that current user can have access */
  async fetchOrgsForCurrentUser() {
    await getOrganizations(UserModule.companyId).then((res) => {
      const response = res.data;
      if (!res) return;

      if (res.code === 200 && response) {
        this.orgsForCurrentUser = flattenOrganizations(response);
      }
    });
  }

  /** Fetch remotly form API report template data */
  async fetchReportTemplates(requestPayload: any) {
    await fetchReportTemplatesByCustomParams(requestPayload).then((res) => {
      if (!res) return;

      if (res.code === 200 && res.data.reportTemplates.length > 0) {
        this.reportTemplateList = res.data.reportTemplates;
      }
    });
  }

  closeDialog() {
    this.$emit('handle-cancel');
  }

  cancelModal() {
    (this.$refs.refSubcribeReportForm as any).resetFields();
    this.$emit('handle-cancel');
  }

  handleSubscribedReportModification() {
    (this.$refs.refSubcribeReportForm as any).validate((valid: any) => {
      if (valid) {
        this.subscriptionToModify[reportTemplateName] =
          this.reportTemplateList.find(
            (r: any) => r.id == this.subscriptionToModify.reportTemplateId
          ).name;
        this.$emit(
          'handle-modify-subscribed-report',
          this.subscriptionToModify
        );
      }
    });
  }

  pickerOptions: any = {
    disabledDate: disabledPastDate,
  };

  /** Rules definition for the input form */
  get rules() {
    const tmpRules = {
      reportName: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      reportTemplateName: [
        {
          required: true,
          message: this.$t('report.tipInputReportTemplateName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      subscriptionFrequency: [
        { required: true, message: this.$t('report.pleaseSelectFrequency') },
        {
          message: this.$t('report.pleaseSelectFrequency'),
          trigger: ['blur', 'change'],
        },
      ],
      subscribedEmail: [
        { required: true, message: this.$t('common.tipInputEmail') },
        {
          type: 'email',
          message: this.$t('common.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      startDate: [
        { required: true, message: this.$t('common.tipSelectDate') },
        { validator: this.validateStartDate, trigger: 'blur' },
      ],
      endDate: [
        { required: true, message: this.$t('common.tipSelectDate') },
        { validator: this.validateEndDate, trigger: 'blur' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  validateStartDate = (rule: any, value: any, callback: any) => {
    let end = moment(this.subscriptionToModify.startDate).format(dateFormat);
    if (end) (this.$refs.refSubcribeReportForm as any).validateField('endDate');
    callback();
  };

  validateEndDate = (rule: any, value: any, callback: any) => {
    let end = new Date(moment(value).format(dateFormat));
    let start = new Date(
      moment(this.subscriptionToModify.startDate).format(dateFormat)
    );

    if (moment(start).isAfter(end)) {
      callback(new Error(`${this.$t('report.tipSelectCorrectDate')}`));
    } else {
      callback();
    }
  };

  validateName = (rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    } else {
      callback();
    }
  };

  /** Format date */
  formatDateTime(date: any) {
    return moment(date).format(dateFormat);
  }
}
