
import { Component, Vue, Prop } from 'vue-property-decorator';
import { HISTORY_DETAILS_COLS } from '@/utils/workData/lookuptable';
import { calculateAssetDeploymentStatus } from './assetDeployment';
import {
  AssetDeploymentLog,
  SystemDeploymentAsset,
} from '@/api/deploymentDetails';

interface tableCols {
  labe: string;
  prop: string;
}

@Component({
  name: 'AssetStatusTable',
})
export default class extends Vue {
  @Prop({}) tableData!: SystemDeploymentAsset;
  @Prop({}) cols!: tableCols;

  /** Local variables */
  detailsCols = HISTORY_DETAILS_COLS;

  /**
   * Get deploy details
   * @param row
   */
  getDeplyDetails(row: SystemDeploymentAsset) {
    this.$emit('selectAsset', row.assetDeploymentId);
  }

  /**
   * Get final asset deployment status by certain conditions
   */
  finalAssetDeploymentStatus(item: AssetDeploymentLog) {
    return (
      calculateAssetDeploymentStatus(item) ??
      this.$t(`asds.${item.assetDeployStatus}`)
    );
  }
}
