
import { getAssetLocation, getAssetsWithLocation } from '@/api/assetLocation';
import { getAssetById } from '@/api/assets';
import { GeofenceAsset } from '@/api/geofenceTypes';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { calculateAssetBoundsWithPadding } from '@/utils/bounds';
import { AssetType } from '@/utils/workData/lookuptable';
import AssetHomeStatus from '@/widgets/fleet/FleetStatus.vue';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'MapExpanded',
  components: {
    LeafletMap,
    AssetHomeStatus,
  },
})
export default class extends Vue {
  searchque: string = '';
  assetListFetched: GeofenceAsset[] = [];
  assetList: GeofenceAsset[] = [];
  center: number[] = [0, 0];
  zoom: number = 10;
  show: boolean = false;
  selectedAssetId: string = '';

  loading: boolean = true;

  $refs!: {
    map: LeafletMap;
  };

  computed: any = {
    columns: function columns() {
      if (this.rows.length == 0) return [];
      return Object.keys(this.rows[0]);
    },
  };

  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  mounted() {
    this.updateAssets();
  }

  private async updateAssets() {
    let assetType: AssetType = AssetType.All;

    switch (this.$route.name) {
      case 'StaticCompactors':
        assetType = AssetType.StaticCompactor;
        break;
      case 'AlbaStaticCompactors':
        assetType = AssetType.AlbaStaticCompactor;
        break;
      case 'MobileCompactors':
        assetType = AssetType.MobileCompactor;
        break;
      case 'TippingVehicles':
        assetType = AssetType.TippingVehicle;
        break;
      case 'TableTopTissectors':
        assetType = AssetType.TableTopTissector;
        break;
      default:
        assetType = AssetType.All;
        break;
    }

    if (this.$route.params.id) {
      const assetId = this.$route.params.id;
      const { data } = await getAssetById(assetId, unref(this.context));
      const { data: assets } = await getAssetLocation(
        data,
        unref(this.context)
      );

      // AHMAPP-5116 discovery:
      // With the following line enabled the map is zoomed out to the the specified zoom level at the first assets coordinates.
      // With the following line disabled the map is zoomed out to the the specified zoom level at specified geo coordinates [0,0].
      // This is different from Map.vue where geofences are rendered as well, causing different behavior.

      this.center = [assets[0].geodeticLatitude, assets[0].geodeticLongitude];

      this.assetListFetched = assets;
      this.assetList = assets;
      this.loading = false;
      return;
    }

    const { data: assets } = await getAssetsWithLocation(
      assetType,
      unref(this.context)
    );

    const bounds = calculateAssetBoundsWithPadding(assets);
    if (bounds.isValid()) {
      this.$nextTick(() => {
        this.$refs.map?.setBounds(bounds);
      });
    }

    this.assetListFetched = assets;
    this.assetList = assets;
    this.loading = false;
  }

  getSelectedAsset(assetId: string) {
    this.selectedAssetId = assetId;
  }
}
