
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import {
  AssetType,
  ASSET_TYPE_LIST,
  THEME_LIST,
  // AMOUNT_OF_USERS_TYPE_LIST
} from '@/utils/workData/lookuptable';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';

@Component({
  name: 'GeneralInfomation',
  components: {
    GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) customerInfo!: any;
  @Prop({ required: false }) logo: any | undefined;

  themeColours: any = [];
  companyType: string = '';
  country: string = '';
  amountOfUsers: string = '';
  companyTypesForInstalledCodeDisplay: any = [
    COMPANY_TYPE.Hyva,
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
  ];

  @Watch('customerInfo', { deep: true })
  handleDataAssetTypes(newValue: any) {
    if (newValue) {
      this.convert();
    }
    return newValue;
  }

  convert() {
    if (this.customerInfo.setting && this.customerInfo.setting.theme) {
      let themeId = this.customerInfo.setting.theme;
      let themes = THEME_LIST.filter((item) => item.id == themeId);
      if (themes.length > 0)
        this.themeColours.push({
          key: themes[0].id,
          name: themes[0].name,
          style: themes[0].value,
        });
    }
    // let amountOfUsersList = AMOUNT_OF_USERS_TYPE_LIST.filter(
    //   (item: any) => item.id == this.customerInfo.amountOfUsers
    // );
    // if(amountOfUsersList && amountOfUsersList.length > 0) {
    //     this.amountOfUsers = amountOfUsersList[0].value;
    // }
  }
}
