
import { Component, Vue } from 'vue-property-decorator';
import {
  DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS,
  PENDING_UPDATES_FILTER_OPTIONS,
} from '@/utils/workData/deploymentPendingUpdatesManagementCols';
import {
  getPendingUpdates,
  PendingUpdatesResponse,
  PendingUpdate,
} from '@/api/systemReleaseManagement';
import { KpiDataRequest } from '@/api/assets';
import {
  GENERAL_QUERY_OPERATORS,
  KPI_FIELDS,
  COMPANY_TYPE,
} from '@/utils/workData/lookuptable';
import PureTable from '@/components/table/PureTable.vue';
import { UserModule } from '@/store/modules/user';
import { getKpiList } from '@/api/assetsAll';
import { customWarningMessage } from '@/utils/prompt';
import {
  flattenOrganizations,
  LoggedInUserContext,
  LoggedInUserRef,
  useLoggedInUser,
} from '@/composables/context';
import { unref } from 'vue';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';

@Component({
  name: 'PendingUpdates',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS;
  assetIds: string[] = [];
  pageTotal = 1;
  pendingUpdatedTableData: PendingUpdate[] = [];
  numberOfReleases = this.pendingUpdatedTableData.length;
  searchInput: string = '';
  filterOptions: { field: string; label?: string; dropdownEnum?: any }[] =
    PENDING_UPDATES_FILTER_OPTIONS;
  isPendingUpdatedTableLoading: boolean = false;
  kpiData: KpiDataRequest = {
    metadata: {
      filter: {
        assetIds: [],
      },
    },
    details: [
      {
        entity: '',
        fields: [
          {
            code: KPI_FIELDS.OperationalStatus,
          },
          {
            code: KPI_FIELDS.LastCommunicationTime,
          },
        ],
      },
    ],
  };
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  filter = {
    name: '',
    operator: GENERAL_QUERY_OPERATORS.Equal,
    values: [''],
  };
  queryParameters: {
    filters: any[];
    pagination: { page: number; size: number };
    sorters: { field: string; order: string }[];
  } = {
    filters: [],
    sorters: [],
    pagination: this.pagination,
  };
  loggedInUser!: LoggedInUserRef;
  filterEnum: any = null;
  isDifferentUserThanHyvaadmin: boolean = false;
  currentUserContext: Readonly<LoggedInUserContext | undefined>;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.currentUserContext = unref(this.loggedInUser);
    this.isDifferentUserThanHyvaadmin =
      UserModule.companyType !== COMPANY_TYPE.Hyva;
    this.prepareDefaultQueryParameters();
    this.getData();
  }

  /**
   * Prepare default query parameters
   */
  prepareDefaultQueryParameters(): void {
    if (this.isDifferentUserThanHyvaadmin && this.currentUserContext) {
      const filterByOrganizations: Filter = {
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: flattenOrganizations(this.currentUserContext.organization).map(
          (org) => org.id
        ),
      };
      this.queryParameters.filters = [filterByOrganizations];
    }
  }

  /**
   * Handle sorting change
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    this.queryParameters.sorters = [];
    if (sortOrder) {
      this.queryParameters.sorters.push({
        field: sortField,
        order: sortOrder,
      });
    }
    await this.getData();
  }

  /**
   * Get pending updates response from API
   */
  async getData(): Promise<void> {
    try {
      this.isPendingUpdatedTableLoading = true;
      const response = await getPendingUpdates(this.queryParameters);

      let data = response?.data?.pendingUpdates;
      this.pageTotal = response?.data?.total;

      if (!data || data.length === 0) {
        this.pendingUpdatedTableData = [];
        return;
      }

      const assetIds: string[] = response.data.pendingUpdates.map(
        (update: PendingUpdate) => update.assetUUID
      );
      if (assetIds.length !== 0) {
        this.kpiData.metadata.filter.assetIds = assetIds;
        const kpis = await getKpiList(this.kpiData);

        if ((kpis.data && kpis.data.errors) || !kpis.data) {
          customWarningMessage(
            this.$t(
              'deployManagementModule.pendingUpdates.errorFetchingData'
            ) as string
          );
          this.pendingUpdatedTableData = data;
          return;
        }

        kpis.data.forEach((assetKPI: any) => {
          const assetId = assetKPI.assetId;

          let pendingUpdate = data.find(
            (update: any) => update.assetUUID == assetId
          );
          if (pendingUpdate) {
            pendingUpdate['lastCommunicationTime'] = assetKPI.values.find(
              (v: { code: string; v: any }) =>
                v.code === KPI_FIELDS.LastCommunicationTime
            ).v;
            pendingUpdate['opreationalStatus'] = assetKPI.values.find(
              (v: { code: string; v: any }) =>
                v.code === KPI_FIELDS.OperationalStatus
            ).v;
          }
        });

        this.pendingUpdatedTableData = data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isPendingUpdatedTableLoading = false;
    }
  }

  /**
   * Handle search filter event
   */
  handleSearchFilterSubmit(): void {
    if (this.searchInput.length < 1) this.handleClear();
    if (this.isDifferentUserThanHyvaadmin) this.applyDefaultFilter();
    if (this.searchInput.length > 0 && this.filter.name.length > 0) {
      const selectedFilter: Filter = {
        name: this.filter.name,
        operator: FilterOperator.LIKE,
        value: [this.searchInput],
      };
      this.queryParameters.filters.push(selectedFilter);
      this.getData();
    }
  }

  /**
   * Handle release filter
   */
  handleReleaseFilter(): void {
    this.searchInput = '';
    this.filter.values = [];
    this.handleClear();
  }

  /**
   * Handle clear
   */
  handleClear(): void {
    if (this.isDifferentUserThanHyvaadmin) this.applyDefaultFilter();
    this.filterEnum = this.filterOptions.find(
      (e) => e.field === this.filter.name
    )?.dropdownEnum;
    this.getData();
  }

  /**
   * Handle deployment filter input event
   */
  handleFilterChange(): void {
    this.searchInput = '';
    this.handleClear();
  }

  /**
   * Handle page click event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    this.getData();
  }

  /**
   * Handle row click
   * @param row
   */
  handleRowClick(row: any): void {
    this.$router.push(
      `pending-updates/details/${row.newSystemReleaseUUID}?assetUUID=${row.assetUUID}`
    );
  }

  /**
   * Batch deploy event
   */
  batchDeployment(): void {
    this.$router.push(`pending-updates/batch-deploy`);
  }

  /**
   * Apply default filter from current logged in user:
   * - current org and sub org levels
   */
  applyDefaultFilter(): void {
    if (!this.currentUserContext) return;
    const filterByOrganizations: Filter = {
      name: 'organizationId',
      operator: FilterOperator.IN,
      value: flattenOrganizations(this.currentUserContext.organization).map(
        (org) => org.id
      ),
    };
    this.queryParameters.filters = [filterByOrganizations];
  }
}
