
import { Component, Vue } from 'vue-property-decorator';
import SubscriptionPackagesTable from './components/SubscriptionPackagesTable.vue';
import { SUBSCRIPTION_PACKAGES_COLS } from '@/utils/workData/subscriptionPackages';
import { getSubscriptionPackages } from '@/api/subscriptionPackages';
import { customFailedMessage } from '@/utils/prompt';
import { PACKAGE_TYPE_LIST } from '@/utils/workData/lookuptable';

@Component({
  name: 'SubscriptionPackagesIndex',
  components: {
    'subscription-table': SubscriptionPackagesTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = SUBSCRIPTION_PACKAGES_COLS;
  allSubscriptions: any = [];
  processedSubscriptions: any = [];
  subscriptionPackagesIsLoading: boolean = false;
  packageTypes: any = [];
  total: number = 0;
  loadingText: string = this.$t('subsMgmt.loadingSubscriptionData') as string;

  created() {
    this.getSubscriptions(1, 15, null, null);
  }

  /**
   * Get subscription by page and size
   * @param pages
   * @param size
   */
  async getSubscriptions(
    page?: number,
    size?: number,
    searchByNameValue?: string | null,
    packageTypeValue?: string | null
  ) {
    this.subscriptionPackagesIsLoading = true;
    await getSubscriptionPackages(
      page,
      size,
      searchByNameValue,
      packageTypeValue
    ).then((res) => {
      if (!res) {
        return;
      }

      if (res.code === 200) {
        this.allSubscriptions = res.data.subscriptionPackages;
        this.processedSubscriptions = res.data.subscriptionPackages;
        this.subscriptionPackagesIsLoading = false;
        this.total = res.data.total;

        res.data.subscriptionPackages.forEach((item: any) => {
          let packageTypeObj = {
            id: item.id,
            type: item.type,
            translatedType: PACKAGE_TYPE_LIST.find(
              (type: any) => type.id === item.type
            )!.value,
          };

          if (!this.packageTypes.some((el: any) => el.type === item.type)) {
            this.packageTypes.push(packageTypeObj);
          }
        });

        return;
      }

      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Event triggered on page selection from table
   */
  async handlePage(page: number, pageSize: number) {
    this.getSubscriptions(page, pageSize, null, null);
  }
}
