
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'QuitEditingTemplateModal',
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleQuitEditingTemplate() {
    this.$emit('quit-edit-template-dialog');
  }
}
