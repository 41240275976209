
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';

@Component({
  name: 'AssignFormPrev',
})
export default class extends Vue {
  @Prop({ required: false }) hasAssignedForm!: any;

  mounted() {}
}
