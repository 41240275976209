
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import {
  createPartMaterial,
  getPartMaterials,
  updatePartMaterial,
} from '@/api/products';
import {
  MODEL_PART_TYPE_LIST,
  PROD_ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import { promptSuccessBox, customFailedMessage } from '@/utils/prompt';
import { ErrorType } from '@/api/types';

@Component({
  name: 'general',
  components: {
    'new-card': NewCard,
    'cus-form-item': CusFormItem,
  },
})
export default class extends Vue {
  @Prop({ required: false }) productModelInfo!: any;
  @Prop({ required: false }) state!: any;

  partTypeList = MODEL_PART_TYPE_LIST;
  errPartNameInfo = '';
  errPartIdInfo = '';

  id = '';

  /**
   * Backend payload structure used in v-model bindings
   */
  materialPartForm = {
    partMaterialNumber: '',
    partType: 'PARTTYP_HYDRAULIC',
    name: '',
    lifeCycle: 'LCL_DRAFT',
  };

  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  mounted() {}

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.partType)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.partTypeNotFound')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleNone')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorCannotBeUpdated'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleUpdateError')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.partMaterialNumber
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.partMaterialNumberDuplicated')}`;
    }

    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  validatePartMaterialNumber = (rule: any, value: any, callback: any) => {
    let newReplaceValue = value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    if (newReplaceValue.length < 1 || newReplaceValue.length > 25) {
      callback(new Error(`${this.$t('prodCata.tipInputPartNumberCorrect')}`));
    } else {
      callback();
    }
  };

  validatePartMaterialName = (rule: any, value: any, callback: any) => {
    let newReplaceValue = value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    if (newReplaceValue.length < 1 || newReplaceValue.length > 50) {
      callback(new Error(`${this.$t('prodCata.tipInputPartNameCorrect')}`));
    } else {
      callback();
    }
  };

  //form validate rules
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('prodCata.tipInputPartName'),
          trigger: 'change',
        },
        { validator: this.validatePartMaterialName, trigger: 'change' },
      ],
      partMaterialNumber: [
        {
          required: true,
          message: this.$t('prodCata.tipInputPartNumber'),
          trigger: 'change',
        },
        { validator: this.validatePartMaterialNumber, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  //send formdata to parent component
  @Emit('send-general-form')
  sendFormData() {
    return this.materialPartForm;
  }

  async getPartDetails(id: any) {
    this.id = id;
    const res = await getPartMaterials(id);
    if (res.code === 200) {
      this.materialPartForm.partMaterialNumber = res.data.partMaterialNumber;
      this.materialPartForm.partType = res.data.partType;
      this.materialPartForm.lifeCycle = res.data.lifeCycle;
      this.materialPartForm.name = res.data.name;
    }
  }

  async createOnePart() {
    (this.$refs.materialPartForm as any).validate(async (valid: any) => {
      if (valid) {
        const res = await createPartMaterial(this.materialPartForm);
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.created') as string);
          this.errorInfos = [];
          this.$router.push('/prod-cata/index?activeName=partsWarehouse');
        } else if (res.code === 400) {
          // customFailedMessage(this.$t('common.created') as string);
          // this.errorInfos = res.data.errors[0].message;
          this.errorInfos = [...res.data.errors[0]];
          customFailedMessage(this.errNameInfo as any);
        }
      }
    });
  }

  async editOnePart() {
    (this.$refs.materialPartForm as any).validate(async (valid: any) => {
      if (valid) {
        const res = await updatePartMaterial(this.id, this.materialPartForm);
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.save') as string);
          this.errorInfos = [];
          this.$router.push('/prod-cata/index?activeName=partsWarehouse');
        } else if (res.code === 400) {
          // customFailedMessage(this.$t('common.save') as string);
          // this.errorInfos = res.data.errors[0].message;
          this.errorInfos = [...res.data.errors[0]];
          customFailedMessage(this.errNameInfo as any);
        }
      }
    });
  }
}
