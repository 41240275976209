
import { Component, Vue } from 'vue-property-decorator';
import {
  getMaintenanceItemsList,
  MaintenanceListItem,
  getMaintenanceItemKPIs,
  MaintenanceItemKPI,
} from '@/api/maintenance';
import { UserModule } from '@/store/modules/user';
import {
  MAINT_CONF_LIST,
  MAINT_CONF_SEARCH_FIELDS,
} from '@/utils/workData/maintenanceConf';
import { customFailedMessage } from '@/utils/prompt';
import { getProductModels } from '@/api/maintenance';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { ProductModel } from '@/api/products';

interface AssetTypeList {
  id: string;
  label: string;
}

@Component({
  name: 'maintConf',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  pageSize = UserModule.gridPageSize;
  page: number = 1;
  cols = MAINT_CONF_LIST;
  assetTypeList: AssetTypeList[] = [];
  total: number = 0;
  maintenanceConfigIsLoading: boolean = false;
  productModelDisabled: boolean = true;
  tableList: MaintenanceListItem[] = [];
  productModels: ProductModel[] = [];
  assetType: string = '';
  productModel: string = '';
  maintFieldList = MAINT_CONF_SEARCH_FIELDS;
  searchObj: any = {
    reference: null,
    value: null,
  };
  sort: { field: string; order: SorterOrder } | null = null;
  sortableOrder: SorterOrder = SorterOrder.DESC;
  productModelDropwdownIsLoading: boolean = false;
  assetTypeListIsLoading: boolean = false;
  productModelsPage: number = 1;
  productModelsListSize: number = 10000;

  async created() {
    await this.getAvailableAssetTypeByKpis();
    this.fetchMaintenanceListItems();
  }

  /**
   * Fetch available asset types by maintenance KPIs
   * Used in the asset type dropdown options for only those asset types that have KPIs, will be used for requesting rules
   */
  async getAvailableAssetTypeByKpis(): Promise<void> {
    try {
      this.assetTypeListIsLoading = true;
      this.assetTypeList = [];
      const response = await getMaintenanceItemKPIs({});
      const assetTypeCodes: string[] = response.data?.map(
        (item: MaintenanceItemKPI) => item.assetTypeCode
      );
      assetTypeCodes.forEach((assetTypeCode: string) => {
        if (
          !this.assetTypeList.some(
            (item: AssetTypeList) => item.id === assetTypeCode
          )
        ) {
          this.assetTypeList.push({ id: assetTypeCode, label: assetTypeCode });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.assetTypeListIsLoading = false;
    }
  }

  /**
   * Prepare query parameters for API requests
   * Take values from search obj, assetType, productModel, sort and pagination
   * If assetTypeCode is selected, value assetType will be send to API
   * When searchObj has reference status (maintenance status) filter operator will have the value: EQUAL
   */
  get queryParameters(): QueryParameter {
    const filters: Filter[] = [];
    if (this.searchObj.reference && this.searchObj.value) {
      filters.push({
        name: this.searchObj.reference,
        operator:
          this.searchObj.reference === 'status'
            ? FilterOperator.EQUAL
            : FilterOperator.LIKE,
        value: [this.searchObj.value],
      });
    }

    if (this.assetType) {
      filters.push({
        name: 'assetType',
        operator: FilterOperator.EQUAL,
        value: [this.assetType],
      });
    }

    if (this.productModel) {
      filters.push({
        name: 'productModel',
        operator: FilterOperator.EQUAL,
        value: [this.productModel],
      });
    }
    const sorters: Sorter[] = [];
    if (this.sort && this.sort.field && this.sort.order) {
      sorters.push({
        field: this.sort.field,
        order: this.sort.order,
      });
    }
    const pagination: Pagination = {
      page: this.page,
      size: this.pageSize,
    };
    const queryParameters: QueryParameter = {
      filters: filters,
      sorters: sorters,
      pagination: pagination,
    };

    return queryParameters;
  }

  /**
   * Fetch maintenance configuration items list
   * @param filter
   */
  async fetchMaintenanceListItems(): Promise<void> {
    try {
      this.maintenanceConfigIsLoading = true;
      const res = await getMaintenanceItemsList(this.queryParameters);
      if (res.code === 200) {
        this.total = res.data.total;
        this.tableList = res.data.maintenanceItems;
        return;
      }

      customFailedMessage(res.data.errors![0].message);
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('maintenance.errorWithFetchingData').toString()
      );
    } finally {
      this.maintenanceConfigIsLoading = false;
    }
  }

  /**
   * Handle pagination event
   * @param page
   * @param pageSize
   */
  handlePagination(page: number, pageSize: number): void {
    this.page = page;
    this.fetchMaintenanceListItems();
  }

  /**
   * Handle filter event
   */
  handleFilter(receivedField: string, order: SorterOrder): void {
    const field: string =
      receivedField === 'assetTypeCode' ? 'assetType' : receivedField;
    this.sort = order && field ? { field, order } : null;
    this.fetchMaintenanceListItems();
  }

  /**
   * Fetch asset type change event
   */
  async handleAssetTypeChanged(assetType: string): Promise<void> {
    try {
      if (assetType === '') {
        this.productModel = '';
        this.productModelDisabled = true;

        this.fetchMaintenanceListItems();
      } else {
        this.productModel = '';

        await this.fetchMaintenanceListItems();
        this.productModelDropwdownIsLoading = true;
        const res = await getProductModels(
          assetType,
          'LCL_APPROVED',
          this.productModelsPage,
          this.productModelsListSize
        );
        if (res.code === 200 && res.data.length > 0) {
          this.productModelDisabled = false;
          const resData = res.data.filter((productModel: ProductModel) =>
            this.tableList.some(
              (maintItem: MaintenanceListItem) =>
                maintItem.productModelId === productModel.id
            )
          );

          resData.forEach((v: any) => {
            v.code = v.code + ' - ' + v.modelNumber;
          });

          this.productModels = resData;
          return;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.productModelDropwdownIsLoading = false;
    }
  }
}
