
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'GrowthPercentage',
})
export default class extends Vue {
  @Prop() private growthPercentage: {
    value: number | undefined;
    growth: number;
  };
}
