
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import { getUserById, getUsers } from '@/api/users';
import moment from 'moment';
import { UserModule } from '@/store/modules/user';
import UserManagementInputForm from './UserManagementInputForm.vue';
import UserDetailPage from './UserDetailPage.vue';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { getRoles } from '@/api/roles';
import {
  updateUser,
  createUser,
  getModules,
  deleteUser,
  updateActivationStatus,
} from '@/api/users';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import { copyUserTemplateFromCompany } from '@/api/users';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { getOrgById } from '@/api/organizations';
import { omit } from 'lodash';

interface SearchOptions {
  label: string;
  prop: string;
}

interface ModuleAccType {
  resource: string;
}

interface UserForm {
  id: string;
  email: string;
  role: string;
  claimsFtd: string[];
  claims: ModuleAccType[];
  organizationId: string | null;
  companyId: string;
  note: string;
  activationStatus: string;
  tenants: string;
  i18nCode: string;
  username: string;
  emailVerified: boolean;
}

@Component({
  name: 'UserManagement',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
    'user-management-input-form': UserManagementInputForm,
    'user-detail-page': UserDetailPage,
    'deactivate-dialog': DeactivateDialog,
    'module-access': ModuleAccess,
  },
})
export default class extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() searchFieldOptions!: SearchOptions[];
  @Prop() cols!: string;
  @Prop() activeName!: string;
  @Prop() customerId!: string;
  @Prop() organizationId!: string;
  @Prop() selectedCompanyType!: string;

  /** Local variables */
  isUserManagementLoading: boolean = false;
  isUserModuleAccessLoading: boolean = false;
  userList: string[] = [];
  totalUsers: number = 0;
  currentPage: number = 1;
  pageSize: number = UserModule.gridPageSize;
  userDetailFormIsVisible: boolean = false;
  userInputFormIsVisible: boolean = false;
  modalWindowIsVisible: boolean = false;
  inputFormIsInEditMode: boolean = false;
  deleteModalWindowIsVisible: boolean = false;
  dialogContent: string = '';
  userRoles = [];
  modulesInfo = [];
  rolesSearchFieldName: string = 'companyType';
  loadingModuleAccess: boolean = false;
  activateOrDeactivate: string = '';
  userSearchParams: any = {
    reference: null,
    value: null,
  };
  userSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  userForm: UserForm = {
    id: '',
    email: '',
    role: '',
    claimsFtd: [],
    claims: [],
    organizationId: null,
    companyId: this.customerId,
    note: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    tenants: this.customerId,
    username: '',
    i18nCode: UserModule.i18nCode,
    emailVerified: false,
  };
  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  activateOrDeactivateBtnIsVisible: boolean = false;
  userDetailsFormIsLoading: boolean = false;

  created() {
    this.fetchRoles();
    this.fetchModules();
    this.prepareGetUsers();
  }

  /** Prepare get users */
  prepareGetUsers() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.userSearchParams,
      this.userSortAndOrderData.sortBy,
      this.userSortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  /**
   * Fetch user management data
   * @param finalUrl
   */
  async fetchUserManagementData(finalUrl: string) {
    try {
      this.isUserManagementLoading = true;
      await getUsers(finalUrl).then((res) => {
        if (res.code === 200) {
          let resData = res.data;
          resData.users.forEach((item: any) => {
            item.emailVerified = item.emailVerified
              ? 'userModule.yes'
              : 'userModule.no';
          });

          this.userList = resData.users;
          this.totalUsers = resData.total;
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isUserManagementLoading = false;
      this.sendTotalUsers();
    }
  }

  /**
   * Handle user input form display when btn click
   */
  handleUserInputFormDisplay() {
    this.userDetailFormIsVisible = false;
    this.userInputFormIsVisible = true;
  }

  fetchUserManagementBySearchParameters() {}

  /** Get users by page selection */
  fetchUsersDataByPageSelection(page: number) {
    this.currentPage = page;
    this.prepareGetUsers();
  }

  /** Get users by sorting event */
  fetchUsersDataBySortEvent(sortBy: string, order: string) {
    order != ''
      ? (this.userSortAndOrderData.sortBy = sortBy)
      : (this.userSortAndOrderData.sortBy = null);
    order != ''
      ? (this.userSortAndOrderData.order = order)
      : (this.userSortAndOrderData.order = null);
    this.prepareGetUsers();
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'createdDate'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'createdDate'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;
    let finalUrl = '';

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    finalUrl += `&companyId=${this.customerId}`;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  /**
   * Handle flow for creating a new user
   */
  async handleRemoteApiRequest() {
    try {
      /** Take email as username otherwhise generate random string for mandatory username */
      this.userForm.username =
        this.userForm.email?.split('@')[0] ??
        (Math.random() + 1).toString(36).substring(7);
      this.isUserManagementLoading = true;

      /** Temporary */
      this.userForm.emailVerified = false;
      const newValues = omit(this.userForm, 'claims');

      if (!this.inputFormIsInEditMode) {
        await createUser(newValues).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.created') as string);
            this.errorInfos = [];
            this.copyUserTemplateFromCompany(res.data.id);
            return;
          }

          if (
            res.code === 400 &&
            res.data.errors[0].code === 'ApiErrorMaxUsersExceeded'
          ) {
            customFailedMessage(
              this.$t('userModule.maximumUsersExceeded') as string
            );
            this.errorInfos = res.data.errors;
            return;
          }

          if (
            res.code === 400 &&
            res.data.errors.indexOf('ApiErrorFieldDuplicate')
          ) {
            customFailedMessage(this.$t('userModule.duplicateEmail') as string);
            return;
          }

          promptFailedBox(this.$t('common.creation') as string);
          this.errorInfos = res.data.errors;
        });
        return;
      }

      await updateUser(this.userForm.id, newValues).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.updated') as string);
          this.errorInfos = [];
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isUserManagementLoading = false;
      this.toggleUserManagementForm();
    }
  }

  /**
   * Copy user template from company for a new created user
   * @param userId
   */
  async copyUserTemplateFromCompany(userId: string) {
    await copyUserTemplateFromCompany(userId).then((res) => {
      if (res.code === 400) {
        promptFailedBox(this.$t('dashboardConf.templateNotExist') as string);
      }
    });
  }

  /**
   * Get roles
   */
  async fetchRoles() {
    try {
      let finalUrl = `?searchFieldName=${this.rolesSearchFieldName}&searchFieldValues=${this.selectedCompanyType}&size=100`;
      await getRoles(finalUrl).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.userRoles = res.data.roles;
          return;
        }

        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Handle email input
   */
  handleEmailInput() {
    this.errorInfos = [];
  }

  /**
   * Change role event handle
   * @param value
   */
  changeRole(value: any) {
    let arr: string[] = [];
    if (value) {
      value.forEach((item: any) => {
        arr.push(item.resource);
      });
      this.userForm.claimsFtd = arr;
    } else {
      this.userForm.claimsFtd = [];
    }
  }

  /**
   * Fetch Access Modules
   */
  async fetchModules() {
    try {
      this.loadingModuleAccess = true;
      await getModules().then((res) => {
        if (res.code === 200) {
          this.modulesInfo = res.data;
        }
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.loadingModuleAccess = false;
    }
  }

  /** Toggle user management form hide or display */
  toggleUserManagementForm() {
    this.userInputFormIsVisible = !this.userInputFormIsVisible;
    if (!this.userInputFormIsVisible) {
      this.prepareGetUsers();
      this.cleanupUserInputForm();
    }
  }

  /** Clean up user input form */
  cleanupUserInputForm() {
    this.userForm = {
      id: '',
      email: '',
      role: '',
      claimsFtd: [],
      claims: [],
      organizationId: null,
      companyId: this.customerId,
      note: '',
      activationStatus: ACTIVATION_STATUS.Activated,
      tenants: this.customerId,
      username: '',
      i18nCode: UserModule.i18nCode,
      emailVerified: false,
    };
    this.inputFormIsInEditMode = false;
  }

  /** Open detail view page for table item row click */
  async handleRowItemClick(userRowItem: any) {
    if (userRowItem.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.activateOrDeactivate = 'activate';
    } else {
      this.activateOrDeactivate = 'deactivate';
    }
    this.userForm = userRowItem;
    this.userDetailFormIsVisible = true;
    await this.handleGetUserModuleAccessData();
  }

  /** Show modal window for deactivate/activate an user*/
  showModalWindow() {
    if (this.userForm.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.userForm.username,
      }) as string;
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.userForm.username,
      }) as string;
    }
    this.modalWindowIsVisible = true;
  }

  /** Show modal window for removing user */
  showModalWindowForRemovingUser() {
    this.deleteModalWindowIsVisible = true;
  }

  /** Cancel activate/deactivate modal window */
  handleCancelDeactivateModal() {
    this.modalWindowIsVisible = false;
  }

  /** Edit current user from detail view page */
  editCurrentUser() {
    this.inputFormIsInEditMode = true;
    this.handleUserInputFormDisplay();
  }

  /** Cancel user input form */
  cancelUserManagementForm() {
    this.cleanupUserInputForm();
    this.userDetailFormIsVisible = false;
  }

  /** Remove user remotly */
  async handleRemoveUser() {
    try {
      this.deleteModalWindowIsVisible = false;
      this.isUserManagementLoading = true;
      var response = await deleteUser(this.userForm.id);
      if (response.code !== 200) {
        promptFailedBox(this.$t('common.removed') as string);
        return;
      }
      promptSuccessBox(this.$t('common.remove') as string);
    } catch (error) {
      console.log(error);
    } finally {
      this.prepareGetUsers();
      this.userDetailFormIsVisible = false;
      this.userInputFormIsVisible = false;
    }
  }

  /** Handle deactivate user */
  async handleDeactivateOrActivateUser() {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivate')
        : this.$t('common.activate')
    ) as string;
    try {
      this.modalWindowIsVisible = false;
      this.isUserManagementLoading = true;
      await updateActivationStatus(this.userForm.id, {
        activationStatus:
          this.activateOrDeactivate === 'deactivate'
            ? ACTIVATION_STATUS.Deactivated
            : ACTIVATION_STATUS.Activated,
      }).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(infoMsg);
          this.userForm.activationStatus = ACTIVATION_STATUS.Deactivated;
        } else if (res.code === 400) {
          promptFailedBox(infoMsg);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.userDetailFormIsVisible = false;
      this.userInputFormIsVisible = false;
      this.cleanupUserInputForm();
      this.prepareGetUsers();
    }
  }

  /** Close dialog related to remove user */
  closeDeleteDialog() {
    this.deleteModalWindowIsVisible = false;
  }

  /**
   * Send total users count to parent component for header statistics component
   */
  @Emit('send-total-users')
  sendTotalUsers() {
    return this.totalUsers;
  }

  handleSelectedAccessModules() {
    this.userForm.claimsFtd = this.userForm.claims.map(
      (item: any) => item.resource
    );
  }

  async handleGetUserModuleAccessData() {
    try {
      this.isUserModuleAccessLoading = true;
      this.userDetailsFormIsLoading = true;
      const result = await getUserById(this.userForm.id);
      this.getOrganizationById(result.data?.organizationId);
      this.userForm.claims = result.data.claims;
      this.handleSelectedAccessModules();
    } catch (error) {
      console.log(error);
    } finally {
      this.isUserModuleAccessLoading = false;
    }
  }

  /**
   * Fetch assigned org by id details for selected user
   * @param organizationId
   */
  async getOrganizationById(organizationId: string): Promise<void> {
    try {
      if (!organizationId) return;
      this.activateOrDeactivateBtnIsVisible =
        (await getOrgById(organizationId)).data.activationStatus ===
        ACTIVATION_STATUS.Activated
          ? true
          : false;
    } catch (error) {
      console.log(error);
    } finally {
      this.userDetailsFormIsLoading = false;
    }
  }
}
