
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';

@Component({
  name: 'SubscribeTemplateTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop({ default: true }) showPage!: boolean;
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() viewPagePath!: string;
  @Prop() actionTable!: string;
  @Prop() maxHeight!: string;

  /** Local variables */
  list: any = [...this.tableList];
  tableComponentKey: string = new Date().toString();
  dropCols: any = [];
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  routesWithEditModelDisabled: any = [
    'alarmConfigurationViewPage',
    'Administration',
  ];

  @Watch('tableList', {
    deep: true,
  })
  onListChange() {
    this.list = [...this.tableList];
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  mounted() {
    this.columnDrop();
  }

  /**
    all the action buttons call the same function, the event name depends on the item param
    * @param item
    * @param row
    */
  handleActionBtnClick(item: string, row: any) {
    this.$emit('action-event', item, row);
  }

  handleRowClick(row: any, column: any, event: any) {
    if (
      row.hasOwnProperty('gatewayId') &&
      row.gatewayId &&
      !row.hasOwnProperty('installTime')
    ) {
      this.$router.push('register-connecting/view/' + row.id);
      return;
    }

    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + '/' + row.id);
    }
  }

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  columnDrop() {
    var that = this;
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        let oldMoveItem = this.dropCols[evt.oldIndex];
        let newMoveItem = this.dropCols[evt.newIndex];

        let oldItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === oldMoveItem.prop;
        });
        let newItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === newMoveItem.prop;
        });
        this.cols.splice(newItemIndex, 0, this.cols.splice(oldItemIndex, 1)[0]);
      },
    });
  }

  get checkIfAlarmConfigIsInEditMode(): boolean {
    return !this.routesWithEditModelDisabled.includes(this.$route.name);
  }

  /** Validate input values to be only number + delete key */
  acceptOnlyNumericValues(event: any) {
    const char = String.fromCharCode(event.keyCode);
    if (!/[0-9|\b|/]/.test(char)) {
      event.preventDefault();
    }
  }
}
