
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { COMPACTOR_PARTS_LIST } from '@/utils/workData/singleAsset';
import { MODEL_PART_TYPE_TABS } from '@/utils/workData/lookuptable';
import PureTable from '@/components/table/PureTable.vue';
import { getAssetById } from '@/api/assets';
import { getProductModelById, ContainedPart } from '@/api/products';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { Ref, unref } from 'vue';

interface Part {
  value: string;
  label: string;
}

interface TableCol {
  label: string;
  prop: string;
  required: boolean;
  visible: boolean;
}

interface SortAndOrder {
  sortBy: string | null;
  order: string | null;
}

@Component({
  name: 'AssetPartsInfo',
  components: {
    WidgetCard,
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  widgetIsLoading: boolean = false;
  productId: string = '';
  partsTableColumns: TableCol[] = COMPACTOR_PARTS_LIST;
  partsTypeTabs: Part[] = MODEL_PART_TYPE_TABS;
  selectedPartType: string = 'PARTTYP_HYDRAULIC';
  total: number = 0;
  partsTableIsLoading: boolean = false;
  tableList: ContainedPart[] = [];
  sortAndOrderData: SortAndOrder = {
    sortBy: null,
    order: null,
  };
  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  mounted(): void {
    this.getProductId();
  }

  /**
   * Detect changes in the part type tab selection and retrieve data from API
   * @param value
   * @param oldVal
   */
  @Watch('selectedPartType')
  private watchPartsTypeChange(value: any, oldVal: any): void {
    this.getSpecificWarehousePart();
  }

  /**
   * Get product model by id
   */
  async getProductId(): Promise<void> {
    try {
      this.widgetIsLoading = true;
      await getAssetById(this.$route.params.id, unref(this.context)).then(
        (res) => {
          if (res.code === 200 && res.data) {
            this.productId = res.data.productModelId;
            this.getSpecificWarehousePart();
          }
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.widgetIsLoading = false;
    }
  }

  /**
   * Get warehouse parts
   */
  async getSpecificWarehousePart(): Promise<void> {
    try {
      this.partsTableIsLoading = true;
      const res = await getProductModelById(this.productId);
      if (res.code === 200 && res.data.containedParts) {
        this.tableList = res.data.containedParts.filter(
          (item: ContainedPart) => {
            return item.partType === this.selectedPartType;
          }
        );
        if (this.tableList.length > 0) {
          this.tableList.forEach((item: ContainedPart) => {
            item.isMaintainable = item.isMaintainable
              ? this.$t('MAINTAINABLE_TRUE').toString()
              : this.$t('MAINTAINABLE_FALSE').toString();
            item.lifeTime = item.lifeTime
              ? `${item.lifeTime} ` + this.$t(item.lifeTimeUnit)
              : '';
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.partsTableIsLoading = false;
    }
  }

  /** Sorting by sort event */
  sortingPartsTable(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
  }
}
