
import { Component, Prop, Vue } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';

@Component({
  name: 'OrganizationDetailPage',
  components: {
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop() orgInfo!: any;
  @Prop() isHyvaHelpdeskAdmin!: boolean;
}
