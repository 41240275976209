
import { Component, Vue } from 'vue-property-decorator';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { getKpiData, KpiDataResponse } from '@/api/assets';
import { UserModule } from '@/store/modules/user';
import {
  AssetType,
  ASSET_TYPE_HOME_STATUS_CLAIM,
  HealthStatus,
  OperationalStatus,
  SYSTEM_FEATURES,
  WarningType,
} from '@/utils/workData/lookuptable';
import mobileCompactor from '@/assets/imgs/home/mobile_compactor.png';
import staticCompactor from '@/assets/imgs/home/static_compactor.png';
import tippingVehicle from '@/assets/imgs/home/tipping_vehicle.png';
import inTransition from '@/assets/imgs/home/status_in_transition.svg';
import inactive from '@/assets/imgs/home/status_inactive.svg';
import maintenance from '@/assets/imgs/home/status_maintenance.svg';
import motorOn from '@/assets/imgs/home/status_motor_on.svg';
import offline from '@/assets/imgs/home/status_offline.svg';
import parked from '@/assets/imgs/home/status_parked.svg';
import stopped from '@/assets/imgs/home/status_stopped.svg';
import HealthModal from '@/views/dashboard/components/HealthModal.vue';
import { getAssetStatusConfig } from '@/utils/assets';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { CommonResult } from '@/api/commonResult';

type KpiData<T extends string> = Partial<
  Record<AssetType, Partial<Record<T, number>>>
>;

type HealthStatusFeatures = {
  type: AssetType;
  enabled: boolean;
}[];

type BaseConfig = {
  asset: BaseConfigAsset[];
  health: BaseConfigHealth[];
  status: BaseConfigStatus[];
};

type BaseConfigAsset = {
  type: AssetType;
  image: string;
  statuses: string[];
  url: string;
};

type BaseConfigHealth = {
  type: HealthStatus;
  color: string;
  warning: WarningType;
};

type BaseConfigStatus = {
  type: OperationalStatus;
  color: string;
  icon: string;
};

@Component({
  name: 'AssetStatus',
  components: {
    WidgetCard,
    HealthModal,
  },
})
export default class extends Vue {
  /** Local variables */
  loading = true;
  operationalStatus: KpiData<OperationalStatus> | null = null;
  healthStatus: KpiData<HealthStatus> | null = null;
  modal = {
    open: AssetType.All,
    title: '',
    warningType: WarningType.Normal,
  };
  healthSystemFeatures: HealthStatusFeatures = [];
  context!: Ref<ActiveContext>;

  /**
   * Get assets count
   */
  get assetCount() {
    return Object.entries(this.healthStatus ?? {}).reduce<
      Partial<Record<AssetType, number>>
    >(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Object.values(value).reduce((a, c) => a + c, 0),
      }),
      {}
    );
  }

  /**
   * Get health to be displayed
   */
  get displayHealth(): boolean {
    return this.healthSystemFeatures.some((f) => f.enabled);
  }

  async created() {
    this.prepareDefaultInitialization();
  }

  async prepareDefaultInitialization(): Promise<void> {
    this.context = useActiveContext();

    // remove assets for which the customer does not have access
    for (const { type, claim } of Object.values(ASSET_TYPE_HOME_STATUS_CLAIM)) {
      const hasMenu = await UserModule.hasMenu(claim);
      const hasType = await UserModule.hasSystemFeature([null, type]);
      const index = this.config.asset.findIndex((asset) => asset.type === type);

      if (!hasMenu || !hasType) {
        this.config.asset.splice(index, 1);
      }
    }

    // check for health features
    for (const { type } of this.config.asset) {
      const enabled = await UserModule.hasSystemFeature([
        SYSTEM_FEATURES.HealthScore,
        type,
      ]);

      this.healthSystemFeatures.push({ type, enabled });
    }

    try {
      const [operationalStatus, healthStatus] = await this.getData();

      // endpoint will return 404 if no assets are present in thingsboard
      // in this case no error should be thrown, don't even bother parsing the data, it's all zeros
      if ((operationalStatus as any).code !== 404) {
        this.operationalStatus = this.parseData<OperationalStatus>(
          operationalStatus.data
        );
      }

      if ((healthStatus as any).code !== 404) {
        this.healthStatus = this.parseData<HealthStatus>(healthStatus.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      // these will be null if an exception was thrown or if response was 404
      if (!this.operationalStatus) {
        this.operationalStatus = {};
      }

      if (!this.healthStatus) {
        this.healthStatus = {};
      }

      this.loading = false;
    }
  }

  /**
   * Check if it has health feature
   * @param type
   */
  hasHealthFeature(type: AssetType): boolean | undefined {
    return this.healthSystemFeatures.find((e) => e.type === type)?.enabled;
  }

  /**
   * Open modal
   * @param type
   * @param health
   */
  openModal(type: AssetType, health: HealthStatus): void {
    const warning = this.config.health.find((h) => h.type === health)?.warning;

    this.modal = {
      open: type,
      title: `${this.$tc(type)} (${this.assetCount[type] || 0})`,
      warningType: warning ?? WarningType.Normal,
    };
  }

  /**
   * Open page
   * @param type
   * @param status
   */
  openAssetsPage(type: AssetType, status: OperationalStatus): void {
    const url = this.config.asset.find((a) => a.type === type)?.url;
    this.$router.push(`/assets/${url}?status=${status}`);
  }

  /**
   * Parse data
   * @param response
   */
  parseData<T extends string>(
    response: KpiDataResponse
  ): Partial<Record<AssetType, Partial<Record<T, number>>>> {
    if (
      !response.details ||
      !response.details.length ||
      !response.details[0].fields.length
    ) {
      throw '';
    }

    const { values } = response.details[0].fields[0];

    if (!values.length || Object.keys(values[0]).length !== 3) {
      throw '';
    }

    const { v, 'KPI.AssetType': t, ...rest } = values[0];
    const key = Object.keys(rest)[0];

    return values.reduce<KpiData<T>>((acc, cur) => {
      const type = cur['KPI.AssetType'] as AssetType;
      const field = cur[key] as T;
      const value = cur.v ? parseInt(cur.v) : 0;

      return {
        ...acc,
        [type]: {
          ...acc[type],
          [field]: value,
        },
      };
    }, {});
  }

  /**
   * Fetch data remotly
   */
  getData(): Promise<
    [CommonResult<KpiDataResponse>, CommonResult<KpiDataResponse>]
  > {
    return Promise.all([
      getKpiData(
        this.getRequestBody('KPI.OperationalStatus'),
        unref(this.context)
      ),
      getKpiData(this.getRequestBody('KPI.HealthStatus'), unref(this.context)),
    ]);
  }

  /**
   * Get request body
   * @param kpiGroup
   */
  getRequestBody(kpiGroup: string) {
    return {
      metadata: {
        filter: {
          organizationIds: unref(this.context).organizationIds,
          groupBy: ['KPI.AssetType', kpiGroup],
        },
      },
      details: [
        {
          entity: 'ENTT_ASSET',
          fields: [
            {
              code: 'KPI.AssetId.count',
            },
          ],
        },
      ],
    };
  }

  /**
   * Retrieve status width
   * @param asset
   * @param type
   */
  getStatusWidth(asset: AssetType, type: OperationalStatus): string | number {
    const total = this.assetCount[asset];
    const count = this.operationalStatus?.[asset]?.[type];

    if (!total || !count) {
      return 0;
    }

    return `${(100 / total) * count}%`;
  }

  /**
   * Get status configuration
   * @param status
   * @param option
   */
  getStatusConfig(status: OperationalStatus, option: any): BaseConfig {
    return getAssetStatusConfig(status, option, this.config);
  }

  config: BaseConfig = {
    asset: [
      {
        type: AssetType.StaticCompactor,
        image: staticCompactor,
        statuses: [
          OperationalStatus.MotorOn,
          OperationalStatus.Stopped,
          OperationalStatus.Offline,
        ],
        url: 'static-compactors',
      },
      {
        type: AssetType.AlbaStaticCompactor,
        image: staticCompactor,
        statuses: [
          OperationalStatus.MotorOn,
          OperationalStatus.Stopped,
          OperationalStatus.Offline,
        ],
        url: 'alba-static-compactors',
      },
      {
        type: AssetType.MobileCompactor,
        image: mobileCompactor,
        statuses: [
          OperationalStatus.MotorOn,
          OperationalStatus.Stopped,
          OperationalStatus.InTransition,
          OperationalStatus.Offline,
        ],
        url: 'mobile-compactors',
      },
      {
        type: AssetType.TippingVehicle,
        image: tippingVehicle,
        statuses: [
          OperationalStatus.Active,
          OperationalStatus.Inactive,
          OperationalStatus.Parked,
          OperationalStatus.Maintenance,
          OperationalStatus.Offline,
        ],
        url: 'tipping-vehicles',
      },
      {
        type: AssetType.TableTopTissector,
        image: staticCompactor,
        statuses: [
          OperationalStatus.Active,
          OperationalStatus.Inactive,
          OperationalStatus.Maintenance,
          OperationalStatus.Offline,
        ],
        url: 'table-top-tissectors',
      },
    ],
    health: [
      {
        type: HealthStatus.Good,
        color: '#4DAF70',
        warning: WarningType.Normal,
      },
      {
        type: HealthStatus.MinorDefects,
        color: '#E89253',
        warning: WarningType.Warning,
      },
      {
        type: HealthStatus.NeedsFix,
        color: '#CE6666',
        warning: WarningType.Alarm,
      },
    ],
    status: [
      {
        type: OperationalStatus.MotorOn,
        color: '#6494E2',
        icon: motorOn,
      },
      {
        type: OperationalStatus.Stopped,
        color: '#E8BA53',
        icon: stopped,
      },
      {
        type: OperationalStatus.InTransition,
        color: '#E89253',
        icon: inTransition,
      },
      {
        type: OperationalStatus.Active,
        color: '#6494E2',
        icon: motorOn,
      },
      {
        type: OperationalStatus.Inactive,
        color: '#E8BA53',
        icon: inactive,
      },
      {
        type: OperationalStatus.Parked,
        color: '#E89253',
        icon: parked,
      },
      {
        type: OperationalStatus.Maintenance,
        color: '#CE6666',
        icon: maintenance,
      },
      {
        type: OperationalStatus.Offline,
        color: '#A7A7A7',
        icon: offline,
      },
    ],
  };

  get nonEmptyAssets() {
    return this.config.asset.filter((x) => (this.assetCount[x.type] || 0) > 0);
  }
}
