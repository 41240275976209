
import echarts, { EChartOption } from 'echarts';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ResizeMixin from '@/components/charts/mixins/resize';

export interface PieChartData {
  categories: string[];
  data: object[];
  colors: string[];
  selectedCategories?: object;
  events?: { name: string; callback: any }[];
}

@Component({
  name: 'Chart',
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'id' }) private id!: string;
  @Prop({ default: 'chart' }) private className!: string;
  @Prop({ default: '100%' }) private width!: string;
  @Prop({ default: '200px' }) private height!: string;
  @Prop({ required: true }) private data!: PieChartData;

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
    if (this.data.events) {
      this.data.events.forEach((e) => {
        if (!this.chart) return;
        this.chart.on(e.name, e.callback);
      });
    }

    this.chart.setOption({
      color: this.data.colors,
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} / {d}%',
      },
      legend: {
        orient: 'vertical',
        top: '2%',
        right: '2%',
        icon: 'circle',
        itemHeight: 20,
        textStyle: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 16,
          fontFamily: 'Roboto',
        },
        data: this.data.categories,
        selected: this.data.selectedCategories,
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: [30, 70],
          center: ['23%', '50%'],
          label: {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 16,
            fontFamily: 'Roboto',
            show: true,
            position: 'inside',
            formatter: function (param: any) {
              return param.value === 0 ? '' : `${param.value}`;
            },
            rich: {
              c: {
                color: '#000',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 16,
                fontFamily: 'Roboto',
              },
              per: {
                color: '#000',
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 14,
                fontFamily: 'Roboto',
              },
            },
          },
          data: this.data.data,
          animationEasing: 'cubicInOut',
          animationDuration: 1000,
        },
      ],
    } as EChartOption<EChartOption.SeriesPie>);
  }
}
