
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  AssetType,
  MAINTENANCE_ITEM_KPI_KIND_ENUM,
  MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM,
  MAINTENANCE_ITEM_STATUS,
  PRODUCT_MODEL_STATUS_ENUM,
} from '@/utils/workData/lookuptable';
import {
  createMaintenanceConfigItem,
  updateMaintenanceItem,
  getMaintItemById,
  getProductModels,
  MaintenanceForm,
  MaintenanceItemKPI,
  getMaintenanceItemKPIs,
  MaintenanceItemPart,
  MaintenanceItemRule,
  getMaintenanceItemsList,
  MainteanceItemsListResponse,
  MaintenanceListItem,
} from '@/api/maintenance';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import CusFormItem from '@/components/form/CusFormItem.vue';
import NewCard from '@/components/cusCard/NewCard.vue';
import BaseHeader from './components/BaseHeader.vue';
import CommonBtn from '@/components/button/CommonBtn.vue';
import MaintTransfer from '@/components/transfer';
import { customFailedMessage } from '@/utils/prompt';
import { ErrorType } from '@/api/types';
import {
  Filter,
  FilterOperator,
  QueryParameter,
  Pagination,
} from '@/model/queryParameters/QueryParameter';
import {
  ContainedPart,
  getProductModelById,
  ProductModel,
} from '@/api/products';
import { omit } from 'lodash';
import { CommonResult, CommonResultPromise } from '@/api/commonResult';

interface AssetTypeList {
  id: string;
  label: string;
}

interface QueryReference {
  name: string;
  value: string | string[];
}

interface TransferList {
  id: string;
  name: string;
}

@Component({
  name: 'AddNewMaintConf',
  components: {
    'new-card': NewCard,
    'base-header': BaseHeader,
    'cus-form-item': CusFormItem,
    'maint-transfer': MaintTransfer,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop() id!: string;

  /** Local variables */
  productModelChangesCount: number = 0; /** Used to determine in edit mode the first setup: 1 made by system then by user in UI */
  showErrorInfoReplaced: boolean = false;
  showErrorInfoChecked: boolean = false;
  loadParts: boolean = false;
  title: string = '';
  rightUpValue: string[] = [];
  rightDownValue: string[] = [];
  transferList: any = [];
  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  assetTypeList: AssetTypeList[] = [];
  productModels: ProductModel[] = [];
  kpiNaturalDayRegex: RegExp =
    /^[1-9][0-9]{0,3}$/; /** Only numbers between 1 - 9999 */
  kpiWorkingHoursRegex: RegExp =
    /^[1-9]\d{0,3}$|^[1-9][0-9]{0,3}$/; /** Only numbers between 1 - 9,999 */
  kpiPayloadRegex: RegExp =
    /^[1-9]\d{0,6}$/; /** Only numbers between 1 - 9,999,999 */
  countKPIKindRegex: RegExp =
    /^[1-9][0-9]{0,5}$/; /** Only numbers between 1 - 999,999 */
  naturalDaysKPIKindRegex: RegExp =
    /^[1-9][0-9]{0,3}$/; /** Only numbers between 1 - 9999 */
  maintenanceItemKPIs: MaintenanceItemKPI[] = [];
  maintConfigForm: MaintenanceForm = {
    name: '',
    assetType: 'ASSTYP_TIPPING_VEHICLES',
    productModelId: '',
    maintenanceParts: [],
    maintenanceRules: [],
    isConductedManually: true,
  };
  maintenanceConfigIsLoading: boolean = false;
  loadingText: string = '';
  maintenanceRulesAreLoading: boolean = false;
  assetTypeListIsLoading: boolean = false;
  productModelFieldIsLoading: boolean = false;
  maintenanceItemStatus: string = MAINTENANCE_ITEM_STATUS.MainiNew;
  pageSizeForListOfItems: number = 100000;

  created() {
    this.prepareDefaultInitialization();
  }

  /**
   * Prepare default initialization after component has been created
   */
  prepareDefaultInitialization() {
    this.getAvailableAssetTypeByKpis();
    if (this.id) {
      this.fetchMaintConfInfoById();
    } else {
      this.handleAssetTypeChanged('ASSTYP_TIPPING_VEHICLES');
    }
  }

  /**
   * Fetch available asset types by maintenance KPIs
   * Used in the asset type dropdown options for only those asset types that have KPIs, will be used for requesting rules
   */
  async getAvailableAssetTypeByKpis(): Promise<void> {
    try {
      this.assetTypeListIsLoading = true;
      this.assetTypeList = [];
      const response = await getMaintenanceItemKPIs({});
      const assetTypeCodes: string[] = response.data?.map(
        (item: MaintenanceItemKPI) => item.assetTypeCode
      );
      assetTypeCodes.forEach((assetTypeCode: string) => {
        if (
          !this.assetTypeList.some(
            (item: AssetTypeList) => item.id === assetTypeCode
          )
        ) {
          this.assetTypeList.push({ id: assetTypeCode, label: assetTypeCode });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.assetTypeListIsLoading = false;
    }
  }

  /** Keep the code for future mandatory validation on the maintenance parts while right now those are not necessary */
  // @Watch('rightUpValue')
  // handleErrorInfoReplaced() {
  //     this.showErrorInfoReplaced = this.rightUpValue.length === 0 ? true : false
  // }

  // @Watch('rightDownValue')
  // handleErrorInfoChecked() {
  //     this.showErrorInfoChecked = this.rightDownValue.length === 0 ? true : false
  // }

  /**
   * Retrieve true/false for a dropdown rule item to make it disable if is already selected
   * @param kpiRuleCode
   */
  checkIfRuleIsSelected(kpiRuleCode: string): boolean {
    let result = this.maintConfigForm.maintenanceRules?.find((item: any) => {
      return item.maintenanceKpiCode === kpiRuleCode;
    });

    if (result) return true;

    return false;
  }

  /**
   * Fetch maintenance item kpis by choosen asset type
   */
  async fetchMaintenanceItemKpis(): Promise<void> {
    try {
      if (!this.maintConfigForm?.assetType) return;
      this.maintenanceRulesAreLoading = true;
      const queryParameters: QueryParameter = {
        filters: [
          {
            name: 'assetType',
            operator: FilterOperator.EQUAL,
            value: [this.maintConfigForm.assetType],
          },
        ],
      };
      const response = await getMaintenanceItemKPIs(queryParameters);
      this.maintenanceItemKPIs = response.data;

      /**
       * When asset type changed (or default selected) and not in edit mode
       * Then add default natural days KPI as a mandatory non editable field!
       */
      this.handleDefaultMaintenanceRules(
        this.id === null || this.id === undefined
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.maintenanceRulesAreLoading = false;
    }
  }

  /**
   * Handle default maintenance rule (natural days) when in edit mode by reseting the array
   * If not in edit mode do nothing
   * @param idEditMode
   */
  handleDefaultMaintenanceRules(idEditMode: boolean): void {
    if (
      this.maintenanceItemKPIs &&
      this.maintenanceItemKPIs.length > 0 &&
      idEditMode
    ) {
      this.maintConfigForm.maintenanceRules = [];
      const kpiWithNaturalDays = this.maintenanceItemKPIs.find(
        (item: MaintenanceItemKPI) => item.code === 'MKPI.NaturalDays'
      );
      this.maintConfigForm.maintenanceRules.push(
        kpiWithNaturalDays
          ? {
              maintenanceKpiCode: 'MKPI.NaturalDays',
              thresholdValue: '',
              ...kpiWithNaturalDays,
            }
          : this.maintenanceItemKPIs[0]
      );
    }
  }

  /**
   * Validate maintenance Kpi Code
   * @param rule
   * @param value
   * @param callback
   */
  validateMaintenanceKpiCode = (_rule: any, value: any, callback: any) => {
    if (value) {
      callback();
    } else {
      callback(new Error(`${this.$t('maintConf.tipSelectKpi')}`));
    }
  };

  /**
   * Validate maintenance kpi input numbers as threshold value related to the selected KPI code
   * As documentation required per AHMAPP-5646
   * For asset type: Tipping vechicle:
   *  - when kpi kind is _COUNT: 1 - 99,999
   *  - when kpi kind is _NATURAL_DAYS: 1 - 999
   * For other asset types, when kpi code is:
   *  - Natural Days: 1 - 999
   *  - Working hours: 1 - 9,999
   *  - Payload (ton): 1 - 9,999,999
   * @param _rule
   * @param value
   * @param callback
   */
  validateKpiThresholdValue = (_rule: any, value: any, callback: any) => {
    let index = _rule.field.split('.')[1];
    if (
      this.maintConfigForm?.maintenanceRules![index].kind ===
        MAINTENANCE_ITEM_KPI_KIND_ENUM.MKPIK_COUNT &&
      this.maintConfigForm?.assetType === AssetType.TippingVehicle
    ) {
      if (this.countKPIKindRegex.test(value)) {
        callback();
      } else {
        callback(new Error(`${this.$t('maintConf.tipCountKind')}`));
      }
    } else if (
      this.maintConfigForm?.maintenanceRules![index].kind ===
        MAINTENANCE_ITEM_KPI_KIND_ENUM.MKPIK_NATURAL_DAYS &&
      this.maintConfigForm?.assetType === AssetType.TippingVehicle
    ) {
      if (this.naturalDaysKPIKindRegex.test(value)) {
        callback();
      } else {
        callback(new Error(`${this.$t('maintConf.tipNaturalDayForm')}`));
      }
    } else if (
      this.maintConfigForm?.maintenanceRules![index].maintenanceKpiCode ===
      'MKPI.NaturalDays'
    ) {
      if (this.kpiNaturalDayRegex.test(value)) {
        callback();
      } else {
        callback(new Error(`${this.$t('maintConf.tipNaturalDayForm')}`));
      }
    } else if (
      this.maintConfigForm?.maintenanceRules![index].maintenanceKpiCode ===
      'MKPI.WorkingHours'
    ) {
      if (this.kpiWorkingHoursRegex.test(value)) {
        callback();
      } else {
        callback(new Error(`${this.$t('maintConf.tipWorkingHoursForm')}`));
      }
    } else if (
      this.maintConfigForm?.maintenanceRules![index].maintenanceKpiCode ===
      'MKPI.Payload'
    ) {
      if (this.kpiPayloadRegex.test(value)) {
        callback();
      } else {
        callback(new Error(`${this.$t('maintConf.tipPayloadForm')}`));
      }
    }
    callback();
  };

  /**
   * Validate maintenance item name
   * @param rule
   * @param value
   * @param callback
   */
  validateMaintItemNameLength = (_rule: any, value: string, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(new Error(`${this.$t('maintConf.tipMaintItemNameForm')}`));
    } else {
      callback();
    }
  };

  /**
   * Rules definition for input forms for creating/updating a maintenance item
   */
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('maintConf.tipInputMaintItemName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateMaintItemNameLength, trigger: 'change' },
      ],
      productModelId: [
        {
          required: true,
          message: this.$t('maintConf.tipSelectProductModel'),
          trigger: 'change',
        },
      ],
      maintenanceKpiCode: [
        { validator: this.validateMaintenanceKpiCode, trigger: 'change' },
      ],
      thresholdValue: [
        { validator: this.validateKpiThresholdValue, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  /**
   * Return default value for input form inline message
   */
  get inlineMsg(): boolean {
    return false;
  }

  /**
   * Get error item name
   */
  get errItemName(): string {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('maintConf.duplicateName')}`;
    }

    return errInfo;
  }

  /**
   * Handle parts right side checked list
   * @param value
   * @param direction
   * @param movedKeys
   */
  handleRightDownChange(value: any, direction: any, movedKeys: any): void {
    this.rightDownValue = value;
  }

  /**
   * Handle name imput
   */
  handleNameInput(): void {
    this.errorInfos = [];
  }

  /**
   * Handle asset type change event
   * Bubble changes towards product model -> product model contained parts -> item KPIs
   * @param assetTypeCode
   */
  async handleAssetTypeChanged(
    assetTypeCode: string,
    isInEditMode?: boolean
  ): Promise<void> {
    try {
      this.loadParts = true;
      this.productModelFieldIsLoading = true;

      /**
       * Determine default setup made automatically 0 to 1 then from > 1 changes made by User interaction
       * Used for to determine in edit mode the parts that are already used or not
       */
      this.productModelChangesCount++;

      /**
       * Very important! Need to clean all maintenance parts when this function is called from non editable content
       * And asset is changed by user click on the UI
       */
      if (!isInEditMode) {
        this.cleanMaintenanceParts();
      }

      /**
       * When asset type changed and is in edit mode then reset the rules in the form and add default natural days kpi
       */
      this.handleDefaultMaintenanceRules(
        this.id != null || this.id != undefined
      );

      /** Retrieve maintenance item KPIS */
      await this.fetchMaintenanceItemKpis();

      const productModelsList: CommonResult<ProductModel[]> =
        await getProductModels(
          assetTypeCode,
          PRODUCT_MODEL_STATUS_ENUM.LclApproved,
          1,
          10000
        );
      if (productModelsList.code === 200) {
        if (productModelsList.data.length > 0) {
          const resData = productModelsList.data;
          resData.forEach((v: any) => {
            v.code = v.code + ' - ' + v.modelNumber;
          });

          this.productModels = resData;
          const productModelObj = resData.find(
            (item: ProductModel) =>
              item.id === this.maintConfigForm.productModelId
          );
          productModelObj
            ? this.maintConfigForm.productModelId === productModelObj.id
            : (this.maintConfigForm.productModelId =
                productModelsList.data[0].id);
          this.handleProductModelChanged(
            productModelObj ? productModelObj.id : productModelsList.data[0].id,
            true
          );
          this.maintenanceConfigIsLoading = false;
          return;
        } else {
          this.productModels = [];
          this.maintConfigForm.productModelId = '';
          this.transferList = [];
        }
      }

      if (
        productModelsList.code === 400 &&
        productModelsList.data.errors[0].code === 'ApiErrorFieldNotFound'
      ) {
        customFailedMessage(
          this.$t('maintConf.maintenanceItemDoesNotExit').toString()
        );
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('maintenance.errorWithFetchingData').toString()
      );
    } finally {
      this.maintenanceConfigIsLoading = false;
    }
  }

  /**
   * Handle product model change from dropdown
   * @param moduleId
   */
  async handleProductModelChanged(
    productModelId: string | undefined,
    isEditMode?: boolean
  ): Promise<void> {
    try {
      this.loadParts = true;

      /** Fetch all existing maintenance items parts ids by asset type and product model of the current selection */
      const allMaintenanceItemPartsIds: string[] =
        await this.getAllMaintenanceItemsPartsIds(productModelId!);

      const productModelById: CommonResult<ProductModel> =
        await getProductModelById(productModelId!);
      if (productModelById.code === 200) {
        this.handleMaintenanceParts(
          productModelById,
          isEditMode,
          allMaintenanceItemPartsIds
        );
      }

      if (
        productModelById.code === 400 &&
        productModelById.data.errors![0].code === 'ApiErrorFieldNotFound'
      ) {
        customFailedMessage(
          this.$t('maintConf.maintenanceItemDoesNotExit').toString()
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadParts = false;
      this.productModelFieldIsLoading = false;
    }
  }

  /**
   * Check scanarios:
   * When creating new maintenance item: display only product models parts that are not already used
   * When editing an existing item:
   *  - make sure that product model selection doesn't have already used parts: do not display!
   *  - make sure when changing to another product model (or asset type) and reset the selection to display the already saved ones
   * @param productModelById
   * @param isEditMode
   * @param allMaintenanceItemPartsIds
   */
  handleMaintenanceParts(
    productModelById: CommonResult<ProductModel>,
    isEditMode: boolean | undefined,
    allMaintenanceItemPartsIds: string[]
  ): void {
    if (productModelById.data.containedParts) {
      if (!isEditMode) this.cleanMaintenanceParts();
      this.transferList = [];
      let transferData: TransferList[] = [];
      productModelById.data.containedParts.map((item: ContainedPart) => {
        if (
          !allMaintenanceItemPartsIds.some(
            (partId: string) => partId === item.id
          )
        ) {
          /** If not in edit mode A
           * And the parts are not already used
           * Then add/display the existing product model items
           */
          transferData.push({
            id: item.id,
            name: item.name,
          });
        } else if (
          this.id &&
          !isEditMode &&
          this.productModelChangesCount <= 1
        ) {
          /** If in edit mode and triggered from user selection
           *  And is the default automatically setup (first) T
           *  Then add/display the existing product model items
           */
          transferData.push({
            id: item.id,
            name: item.name,
          });
        } else if (
          this.id &&
          this.productModelChangesCount <= 1 &&
          this.maintConfigForm.maintenanceParts!.some(
            (part: MaintenanceItemPart) => part.containedPartId === item.id
          )
        ) {
          /**
           * If in edit mode
           * And is the default automatically setup (created hook)
           * And is contained already in the editing item
           * Then add/display the existing product model items
           */
          transferData.push({
            id: item.id,
            name: item.name,
          });
        }
      });

      this.transferList = transferData;

      /** Put each part into coresponding replace/check fields */
      this.handleMaintenancePartsOnEditMode();
      return;
    }
    this.transferList = [];
  }

  /**
   * Retrieve all maintenance items contained parts ids
   * @param productModelId
   * @return string[]
   */
  async getAllMaintenanceItemsPartsIds(
    productModelId: string
  ): Promise<string[]> {
    if (!productModelId) return [];
    const selectedProductModel = this.productModels.find(
      (item: ProductModel) => item.id === productModelId!
    );

    /**
     * Prepare query references: product model(id) , asset type, status
     * Status is important to include only new and applied therefore when an item is deprecated it should be able to use those parts
     * In case of status new and applied it should not be able to see those parts (maintenance items are retrieved to check againest those)
     */
    const filterReferences: QueryReference[] = [
      {
        name: 'productModel',
        value: selectedProductModel?.code!,
      },
      {
        name: 'assetType',
        value: this.maintConfigForm.assetType!,
      },
      {
        name: 'status',
        value: ['MAINI_NEW', 'MAINI_APPLIED'],
      },
    ];

    const maintenanceItems: MainteanceItemsListResponse | undefined =
      await this.fetchMaintenanceItemsList(filterReferences);

    return maintenanceItems!.maintenanceItems
      .flatMap((item: MaintenanceListItem) =>
        item.maintenanceParts ? item.maintenanceParts : []
      )
      .map((item: MaintenanceItemPart) => item.containedPartId);
  }

  /**
   * Fetch list for existing maintenance items filtered by certain reference
   * Basic filtration: productModel (id), assetType code
   */
  async fetchMaintenanceItemsList(
    queryReferences: QueryReference[]
  ): Promise<MainteanceItemsListResponse | undefined> {
    try {
      const queryParameters = this.queryParametersForItemsList(queryReferences);
      const maintenanceItemsList: CommonResult<MainteanceItemsListResponse> =
        await getMaintenanceItemsList(queryParameters);
      if (maintenanceItemsList.code === 200) {
        return maintenanceItemsList.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Prepare query parameters for getting list of maintenance items
   * Adding filters from parameters
   */
  queryParametersForItemsList(
    queryReferences: QueryReference[]
  ): QueryParameter {
    const filters: Filter[] = [];
    queryReferences.forEach((reference: QueryReference) => {
      filters.push({
        name: reference.name,
        operator:
          reference.name === 'status'
            ? FilterOperator.IN
            : FilterOperator.EQUAL,
        value:
          reference.name === 'status'
            ? [...reference.value]
            : [reference.value],
      });
    });

    const pagination: Pagination = {
      page: 1,
      size: this.pageSizeForListOfItems,
    };

    const queryParameters: QueryParameter = {
      filters: filters,
      sorters: [],
      pagination: pagination,
    };

    return queryParameters;
  }

  /**
   * Handle save/update operations for maintenance item
   */
  async saveNewMaintItem(): Promise<void> {
    (this.$refs.maintConfigForm as any).validate(async (valid: any) => {
      if (valid) {
        this.maintenanceConfigIsLoading = true;
        this.maintConfigForm.name = this.maintConfigForm.name.trim();
        if (this.id) {
          this.maintConfigForm.maintenanceParts = [];
        }
        this.rightUpValue.forEach((v) => {
          if (
            !this.maintConfigForm.maintenanceParts?.some(
              (item: MaintenanceItemPart) => item.containedPartId === v
            )
          ) {
            (
              this.maintConfigForm.maintenanceParts as MaintenanceItemPart[]
            ).push({
              containedPartId: v,
              maintenanceOperationKind: 'MAINOK_REPLACE',
            });
          }
        });

        this.rightDownValue.forEach((v) => {
          if (
            !this.maintConfigForm.maintenanceParts?.some(
              (item: MaintenanceItemPart) => item.containedPartId === v
            )
          ) {
            (
              this.maintConfigForm.maintenanceParts as MaintenanceItemPart[]
            ).push({
              containedPartId: v,
              maintenanceOperationKind: 'MAINOK_CHECK',
            });
          }
        });

        if (this.id) {
          this.loadingText = this.$t(
            'maintenance.updatingMaintenanceItem'
          ) as string;

          /** Clean up any unused request body field for PUT API request */
          this.prepareMaintenanceRulesForRequestBody();
          let updateRequestBody =
            this.maintenanceItemStatus === MAINTENANCE_ITEM_STATUS.MainiApplied
              ? omit(
                  this.maintConfigForm,
                  'assetType',
                  'assetTypeCode',
                  'productModelId',
                  'productModel',
                  'status',
                  'id',
                  'maintenanceParts',
                  'maintenanceRules',
                  'duration'
                )
              : this.maintConfigForm;

          await updateMaintenanceItem(
            this.id,
            omit(updateRequestBody, 'productModel')
          ).then((res) => {
            if (res.code === 200) {
              this.errorInfos = [];
              promptSuccessBox(this.$t('common.saved') as string);
              this.$router.push('/maintenance-conf/index');
            } else if (res.code === 400) {
              promptFailedBox(this.$t('common.saved') as string);
              this.errorInfos = res.data.errors;
            }
            this.maintenanceConfigIsLoading = false;
          });
        } else {
          this.loadingText = this.$t(
            'maintenance.savingMaintenanceItem'
          ) as string;

          /** Prepare request body for create POST API request */
          this.prepareMaintenanceRulesForRequestBody();
          await createMaintenanceConfigItem(this.maintConfigForm).then(
            (res) => {
              if (res.code === 200) {
                this.errorInfos = [];
                promptSuccessBox(this.$t('common.created') as string);
                this.$router.push('/maintenance-conf/index');
              } else if (res.code === 400) {
                promptFailedBox(this.$t('common.create') as string);
                this.errorInfos = res.data.errors;
              }
              this.maintenanceConfigIsLoading = false;
            }
          );
        }
      } else {
        customFailedMessage(this.$tc('common.completeRequiredFieldsFirst'));
      }
      this.loadingText = '';
    });
  }

  /**
   * Before making POST/PUT request, update maintenance form > maintenance rules
   * To extract only maintenanceKpiCode & thresholdValue fields
   */
  prepareMaintenanceRulesForRequestBody(): void {
    const updatedRules = this.maintConfigForm.maintenanceRules?.map(
      (item: MaintenanceItemRule) => {
        return {
          maintenanceKpiCode: item.maintenanceKpiCode,
          thresholdValue: Number(item.thresholdValue),
        };
      }
    );
    Object.assign(this.maintConfigForm.maintenanceRules!, updatedRules);
  }

  /**
   * Fetch maintenance item by id from API
   */
  async fetchMaintConfInfoById(): Promise<void> {
    try {
      this.maintenanceConfigIsLoading = true;
      this.productModelFieldIsLoading = true;
      const res = await getMaintItemById(this.id);
      if (res.code === 200) {
        this.title = res.data.name;
        Object.assign(this.maintConfigForm, res.data);
        this.moveDefaultRuleToFirstPosition();
        this.maintenanceItemStatus = res.data.status;
        this.handleAssetTypeChanged(this.maintConfigForm.assetType!, true);
        return;
      }

      if (
        res.code === 400 &&
        res.data.errors![0].code === 'ApiErrorFieldNotFound'
      ) {
        customFailedMessage(
          this.$t('maintConf.maintenanceItemDoesNotExit').toString()
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.maintenanceConfigIsLoading = false;
    }
  }

  /**
   * Make sure if NaturalDays KPI rule exist for the selected asset
   * To be always on first position in DOM/array because that one will be mandatory disabled for editing
   */
  moveDefaultRuleToFirstPosition(): void {
    const indexOfNaturalKPICode =
      this.maintConfigForm?.maintenanceRules?.findIndex(
        (item: MaintenanceItemRule) =>
          item.maintenanceKpiCode === 'MKPI.NaturalDays'
      );
    if (indexOfNaturalKPICode && indexOfNaturalKPICode > 0) {
      const element = this.maintConfigForm?.maintenanceRules?.splice(
        indexOfNaturalKPICode,
        1
      )[0];
      this.maintConfigForm?.maintenanceRules?.splice(0, 0, element!);
    }
  }

  /**
   * On edit mode handle parts by replaced/checked of the selected product model
   * Extra fetch needed for product model by id for the name of the part
   */
  handleMaintenancePartsOnEditMode(): void {
    this.rightUpValue = [];
    this.rightDownValue = [];
    this.maintConfigForm?.maintenanceParts!.forEach(
      (v: MaintenanceItemPart) => {
        if (
          v.maintenanceOperationKind ===
          MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM.MAINOK_REPLACE
        ) {
          this.rightUpValue.push(v.containedPartId);
        } else if (
          v.maintenanceOperationKind ===
          MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM.MAINOK_CHECK
        ) {
          this.rightDownValue.push(v.containedPartId);
        }
      }
    );
    this.loadParts = false;
  }

  /**
   * Field is editable only when is in edit mode and different than applied or deprecated
   */
  get fieldIsEditable(): boolean {
    return (
      this.id != null &&
      this.id != undefined &&
      ['MAINI_APPLIED', 'MAINI_DEPRECATED'].includes(this.maintenanceItemStatus)
    );
  }

  /**
   * On adding a new maintenance rule, replace current object selected with all fields related to same code from maintenance kpis
   * Those fields are mandatory for different scenario validation on input per each kpi code and value
   */
  addNewMaintenanceRule(selectedKPICode: string) {
    let kpiWithSameCodeAndFields = this.maintenanceItemKPIs.find(
      (item: MaintenanceItemKPI) => item.code === selectedKPICode
    );
    if (!kpiWithSameCodeAndFields) return;
    const kpiToReplaceWith = {
      maintenanceKpiCode: selectedKPICode,
      thresholdValue: '',
      ...kpiWithSameCodeAndFields,
    };
    this.maintConfigForm.maintenanceRules?.splice(
      this.maintConfigForm.maintenanceRules?.findIndex(
        (currentRuleObj: MaintenanceItemRule) =>
          currentRuleObj.maintenanceKpiCode === selectedKPICode
      ),
      1,
      kpiToReplaceWith
    );
  }

  /**
   * Setup to clean up all reference to maintenance parts
   */
  cleanMaintenanceParts(): void {
    this.maintConfigForm.maintenanceParts = [];
    this.rightUpValue = [];
    this.rightDownValue = [];
    this.transferList = [];
  }
}
