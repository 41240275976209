
import { Ref, unref } from 'vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { getAssetLocation, getAssetsWithLocation } from '@/api/assetLocation';
import { GeofenceAsset, Geofence } from '@/api/geofenceTypes';
import { getAllGeofencesByOrganisationId } from '@/api/geofence';
import MapExpanded from '@/widgets/home/expanded/MapExpanded.vue';
import {
  ActiveContext,
  useActiveContext,
  flattenOrganizations,
} from '@/composables/context';
import { RouteAssetItem, ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { getAssetById } from '@/api/assets';
import { calculateAssetBoundsWithPadding } from '@/utils/bounds';
import { getCustomerById } from '@/api/customer';
import { getOrgByIdWithMultipleFiltering } from '@/api/organizations';
import { UserModule } from '@/store/modules/user';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';

@Component({
  name: 'Map',
  components: {
    MapExpanded,
    WidgetCard,
    LeafletMap,
  },
})
export default class extends Vue {
  /** Local variables */
  center: number[] = [0, 0];
  zoom: number = 10;
  assets: Array<GeofenceAsset> = [];
  geofences: Geofence[] = [];
  loading: boolean = true;
  @Inject() expanded!: boolean;
  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  $refs!: {
    map: LeafletMap;
  };

  async mounted() {
    this.assets = await this.fetchAssets();
    this.geofences = await this.fetchGeofences();
    this.$refs.map?.initializeGeofence(this.geofences);

    if (this.assets.length === 1) {
      this.center = [
        this.assets[0].geodeticLatitude,
        this.assets[0].geodeticLongitude,
      ];
      this.zoom = 10;
    } else if (this.assets.length > 1) {
      const bounds = calculateAssetBoundsWithPadding(this.assets);
      if (bounds.isValid()) {
        this.$nextTick(() => {
          this.$refs.map?.setBounds(bounds);
        });
      }
    }

    this.loading = false;
  }

  private async fetchAssets(): Promise<GeofenceAsset[]> {
    /** Viewing a single selected asset */
    if (this.$route.params.id) {
      const assetId = this.$route.params.id;
      if (!unref(this.context)) return [];
      const { data } = await getAssetById(assetId, unref(this.context));
      const { data: assets } = await getAssetLocation(
        data,
        unref(this.context)
      );

      // AHMAPP-5116 discovery:
      // With the following line enabled the map automatically extends the bounds to include geofences.
      // With the following line disabled the map is max-zoomed in to the asset position.
      // This is different from MapExpanded.vue where geofences are not rendered, causing different behavior.
      // Note that simply enabling the following line would not solve the issue completely, because it will
      // still have the same incorrect behavior when there are no geofences.

      // this.center = [assets[0].geodeticLatitude, assets[0].geodeticLongitude];

      return assets;
    }

    // Viewing a fleet of assets, possible only of a specific asset type
    const assetRelatedData: RouteAssetItem | undefined = this.$route.name
      ? ROUTE_ASSET_MAP.get(this.$route.name) ?? undefined
      : undefined;
    const { data: assets } = await getAssetsWithLocation(
      assetRelatedData?.assetTypeCode,
      unref(this.context)
    );
    return assets;
  }

  /**
   * Fetch geofences
   */
  private async fetchGeofences(): Promise<Geofence[]> {
    let selectedCustomer = unref(this.context)?.selectedCompany;
    let orgIds = await this.handleOrganizationsUUIDs(selectedCustomer!);
    let result = Array.of<Geofence>();

    for (const organization of orgIds) {
      const { data: response } = await getAllGeofencesByOrganisationId(
        organization,
        unref(this.context)
      );
      // TODO-TYPING: Cast to any is needed because GeofenceResponse and Geofence don't overlap enough. Should be resolved.
      result = result.concat(response.geofences as any);
    }
    return result;
  }

  /**
   * Handle map widget resize
   */
  handleMapWidgetResize(): void {
    this.$refs.map.fixMapSize();
  }

  /**
   * Handle organization ids used on requesting geofences for:
   * - Company type helpdesk: return flatten org ids of the selected one
   * - Tipical customer: return org ids of the selected organization from active context
   * @param selectedCustomer
   */
  async handleOrganizationsUUIDs(selectedCustomer: string): Promise<string[]> {
    if (UserModule.companyType != COMPANY_TYPE.Helpdesk) {
      return unref(this.context).organizationIds!;
    }
    const customerById = await getCustomerById(selectedCustomer!);
    const primaryOrganizationResponse = await getOrgByIdWithMultipleFiltering(
      customerById?.data?.primaryOrganizationId,
      `?selectedCustomer=${selectedCustomer!}`
    );
    return flattenOrganizations(primaryOrganizationResponse.data).map(
      (org) => org.id
    );
  }
}
