var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t('KPI.CurrentPayload')))]),_c('p',{staticClass:"kpi-value",style:({
          color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.payload),
        })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.payload))]),_vm._v(" "+_vm._s(_vm.$t(_vm.payloadUnit))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('LineChart',{ref:"payloadKpi",attrs:{"height":"150px","limitWarningRange":_vm.payloadLimitWarningRange,"chartData":_vm.payloadChartData,"yAxisUnit":_vm.$t(_vm.payloadUnit),"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1),_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t('KPI.CompactorFillingPercentage')))]),_c('p',{staticClass:"kpi-value",style:({
          color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.filling),
        })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.filling))]),_vm._v(" "+_vm._s(_vm.$t(_vm.fillingUnit))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"fillingKpi",attrs:{"height":"150px","limitWarningRange":_vm.fillingLimitWarningRange,"chartData":_vm.fillingChartData,"yAxisUnit":_vm.$t('metricUnits.percentage'),"isPercentage":true,"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1),_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t('KPI.OilTemperature')))]),_c('p',{staticClass:"kpi-value",style:({
          color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.oilTemperature),
        })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.oilTemperature))]),_vm._v(" "+_vm._s(_vm.$t(_vm.oilTemperatureUnit))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"oilTemperatureKpi",attrs:{"height":"150px","limitWarningRange":_vm.oilTemperatureLimitWarningRange,"chartData":_vm.oilTemperatureChartData,"yAxisUnit":_vm.$t('metricUnits.celsius'),"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1),_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t('KPI.HydraulicPressure')))]),_c('p',{staticClass:"kpi-value",style:({
          color: _vm.realTimeKPIColor(
            _vm.allRealTimeKPILastestData.hydraulicPressure
          ),
        })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.hydraulicPressure))]),_vm._v(" "+_vm._s(_vm.$t(_vm.hydraulicPressureUnit))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"hydraulicPressureKpi",attrs:{"height":"150px","limitWarningRange":_vm.hydraulicPressureLimitWarningRange,"chartData":_vm.hydraulicPressureChartData,"yAxisUnit":_vm.$t('metricUnits.bar'),"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1),_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t('KPI.OilLevel')))]),_c('p',{staticClass:"kpi-value",style:({
          color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.oilLevel),
        })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.oilLevel))])])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"oilLevelKpi",attrs:{"height":"150px","limitWarningRange":_vm.oilLevelLimitWarningRange,"chartData":_vm.oilLevelChartData,"isOilLevel":true,"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }