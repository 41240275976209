
import { Component, Vue } from 'vue-property-decorator';
import {
  REGISTERED_ASSETS_COLS,
  REGISTERED_ASSETS_SEARCH_OPTIONS,
  BODY_BUILDER_DEALER_REGISTERED_ASSETS_SEARCH_OPTIONS,
} from '@/utils/workData/assetsMgmt';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import { getAssetOnBoardingByFilterParam } from '@/api/assetsMgmt';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import { UserModule } from '@/store/modules/user';
import moment from 'moment';
import {
  COMPANY_TYPE,
  REGISTERED_ASSET_CONNECTION_STATUSES,
  CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING,
} from '@/utils/workData/lookuptable';
import { getAccessibleAssets } from '@/api/accessibleAssets';
import {
  CREATED_ON,
  ASSET_CONNECT_STATUS_FIELD,
  ASSET_CONNECTION_STATUS_FIELD,
} from '@/utils/constants';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  Pagination,
  addFilter,
  setFilters,
  setSorters,
  SorterOrder,
  addSorter,
} from '@/model/queryParameters/QueryParameter';

interface SortOrder {
  sortBy: string | null;
  order: string | null;
}

interface SearchParam {
  reference: string | null;
  operator: string | null;
  value: string | null;
}

interface Column {
  label: string;
  prop: string;
  required?: boolean;
  visible?: boolean;
  sortable?: boolean;
  opeartor?: string;
}

@Component({
  name: 'RegisteredAsset',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  assetSerial: string = '';
  registeredAssetsCols = REGISTERED_ASSETS_COLS;
  registeredAssetsSearchFields: Column[] = REGISTERED_ASSETS_SEARCH_OPTIONS;
  tableList = [];
  totalRegisteredAssets: number = 0;
  isRegisteredAssetsTableLoading: boolean = false;
  tableElementPath: string = 'register/view';
  pageSize = UserModule.gridPageSize;
  currentPage: number = 1;
  useAccessibleAssets: boolean = false;
  shouldUseAccessibleAssets: boolean = false;
  companyTypesForAccessibleAssets: string[] = [
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
  ];
  defaultFiltersForRegisteredTab: string[] = [
    REGISTERED_ASSET_CONNECTION_STATUSES.AssconbRegistered,
    REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnecting,
    REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnectingFailed,
  ];
  sortAndOrderData: SortOrder = {
    sortBy: null,
    order: null,
  };
  searchParams: SearchParam = {
    reference: null,
    operator: null,
    value: null,
  };
  defaultSortByReference: string = 'createdOn';
  defaultSortOrder: SorterOrder = SorterOrder.DESC;

  created() {
    this.$nextTick(() => {
      this.prepareDefaultInitialization();
    });
  }

  /**
   * Prepare default initialization for entering on Assets management > registered tab as default entry
   * - for hyvaadmin on old endpoint: GET /assetOnBoarding > prepare filter by connectionStatus: registered, connecting, connecting failed
   */
  prepareDefaultInitialization(): void {
    this.useAccessibleAssets = this.companyTypesForAccessibleAssets.includes(
      UserModule.companyType
    );
    if (this.useAccessibleAssets) {
      this.registeredAssetsSearchFields =
        BODY_BUILDER_DEALER_REGISTERED_ASSETS_SEARCH_OPTIONS;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Filter by sort event
   * @param sortBy
   * @param order
   */
  fetchRegisteredAssetsDataBySortEvent(sortBy: string, order: string): void {
    if (sortBy === 'serialNumber') sortBy = 'assetSerialNumber';
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    if (
      this.searchParams.reference ===
      CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus
    ) {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        this.currentPage,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order,
        this.searchParams.value
      );
      this.fetchRegisteredAssets(finalUrlParamsForSearch);
      return;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Handle paging event
   * @param page
   * @param pageSize
   */
  fetchRegisteredAssetsDataByPageSelection(
    page: number,
    pageSize: number
  ): void {
    this.currentPage = page;
    if (
      this.searchParams.reference ===
      CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus
    ) {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        this.currentPage,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order,
        this.searchParams.value
      );
      this.fetchRegisteredAssets(finalUrlParamsForSearch);
      return;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Handle search event
   * @param col
   * @param order
   */
  handleSearching(col: string, order: string): void {
    if (
      this.searchParams.reference ===
      CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus
    ) {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        this.currentPage,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order,
        this.searchParams.value
      );
      this.fetchRegisteredAssets(finalUrlParamsForSearch);
      return;
    }
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRegisteredAssets(finalUrlParamsForSearch);
  }

  /**
   * Generate request URL by multiple factors
   * @param pageNumber
   * @param pageSize
   * @param searchParams
   * @param sortBy
   * @param order
   * @param connectionStatus
   * @return string
   */
  generateRequestUrlWithParams(
    pageNumber: number,
    pageSize: number,
    searchParams: SearchParam,
    sortBy: string | null,
    order: string | null,
    connectionStatus?: string | null
  ): string {
    let finalUrl = '';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'installTime'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'installTime'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    finalUrl += connectionStatus
      ? `&${ASSET_CONNECTION_STATUS_FIELD}=${connectionStatus}`
      : `&${ASSET_CONNECTION_STATUS_FIELD}=${this.defaultFiltersForRegisteredTab}`;

    /** Do not add searchFieldName and searchFieldValue if the filtering is made on connectStatus reference */
    if (searchFieldName && searchFieldValue && !connectionStatus) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${searchFieldValue}`;
    }

    sortBy && order
      ? (finalUrl += `&sortBy=${sortBy}&order=${order}`)
      : (finalUrl += `&sortBy=${this.defaultSortByReference}&order=${this.defaultSortOrder}`);

    return finalUrl;
  }

  /**
   * Fetch registered assets accordingly with company type:
   * - for hyvaadmin: from asset on boarding
   * - for body builder: from accessible assets by org id
   * @param finalUrlParamsForSearch
   */
  async fetchRegisteredAssets(finalUrlParamsForSearch: string): Promise<void> {
    try {
      this.isRegisteredAssetsTableLoading = true;
      const res = this.useAccessibleAssets
        ? await getAccessibleAssets(
            this.prepareQueryParameterForAccessibleAssets(),
            UserModule.organizationId
          )
        : await getAssetOnBoardingByFilterParam(finalUrlParamsForSearch);

      this.totalRegisteredAssets = res.data.total;
      if (this.useAccessibleAssets) {
        this.tableList = res.data.assets.map((asset: any) => {
          if (Object.keys(asset).includes('connectionStatus')) {
            asset.connectStatus = asset.connectionStatus;
          }
          return asset;
        });
      } else {
        this.tableList = res.data.assetsOnBoarding.map((asset: any) => {
          if (Object.keys(asset).includes('connectionStatus')) {
            asset.connectStatus = asset.connectionStatus;
          }
          return asset;
        });
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isRegisteredAssetsTableLoading = false;
    }
  }

  /**
   * Prepare query parameters for accessible assets
   */
  prepareQueryParameterForAccessibleAssets(): QueryParameter {
    const defaultFilterForRegisteredAsset: Filter = {
      name: ASSET_CONNECT_STATUS_FIELD,
      operator: FilterOperator.IN,
      value: [
        REGISTERED_ASSET_CONNECTION_STATUSES.AssconbRegistered,
        REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnecting,
        REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnectingFailed,
      ],
    };
    const sorterField: string =
      this.sortAndOrderData.sortBy === 'assetSerialNumber'
        ? 'serialNumber'
        : this.sortAndOrderData.sortBy ?? '';
    const sorter: Sorter =
      this.sortAndOrderData.sortBy != null
        ? {
            field: sorterField,
            order:
              this.sortAndOrderData.order === SorterOrder.ASC
                ? SorterOrder.ASC
                : SorterOrder.DESC,
          }
        : { field: CREATED_ON, order: SorterOrder.DESC };
    const pagination: Pagination = {
      page: this.currentPage,
      size: this.pageSize,
    };
    let queryParameter: QueryParameter = {
      filters: [defaultFilterForRegisteredAsset],
      sorters: [sorter],
      pagination: pagination,
    };

    if (this.searchParams.value != null) {
      /** filter option assetSerialNumber as we receive from response payload in request filter is mapped as serialNumber */
      const filterName: string =
        this.searchParams.reference === 'assetSerialNumber'
          ? 'serialNumber'
          : this.searchParams.reference ?? '';
      const operator: any = this.searchParams.operator
        ? this.searchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: filterName,
        operator: operator,
        value: [this.searchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }
}
