
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { promptSuccessBox, customFailedMessage } from '@/utils/prompt';
import { cloneDeep } from 'lodash';
import { UserModule } from '@/store/modules/user';
import {
  MAINTAINABLE_LIST,
  LIFETIME_UNIT_LIST,
  PROD_ERROR_CODE_LIST,
  PRODUCT_MODEL_LIFECYCLE_ENUM,
  PROD_CATA_OBU_INSTANCE_LIST,
  MODEL_PART_TYPE_ENUM,
} from '@/utils/workData/lookuptable';
import {
  getPartMaterials,
  getFilterPartMaterials,
  updateProductModel,
  PartMaterial,
  ProductModel,
} from '@/api/products';
import { PROD_CATALOGUE_MODEL_PARTS_INFORMATIONS } from '@/utils/workData/prodCata';
import { ErrorType } from '@/api/types';

interface Column {
  label: string;
  prop: string;
  required: boolean;
  visible: boolean;
  sortable: string;
}

interface SortAndOrder {
  sortBy: string | null;
  order: string | null;
}

interface Part {
  name: string;
  partMaterialId: string;
  partMaterialName: string;
  partType: string;
  isMaintainable: boolean;
  lifeTime: string;
  lifeTimeUnit: string;
  disabled?: boolean;
  obuInstance?: string;
}

interface PartForm {
  parts: Part[];
}

@Component({
  name: 'partsForm',
  components: {},
})
export default class extends Vue {
  @Prop({ required: false }) partsInfoPrev!: any;
  @Prop({ required: false }) lifeCycle!: string;
  @Prop({ required: false }) state!: any;

  /** Local variables */
  maintainableList = MAINTAINABLE_LIST;
  obuInstances = PROD_CATA_OBU_INSTANCE_LIST;
  partsTableIsLoading: boolean = false;
  lifeTimeUnitList = LIFETIME_UNIT_LIST;
  materialNumberList: PartMaterial[] = [];
  columns: Column[] = PROD_CATALOGUE_MODEL_PARTS_INFORMATIONS;
  partForm: PartForm = {
    parts: [],
  };
  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  pageSize: string = UserModule.gridPageSize.toString();
  page: string = '1';
  sortAndOrderData: SortAndOrder = {
    sortBy: null,
    order: null,
  };
  selectForm = {
    partNameorId: '',
    partType: '',
    lifeCycle: 'LCL_APPROVED',
  };

  created() {}

  /**
   * Error name info
   */
  get errNameInfo(): string {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.partType)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleError')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.partMaterialNumber
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.partMaterialNumberDuplicated')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  /**
   * Watch over page in preview mode to preset conditions before changing to edit mode
   * @param value
   * @param oldVal
   */
  @Watch('partsInfoPrev', { immediate: true, deep: true })
  private watchPartsInfo(value: any, oldVal: any): void {
    if (
      this.$route.params.action ||
      this.$route.params.action === 'copy' ||
      this.state === 'edit'
    ) {
      this.partForm.parts = this.partsInfoPrev.containedParts
        ? cloneDeep(this.partsInfoPrev.containedParts)
        : [];
    }
    if (this.state === 'edit' && this.lifeCycle != 'LCL_DRAFT') {
      this.partForm.parts.map((item: any) => (item.disabled = true));
    } else {
      this.partForm.parts.map((item: any) => (item.disabled = false));
    }
  }

  //send specData to parent component
  @Emit('send-parts-info')
  sendPartsData() {
    let allowedKeys = [
      'name',
      'partMaterialId',
      'partMaterialNumber',
      'partMaterialName',
      'isMaintainable',
      'partType',
      'lifeTime',
      'lifeTimeUnit',
      'obuInstance',
    ];
    this.partForm.parts.forEach((rawObject: Part) => {
      Object.keys(rawObject)
        .filter((key) => !allowedKeys.includes(key))
        .forEach((item) => delete rawObject[item as keyof typeof rawObject]);
    });
    return [...this.partForm.parts];
  }

  /** Get all material ids */
  async fetchAllMaterialNumbers(): Promise<void> {
    try {
      if (this.materialNumberList.length === 0) {
        this.partsTableIsLoading = true;
        const requestUrl = this.generateRequestUrlWithParams(
          this.page,
          this.pageSize,
          this.selectForm,
          this.sortAndOrderData
        );
        const res = await getFilterPartMaterials(requestUrl);
        this.materialNumberList = res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.partsTableIsLoading = false;
    }
  }

  /**
   * Generate request URL by multiple factors
   * Mandatory to filter by lifeCycle of type LCL_APPROVED to retrive items that are already approved
   * @param pageNumber
   * @param pageSize
   * @param selectForm
   * @param sortAndOrderData
   */
  generateRequestUrlWithParams(
    pageNumber: string,
    pageSize: string,
    selectForm: any,
    sortAndOrderData: any
  ): string {
    let finalUrl = '';
    selectForm.lifeCycle
      ? (finalUrl += `&lifeCycle=${selectForm.lifeCycle}`)
      : (finalUrl += '&lifeCycle=LCL_APPROVED');
    if (selectForm.partType) finalUrl += `&partType=${selectForm.partType}`;
    if (selectForm.partNameorId)
      finalUrl += `&nameOrNumber=${selectForm.partNameorId}`;
    pageNumber
      ? (finalUrl += `&page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    if (sortAndOrderData.sortBy && sortAndOrderData.order)
      finalUrl += `&sortBy=${sortAndOrderData.sortBy}&order=${sortAndOrderData.order}`;
    if (finalUrl) finalUrl = '?' + finalUrl.slice(1);
    return finalUrl;
  }

  /**
   * Add new part for current product model >  parts informations
   */
  addNewPart(): void {
    this.partForm.parts.push({
      name: '',
      partMaterialId: '',
      partMaterialName: '',
      isMaintainable: false,
      partType: '',
      lifeTime: '',
      lifeTimeUnit: '',
      disabled: false,
      obuInstance: undefined,
    });
  }

  /**
   * Fetch it after the user has chosen the material number
   * @param e
   * @param index
   */
  matchNameAndType(e: any, index: any): void {
    if (!e) {
      getPartMaterials(this.partForm.parts[index].partMaterialId).then(
        (res: any) => {
          if (res.code === 200) {
            this.partForm.parts[index].partType = res.data?.partType;
            this.partForm.parts[index].partMaterialName = res.data?.name;
          }
        }
      );
    }
  }

  /**
   * Update product model parts data
   * @param productModelId
   */
  async updatePartsData(productModelId: string): Promise<void> {
    try {
      this.partsTableIsLoading = true;
      const requestPayload = new FormData();
      requestPayload.append('productModelPicture', '');
      requestPayload.append(
        'productModelRequestBody',
        new Blob([JSON.stringify({ containedParts: this.partForm.parts })], {
          type: 'application/json',
        })
      );
      const res = await updateProductModel(productModelId, requestPayload);
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.updated') as string);
        this.errorInfos = [];
        this.$router.push('/prod-cata/index?activeName=productModels');
      }
      if (res.code === 400) {
        customFailedMessage(this.$t('common.edit') as string);
        this.errorInfos = res.data.errors;
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.errNameInfo as any);
    } finally {
      this.partsTableIsLoading = false;
    }
  }

  /**
   * Handle delete part row
   * - if is approved do not allow deletion
   * @param index
   */
  handleDeletePartItem(index: number, isDisabled: boolean): void {
    if (
      this.lifeCycle != PRODUCT_MODEL_LIFECYCLE_ENUM.Approved ||
      !isDisabled
    ) {
      this.partForm.parts.splice(index, 1);
      return;
    }
    customFailedMessage(this.$t('prodCata.approvedNoDeletable').toString());
  }

  /**
   * Get state of life cycle
   */
  get partIsApproved(): boolean {
    return this.lifeCycle != PRODUCT_MODEL_LIFECYCLE_ENUM.Draft;
  }

  /**
   * Get delete row button style
   */
  deleteRowBtnStyle(isDisabled: boolean): string {
    return this.lifeCycle != PRODUCT_MODEL_LIFECYCLE_ENUM.Draft && isDisabled
      ? 'color: gray;'
      : '';
  }

  obuInstanceIsApplicable(rowPartType: string): boolean {
    return rowPartType === MODEL_PART_TYPE_ENUM.PartTypeDigital;
  }
}
