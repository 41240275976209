
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import VerticalDivide from '../components/VerticalDivide.vue';
import { getAssetInfoById } from '@/api/assetsMgmt';
import moment from 'moment-timezone';
import ConnectionProgress from '../components/ConnectionProgress.vue';
import { PageModule } from '@/store/modules/page';
import { formatServerDate } from '../helper';
import { AssetType } from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'ConnectAssetView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    VerticalDivide,
    'connection-progress': ConnectionProgress,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;

  /** Local variables */
  assetOnBoardingObj: any = {};
  title = '';
  connectAssetViewIsLoading: boolean = false;
  isConnectionProgressLoading: boolean = false;

  mounted() {
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.connectAssetViewIsLoading = true;
    this.isConnectionProgressLoading = true;
    await getAssetInfoById(this.assetId).then((res) => {
      if (res.code === 200) {
        this.assetOnBoardingObj = res.data;
        this.assetOnBoardingObj.manufactureDate = formatServerDate(
          this.assetOnBoardingObj.manufactureDate
        );
        PageModule.setTitle(res.data.assetId);
      }

      this.isConnectionProgressLoading = false;
      this.connectAssetViewIsLoading = false;
    });
  }

  routeMap = new Map<string, string>([
    [AssetType.MobileCompactor, '/assets/mobile-compactors'],
    [AssetType.StaticCompactor, '/assets/static-compactors'],
    [AssetType.TippingVehicle, '/assets/tipping-vehicles'],
  ]);

  authResourceByAssetType = new Map<string, string>([
    [AssetType.TippingVehicle, 'AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG'],
    [AssetType.StaticCompactor, 'AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG'],
    [AssetType.MobileCompactor, 'AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG'],
  ]);

  /**
   * Open single asset parameters config route
   */
  openParametersConfig(): void {
    this.$router.push(
      `${this.routeMap.get(this.assetOnBoardingObj.assetType)}/${
        this.assetOnBoardingObj.id
      }?tab=${this.authResourceByAssetType.get(
        this.assetOnBoardingObj.assetType
      )}`
    );
  }

  /**
   * Check if the logged in user has auth claim to view the tab in single asset page per asset type
   * If don't, do not display the button
   */
  get parametersButtonIsVisible(): boolean {
    return UserModule.claims.hasClaim(
      this.authResourceByAssetType.get(this.assetOnBoardingObj.assetType)!
    );
  }
}
