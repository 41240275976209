
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LiveMonitoringLineCharts from './LiveMonitoringLineCharts.vue';
import {
  getKpiData,
  subscribeLiveMonitoring,
  unsubscribeLiveMonitoring,
  liveMonitoringFrenquency,
  KpiDataField,
} from '@/api/assets';
import { realTimeKPIPostBody } from '@/utils/kpidata';
import { customFailedMessage } from '@/utils/prompt';
import TimeInterval from '@/components/timeInterval/TimeInterval.vue';
import { CustomizedKpiLimit } from '@/api/alarmConfig';

@Component({
  name: 'LiveMonitoringModal',
  components: {
    LiveMonitoringLineCharts,
    TimeInterval,
  },
})
export default class extends Vue {
  @Prop({ default: '1500px' }) popupWidth!: string;
  @Prop({ default: '5vh' }) popupTop!: string;
  @Prop({ required: true }) modalVisiable!: boolean;
  @Prop({ default: false }) hideFootBtns!: boolean;
  @Prop({ required: true }) title!: boolean;
  @Prop({ required: true }) assetId!: string;
  @Prop({ required: true }) limitAlarmInfo!: CustomizedKpiLimit[];

  allTypeChartData: KpiDataField[] = [];
  isRendering: boolean = true; //true: lineCharts render; false: lineCharts stop rendering but the data is still pushed
  timer: any = null;
  refreshSeconds: number = 5;
  subscribeDuration: number = 300;
  tokenRefreshThreshold: number = 0.9; // refresh when 90% of the subscription duration elapsed
  subscribeTimer: any = null;
  subscribeId: string | null = null;

  @Watch('visible')
  async switchTimer(newData: boolean) {
    if (newData) {
      await Promise.all([
        this.setSubscribeTimer(),
        this.getRefreshTime(),
        this.fecthRealTimeKpi(),
      ]);

      this.timer = setInterval(() => {
        this.fecthRealTimeKpi();
      }, this.refreshSeconds * 1000);

      //start to timing
      this.$nextTick(() => {
        (this.$refs.timeInterval as any).startTime();
      });
    } else {
      // when close modal, all the data should reset and cleat timeinterval
      await this.resetDataAndClearTimer();
    }
  }

  get visible() {
    return this.modalVisiable;
  }

  set visible(val) {
    this.$emit('updateVisible', val);
  }

  async beforeDestroy() {
    if (this.visible) {
      await this.resetDataAndClearTimer();
    }
  }

  //get all the realtime kp data
  async fecthRealTimeKpi() {
    const res = await getKpiData(realTimeKPIPostBody(this.$route.params.id));
    if (res?.code === 200) {
      this.allTypeChartData = res.data.details[0].fields;
    } else {
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    }
  }

  exportDatatoCSV() {
    (this.$refs.liveMonitoring as any).exportCsvFile();
  }

  toggleRendering() {
    this.isRendering = !this.isRendering;
    this.$nextTick(() => {
      if (this.isRendering) {
        (this.$refs.timeInterval as any).startTime();
      } else {
        (this.$refs.timeInterval as any).pauseTime();
      }
    });
  }

  quitModal() {
    this.visible = false;
  }

  //send the notification to BE so that they will get data from Thingsboard
  async sendSubscribeLiveMonitoring() {
    let submitData: any = {
      subscriptionExpireDurationInSeconds: this.subscribeDuration,
    };
    if (this.subscribeId) {
      submitData.id = this.subscribeId;
    }

    const res = await subscribeLiveMonitoring(this.assetId, submitData);
    if (res && res.code === 200) {
      this.subscribeDuration = res.data.subscriptionExpireDurationInSeconds;
      this.subscribeId = res.data.id;
      return;
    }
    customFailedMessage(this.$t('KPI.errorSubscribeLiveMonitoring').toString());
  }

  //get refresh secondes
  async getRefreshTime() {
    const res = await liveMonitoringFrenquency();
    if (res && res.code === 200) {
      this.refreshSeconds = res.data.frequencyInSeconds;
      return;
    }
    customFailedMessage(this.$t('KPI.errorSubscribeLiveMonitoring').toString());
  }

  //set subscription timer
  setSubscribeTimer() {
    this.sendSubscribeLiveMonitoring();
    this.subscribeTimer = setInterval(() => {
      this.sendSubscribeLiveMonitoring();
    }, this.subscribeDuration * this.tokenRefreshThreshold * 1000);
  }

  //unsubscription live Monitoring
  async sendUnsubscribeLiveMonitoringInform() {
    if (!this.subscribeId) {
      return;
    }

    let submitData: any = {
      id: this.subscribeId,
    };

    const res = await unsubscribeLiveMonitoring(this.assetId, submitData);
    if (res && res.code === 200) {
      this.subscribeId = null;
      return;
    }
    customFailedMessage(
      this.$t('KPI.errorUnsubscribeLiveMonitoring').toString()
    );
  }

  //reset all the data and clear the timer
  async resetDataAndClearTimer() {
    clearInterval(this.subscribeTimer);
    clearInterval(this.timer);
    this.allTypeChartData = [];
    this.isRendering = true;
    await this.sendUnsubscribeLiveMonitoringInform();
  }
}
