
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CreateSubscriptionCard',
})
export default class extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) source!: string;
  @Prop({ required: true }) isEditable!: boolean;
  @Prop({ required: false }) createdOrEdit!: boolean;
  @Prop({ default: true }) deacDeprecate!: boolean;
  @Prop({ default: true }) visible!: boolean;
  @Prop({ default: () => ['AUTHRSC_MOD_HOME'] }) editBtnAuth!: string[];
  @Prop({ default: () => ['AUTHRSC_MOD_HOME'] }) deacBtnAuth!: string[];

  handleCreate() {
    this.$emit('create-new-package');
  }

  handleDeactive() {
    this.$emit('handle-deactive');
  }

  handleEdit() {
    this.$emit('is-editable-event');
  }

  handleUpdate() {
    this.$emit('handle-update-event');
  }

  handleDeletePackage() {
    this.$emit('handle-delete-package');
  }

  goBack() {
    this.$router.push('/subscription-packages/index');
  }
}
