
import { ElTree } from 'element-ui/types/tree';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'TableContainer',
})
export default class extends Vue {
  @Prop() content!: any[];
  @Prop() filterIds!: string[];
  @Prop() titleName!: string;
  @Prop() titleValue!: string;

  unwatch: any = null;
  mounted() {
    this.unwatch = this.$watch('filterIds', (newValue, oldValue) => {
      const tree = this.$refs.tree as ElTree<HTMLElement, any>;
      tree.filter(true);
    });
  }

  beforeUnmount() {
    if (this.unwatch) {
      this.unwatch();
    }
  }

  filterTreeNode(value: any, node: any) {
    return this.filterIds.includes(node.id) == value;
  }
}
