
import { Component, Vue } from 'vue-property-decorator';
import { getCustomersByCustomUrl } from '@/api/customer';
import {
  CUSTOMER_COLS,
  CUST_SELECTION_OPTIONS,
} from '@/utils/workData/customerMgmt';
import { UserModule } from '@/store/modules/user';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import {
  COMPANY_TYPE_LIST,
  DEPLOYMENT_REGION_LIST,
} from '@/utils/workData/lookuptable';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import { customFailedMessage } from '@/utils/prompt';

@Component({
  name: 'CustomerManagement',
  components: {
    SelectTableHeader,
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  customerTableColumns = CUSTOMER_COLS;
  companyTypes = COMPANY_TYPE_LIST;
  regionTypes = DEPLOYMENT_REGION_LIST;
  searchFieldOptions = CUST_SELECTION_OPTIONS;
  loadingText: string = this.$t('customerModule.loadingCustomerData') as string;
  total: number = 0;
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  customerTableIsLoading: boolean = false;
  listLoading: boolean = false;
  list = [];
  companyType: string = '';
  deploymentRegion: string = '';
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';

  created() {
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
    CustMgmtModule.setReStore(false);
    CustMgmtModule.clearData();
  }

  /** Fetch when search is triggered */
  fetchCompaniesBySearchParams() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchCompaniesBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchCompaniesByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue = searchParams ? searchParams.value : null;
    let finalUrl = '';

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}`;
    } else {
      finalUrl += `&sortBy=${this.defaultSort}&order=${this.defaultOrder}`; // default must be sorted by createdOn DESC !
    }

    return finalUrl;
  }

  /** Remote fetch companies by custom URL */
  async fetchCustomersRemoteByCustomUrl(customUrl: any) {
    this.customerTableIsLoading = true;
    await getCustomersByCustomUrl(customUrl).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.total = res.data.total;
        this.list = res.data.companies;
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.customerTableIsLoading = false;
    });
  }
}
