
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import {
  DASHBOARDCONF_COLS,
  DASHBOARDCONF_SEARCH_FIELDS,
} from '@/utils/workData/dashboardConf';
import { COMPANY_TYPE_LIST, INDUSTRY_LIST } from '@/utils/workData/lookuptable';
import { getTemplates } from '@/api/dashboardConf';
import { generateUrl } from '@/utils/index';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';

@Component({
  name: 'DashboardConf',
  components: {
    BaseCard,
    SelectTableHeader,
    PureTable,
  },
})
export default class extends Vue {
  pageSize = UserModule.gridPageSize;
  dashboardConfCols = DASHBOARDCONF_COLS;
  dashboardFieldList = DASHBOARDCONF_SEARCH_FIELDS;
  custTypes = COMPANY_TYPE_LIST;
  industries = INDUSTRY_LIST;
  isTemplateTableLoading: boolean = false;
  templateName: string = '';
  custType: string = '';
  industry: string = '';
  loadingText: string = this.$t('dashboardConf.loadingTemplates') as string;
  tableList: any = [];
  total: number = 0;

  searchObj: any = {
    reference: null,
    value: null,
  };

  async handlePagination(page: number, pageSize: number) {}

  getFilterUrl(col: string, order: string) {
    let filterParams: any = {
      customerType: this.custType,
      industry: this.industry,
      orderBy: col,
      order: order,
    };

    let key: string = this.searchObj.reference;
    let value: string = this.searchObj.value;

    if (key && value) {
      filterParams[key] = value;
    }

    return generateUrl(filterParams);
  }

  async handleFilter(col: string, order: string) {
    let paramsUrl: string = this.getFilterUrl(col, order);
    this.fetchTemplates(paramsUrl);
  }

  handleCopy(rowInfo: any) {
    this.$router.push({
      path: `/dashboard-conf/edit/${rowInfo.id}`,
      query: {
        action: 'copy',
      },
    });
  }

  async fetchTemplates(filter: string) {
    this.isTemplateTableLoading = true;
    await getTemplates(filter).then((res) => {
      if (!res) return;

      if (res.code === 200) {
        let resData = res.data;
        this.total = resData.length;

        resData.forEach((item: any) => {
          item.type = item.isDefault
            ? 'dashboardConf.defalutTempalte'
            : 'dashboardConf.normalTemplate';
          item.action = ['COPY'];
        });

        this.tableList = resData;
      }

      this.isTemplateTableLoading = false;
    });
  }

  created() {
    this.fetchTemplates('');
  }
}
