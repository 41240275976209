
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { getTemplates } from '@/api/dashboardConf';
import { generateUrl } from '@/utils/index';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import ViewDashTemplate from '@/components/template/ViewDashTemplate.vue';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';

@Component({
  name: 'dashboardTemplate',
  components: {
    'view-dash-template': ViewDashTemplate,
  },
})
export default class extends Vue {
  @Prop() customerId!: string;
  @Prop() customerType!: COMPANY_TYPE;
  @Prop() industry!: string;
  @Prop({ default: true }) isCreate!: boolean;
  @Prop({ default: false }) isView!: boolean;
  @Ref('viewDashTemplate') viewDashTemplateRef!: ViewDashTemplate;

  id: string = '';
  templateId: string = '';
  title: string = '';
  custTemplates: any = [];
  templateForm: any;

  changeTemplateName(templateId: string) {
    this.id = templateId;
    this.viewDashTemplateRef.fetchModules(templateId, this.customerType);
  }

  handleModifyTemplate() {
    this.$emit('temporary-save', true);

    CustMgmtModule.setTemplateId(this.templateId);
    CustMgmtModule.setCustomerId(this.customerId); //Not Null Only view or edit
    this.$router.push(
      `/customer-management/modify-template/${this.templateId}`
    );
  }

  get isModifyTemplateVisible(): boolean {
    return !this.isView && this.id.length > 0;
  }

  getCurrentTemplateId() {
    return this.id;
  }

  async fetchTemplateByCompanyId() {
    let filterParams = {
      companyId: this.customerId,
    };

    let paramsUrl: string = generateUrl(filterParams);

    await getTemplates(paramsUrl).then(async (res) => {
      if (res.code === 200 && res.data.length > 0) {
        let resData = res.data[0];
        this.id = resData.id;
        this.templateId = resData.id;
        this.title = resData.name;
        await this.viewDashTemplateRef.fetchModules(this.id, this.customerType);
      }
    });
  }

  async fetchAllTemplates(companyType: COMPANY_TYPE, industry: string) {
    let filterParams = {
      customerType: companyType,
      industry: industry,
    };

    let paramsUrl: string = generateUrl(filterParams);

    await getTemplates(paramsUrl).then((res) => {
      if (res.code === 200 && res.data.length > 0) {
        this.custTemplates = res.data;
        if (CustMgmtModule.templateId) {
          if (CustMgmtModule.custTemplateId) {
            this.id = CustMgmtModule.custTemplateId;
            this.templateId = CustMgmtModule.templateId;
          } else {
            this.id = CustMgmtModule.templateId;
            this.templateId = CustMgmtModule.templateId;
          }
        } else {
          this.id = res.data[0].id;
          this.templateId = res.data[0].id;
        }
        this.viewDashTemplateRef.fetchModules(this.id, companyType);
        CustMgmtModule.setCustTemplateId('');
        CustMgmtModule.setTemplateId('');
      }
    });
  }

  mounted() {
    if (this.customerId) {
      this.fetchTemplateByCompanyId();
    } else {
      this.fetchAllTemplates(this.customerType, this.industry);
    }
  }
}
