
import { Component, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import NewPartForm from '../components/NewPartForm.vue';
import { getProductModelById } from '@/api/products';

@Component({
  name: 'ManageParts',
  components: {
    NavPage,
    NewPartForm,
  },
})
export default class extends Vue {
  activeName: string = 'general';
  id = this.$route.params.id ? this.$route.params.id : -1; //has id if admin visited from the index table
  productModelInfo = {};

  created() {}

  mounted() {
    if (this.id && this.id != -1) {
      //has id so have to get all the detail info
      (this.$refs.newPartForm as any).getPartDetails(this.id);
    }
  }

  // get Model Detail Info
  async getProductModelInfo() {
    const res = await getProductModelById(this.$route.params.id);
    this.productModelInfo = res.data;
  }

  createOnePart() {
    (this.$refs.newPartForm as any).createOnePart();
  }

  editOnePart() {
    (this.$refs.newPartForm as any).editOnePart();
  }
}
