import { render, staticRenderFns } from "./AssignSelectTable.vue?vue&type=template&id=69a61e22&scoped=true&"
import script from "./AssignSelectTable.vue?vue&type=script&lang=ts&"
export * from "./AssignSelectTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a61e22",
  null
  
)

export default component.exports