
import { Component, Prop, Vue } from 'vue-property-decorator';

import { getReportTemplates } from '@/api/report';
import { ChangedReport } from '@/api/reportSubscription';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { KpisDataPayload } from '@/utils/kpidata';
import { LANGUAGE } from '@/utils/workData/lookuptable';

import { substractDaysFromData } from '../../helpers/substractDaysFromData';
import { composeCsvReport } from './helpers/composeCsvReport';

@Component({
  name: 'ExportReportModal',
  components: {
    CusFormItem,
    WidgetDialog,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) fileName!: string;
  @Prop({ required: true }) data!: KpisDataPayload;
  @Prop({ required: true }) startDate!: string;
  @Prop({ required: true }) endDate!: string;

  reportFormatList = [] as string[];
  reportFormValues: ChangedReport = { name: this.fileName } as ChangedReport;

  created() {
    this.initializeDefaultSettings();
  }

  async initializeDefaultSettings() {
    const res = await getReportTemplates();
    if (res.code === 200) {
      this.reportFormatList = res.data;
    }
  }

  addSimpleRow(list: string[], text: string) {
    list.push(text);
    list.push('\n');
  }

  getEnTranslation(text: string) {
    return this.$t(text, LANGUAGE.EN_US);
  }

  async exportReport() {
    (this.$refs.refSubcribeReportForm as any).validate((valid: any) => {
      if (valid && this.reportFormValues.reportFormat.includes('CSV')) {
        const csv = composeCsvReport({
          startDate: this.startDate,
          endDate: substractDaysFromData({ dateToBeSubstracted: this.endDate }),
          data: this.data,
        });

        const anchor = document.createElement('a');
        anchor.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.join(''));
        anchor.target = '_blank';
        anchor.download = this.reportFormValues.name;
        anchor.click();
        this.close();
      }
    });
  }

  /** In case of close modal, the visible attribute from parent is set to false */
  close(): void {
    this.$emit('update:visible', false);
  }

  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  validateName = (_rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    } else {
      callback();
    }
  };
}
