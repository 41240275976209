
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  PACKAGE_TYPE,
  REAL_TIME_KPIS_METRICS,
} from '@/utils/workData/lookuptable';
import {
  getKpiData,
  KpiDataField,
  KpiDataResponse,
  KpiDataValue,
} from '@/api/assets';
import {
  getCustomizedAlarmsDataByCustomUrl,
  PagedCustomizedKpiLimitsResponse,
  CustomizedKpiLimit,
} from '@/api/alarmConfig';
import { realTimeKPIPostBody, valueLimitCheckToColor } from '@/utils/kpidata';
import {
  CompanySubscription,
  getCompanySubscriptions,
} from '@/api/subscriptionPackages';
import { UserModule } from '@/store/modules/user';
import LiveMonitoringModal from '@/views/assets/components/LiveMonitoringModal.vue';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { SYSTEM_FEATURES } from '@/utils/workData/lookuptable';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { computed, Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { PageModule } from '@/store/modules/page';

@Component({
  name: 'AssetRealTime',
  components: {
    WidgetCard,
    LiveMonitoringModal,
  },
})
export default class extends Vue {
  /** Local Variabled */
  routeAssetMap = ROUTE_ASSET_MAP;
  realTimeKpisMetrics: any = REAL_TIME_KPIS_METRICS;
  realKpiWidgetIsLoading: boolean = false;
  kpiData: KpiDataField[] = [];
  timer: any = null;
  limitInformation: CustomizedKpiLimit[] = [];
  assetTypeCode: string = '';

  hasLiveMonitoringFeature: boolean = true;
  showLiveMonitoringModal: boolean = false;

  context!: Ref<ActiveContext>;
  title = computed(() => PageModule.title[PageModule.title.length - 1]);

  created() {
    this.context = useActiveContext();
    this.assetTypeCode = this.$route.name
      ? (this.routeAssetMap.get(this.$route.name) as any).assetTypeCode
      : '';
  }

  async mounted() {
    this.fecthKpi();
    this.getLimitsInfo();
    this.timer = setInterval(() => {
      this.fecthKpi();
    }, 600000);

    //check whether user could see the live Monitoring
    this.checkLiveMonitoringPackage();

    //check live Monitoring open state
    let modal = this.$refs.liveMonitoringModal as LiveMonitoringModal;
    if (modal.subscribeId) {
      await modal.sendUnsubscribeLiveMonitoringInform();
    }
  }

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  //check whether user could see the live Monitoring
  async checkLiveMonitoringPackage() {
    this.hasLiveMonitoringFeature = false;
    const subscriptions = await this.fetchCompanySubscriptions();

    this.hasLiveMonitoringFeature = !!subscriptions
      .filter(
        (subscription) =>
          subscription.subscriptionPackageType === PACKAGE_TYPE.CompanyType
      )
      .flatMap((subscription) => subscription.systemFeatures)
      .find(
        (feature) =>
          feature.code === SYSTEM_FEATURES.LiveMonitoring &&
          feature.value !== 'false'
      );
  }

  private async fetchCompanySubscriptions() {
    const res: any = await getCompanySubscriptions(UserModule.companyId);
    if (!res || res.code !== 200 || res.data.length === 0) {
      return [];
    }
    return res.data as CompanySubscription[];
  }

  //click startLiveMonitoring button
  startLiveMonitoring() {
    this.showLiveMonitoringModal = true;
  }

  updateVisible(val: boolean) {
    this.showLiveMonitoringModal = val;
  }

  /**
   * Fetch kpi data
   */
  async fecthKpi() {
    try {
      this.realKpiWidgetIsLoading = true;
      const res = await getKpiData(
        realTimeKPIPostBody(this.$route.params.id),
        unref(this.context)
      );
      this.kpiData = res.data.details[0]!.fields;
    } catch (error) {
      console.log(error);
      // customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString())
    } finally {
      this.realKpiWidgetIsLoading = false;
    }
  }

  /**
   * Fetch customized limits by org id for the logged user
   */
  async getLimitsInfo() {
    try {
      const org = unref(this.context).organization;
      if (!org) {
        // Should never happen
        throw new Error('No organization selected');
      }
      const res = await getCustomizedAlarmsDataByCustomUrl(
        org.id,
        '?page=1&size=1000'
      );
      this.limitInformation = res.data.limits.filter(
        (item: CustomizedKpiLimit) => {
          return item.assetType === this.assetTypeCode;
        }
      );
    } catch (error) {
      console.log(error);
      // customFailedMessage(this.$t('common.serverError').toString());
    } finally {
    }
  }

  /**
   * Determine limit color for the alarm property
   * @param values
   */
  determineLimitColor(values: KpiDataValue[]): string {
    const resultColor = valueLimitCheckToColor(values[0]?.lc);
    return `color:${resultColor}`;
  }
}
