
import { Component, Vue } from 'vue-property-decorator';
import {
  AnyKpiLimit,
  getAlarmsDataByCustomUrl,
  getCustomizedAlarmsDataByCustomUrl,
} from '@/api/alarmConfig';
import {
  ALARM_CONFIG_TABLE_COLUMNS,
  ALARM_CONFIG_SELECTION_OPTIONS,
} from '@/utils/workData/alarmConfig';
import { UserModule } from '@/store/modules/user';
import { ASSET_TYPE_LIST, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import AlarmConfigTable from './components/AlarmConfigTable.vue';
import { customFailedMessage } from '@/utils/prompt';
import { isCompanyTypeOf } from '@/utils/companyService';
import { LoggedInUserRef, useLoggedInUser } from '@/composables/context';
import { unref } from 'vue';

@Component({
  name: 'Alarm',
  components: {
    AlarmConfigTable,
    SelectTableHeader,
  },
})
export default class extends Vue {
  /** Local variables */
  alarmTableColumns = ALARM_CONFIG_TABLE_COLUMNS;
  assetTypeList = ASSET_TYPE_LIST;
  searchFieldOptions = ALARM_CONFIG_SELECTION_OPTIONS;
  loadingText: string = this.$t('customerModule.loadingAlarmData') as string;
  totalAlarms: number = 0;
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  alarmTableIsLoading: boolean = false;
  tableDataList: AnyKpiLimit[] = [];
  isColumnSelectionVisible: boolean = false;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';
  loggedInUser!: LoggedInUserRef;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Fetch when search is triggered */
  fetchAlarmDataBySearchParams() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchAlarmsDataBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchAlarmsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let finalUrl = '';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue: any = searchParams ? searchParams.value : null;

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    /** Scenarios covered: when value is boolean or when value is a string */
    if (searchFieldName === 'isLocked' && searchFieldValue) {
      switch (searchFieldValue) {
        case 'IS_LOCK_ACTIVATED':
          finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${true}`;
          break;
        case 'IS_LOCK_DEACTIVATED':
          finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${false}`;
          break;
      }
    } else if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}`;
    } else {
      finalUrl += `&sortBy=${this.defaultSort}&order=${this.defaultOrder}`; // default must be sorted by createdOn DESC !
    }

    return finalUrl;
  }

  fetchRemoteAlarms(finalUrlParamsForSearch: string) {
    if (isCompanyTypeOf([COMPANY_TYPE.Hyva])) {
      this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
    } else {
      this.fetchRemoteCustomizedAlarmsDataByCustomUrl(finalUrlParamsForSearch);
    }
  }

  /** Remote fetch alarms bata by custom URL */
  async fetchRemoteAlarmsDataByCustomUrl(customUrl: any) {
    this.alarmTableIsLoading = true;
    await getAlarmsDataByCustomUrl(customUrl).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.totalAlarms = res.data.total;
        this.tableDataList = res.data.limits;
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.alarmTableIsLoading = false;
    });
  }

  async fetchRemoteCustomizedAlarmsDataByCustomUrl(customUrl: any) {
    this.alarmTableIsLoading = true;
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    await getCustomizedAlarmsDataByCustomUrl(
      user.organization.id,
      customUrl
    ).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.totalAlarms = res.data.total;
        this.tableDataList = res.data.limits;
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.alarmTableIsLoading = false;
    });
  }
}
