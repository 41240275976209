
import { Component, Vue } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import RegisteredAssets from './register/RegisteredAssets.vue';
import ConnectedAssets from './connect/ConnectedAssets.vue';
import AssignedAssets from './assigned/AssignedAssets.vue';
import { UserModule } from '@/store/modules/user';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';

@Component({
  name: 'AssetManagement',
  components: {
    NavPage,
    RegisteredAssets,
    ConnectedAssets,
    AssignedAssets,
  },
})
export default class extends Vue {
  /** Local variables */
  activeName: string = 'registerAssets';
  previousTabIndex: number = 0;
  allowAssignAsset: boolean = true;
  context!: Ref<ActiveContext>;

  /** Create initialization - need to set all btn to empty array on this index page */
  created() {
    this.context = useActiveContext();
    this.allowAssignAsset = ![
      COMPANY_TYPE.BodyBuilder.toString(),
      COMPANY_TYPE.Dealer.toString(),
    ].includes(UserModule.companyType);
    this.showDefaultTab();
  }

  /** Handle tab click */
  handleTabClick() {
    if (
      this.$route.name != this.activeName &&
      this.activeName === 'registeredAssets'
    )
      this.$router.push('/asset-mgmt/registeredAssets');
    if (
      this.$route.name != this.activeName &&
      this.activeName === 'connectedAssets'
    )
      this.$router.push('/asset-mgmt/connectedAssets');
    if (
      this.$route.name != this.activeName &&
      this.activeName === 'assignedAssets'
    )
      this.$router.push('/asset-mgmt/assignedAssets');
  }

  /** Handle display tabs */
  showDefaultTab() {
    if (this.$route.name) {
      this.activeName = this.$route.name as string;
      return;
    }
    this.activeName = 'registeredAssets';
  }

  checkClaim(claim: string) {
    return unref(this.context).claims.hasClaim(claim);
  }
}
