
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  PACKAGE_TYPE,
  PACKAGE_TYPE_LIST,
  ASSET_TYPE_LIST,
  COMPANY_TYPE_LIST,
} from '@/utils/workData/lookuptable';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import CusFormItem from '@/components/form/CusFormItem.vue';
import CommonBtn from '@/components/button/CommonBtn.vue';

@Component({
  name: 'General',
  components: {
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop({ required: true }) editData!: any;
  @Prop({ required: true }) source!: any;
  @Prop({ required: true }) isEditable!: any;
  @Prop({ required: true }) generalData!: any;
  @Prop({ required: false }) fieldErrors!: any;

  /** Local variables */
  isCustomerType: boolean = false;
  assetTypeList: any = ASSET_TYPE_LIST;
  customerTypeList: any = COMPANY_TYPE_LIST;
  packageTypeList: any = PACKAGE_TYPE_LIST;
  packageNameError: string = '';

  created() {
    if (Object.keys(this.editData).length > 0) {
      this.handlePackageTypeSelection();
    }
  }

  /**
   * Triggered when package type is changed
   */
  handlePackageTypeSelection() {
    if (this.source === 'new' && this.generalData.packageType)
      this.$emit('handle-package-type-features', this.generalData.packageType);

    if (this.generalData.packageType != PACKAGE_TYPE.AssetType) {
      this.generalData.assetType = '';
      this.isCustomerType = true;
      return;
    }

    this.generalData.companyType = '';
    this.isCustomerType = false;
  }

  @Watch('generalData', { deep: true })
  handleDataImput() {
    this.$emit('general-data-update', this.generalData);
  }
}
