
import { Component, Vue } from 'vue-property-decorator';
import { RELEASES_MANAGEMENT_COLS } from '@/utils/workData/releasesManagementCols';
import PureTable from '@/components/table/PureTable.vue';
import moment from 'moment';
import {
  getFilteredReleases,
  SystemReleaseResponse,
  SystemRelease,
} from '@/api/systemReleaseManagement';
import {
  GENERAL_QUERY_OPERATORS,
  PROPER_ASSET_TYPE,
  SYSTEM_RELEASE_MATURITY_LEVEL,
  SYSTEM_RELEASE_STATUS,
} from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';
import Debouncer from '@/utils/debounce';

@Component({
  name: 'releasesManagement',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = RELEASES_MANAGEMENT_COLS;
  pageTotal = 1;
  data: SystemRelease[] = [];
  searchField: string = '';
  numberOfReleases: number = 0;
  releaseManagementPageIsLoading: boolean = false;
  filterList: {
    field: string;
    operator: GENERAL_QUERY_OPERATORS;
    label?: string;
    dropdownEnum?: any;
  }[] = [
    {
      field: 'systemReleaseId',
      operator: GENERAL_QUERY_OPERATORS.Like,
      label: 'releasesManagement.productSystemReleaseId',
    },
    {
      field: 'maturityLevel',
      operator: GENERAL_QUERY_OPERATORS.Equal,
      dropdownEnum: SYSTEM_RELEASE_MATURITY_LEVEL,
    },
    {
      field: 'releaseStatus',
      operator: GENERAL_QUERY_OPERATORS.Equal,
      dropdownEnum: SYSTEM_RELEASE_STATUS,
    },
    {
      field: 'assetType',
      operator: GENERAL_QUERY_OPERATORS.Like,
      dropdownEnum: PROPER_ASSET_TYPE,
    },
    {
      field: 'productModelCode',
      operator: GENERAL_QUERY_OPERATORS.Like,
      label: 'releasesManagement.productModel',
    },
    {
      field: 'productModelNumber',
      operator: GENERAL_QUERY_OPERATORS.Like,
      label: 'releasesManagement.modelNumber',
    },
    {
      field: 'defaultRelease',
      operator: GENERAL_QUERY_OPERATORS.Equal,
      dropdownEnum: { Yes: 'true', No: 'false' },
    },
  ];
  filterEnum: any = null;
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  sorter = [];
  filter = {
    name: this.filterList[0].field,
    operator: this.filterList[0].operator,
    value: [''],
  };
  appliedFilters: {
    filters: any[];
    sorters: any[];
    pagination: { page: number; size: number };
  } = {
    filters: [],
    sorters: this.sorter,
    pagination: this.pagination,
  };

  created() {
    this.getData();
  }

  /**
   * Handle search filter event
   */
  handleSearchFilterSubmit(): void {
    if (this.searchField.length < 1) {
      this.handleClear();
      return;
    }
    Debouncer.debounce(this.submitSearch);
  }

  /**
   * Submit search event
   */
  submitSearch(): void {
    if (this.searchField.length > 0 && this.filter.name.length > 0) {
      this.filter.value[0] = this.searchField;
      this.appliedFilters.filters = [this.filter];
      this.getData();
    }
  }

  /**
   * Cleanup
   */
  handleClear(): void {
    this.appliedFilters.filters = [];
    this.getData();
  }

  /**
   * Handle search input
   */
  handleSearchInput(): void {}

  /**
   * Handle search when input is empty
   * @param value
   */
  handleInputEmpty(value: string): void {
    if (!value) this.handleSearchInput();
  }

  /**
   * Handle page event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    this.getData();
  }

  /**
   * Handle sort change
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    this.appliedFilters.sorters = [];
    if (sortOrder) {
      this.appliedFilters.sorters.push({
        field: sortField,
        order: sortOrder,
      });
    }
    await this.getData();
  }

  /**
   * Handle filter event
   */
  handleReleaseFilter(): void {
    this.searchField = '';
    this.filter.operator =
      this.filterList.find((f) => f.field === this.filter.name)?.operator ??
      GENERAL_QUERY_OPERATORS.Like;
    this.filter.value = [];
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.filter.name
    )?.dropdownEnum;
    this.handleClear();
  }

  /**
   * Handle get data from API
   */
  async getData(): Promise<void> {
    try {
      this.releaseManagementPageIsLoading = true;
      const response = await getFilteredReleases(this.appliedFilters);
      this.pageTotal = response.data.total;
      for (let i in response.data.systemReleases) {
        response.data.systemReleases[i].deploymentDuration = moment
          .utc(
            response.data.systemReleases[i].deploymentDurationMinutes *
              1000 *
              60
          )
          .format('HH:mm:ss');
        if (response.data.systemReleases[i].defaultRelease)
          response.data.systemReleases[i].defaultRelease =
            this.$t('userModule.yes').toString();
        if (!response.data.systemReleases[i].defaultRelease)
          response.data.systemReleases[i].defaultRelease =
            this.$t('userModule.no').toString();
      }
      this.data = response.data.systemReleases;
      this.numberOfReleases = response.data.total;
    } catch (err) {
      console.log(err);
    } finally {
      this.releaseManagementPageIsLoading = false;
    }
  }

  /**
   * Change route to add new release page
   */
  addNewRelease(): void {
    this.$router.push(`/release/new`);
  }
}
