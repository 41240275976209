
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CusFormItem from '@/components/form/CusFormItem.vue';

@Component({
  name: 'SubsMgmtModal',
  components: {
    'cus-form-item': CusFormItem,
  },
})
export default class extends Vue {
  @Prop({ required: false }) isModalVisible!: boolean;
  @Prop({ required: true }) triggerSource!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) modalContent!: string;

  /** Buttons events */
  closeDialog() {
    this.$emit('handle-cancel');
  }

  /** Close modal event triggering */
  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  /** Trigger event to parrent for renewing an order */
  handleRenewOrder() {
    this.$emit('handle-renew-event');
  }

  /** Trigger event to parrent for terminating an order */
  handleTerminateOrder() {
    this.$emit('handle-terminate-event');
  }
}
