
import { Component, Vue } from 'vue-property-decorator';
import { getRoles } from '@/api/roles';
import {
  USER_ROLE_MANAGEMENT,
  USER_ROLE_MANAGEMENT_SEARCH_OPTIONS,
} from '@/utils/workData/userMgmt';
import { UserModule } from '@/store/modules/user';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import { customFailedMessage } from '@/utils/prompt';

@Component({
  name: 'UserRoleMgmt',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  currentCol: string = '';
  list: any = [];
  pageSize = UserModule.gridPageSize;
  userRoleManagementColumns = USER_ROLE_MANAGEMENT;
  totalUserRoles: number = 0;
  imgSrc: string = '';
  isUserRoleLoading: boolean = false;
  loadingText: string = this.$t(
    'userRoleModule.loadingUserRolesData'
  ) as string;
  userRoleManagementSearchOptions: any = USER_ROLE_MANAGEMENT_SEARCH_OPTIONS;
  currentPage: number = 1;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };

  created() {
    this.prepareDefaultInitialization();
  }

  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      null,
      null
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Retrieve remotly user role by parameters */
  fetchUserRolesByParameters() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchRolesBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'claims') return; // need IoT implementation !
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchRolesByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue = searchParams ? searchParams.value : null;
    let finalUrl = '';

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  async fetchUserRolesData(customUrl: any) {
    this.isUserRoleLoading = true;
    await getRoles(customUrl).then((res) => {
      if (!res) return;

      if (res.code === 200) {
        this.list = res.data.roles;
        this.totalUserRoles = res.data.total;
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.isUserRoleLoading = false;
    });
  }
}
