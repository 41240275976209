import { render, staticRenderFns } from "./GeneralForm.vue?vue&type=template&id=0c836b84&scoped=true&"
import script from "./GeneralForm.vue?vue&type=script&lang=ts&"
export * from "./GeneralForm.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralForm.vue?vue&type=style&index=0&id=0c836b84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c836b84",
  null
  
)

export default component.exports