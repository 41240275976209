
import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatTimer, calculateTimeDifference } from '@/utils/misc';
import { Moment } from 'moment';
import { AssetDeploymentLog } from '@/api/deploymentDetails';
import {
  assetLifecyclesWhenAssetDeploymentCanBeCancelled,
  assetsControlStatusesWhenAssetDeploymentCanBeCancelled,
  statesWhenAssetDeploymentCanBeHold,
  assetsControlStatusesWhenAssetDeploymentCanBeHold,
  statesWhenAssetDeploymentCanBeResume,
  assetsControlStatusesWhenAssetDeploymentCanBeResume,
  calculateAssetDeploymentStatus,
} from './assetDeployment';

@Component({
  name: 'Details',
})
export default class extends Vue {
  @Prop({ default: '' }) deployLogId!: string;
  @Prop({}) data!: AssetDeploymentLog;
  @Prop({ default: false }) buttons!: boolean;

  /** Local variables */
  unwatch: any = null;
  details: any = {};
  deployLog: string = '';

  mounted() {
    this.unwatch = this.$watch('data', (newValue, oldValue) => {
      if (!newValue) return;
      this.details = newValue;
    });

    this.unwatch = this.$watch('deployLogId', (newValue, oldValue) => {
      if (!newValue) return;
      this.deployLog = newValue;
    });
  }

  /**
   * Calculate difference between deployment start and end time
   */
  timeDifference(
    startDateAndTime: string | number,
    endDateAndTime: string | number
  ): string | undefined {
    return calculateTimeDifference(startDateAndTime, endDateAndTime);
  }

  /**
   * Cancel asset deployment
   */
  cancelAassetDeployment(): void {
    this.$emit('cancelAassetDeployment');
  }

  /**
   * Hold asset deployment
   */
  holdAssetDeployment(): void {
    this.$emit('holdAssetDeployment');
  }

  /**
   * Resume asset deployment
   */
  resumeAssetDeployment(): void {
    this.$emit('resumeAssetDeployment');
  }

  /**
   * Format date and time to logged in user time zone
   * @param manifestCreationTime
   */
  formatDateAndTime(manifestCreationTime: string): string | Moment {
    return formatTimer(manifestCreationTime, 'datetime');
  }

  /**
   * Handle display of asset deployment cancel button
   */
  get assetDeploymentCanBeCancelled(): boolean {
    return (
      assetLifecyclesWhenAssetDeploymentCanBeCancelled.includes(
        this.data.assetDeployStatus
      ) &&
      assetsControlStatusesWhenAssetDeploymentCanBeCancelled.includes(
        this.data.deploymentControl
      )
    );
  }

  /**
   * Handle display of asset deployment hold button
   */
  get assetDeploymentCanBeHold(): boolean {
    return (
      statesWhenAssetDeploymentCanBeHold.includes(
        this.data.assetDeployStatus
      ) &&
      assetsControlStatusesWhenAssetDeploymentCanBeHold.includes(
        this.data.deploymentControl
      )
    );
  }

  /**
   * Handle display of asset deployment resume button
   */
  get assetDeploymentCanBeResume(): boolean {
    return (
      statesWhenAssetDeploymentCanBeResume.includes(
        this.data.assetDeployStatus
      ) &&
      assetsControlStatusesWhenAssetDeploymentCanBeResume.includes(
        this.data.deploymentControl
      )
    );
  }

  /**
   * Get final asset deployment status by certain conditions
   */
  get finalAssetDeploymentStatus() {
    return calculateAssetDeploymentStatus(this.data);
  }
}
