
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PROD_CATA_MODEL_PARTS_LIST } from '@/utils/workData/prodCata';
import ModelSelect from '../components/ModelSelect.vue';
import PureTable from '@/components/table/PureTable.vue';

@Component({
  name: 'partsInfoPrev',
  components: {
    'pure-table': PureTable,
    'model-select': ModelSelect,
  },
})
export default class extends Vue {
  @Prop({ required: true }) partsInfoPrev!: any;

  cols = PROD_CATA_MODEL_PARTS_LIST;
  tableList: any = [];

  mounted() {
    this.$nextTick(() => {
      if (!this.partsInfoPrev?.containedParts) return;
      this.tableList = JSON.parse(
        JSON.stringify(this.partsInfoPrev.containedParts)
      );
      this.tableList.forEach((item: any) => {
        item.isMaintainable = item.isMaintainable
          ? 'MAINTAINABLE_TRUE'
          : 'MAINTAINABLE_FALSE';
      });
    });
  }

  @Watch('partsInfoPrev', { deep: true })
  private watchPartsInfo(value: any, oldVal: any) {
    this.tableList = JSON.parse(JSON.stringify(value.containedParts));
    this.tableList.forEach((item: any) => {
      item.isMaintainable = item.isMaintainable
        ? 'MAINTAINABLE_TRUE'
        : 'MAINTAINABLE_FALSE';
    });
  }
}
