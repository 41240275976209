
import { Component, Vue } from 'vue-property-decorator';
import { PROD_CATA_PARTS_LIST } from '@/utils/workData/prodCata';
import PartsSelect from '../components/PartsSelect.vue';
import PureTable from '@/components/table/PureTable.vue';
import {
  getFilterPartMaterials,
  updatePartMaterial,
  PartMaterial,
} from '@/api/products';
import ConfirmDialog from '../components/Dialog.vue';
import { customFailedMessage } from '@/utils/prompt';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'ProdCata',
  components: {
    'parts-select': PartsSelect,
    'pure-table': PureTable,
    'confirm-dialog': ConfirmDialog,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = PROD_CATA_PARTS_LIST;
  tableList: PartMaterial[] = [];
  total: number = 0;
  visible: boolean = false;
  toDeprecateItem: any = {};
  filterForm = {
    partNameorId: '',
    partType: '',
    lifeCycle: '',
  };
  isWarehousePartsLoading: boolean = false;
  pageSize: string = UserModule.gridPageSize.toString();
  page: string = '1';
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };

  created() {
    this.fetchPartsWarehouse();
  }

  /**
   * Get warehouse parts
   */
  async fetchPartsWarehouse() {
    try {
      this.isWarehousePartsLoading = true;
      const requestUrl: string = this.generateRequestUrlWithParams(
        this.page,
        this.pageSize,
        this.filterForm,
        this.sortAndOrderData
      );
      const response = await getFilterPartMaterials(requestUrl);
      if (!response) return;

      response.data.forEach((item: any) => {
        if (item.lifeCycle === 'LCL_DRAFT') {
          item.action = ['EDIT'];
        }
        if (item.lifeCycle === 'LCL_APPROVED') {
          item.action = ['DEPRECATE'];
        }
      });
      this.tableList = response.data;
      this.total = response.data.length;
    } catch (error) {
      console.log(error);
    } finally {
      this.isWarehousePartsLoading = false;
    }
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: string,
    pageSize: string,
    filterForm: any,
    sortAndOrderData: any
  ): string {
    let finalUrl = '';
    if (filterForm.partType) finalUrl += `&partType=${filterForm.partType}`;
    if (filterForm.lifeCycle) finalUrl += `&lifeCycle=${filterForm.lifeCycle}`;
    if (filterForm.partNameorId)
      finalUrl += `&nameOrNumber=${filterForm.partNameorId}`;
    pageNumber
      ? (finalUrl += `&page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    if (sortAndOrderData.sortBy && sortAndOrderData.order)
      finalUrl += `&sortBy=${sortAndOrderData.sortBy}&order=${sortAndOrderData.order}`;
    if (finalUrl) finalUrl = '?' + finalUrl.slice(1);
    return finalUrl;
  }

  editOnePart(row: any) {
    this.$router.push({
      path: `/prod-cata/edit-part/${row.id}`,
    });
  }

  deprecateOnePart(row: any) {
    this.toDeprecateItem = row;
    this.visible = true;
  }

  //get filter partWarehouse Data
  async fetchFilterPartsWarehouse(sendData: any) {
    this.filterForm.partNameorId = sendData.partNameorId;
    this.filterForm.partType = sendData.partType;
    this.filterForm.lifeCycle = sendData.lifeCycle;
    this.fetchPartsWarehouse();
  }

  updateVisible(val: boolean) {
    this.visible = val;
  }

  resetPopupData() {
    this.visible = false;
  }

  async submitPopupData() {
    let submitData = {
      lifeCycle: 'LCL_DEPRECATED',
    };
    await updatePartMaterial(this.toDeprecateItem.id, submitData).then(
      (res) => {
        if (!res) return;

        if (res.code === 200) {
          this.visible = false;
          this.fetchPartsWarehouse();
        } else if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
        }
      }
    );
  }
}
