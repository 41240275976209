
import { MaintStatus } from '@/api/maintenance';
import Close from '@/assets/imgs/maintenance/overview/close.svg';
import Due from '@/assets/imgs/maintenance/overview/due.svg';
import Ok from '@/assets/imgs/maintenance/overview/ok.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MaintStatusTile',
})
export default class extends Vue {
  @Prop({ required: true }) maintStatus!: MaintStatus;
  @Prop({ required: true }) maintStatusCount!: number | undefined;
  @Prop({ required: true }) isSelected!: boolean;
  @Prop({ required: true }) isClickable!: boolean;
  emits!: ['click-status'];

  toolTipMessage: any = '';

  get status() {
    let status = this.$t('maintenance.ok');
    this.toolTipMessage = this.$t('maintenance.maintenanceOverviewOk');

    if (this.maintStatus === MaintStatus.CLOSE) {
      status = this.$t('maintenance.close');
      this.toolTipMessage = this.$t('maintenance.maintenanceOverviewClose');
    } else if (this.maintStatus === MaintStatus.DUE) {
      status = this.$t('maintenance.due');
      this.toolTipMessage = this.$t('maintenance.maintenanceOverviewDue');
    }

    return status;
  }

  get imgSrc() {
    let imgSrc = Ok;

    if (this.maintStatus === MaintStatus.CLOSE) {
      imgSrc = Close;
    } else if (this.maintStatus === MaintStatus.DUE) {
      imgSrc = Due;
    }

    return imgSrc;
  }

  handleClick() {
    if (this.isClickable) {
      this.$emit('click-status');
    }
  }
}
