
import { Component, Vue } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import ProductModels from './productModels/ProductModels.vue';
import PartsWarehouse from './partsWarehouse/PartsWarehouse.vue';

@Component({
  name: 'ProductCatalogue',
  components: {
    NavPage,
    'product-models': ProductModels,
    'parts-warehouse': PartsWarehouse,
  },
})
export default class extends Vue {
  activeName: string = 'productModels';

  mounted() {
    this.showTabs();
  }

  handleTabClick() {
    if (
      this.activeName === 'productModels' &&
      this.$route.query.activeName != 'productModels'
    ) {
      this.$router.push('/prod-cata/index?activeName=productModels');
    } else if (
      this.activeName === 'partsWarehouse' &&
      this.$route.query.activeName != 'partsWarehouse'
    ) {
      this.$router.push('/prod-cata/index?activeName=partsWarehouse');
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'productModels';
    }
  }
}
