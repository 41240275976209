
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'AssignDialog',
})
export default class extends Vue {
  @Prop({ required: false }) title!: string;
  @Prop({ default: '800px' }) popupWidth!: string;
  @Prop({ default: '25vh' }) popupTop!: string;
  @Prop({ required: true }) modalVisiable!: boolean;

  created() {}

  get visible() {
    return this.modalVisiable;
  }

  set visible(val) {
    this.$emit('updateVisible', val);
  }

  cancelModal() {
    this.visible = false;
  }

  continueModal() {
    this.$emit('submitPopupData');
  }
}
