
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import { GeofenceAsset, Geofence } from '@/api/geofenceTypes';
import LeafletMapAssets from '@/components/leafletMap/LeafletMapAssets.vue';
import { getEvent } from '@/api/event';
import { AssetObjectModel, getAssetById } from '@/api/assets';
import { getProductModelById } from '@/api/products';
import { getAllServiceRegionsList } from '@/utils/serviceRegions';
import { customFailedMessage } from '@/utils/prompt';
import { LControlZoom, LMap, LTileLayer } from 'vue2-leaflet';
import MobileCompactor from '@/icons/svg/mobileCompactor.svg';
import StaticCompactor from '@/icons/svg/staticCompactor.svg';
import TippingVehicle from '@/icons/svg/tippingVehicle.svg';
import { AssetType, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { CommonResult } from '@/api/commonResult';
import { Ref, unref } from 'vue';
import {
  ActiveContext,
  LoggedInUserRef,
  useActiveContext,
  useLoggedInUser,
} from '@/composables/context';

@Component({
  name: 'EventDetailsModal',
  components: {
    LeafletMap,
    LMap,
    LControlZoom,
    LTileLayer,
    LeafletMapAssets,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop() eventId!: string;

  /** Local variables */
  url: string | undefined = Vue.prototype.$envConfig.VUE_APP_MAP_URL;
  center: number[] = [0, 0];
  zoom: number = 10;
  assets: Array<GeofenceAsset> = [];
  geofences: Geofence[] = [];
  event: any = null;
  asset: any = null;
  productModel: any = null;
  regionName: string = '';
  productModelImageStyle: any = {
    backgroundImage: '',
  };
  addressLabel: string | null = null;

  context!: Ref<ActiveContext>;
  loggedInUser!: LoggedInUserRef;

  $refs!: {
    eventDetailsMap: LMap;
    mapEventsAssets: LeafletMapAssets;
  };

  async created() {
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();
    await this.getEventDetails();
  }

  /** Get event details */
  async getEventDetails() {
    if (this.eventId) {
      const loggedInUser = unref(this.loggedInUser);
      const activeContext =
        loggedInUser && loggedInUser.companyType === COMPANY_TYPE.Helpdesk
          ? unref(this.context)
          : undefined;
      await getEvent(this.eventId, activeContext).then((response) => {
        if (response.code !== 200) {
          this.closeDialog();
          customFailedMessage(response.message);
          return;
        }
        this.event = response.data;

        if (this.event.location?.lat && this.event.location?.lgt) {
          this.center = [this.event.location.lat, this.event.location.lgt];
        } else {
          this.zoom = 1;
        }

        if (this.event.address?.addressLabel) {
          this.addressLabel = this.event.address.addressLabel;
        }

        this.getAssetDetails();
      });
    }
  }

  /** Get asset details, trigger product model after response */
  async getAssetDetails() {
    await Promise.all([
      getAssetById(this.event.assetId, unref(this.context)),
      getAllServiceRegionsList(),
    ]).then(
      ([responseAsset, responseServiceRegions]: [
        CommonResult<AssetObjectModel>,
        any
      ]) => {
        this.asset = responseAsset.data;
        this.regionName = responseServiceRegions.find(
          (r: any) => r.serviceRegionCode == this.asset.serviceRegion
        ).serviceRegionName;
        this.getProductModelDetails();
        const asset: GeofenceAsset = {
          id: this.asset.id,
          companyAssetId: this.asset.companyAssetId,
          assetDataCollectionId: this.asset.assetDataCollectionId,
          operationalStatus: this.asset.status,
          geodeticLatitude: this.event.location?.lat || 0,
          geodeticLongitude: this.event.location?.lgt || 0,
          assetTypeCode: this.asset.assetType,
          lastSyncTime: new Date(),
          popup: false,
        };

        if (
          asset.geodeticLatitude &&
          asset.geodeticLongitude &&
          asset.geodeticLatitude * asset.geodeticLongitude != 0
        ) {
          this.assets = [asset];
        }
      }
    );
  }

  /** Get product model by id */
  async getProductModelDetails() {
    try {
      const response = await getProductModelById(this.asset.productModelId);
      this.productModel = response.data;
      if (this.productModel?.picturePath) {
        this.productModelImageStyle.backgroundImage = `url("${this.productModel?.picturePath}")`;
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Handle product model picture
   * - check product model if exist otherwise loading will be displayed
   * - if product model exist and picture path is defined, show product model picture path uploaded in the system
   * - if no one from above > show default svg picture of the asset
   */
  get pictureUrlSource() {
    if (!this.productModel) {
      return null;
    }
    if (
      this.productModel?.picturePath &&
      this.productModel?.picturePath.length > 0
    ) {
      return this.productModel?.picturePath;
    }
    let tempSvg;
    switch (this.asset?.assetType) {
      case AssetType.MobileCompactor: {
        tempSvg = MobileCompactor;
        break;
      }
      case AssetType.StaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.AlbaStaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.TippingVehicle: {
        tempSvg = TippingVehicle;
        break;
      }
      case AssetType.TableTopTissector: {
        tempSvg = StaticCompactor; // TODO Update to correct picture
        break;
      }
    }
    return tempSvg;
  }

  /** Close modal window */
  closeDialog() {
    this.$emit('quit-event-details-dialog');
  }
}
