
import {
  Component,
  Vue,
  ProvideReactive,
  Inject,
} from 'vue-property-decorator';
import KpiBarChart from '@/components/widget/KpiBarChart.vue';
import { getKpiData } from '@/api/assets';
import { customFailedMessage } from '@/utils/prompt';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { collateExpandTableData } from '@/utils/kpidata';
import { determineEntityType } from '@/utils/assetRouteChecker';
import { EntityType } from '@/utils/workData/lookuptable';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';

@Component({
  name: 'AssetProductivity',
  components: {
    KpiBarChart,
  },
})
export default class extends Vue {
  dateRange = DEFAULT_DATE_RANGE;
  kpiNumberList: any = [
    {
      name: this.$t('KPI.overallProductivity'),
      value: 0,
      unit: this.$t('UNIT_METRIC_TONNE_P_HOUR'),
      growth: '0%',
    },
    {
      name: this.$t('KPI.totalLoad'),
      value: 0,
      unit: this.$t('UNIT_METRIC_TONNE'),
      growth: '0%',
    },
    {
      name: this.$t('KPI.overallMotorWorkingHours'),
      value: 0,
      unit: this.$t('UNIT_HOUR'),
      growth: '0%',
    },
  ];
  totalNumber: number = 0;
  averageNumber: number | string = 0;
  growth: string = '0%';
  KpiChartIsLoading: boolean = false;
  chartData: any = [];
  unitCode = 'UNIT_METRIC_TONNE_P_HOUR';
  routeAssetMap = ROUTE_ASSET_MAP;
  kpiTypeList = [
    {
      code: 'KPI.Productivity',
      tableHeadLabel: 'KPI.productivity',
      tableProp: 'productivity',
      unit: 'UNIT_METRIC_TONNE_P_HOUR',
      needGrowthPercentage: true,
    },
    {
      code: 'KPI.Payload',
      tableHeadLabel: 'KPI.payload',
      tableProp: 'payload',
      unit: 'UNIT_METRIC_TONNE',
      needGrowthPercentage: true,
    },
    {
      code: 'KPI.WorkingHours',
      tableHeadLabel: 'KPI.workingHours',
      tableProp: 'workingHours',
      unit: 'UNIT_HOUR',
      needGrowthPercentage: true,
    },
  ];
  entityType: EntityType = determineEntityType(this.$route);

  @ProvideReactive('tableList') tableList: any = [];
  @ProvideReactive('cols') cols: any = [];

  @Inject() expanded!: boolean;
  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  dateRangeChange(dateRange: DateRange) {
    this.dateRange = dateRange;
    this.fecthKpi();
  }

  collateAndCalculate(toHandleData: any) {
    toHandleData.values.forEach((item: any) => {
      this.chartData.push([
        item.ts,
        item.v != null ? Number(item.v).toFixed(2) : '#N/A',
      ]);
    });
  }

  calculateTotalKpi(detailData: any) {
    let totalValue: number = 0;
    let availableLength: number = 0;
    detailData.forEach((item: any) => {
      if (item.v != null) {
        availableLength++;
      }
      totalValue = totalValue + Number(item.v ? item.v : 0);
    });
    return availableLength > 0 ? Number(totalValue.toFixed(2)) : '#N/A';
  }

  async fecthKpi() {
    this.KpiChartIsLoading = true;
    let submitFields: any = [];
    this.kpiTypeList.forEach((item: any) => {
      submitFields.push({
        code: item.code,
        unit: item.unitCode,
        needGrowthPercentage: item.needGrowthPercentage,
      });
    });
    let realTimeKpiPostData = {
      metadata: {
        filter: {
          assetId: this.$route.params.id,
        },
        selection: {
          startDate: this.dateRange.start,
          endDate: this.dateRange.endExclusive,
          dataBucketSize: 'DBSIZE_DAY',
        },
      },
      details: [
        {
          entity: this.entityType,
          fields: submitFields,
        },
      ],
    };
    await getKpiData(realTimeKpiPostData, unref(this.context)).then((res) => {
      this.KpiChartIsLoading = false;
      if (!res) return;
      if (res.code === 200) {
        this.chartData = [];
        //this charts needs to show more kpi data. FE have to filter them
        let ProductivityDetailData = res.data.details[0].fields.filter(
          (item) => {
            return item.code === 'KPI.Productivity';
          }
        );
        let payloadDetailData = res.data.details[0].fields.filter((item) => {
          return item.code === 'KPI.Payload';
        });
        let workingHoursDetailData = res.data.details[0].fields.filter(
          (item) => {
            return item.code === 'KPI.WorkingHours';
          }
        );

        this.collateAndCalculate(ProductivityDetailData[0]);

        this.kpiNumberList.forEach((item: any) => {
          this.$set(
            this.kpiNumberList[0],
            `value`,
            this.calculateTotalKpi(ProductivityDetailData[0].values)
          );
          this.$set(
            this.kpiNumberList[0],
            `growth`,
            ProductivityDetailData[0].growthPercentage != null
              ? ProductivityDetailData[0].growthPercentage
              : null
          );

          this.$set(
            this.kpiNumberList[1],
            `value`,
            this.calculateTotalKpi(payloadDetailData[0].values)
          );
          this.$set(
            this.kpiNumberList[1],
            `growth`,
            payloadDetailData[0].growthPercentage != null
              ? payloadDetailData[0].growthPercentage
              : null
          );

          this.$set(
            this.kpiNumberList[2],
            `value`,
            this.calculateTotalKpi(workingHoursDetailData[0].values)
          );
          this.$set(
            this.kpiNumberList[2],
            `growth`,
            workingHoursDetailData[0].growthPercentage != null
              ? workingHoursDetailData[0].growthPercentage
              : null
          );
        });

        if (this.expanded) {
          //it's in the expanded page, FE should caculate the table data
          let { cols, tableList } = collateExpandTableData(
            res.data.details[0].fields,
            this.kpiTypeList,
            'date'
          );
          this.cols = cols;
          this.tableList = tableList;
        }
        return;
      } else {
        this.chartData = [];
      }
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    });
  }
}
