
import { Component, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import { getProductModelById, ProductModel } from '@/api/products';
import GeneralPrev from '../components/GeneralPrev.vue';
import GeneralForm from '../components/GeneralForm.vue';
import ModelSpecPrev from '../components/ModelSpecPrev.vue';
import ModelSpecForm from '../components/ModelSpecForm.vue';
import PartsPrev from '../components/PartsPrev.vue';
import PartsForm from '../components/PartsForm.vue';
import { PageModule } from '@/store/modules/page';
import { TranslateResult } from 'vue-i18n';

@Component({
  name: 'editModel',
  components: {
    NavPage,
    'general-prev': GeneralPrev,
    'general-form': GeneralForm,
    ModelSpecPrev,
    'model-spec': ModelSpecForm,
    'parts-prev': PartsPrev,
    'parts-form': PartsForm,
  },
})
export default class extends Vue {
  /** Local variables */
  activeName: string = 'general';
  isEditing: boolean = false;
  title: string = '';
  id = this.$route.params.id;
  productModelIsLoading: boolean = false;
  productModelInfo: ProductModel = {
    id: '',
    code: '',
    modelNumber: '',
    assetTypeId: '',
    assetTypeCode: '',
    lifeCycle: '',
    numberOfParts: '',
    picturePath: '',
    properties: [],
    containedParts: [],
  };

  created() {
    this.showTabs();
    this.getProductModelInfo();
  }

  mounted() {}

  //if the tab is partsinfo, fetch the partNumber info
  @Watch('activeName')
  onActiveChange(val: string, oldVal: string): void {
    this.isEditing = false;
    switch (val) {
      case 'general':
        this.$router.push(
          '/prod-cata/configure-model/' + this.id + '?activeName=general'
        );
        break;
      case 'modelSpec':
        this.$router.push(
          '/prod-cata/configure-model/' + this.id + '?activeName=modelSpec'
        );
        break;
      case 'partsInfo':
        this.$router.push(
          '/prod-cata/configure-model/' + this.id + '?activeName=partsInfo'
        );
        break;
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'general';
    }
  }

  /** Get product model info */
  async getProductModelInfo(): Promise<void> {
    try {
      this.productModelIsLoading = true;
      const res = await getProductModelById(this.$route.params.id);
      let translationAssetType: TranslateResult = this.$t(
        res.data.assetTypeCode
      );
      PageModule.setTitle(`${res.data.code}/${translationAssetType}`);
      this.productModelInfo = res.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.productModelIsLoading = false;
    }
  }

  /** Change state after clicking the configure button */
  switchEditState(): void {
    this.isEditing = true;

    if (this.activeName === 'modelSpec') {
      this.$nextTick(() => {
        // update the properties (it depends on the assetType) after the dom has been updated
        (this.$refs.modelSpec as any).updateProperties(this.id);
      });
    }
    if (this.activeName === 'partsInfo') {
      this.$nextTick(() => {
        // fetch all material  numbers after the dom has been updated
        (this.$refs.partsInfo as any).fetchAllMaterialNumbers();
      });
    }
  }

  // fetch update API after clicking save. by the way: the update info can be divided by three parts depends on the tabs.
  updateProductModel(): void {
    switch (this.activeName) {
      case 'general':
        (this.$refs.general as any).updateFormData(this.$route.params.id);
        break;
      case 'modelSpec':
        (this.$refs.modelSpec as any).updateModelSpec(this.$route.params.id);
        break;
      case 'partsInfo':
        (this.$refs.partsInfo as any).updatePartsData(this.$route.params.id);
        break;
    }
  }
}
