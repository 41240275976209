
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import echarts from 'echarts';
import ResizeMixin from '@/components/charts/mixins/resize';
import { MONTH_DAY_FORMAT } from '@/utils/time';
import { ChartData } from '@/widgets/maintenance/MaintenancePerformed.vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';

@Component({
  name: 'MaintChart',
})
export default class extends mixins(ResizeMixin) {
  @Prop() id!: string;
  @Prop() className!: string;
  @Prop({ default: '100%' }) width!: string;
  @Prop({ default: '200px' }) height!: string;
  @Prop({ required: true }) chartData!: ChartData;

  chart: any = null;
  originalDateForTooltip: string[] = [];

  @Watch('chartData', { deep: true })
  handleData(newData: any) {
    if (newData) {
      this.initChart();
    }
    return newData;
  }

  mounted() {
    this.originalDateForTooltip = cloneDeep(this.chartData?.xAxies);
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
    this.chart.setOption({
      xAxis: [
        {
          name: this.$t('common.day').toString(),
          type: 'category',
          data: this.chartData?.xAxies.reduce((arr, data) => {
            arr.push(moment(new Date(data)).format(MONTH_DAY_FORMAT));
            return arr;
          }, [] as string[]) as any,
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#C1C1C1',
          },
          axisTick: {
            show: false,
          },
          nameLocation: 'end',
          nameGap: 25,
          nameTextStyle: {
            color: '#C1C1C1',
            fontSize: 14,
            fontWeight: 'bold',
            align: 'right',
            verticalAlign: 'top',
            padding: [7, 0, 0, 0],
          },
        },
      ],
      yAxis: [
        {
          name: this.$t('maintenance.title').toString(),
          type: 'value',
          axisLabel: {
            fontSize: 12,
            color: '#C1C1C1',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          nameLocation: 'end',
          nameTextStyle: {
            color: '#C1C1C1',
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
      ],
      series: [
        {
          name: 'lineChart',
          type: 'line',
          data: this.chartData?.values,
          lineStyle: {
            color: '#C1C1C1',
            width: 1,
          },
          symbol: 'circle',
          symbolSize: 10,
          itemStyle: {
            color: '#0d8884',
          },
          barMaxWidth: 24,
        },
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        position: 'top',
        backgroundColor: 'rgba(55, 62, 65, 0.8)',
        textStyle: {
          color: '#fff',
        },
        formatter: (datas: any) => {
          let resHtml = `<p style="color: #B6B6B6;font-size: 12px; text-align:left; margin: 7px 9px 4px">${
            this.originalDateForTooltip[datas[0].dataIndex]
          }</p><p style="color: #fff;
          text-align:center; margin: 3px 7px 10px 7px;"> <span style="font-size: 16px;">
            ${
              datas[0].value
            }</span> <span style="font-size: 13px; margin: 0px 7px 0px 0px"> ${this.$t(
            'maintenance.maintenaceAppointments'
          ).toString()}</span></p>`;
          return resHtml;
        },
      },
      grid: {
        top: '25%',
        left: '80',
        bottom: '10%',
        right: '70',
      },
    });
  }
}
