
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getMaintItemById,
  MainteananceItemById,
  MaintenanceItemPart,
  MaintenanceItemRule,
} from '@/api/maintenance';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import NewCard from '@/components/cusCard/NewCard.vue';
import BaseHeader from './components/BaseHeader.vue';
import Modal from './components/Modal.vue';
import {
  ASSET_CONNECT_STATUS,
  MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM,
  MAINTENANCE_ITEM_STATUS,
} from '@/utils/workData/lookuptable';
import { promptSuccessBox, customFailedMessage } from '@/utils/prompt';
import {
  applyMaintenanceItemById,
  deprecateMaintenanceItemById,
} from '@/api/maintenance';
import {
  getAssetsByCustomParameters,
  getAssetDistribution,
  AssetDistribution,
} from '@/api/assets';
import {
  getProductModelById,
  ProductModel,
  ContainedPart,
} from '@/api/products';
import {
  Filter,
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';

@Component({
  name: 'viewMaintConf',
  components: {
    'general-info': GeneralInfo,
    'new-card': NewCard,
    'base-header': BaseHeader,
    modal: Modal,
  },
})
export default class extends Vue {
  @Prop() id!: string;

  /** Local variables */
  title: string = '';
  visible: boolean = false;
  dialogContent: any = '';
  partToReplacedList: string[] = [];
  partToCheckedList: string[] = [];
  maintenanceItemStatus: string = MAINTENANCE_ITEM_STATUS.MainiNew;
  isMaintenanceViewConfigLoading: boolean = false;
  isApplyBtnLoading: boolean = false;
  maintConfigInfo: MainteananceItemById = {
    id: '',
    name: '',
    assetType: '',
    productModel: '',
    maintenanceParts: [],
    maintenanceRules: [],
    productModelId: '',
    status: '',
    duration: '',
    isConductedManually: false,
  };
  maintenancePartsListIsLoading: boolean = false;
  modalDialogIsLoading: boolean = false;

  created() {
    this.fetchMaintConfInfoById();
  }

  /**
   * Handle edit maintenance item
   */
  handleEdit(): void {
    this.$router.push(
      `/maintenance-conf/create-new-maint-item/edit/${this.id}`
    );
  }

  /**
   * Prepare custom query parameters for requesting asset distribution
   */
  get queryParametersForAssetDistribution() {
    const finalQueryParameters: QueryParameter = {};
    finalQueryParameters.filters = [
      {
        name: 'productModelId',
        operator: FilterOperator.EQUAL,
        value: [this.maintConfigInfo.productModelId],
      },
      {
        name: 'connectStatus',
        operator: FilterOperator.EQUAL,
        value: [ASSET_CONNECT_STATUS.AssconAssigned],
      },
    ];

    return finalQueryParameters;
  }

  /**
   * Handle display modal for appling maintenance item
   * Show to user the number of affected assets and customers
   */
  async handleDisplayModal(): Promise<void> {
    try {
      this.isApplyBtnLoading = true;
      const res = await getAssetDistribution(
        this.queryParametersForAssetDistribution
      );
      if (res.code === 200) {
        let totalAssets: number = res.data.reduce<number>(
          (
            previousValue: number,
            currentValue: AssetDistribution,
            _currentIndex: number,
            _array: AssetDistribution[]
          ) => {
            const result =
              Number(previousValue) + Number(currentValue.noOfAssets);
            return result;
          },
          0
        );
        let uniqueCustomers: string[] = [];
        let methodAction: string =
          this.maintenanceItemStatus === MAINTENANCE_ITEM_STATUS.MainiNew
            ? this.$t('common.apply').toString().toLowerCase()
            : this.maintenanceItemStatus ===
              MAINTENANCE_ITEM_STATUS.MainiApplied
            ? this.$t('common.deprecate').toString().toLowerCase()
            : '';

        res.data.forEach((item: AssetDistribution) => {
          if (
            !uniqueCustomers.some(
              (uniqueCustomer: string) => uniqueCustomer === item.companyId
            )
          ) {
            uniqueCustomers.push(item.companyId);
          }
        });

        this.dialogContent = this.$t('maintConf.modalContent', {
          method: methodAction,
          itemName: `<span class="highlight-modal-info">${this.title}</span>`,
          totalAssets: `<span class="highlight-modal-info">${totalAssets}</span>`,
          totalCustomers: `<span class="highlight-modal-info">${uniqueCustomers.length}</span>`,
        }).toString();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.visible = true;
      this.isApplyBtnLoading = false;
    }
  }

  /**
   * Handle cancel
   */
  handleCancel(): void {
    this.visible = false;
  }

  /**
   * Handle Apply mainteance item
   */
  async handleApply(): Promise<void> {
    try {
      this.modalDialogIsLoading = true;
      const res = await applyMaintenanceItemById(this.maintConfigInfo.id);
      if (res.code === 200) {
        promptSuccessBox(this.$t('MAINI_APPLIED').toString());
        this.maintenanceItemStatus = MAINTENANCE_ITEM_STATUS.MainiApplied;
      }

      if (res.code === 400 || res.code === 500) {
        customFailedMessage(this.$tc('maintConf.couldNotApply'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.modalDialogIsLoading = false;
      this.visible = false;
    }
  }

  /**
   * Handle deprecate maintenance item
   */
  async handleDeprecate(): Promise<void> {
    try {
      this.modalDialogIsLoading = true;
      const res = await deprecateMaintenanceItemById(this.maintConfigInfo.id);
      if (res.code === 200) {
        promptSuccessBox(this.$t('MAINI_DEPRECATED').toString());
        this.maintenanceItemStatus = MAINTENANCE_ITEM_STATUS.MainiDeprecated;
      }

      if (res.code === 400 || res.code === 500) {
        customFailedMessage(this.$tc('maintConf.couldNotDeprecate'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.modalDialogIsLoading = false;
      this.visible = false;
    }
  }

  /**
   * Fetch maintenance configuration item by id
   */
  async fetchMaintConfInfoById(): Promise<void> {
    try {
      this.isMaintenanceViewConfigLoading = true;
      const res = await getMaintItemById(this.id);
      if (res.code === 200) {
        this.fetchProductModelById(res.data.productModelId, res.data!);
        this.title = res.data.name;
        this.maintConfigInfo = res.data;
        this.maintenanceItemStatus = res.data.status;
        this.moveDefaultRuleToFirstPosition();
        return;
      }

      if (
        res.code === 400 &&
        res.data.errors![0].code === 'ApiErrorFieldNotFound'
      ) {
        customFailedMessage(
          this.$t('maintConf.maintenanceItemDoesNotExit').toString()
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isMaintenanceViewConfigLoading = false;
    }
  }

  moveDefaultRuleToFirstPosition(): void {
    const indexOfNaturalKPICode =
      this.maintConfigInfo?.maintenanceRules?.findIndex(
        (item: MaintenanceItemRule) =>
          item.maintenanceKpiCode === 'MKPI.NaturalDays'
      );
    if (indexOfNaturalKPICode && indexOfNaturalKPICode > 0) {
      const element = this.maintConfigInfo?.maintenanceRules?.splice(
        indexOfNaturalKPICode,
        1
      )[0];
      this.maintConfigInfo?.maintenanceRules?.splice(0, 0, element!);
    }
  }

  /**
   * Fetch product model by id then from contained parts
   * Extract contained part name that will be corelated with mainteannce item part status to be checked/ to be replaced
   */
  async fetchProductModelById(
    productModelId: string,
    maintenanceItem: MainteananceItemById
  ): Promise<void> {
    try {
      this.maintenancePartsListIsLoading = true;
      const res = await getProductModelById(productModelId);
      const productModel: ProductModel = res.data;

      this.maintConfigInfo?.maintenanceParts?.forEach(
        (v: MaintenanceItemPart) => {
          const productModelName = productModel?.containedParts?.find(
            (part: ContainedPart) => part.id === v.containedPartId
          )?.name;
          if (
            v.maintenanceOperationKind ===
              MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM.MAINOK_REPLACE &&
            productModelName
          ) {
            this.partToReplacedList.push(productModelName);
          } else if (
            v.maintenanceOperationKind ===
              MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM.MAINOK_CHECK &&
            productModelName
          ) {
            this.partToCheckedList.push(productModelName);
          }
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.maintenancePartsListIsLoading = false;
    }
  }
}
