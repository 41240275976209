
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  MODEL_PART_TYPE_LIST,
  PRODUCT_MODEL_STATUS,
} from '@/utils/workData/lookuptable';

@Component({
  name: 'model-select',
  components: {},
})
export default class extends Vue {
  @Prop({ default: true }) showLifeCycleSelect!: boolean;

  partTypeList = MODEL_PART_TYPE_LIST;
  statusList = PRODUCT_MODEL_STATUS;
  partNameorId = '';
  lifeCycle: string = '';
  partType: string = '';

  mounted() {}

  sendFilterData() {
    let sendData = {
      partNameorId: this.partNameorId,
      partType: this.partType,
      lifeCycle: this.lifeCycle,
    };
    this.$emit('send-filter-data', sendData);
  }
}
