
import { Component, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import UserProfile from './components/UserProfile.vue';
import AccountSettings from './components/AccountSettings.vue';
import ViewSettings from './components/ViewSettings.vue';
import { PageModule } from '@/store/modules/page';

@Component({
  name: 'userSetting',
  components: {
    'new-card': NewCard,
    'user-profile': UserProfile,
    'account-settings': AccountSettings,
    'view-settings': ViewSettings,
  },
})
export default class extends Vue {
  activeName: string = 'myProfile';

  handleTabClick() {
    if (
      this.activeName === 'accountSettings' &&
      this.$route.query.activeName != 'accountSettings'
    ) {
      this.$router.push('/user-setting/index?activeName=accountSettings');
    } else if (
      this.activeName === 'viewSettings' &&
      this.$route.query.activeName != 'viewSettings'
    ) {
      this.$router.push('/user-setting/index?activeName=viewSettings');
    } else if (
      this.activeName === 'myProfile' &&
      this.$route.query.activeName != 'myProfile'
    ) {
      this.$router.push('/user-setting/index?activeName=myProfile');
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'myProfile';
    }
  }

  mounted() {
    this.showTabs();
  }
}
