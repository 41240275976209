
import { Component, Vue } from 'vue-property-decorator';
import { DEPLOYMENT_MANAGEMENT_COLS } from '@/utils/workData/deploymentManagementCols';
import {
  getDeployments,
  DeploymentsResponse,
  Deployment,
} from '@/api/systemReleaseManagement';
import PureTable from '@/components/table/PureTable.vue';
import {
  GENERAL_QUERY_OPERATORS,
  PROPER_ASSET_TYPE,
  UserRole,
} from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';
import Debouncer from '@/utils/debounce';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';

@Component({
  name: 'releasesManagement',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = DEPLOYMENT_MANAGEMENT_COLS;
  pageTotal = UserModule.gridPageSize;
  userRole = UserModule.role;
  data: Deployment[] = [];
  numberOfReleases = this.data.length;
  searchInput = '';
  isUserAdmin: boolean = false;
  deploymentsTableIsLoading: boolean = false;
  pagination = {
    page: 1,
    size: this.pageTotal,
  };

  // table filter list
  filterList: { field: string; label?: string; dropdownEnum?: any }[] = [
    { field: 'systemReleaseId' },
    { field: 'companyName' },
    { field: 'deploymentBy' },
    { field: 'assetType', dropdownEnum: PROPER_ASSET_TYPE },
    { field: 'productModelCode' },
    { field: 'productModelNumber' },
  ];
  filterEnum: any = null;
  tableFilter: { searchFieldName: string; searchFieldValue: string | null } = {
    searchFieldName: this.filterList[0].field,
    searchFieldValue: null,
  };
  appliedFilters: {
    filters: any[];
    pagination: { page: number; size: number };
    sorters: { field: string; order: string }[];
  } = {
    filters: [],
    pagination: this.pagination,
    sorters: [],
  };

  created() {
    this.isUserAdmin = this.userRole === UserRole.HyvaAdmin;
    this.prepareDefaultQueryParameters();
    this.getData();
  }

  /**
   * Prepare default query parameters
   */
  prepareDefaultQueryParameters(): void {
    this.applyDefaultSorterByCreatedOn();
  }

  /**
   * Get deployments data from API
   */
  async getData(): Promise<void> {
    try {
      this.deploymentsTableIsLoading = true;
      const res = await getDeployments(this.appliedFilters);
      const response = res.data;
      for (let i in response.deployments) {
        response.deployments[
          i
        ].progressPercentage = `${response.deployments[i].progressPercentage}%`;
        response.deployments[i].defaultRelease = response.deployments[i]
          .defaultRelease
          ? this.$t('userModule.yes').toString()
          : this.$t('userModule.no').toString();
      }
      this.data = response.deployments;
      this.pageTotal = response.total;
    } catch (err) {
      console.log(err);
    } finally {
      this.deploymentsTableIsLoading = false;
    }
  }

  /**
   * Handle sort change event
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    this.appliedFilters.sorters = [];
    if (sortOrder) {
      this.appliedFilters.sorters.push({
        field: sortField,
        order: sortOrder,
      });
    } else {
      this.applyDefaultSorterByCreatedOn();
    }
    await this.getData();
  }

  /**
   * Handle search input event
   */
  handleSearchInput(): void {
    this.appliedFilters.filters = [];
    if (this.tableFilter.searchFieldValue) {
      this.appliedFilters.filters = [
        {
          name: this.tableFilter.searchFieldName,
          operator: GENERAL_QUERY_OPERATORS.Like,
          value: [encodeURIComponent(this.tableFilter.searchFieldValue)],
        },
      ];
    }
    Debouncer.debounce(this.getData);
  }

  async handleSearchClear(): Promise<void> {
    this.tableFilter.searchFieldValue = '';
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.tableFilter.searchFieldName
    )?.dropdownEnum;
    this.handleSearchInput();
  }

  /**
   * Handle page change event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    Debouncer.debounce(this.getData);
  }

  /**
   * Add new deployment
   */
  addnewDeploy(): void {
    this.$router.push('new/');
  }

  /**
   * Apply default sorter by created on
   * - deploymentFinishTime will be retrieved DESC by the API
   */
  applyDefaultSorterByCreatedOn(): void {
    const defaultSorter: Sorter = {
      field: 'deploymentStartTime',
      order: SorterOrder.DESC,
    };
    this.appliedFilters.sorters = [defaultSorter];
  }
}
