
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Modal',
})
export default class extends Vue {
  @Prop({ required: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ default: false }) showLoginBtn!: boolean;
  @Prop({ default: true }) showDeactivateBtn!: boolean;
  @Prop({ default: true }) isApplyStatus!: boolean;
  @Prop({ default: false }) dialogIsLoading?: boolean;

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleApply() {
    this.$emit('handle-apply');
  }

  handleDeprecate() {
    this.$emit('handle-deprecate');
  }
}
