
import { Component, Vue } from 'vue-property-decorator';
import CreateSubscriptionCard from './components/CreateSubscriptionCard.vue';
import General from '@/views/subscriptionPackages/components/General.vue';
import Features from '@/views/subscriptionPackages/components/Features.vue';
import {
  getSubscriptionPackages,
  getSystemFeatures,
  createSubscriptionPackage,
  deleteSubscriptionPackageById,
} from '@/api/subscriptionPackages';
import {
  PACKAGE_TYPE_LIST,
  PACKAGE_TYPE,
  APPLICABILITY_TYPE,
  FEATURE_VALUE_TYPE,
} from '@/utils/workData/lookuptable';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import {
  getSubscriptionPackageById,
  updateSubscriptionPackageById,
} from '@/api/subscriptionPackages';
import { SYSTEM_FEATURES } from '@/utils/workData/lookuptable';
import { PageModule } from '@/store/modules/page';
import { CommonResult } from '@/api/commonResult';

@Component({
  name: 'CreatePackage',
  components: {
    'new-card': CreateSubscriptionCard,
    general: General,
    features: Features,
  },
})
export default class extends Vue {
  /** Local variables */
  activeName: string = '';
  allSubscriptions: any = [];
  generalTabIsLoading: boolean = false;
  featuresData: any = [];
  processedFeaturesData: any = [];
  generalEditData: any = {};
  source: string = 'new';
  isCardVisible: boolean = false;
  isEditable: boolean = true;
  formErrors: boolean = false;
  featuresWithInputType: any = [
    SYSTEM_FEATURES.MaxUsersCount,
    SYSTEM_FEATURES.MaxOrgs,
  ]; // Features table > value col > all input type
  featuresWithDateType: any = [SYSTEM_FEATURES.Duration]; // Features table > value col > all data type
  moduleTitle: string = '';
  featuresWithBooleanType: any = [
    // Features table > value col > all boolean type
    SYSTEM_FEATURES.NewReportTmlp,
    SYSTEM_FEATURES.NewMainPlan,
    SYSTEM_FEATURES.LiveMonitoring,
    SYSTEM_FEATURES.ConfigDashboard,
    SYSTEM_FEATURES.CustomDashboard,
    SYSTEM_FEATURES.BookmarkPage,
    SYSTEM_FEATURES.TripLive,
    SYSTEM_FEATURES.SafetyDashboard,
    SYSTEM_FEATURES.GraphType,
    SYSTEM_FEATURES.Notification,
    SYSTEM_FEATURES.PartsHistory,
    SYSTEM_FEATURES.UecDescription,
    SYSTEM_FEATURES.CreateUser,
    SYSTEM_FEATURES.HealthScore,
    SYSTEM_FEATURES.Ooe,
    SYSTEM_FEATURES.LiveImagery,
    SYSTEM_FEATURES.AutoRenewal,
  ];
  generalData: any = {
    packageName: '',
    packageType: '',
    companyType: '',
    assetType: '',
    note: '',
  };
  fieldErrors: any = {
    packageName: '',
    packageType: '',
    companyType: '',
    assetType: '',
    note: '',
  };
  featureValueType = FEATURE_VALUE_TYPE;
  timeDurationFieldHasErrors: any = [];

  created() {
    if (this.$route.params.packageId) {
      // check if loaded route has packageId set => edit mode and display current package data
      this.source = 'edit';
      this.isEditable = false;
      this.getPackageById(this.$route.params.packageId);
      return;
    }

    this.getAllSubscriptionPackages();
  }

  /**
   * Get a specific subscription package by route packageId
   * @param packageId
   */
  async getPackageById(packageId: string) {
    this.generalTabIsLoading = true;
    await getSubscriptionPackageById(packageId).then((res: any) => {
      if (res.code && res.code === 200) {
        PageModule.setTitle(res.data.name);
        this.generalEditData = {
          id: res.data.id,
          packageName: res.data.name,
          packageType: res.data.type,
          assetType: res.data.assetType,
          companyType: res.data.companyType,
          note: res.data.note,
        };

        this.generalData = this.generalEditData;
        this.processedFeaturesData = res.data.systemFeatures;

        this.source = 'edit';
        this.activeName = 'general';
        this.isCardVisible = true;
        this.generalTabIsLoading = false;
        return;
      }

      this.generalTabIsLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Get all subscription packages for further processing of unique name on the new creation
   */
  async getAllSubscriptionPackages() {
    this.generalTabIsLoading = true;
    await getSubscriptionPackages().then((res) => {
      if (res.code === 200) {
        this.allSubscriptions = res.data.subscriptionPackages;

        res.data.subscriptionPackages.forEach((item: any) => {
          let packageTypeObj = {
            id: item.id,
            type: item.type,
            translatedType: PACKAGE_TYPE_LIST.find(
              (type: any) => type.id === item.type
            )!.value,
          };
        });

        this.source = 'new';
        this.activeName = 'general';
        this.isCardVisible = true;
        this.generalTabIsLoading = false;
        return;
      }

      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Get features specific to a package type
   * @param packageType
   */
  async getFeatures(packageType: string) {
    await getSystemFeatures().then((res: any) => {
      if (res.code && res.code === 200) {
        this.featuresData = res.data;
        this.processedFeaturesData = res.data.filter((item: any) => {
          if (this.featuresWithBooleanType.includes(item.code))
            item.value = 'false';
          if (packageType === PACKAGE_TYPE.AssetType)
            return item.applicabilityType === APPLICABILITY_TYPE.FatAssetType;
          if (packageType === PACKAGE_TYPE.CompanyType)
            return item.applicabilityType === APPLICABILITY_TYPE.FatCompanyType;
        });

        if (this.processedFeaturesData.length === 0)
          this.processedFeaturesData = ['noFeatures'];
        return;
      }

      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Create new package remotely
   */
  async handleCreateNewPackage() {
    this.validateFields();

    if (
      !this.generalData.packageType &&
      this.processedFeaturesData.length === 0
    ) {
      customFailedMessage(
        this.$t('subscriptionPackages.atLeastOneFeatureRequired').toString()
      );
      return;
    }

    if (this.formErrors) {
      customFailedMessage(
        this.$t('subscriptionPackages.completeRequiredFields').toString()
      );
      return;
    }

    let payload = this.prepareBackendPayload('post');

    if (payload.systemFeatures.length === 0) {
      customFailedMessage(
        this.$t(
          'subscriptionPackages.oneFeatureShouldHaveValidValue'
        ).toString()
      );
      return;
    }

    if (this.timeDurationFieldHasErrors.some((item: any) => item)) {
      customFailedMessage(
        this.$t('subscriptionPackages.timeDurationIncorrectFormat').toString()
      );
      this.timeDurationFieldHasErrors = [];
      return;
    }

    await createSubscriptionPackage(payload).then((res: any) => {
      if (res.code && (res.code === 201 || res.code === 200)) {
        this.$router.push('/subscription-packages/index');
        promptSuccessBox(
          this.$t('subscriptionPackages.packageCreated').toString()
        );
        return;
      }

      if (res.data.errors[0].code === 'ApiErrorFieldDuplicate') {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.nameMustBeUnique'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameDuplicate').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldName'
      ) {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.packageNameTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameTooLong').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldNote'
      ) {
        this.fieldErrors.note = this.$t(
          'subscriptionPackages.packageNoteTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNoteTooLong').toString()
        );
        return;
      }

      promptFailedBox(
        this.$t('subscriptionPackages.packageCreation').toString()
      );
    });
  }

  validateFields() {
    let hasErrors = [];
    let excludedKeys: any = ['note'];

    if (
      !this.generalData.packageType &&
      this.processedFeaturesData.length === 0
    ) {
      this.formErrors = true;
    }

    if (this.generalData.packageType === PACKAGE_TYPE.CompanyType) {
      excludedKeys.push('assetType');
    } else {
      excludedKeys.push('companyType');
    }

    for (const [key, value] of Object.entries(this.generalData)) {
      if (value === '' && !excludedKeys.includes(key.toString())) {
        hasErrors.push(true);
        this.fieldErrors[key] =
          this.$t('subscriptionPackages.fieldIsRequired') + '.';
      } else {
        this.fieldErrors[key] = '';
      }
    }

    if (hasErrors.includes(true)) {
      this.formErrors = true;
      return;
    }

    this.formErrors = false;
  }

  /**
   * Update an existing package remotely
   */
  async handleUpdate() {
    this.validateFields();
    if (this.formErrors) {
      customFailedMessage(
        this.$t('subscriptionPackages.completeRequiredFields').toString()
      );
      return;
    }

    let payload = this.prepareBackendPayload('put');

    if (payload.systemFeatures.length === 0) {
      customFailedMessage(
        this.$t(
          'subscriptionPackages.oneFeatureShouldHaveValidValue'
        ).toString()
      );
      return;
    }

    if (this.timeDurationFieldHasErrors.some((item: any) => item)) {
      customFailedMessage(
        this.$t('subscriptionPackages.timeDurationIncorrectFormat').toString()
      );
      this.timeDurationFieldHasErrors = [];
      return;
    }

    await updateSubscriptionPackageById(
      this.$route.params.packageId,
      payload
    ).then((res: any) => {
      if (res.code && (res.code === 201 || res.code === 200)) {
        this.$router.push('/subscription-packages/index');
        promptSuccessBox(
          this.$t('subscriptionPackages.packageUpdated').toString()
        );
        return;
      }

      if (res.data.errors[0].code === 'ApiErrorFieldDuplicate') {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.nameMustBeUnique'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameDuplicate').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldName'
      ) {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.packageNameTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameTooLong').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldNote'
      ) {
        this.fieldErrors.note = this.$t(
          'subscriptionPackages.packageNoteTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNoteTooLong').toString()
        );
        return;
      }

      promptFailedBox(
        this.$t('subscriptionPackages.packageUpdateFail').toString()
      );
    });
  }

  /**
   * Prepare payload structure for BE requests POST & PUT
   */
  prepareBackendPayload(type: string) {
    let finalFeatures: any = [];
    this.timeDurationFieldHasErrors = [];
    this.processedFeaturesData.forEach((item: any) => {
      // First version of requirement -> temporary left here for feature refactoring if unde needed
      // if (this.featureValueType.FvtDuration !== item.valueType && item.value && item.value != "false" &&
      //     item.value.length > 0)
      // {
      //     finalFeatures.push(
      //         {
      //             id: item.id,
      //             value: item.value ? item.value.trim() : '',
      //         }
      //     )
      // }

      if (this.featureValueType.FvtDuration !== item.valueType) {
        finalFeatures.push({
          id: item.id,
          value: item.value ? item.value.trim() : '',
        });
      }

      if (
        this.featureValueType.FvtDuration === item.valueType &&
        item.value &&
        item.value.length > 0 &&
        item.unit &&
        item.unit.length > 0
      ) {
        finalFeatures.push({
          id: item.id,
          value: item.value ? item.value.trim() : '',
          unit: item.unit ? item.unit.trim() : '',
        });
      }

      if (
        this.featureValueType.FvtDuration === item.valueType &&
        ((!item.value && item.unit) || (item.value && !item.unit))
      ) {
        this.timeDurationFieldHasErrors.push(true);
      } else {
        this.timeDurationFieldHasErrors.push(false);
      }
    });

    let payload: any = {
      name: this.generalData.packageName,
      systemFeatures: finalFeatures,
      note: this.generalData.note,
    };

    if (type === 'post') {
      payload.type = this.generalData.packageType;
    }

    if (this.generalData.companyType) {
      payload.companyType = this.generalData.companyType;
    } else {
      payload.assetType = this.generalData.assetType;
    }

    return payload;
  }

  /**
   * Event triggered when package type is changed -> bring the coresponding features by package type
   */
  handlePackageTypeFeatures(packageType: string) {
    this.getFeatures(packageType);
  }

  /**
   * Triggered from child when updating
   */
  handleGeneralDataUpdate(data: any) {
    if (this.isEditable) this.validateFields();
    this.generalData = data;
  }

  /**
   * Delete subscription package when edit mode
   */
  async handleDeletePackage() {
    const result = await deleteSubscriptionPackageById(
      this.$route.params.packageId
    );
    if (result.code === 400) {
      customFailedMessage(`${result.data.errors[0].message}.`);

      return;
    }

    this.$router.push('/subscription-packages/index');
    promptSuccessBox(this.$t('subscriptionPackages.packageDeleted').toString());
  }

  /**
   * Handle Tab click
   */
  handleTabClick() {
    if (this.activeName === 'general') {
      // this.$router.push("/subscription-packages/create-package?activeName=general");
    } else this.activeName === 'features';
    {
      // this.processedFeaturesData = this.featuresData
      // this.$router.push("/subscription-packages/create-package?activeName=features");
    }
  }

  /**
   * Show a specific tab
   */
  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'general';
    }
  }

  // mounted() {
  //     this.showTabs();
  // }
}
