
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import MultiSearch from '@/components/form/MultiSearch.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import { getEvents, Event } from '@/api/event';
import {
  EventSeverity,
  UI_COLUMN_CODES,
  EVENT_TYPE_CATEGORIES_FILTER_OPTIONS,
  COMPANY_TYPE,
} from '@/utils/workData/lookuptable';
import { TableColumn } from '@/utils/types/columnCustomizationTypes';
import { OPERATION_LOG_TABLE_CUSTOMIZATION } from '@/utils/workData/tableCustomization';
import UtilTable from '@/components/table/UtilTable.vue';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';
import { UserModule } from '@/store/modules/user';
import { RouteAssetItem, ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { IncludedWidget } from '@/api/widgets';
import Card from '@/components/layout/widget/Card.vue';
import { DateRange } from '@/utils/types/date';
import { Ref, unref } from 'vue';
import {
  ActiveContext,
  flattenOrganizations,
  LoggedInUserRef,
  useActiveContext,
  useLoggedInUser,
} from '@/composables/context';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { isCompanyTypeOf } from '@/utils/companyService';
import { getCustomerById } from '@/api/customer';
import {
  getOrgByIdWithMultipleFiltering,
  Organization,
} from '@/api/organizations';
import { CommonResult } from '@/api/commonResult';

@Component({
  name: 'EventsExpanded',
  components: {
    Card,
    MultiSearch,
    TimeSelect,
    UtilTable,
    EventDetailsModal,
  },
})
export default class extends Vue {
  @Inject() widget!: IncludedWidget;

  /** Local variables */
  cols: TableColumn[] = [];
  rows: Event[] = [];
  total: number = 0;
  page: number = 1;
  tableCustomization = OPERATION_LOG_TABLE_CUSTOMIZATION;
  selectedEvent: string | null = null;
  assetRelatedData: RouteAssetItem | null = null;
  filter: { key: string; value: string } | null = null;
  sort: { field: string; order: SorterOrder } | null = null;
  timespan: DateRange | null = null;
  loading = false;
  isLoggedInUserHelpdesk: boolean = isCompanyTypeOf([COMPANY_TYPE.Helpdesk]);
  loggedInUser!: LoggedInUserRef;
  context!: Ref<ActiveContext>;
  selectedCustomerFlattenOrgs: Organization[] = [];

  async created() {
    this.loggedInUser = useLoggedInUser();
    this.context = useActiveContext();
    this.selectedCustomerFlattenOrgs =
      (await this.fetchFlattenOrgsForSelectedCustomer()) ?? [];
  }

  /**
   * Get default options
   * Different approach for flatten orgs of the selected customer when logged in as helpdesk
   */
  get options() {
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    const activeContext = unref(this.context);

    let orgs: Organization[] = [];
    if (UserModule.companyType != COMPANY_TYPE.Helpdesk) {
      orgs = flattenOrganizations(
        activeContext.organization
          ? activeContext.organization
          : user.organization
      );
    } else {
      orgs = this.selectedCustomerFlattenOrgs;
    }

    return [
      {
        key: 'companyAssetId',
        label: this.$t('operationLog.UICOL_OPERATION_LOG_ASSET').toString(),
      },
      {
        key: 'organizationId',
        label: this.$t(
          'operationLog.UICOL_OPERATION_LOG_ORGANIZATION'
        ).toString(),
        options: orgs.map((s: Organization) => ({
          key: s.id,
          label: s.name,
        })),
      },
      {
        key: 'severityCode',
        label: this.$t('operationLog.UICOL_OPERATION_LOG_ICON').toString(),
        options: Object.values(EventSeverity),
      },
      {
        key: 'eventTypeCategoryCode',
        label: this.$t(
          'operationLog.UICOL_OPERATION_LOG_EVENT_TYPE'
        ).toString(),
        options: Object.values(EVENT_TYPE_CATEGORIES_FILTER_OPTIONS),
      },
      // { key: 'eventName', label: this.$t('operationLog.UICOL_OPERATION_LOG_EVENT_NAME').toString() }
    ];
  }

  /**
   * Fetch selected customer flatten organization from hierarchy
   * Used only for scenario when logged in as helpdesk!
   */
  async fetchFlattenOrgsForSelectedCustomer(): Promise<
    Organization[] | undefined
  > {
    try {
      const selectedCustomer = unref(this.context)?.selectedCompany;
      if (UserModule.companyType != COMPANY_TYPE.Helpdesk || !selectedCustomer)
        return undefined;
      const customerById = await getCustomerById(selectedCustomer);
      const primaryOrganizationResponse: CommonResult<Organization> =
        await getOrgByIdWithMultipleFiltering(
          customerById?.data?.primaryOrganizationId,
          `?selectedCustomer=${selectedCustomer}`
        );
      return flattenOrganizations(primaryOrganizationResponse?.data);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetch filters added dynamically
   * - if no org ids from impersonated customer(s) (could be empty list), add default orgId filter from logged in user
   */
  get filters(): Filter[] {
    const filters: Filter[] = [];

    if (!this.isLoggedInUserHelpdesk) {
      filters.push({
        name: 'organizationId',
        operator: FilterOperator.IN,
        value:
          unref(this.context)?.organizationIds &&
          !unref(this.context)?.organizationIds?.some((el) => el === undefined)
            ? unref(this.context)?.organizationIds
            : [UserModule.organizationId],
      });
    }

    if (this.assetRelatedData) {
      filters.push({
        name: 'assetTypeCode',
        operator: FilterOperator.EQUAL,
        value: [this.assetRelatedData.assetTypeCode],
      });
    }

    if (this.$route.params.id) {
      filters.push({
        name: 'assetId',
        operator: FilterOperator.EQUAL,
        value: [this.$route.params.id],
      });
    }

    if (this.filter?.value) {
      filters.push({
        name: this.filter.key,
        operator: ['companyAssetId'].some(
          (el: string) => el === this.filter?.key
        )
          ? FilterOperator.LIKE
          : FilterOperator.EQUAL,
        value: [this.filter.value],
      });
    }

    if (this.timespan) {
      filters.push({
        name: 'timestamp',
        operator: FilterOperator.BETWEEN_EXCLUSIVE,
        value: [this.timespan.start, this.timespan.endExclusive],
      });
    }

    if (UserModule.i18nCode) {
      filters.push({
        name: 'i18nCode',
        operator: FilterOperator.EQUAL,
        value: [UserModule.i18nCode],
      });
    }

    return filters;
  }

  /**
   * Retrieve default sorter by timestamp
   */
  get sorters(): Sorter[] {
    const sorters: Sorter[] = [
      {
        field: 'timestamp',
        order: SorterOrder.DESC,
      },
    ];

    return this.sort ? [this.sort] : sorters;
  }

  mounted() {
    if (this.$route.name) {
      this.assetRelatedData = ROUTE_ASSET_MAP.get(this.$route.name) || null;
    }

    this.cols = [
      this.getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_ICON, 'severityName'),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_ASSET,
        'companyAssetId'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_ORGANIZATION,
        'organizationName'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_TIMESTAMP,
        'timestamp'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE,
        'eventTypeCategoryName'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_NAME,
        'eventName'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_DESCRIPTION,
        'eventDescription'
      ),
    ];

    this.getData();
  }

  /**
   * Deep watch over filters and sorters
   */
  @Watch('filters', { deep: true })
  @Watch('sorters', { deep: true })
  async getData(): Promise<void> {
    try {
      this.loading = true;
      const eventsResponse = await this.fetchEvents();
      this.rows = eventsResponse.data.events;
      this.total = eventsResponse.data.total;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  private async fetchEvents() {
    const loggedInUser = unref(this.loggedInUser);
    const queryParameter: QueryParameter = {
      filters: this.filters,
      sorters: this.sorters,
      pagination: {
        page: this.page,
        size: UserModule.gridPageSize,
      },
      timezone:
        loggedInUser!.companyType === COMPANY_TYPE.Helpdesk
          ? unref(this.context)?.primaryOrgTimeZone
          : UserModule.timeZone,
    };
    return loggedInUser && loggedInUser.companyType === COMPANY_TYPE.Helpdesk
      ? await getEvents(queryParameter, unref(this.context))
      : await getEvents(queryParameter);
  }

  /**
   * Handle sort event
   * @param field
   * @param receivedOrder
   */
  handleSort(field: string, order: SorterOrder): void {
    this.sort = order ? { field, order } : null;
  }

  /**
   * Handle page event
   */
  handlePagination(page: number): void {
    this.page = page;
    this.getData();
  }

  /**
   * Get column
   * @param code
   * @param propertyName
   */
  private getColumn(code: string, propertyName: string): TableColumn {
    return {
      label: this.$t(`operationLog.${code}`) as string,
      code: code,
      prop: propertyName,
      required: true,
      visible: true,
      order: 0,
      kpiUnitCode: '',
      isKpiData: false,
    };
  }
}
