
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import VerticalDivide from '../components/VerticalDivide.vue';
import {
  getAssetOnBoardingById,
  getUnassignedGateways,
} from '@/api/assetsMgmt';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import CusFormItem from '@/components/form/CusFormItem.vue';
import moment from 'moment';
import ConnectionProgress from '../components/ConnectionProgress.vue';
import { PageModule } from '@/store/modules/page';
import { formatServerDate } from '../helper';

@Component({
  name: 'EditConnectAsset',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    VerticalDivide,
    'cus-form-item': CusFormItem,
    'connection-progress': ConnectionProgress,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetOnBoardingId!: string;

  /** Local variables */
  connecAssetInfo: any = {};
  title = '';
  isConnectAssetLoading: boolean = false;
  unassignedGatewaysList: any = [];
  selfCheckingIsLoading: boolean = false;
  isSelfChecked: boolean = false;
  selfCheckActions: any = [];
  isConnectionProgressLoading: boolean = false;
  companyAssetId: string = '';

  created() {
    this.fetchAssetOnBoardingById();
    this.fetchUnassignedGateways();
  }

  /**
   * Retrieve from API the assetOnBoarding by Id data
   */
  async fetchAssetOnBoardingById() {
    this.isConnectionProgressLoading = true;
    this.isConnectAssetLoading = true;
    await getAssetOnBoardingById(this.assetOnBoardingId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        let response = res.data;
        this.connecAssetInfo = response;
        this.connecAssetInfo.manufactureDate = formatServerDate(
          res.data.manufactureDate
        );
        PageModule.setTitle(
          this.$t('assetMgmt.connectAssets') + `: ${res.data.serialNumber}`
        );
        this.isConnectAssetLoading = false;
      }

      if (res.code === 400) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        this.isConnectAssetLoading = false;
      }

      this.isConnectionProgressLoading = false;
    });
  }

  /**
   * Retrieve from API unassigned gateways
   */
  async fetchUnassignedGateways() {
    await getUnassignedGateways().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.unassignedGatewaysList = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  get rules() {
    const rules = {
      gatewayId: [
        {
          required: true,
          message: this.$t('assetMgmt.gatewayIdRequired'),
          trigger: 'change',
        },
      ],
    };

    return rules;
  }

  handleCompanyAssetIdChange() {
    this.connecAssetInfo.companyAssetId = this.companyAssetId;
  }
}
