
import { Component, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import {
  GENERAL_QUERY_OPERATORS,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import {
  getPendingUpdates,
  PendingUpdatesResponse,
  PendingUpdate,
} from '@/api/systemReleaseManagement';
import { UserModule } from '@/store/modules/user';
import { DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS } from '@/utils/workData/deploymentPendingUpdatesManagementCols';
import {
  startNewRelease,
  getAssetListForSpecifiedRelease,
} from '@/api/systemReleaseManagement';
import { customFailedMessage } from '@/utils/prompt';
import {
  flattenOrganizations,
  LoggedInUserRef,
  useLoggedInUser,
} from '@/composables/context';
import { unref } from 'vue';
import { KpiDataRequest } from '@/api/assets';

interface SelectedRelease {
  asset: string | string[];
  releaseUUID: string;
}

interface NewRelease {
  systemReleaseUUID: string;
  companyId: string;
  assets: string[];
}

@Component({
  name: 'BatchDeploy',
  components: {
    'new-card': NewCard,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  isViewModeLoading: boolean = false;
  isBatchDeploymentPageLoading: boolean = false;
  customerId: string = this.$route.params.customerId;
  activateOrDeactivate: string = '';
  data: PendingUpdate[] = [];
  selectedReleases: SelectedRelease[] = [];
  deploymentCols = DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS;
  deploymentsTotal: number = 0;
  assetList: string[] = [];
  kpiData: KpiDataRequest = {
    metadata: {
      filter: {
        assetIds: [],
      },
    },
    details: [
      {
        entity: '',
        fields: [
          {
            code: KPI_FIELDS.OperationalStatus,
          },
          {
            code: KPI_FIELDS.LastCommunicationTime,
          },
        ],
      },
    ],
  };
  releaseAssetListFilter = {
    filters: [
      {
        name: 'systemReleaseId',
        operator: GENERAL_QUERY_OPERATORS.Like,
        value: [''],
      },
    ],
    sorters: [
      {
        field: 'assetType',
        order: 'asc',
      },
    ],
    pagination: {
      page: '1',
      size: '10',
    },
  };
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  filter = {
    name: '',
    operator: GENERAL_QUERY_OPERATORS.Equal,
    values: [''],
  };
  filters = {
    filters: [
      {
        name: 'organizationId',
        operator: GENERAL_QUERY_OPERATORS.In,
        value: [] as string[], // Filled in in created()
      },
    ],
    sorters: [
      {
        field: 'assetType',
        order: 'asc',
      },
    ],
    pagination: this.pagination,
  };
  loggedInUser!: LoggedInUserRef;

  created() {
    this.loggedInUser = useLoggedInUser();
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    this.filters.filters[0].value = flattenOrganizations(user.organization).map(
      (org) => org.id
    );
    this.getData();
  }

  /**
   * Get pending updates from API
   */
  async getData(): Promise<void> {
    try {
      this.isViewModeLoading = true;
      const response = await getPendingUpdates(this.filters);
      this.kpiData.metadata.filter.assetIds = response.data.pendingUpdates.map(
        (asset: PendingUpdate) => asset.id
      );
      this.data = response.data.pendingUpdates;
    } catch (err) {
      console.error(err);
    } finally {
      this.isViewModeLoading = false;
    }
  }

  /**
   * Handle update selected release
   * @param selectedReleases
   */
  handleUpdateSelectedRelease(selectedReleases: SelectedRelease[]): void {
    this.selectedReleases = selectedReleases;
  }

  /**
   * Generate deployment body
   */
  async generateDeploymentsBody(): Promise<NewRelease[]> {
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    let deployments: NewRelease[] = [];
    let releases = Object.keys(this.selectedReleases);
    for (let release of releases) {
      let newRelease = {
        systemReleaseUUID: release,
        companyId: user.companyId, // company ID is not returned on the API
        assets: this.getAssetIdsForSystemRelease(
          this.selectedReleases[release as any]
        ),
      };
      deployments.push(newRelease);
    }
    return deployments;
  }

  /**
   * Get asset ids for system release
   * @param releases
   */
  getAssetIdsForSystemRelease(releases: any): string[] {
    let ids: string[] = releases.map(
      (release: SelectedRelease) => release.asset
    );
    return ids;
  }

  /**
   * Handle deploy event
   */
  async handleDeploy(): Promise<void> {
    try {
      this.isBatchDeploymentPageLoading = true;
      const response = await startNewRelease({
        deployments: await this.generateDeploymentsBody(),
      });
      if (response.code === 200) this.$router.push(`/deploy/index`);
      if (response.code === 400) {
        customFailedMessage(response.data.errors[0].message as string);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isBatchDeploymentPageLoading = false;
    }
  }
}
