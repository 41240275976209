
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  MODEL_ASSET_TYPE_LIST,
  PRODUCT_MODEL_STATUS,
} from '@/utils/workData/lookuptable';

@Component({
  name: 'model-select',
  components: {},
})
export default class extends Vue {
  assetTypeList = MODEL_ASSET_TYPE_LIST;
  statusList = PRODUCT_MODEL_STATUS;
  productModel = '';
  lifeCycle: string = '';
  assetType: string = '';

  mounted() {}

  sendFilterData() {
    let sendData = {
      code: this.productModel,
      assetType: this.assetType,
      lifeCycle: this.lifeCycle,
    };
    this.$emit('send-filter-data', sendData);
  }
}
