
import {
  getKpiData,
  KpiDataRequest,
  KpiDataResponse,
  KpiDataField,
  KpiDataValue,
} from '@/api/assets';
import ScatterChart from '@/components/charts/ScatterChart.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import MultiAssetSelect from '@/components/multiAssetSelect/MultiAssetSelect.vue';
import KpiPerformanceTable from '@/components/table/KpiPerformanceTable.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { AssetType, KPI_FIELDS, KPI_UNIT } from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import {
  generateFleetPerformanceChartData,
  getFleetPerformanceAverageValue,
} from '../../utils/handleWidgetOperations';
import moment from 'moment';

interface seriesArray {
  symbolSize: number;
  data: number[][];
  type: string;
}

interface MultiAssetSelectList {
  id: string;
  name: string;
  selected: boolean;
}

interface ChartOptions {
  xAxis?: {};
  yAxis?: {};
  series: seriesArray[];
}

interface TableData {
  assetId: string;
  avgTripTime: string;
  id: string;
  loadPerHour: string;
  loadPrTrip: string;
  trips: number;
}

@Component({
  name: 'FleetPerformanceExpanded',
  components: {
    TimeSelect,
    WidgetCard,
    ScatterChart,
    KpiPerformanceTable,
    MultiAssetSelect,
  },
})
export default class extends Vue {
  /** Local variables */
  loading: boolean = true;
  tableData: TableData[] = [];
  assetList: string[] = [];
  chartData: (string | null | undefined)[][] = [];
  route: string = '';
  firstLoad: boolean = true;
  averageValue: number = 0;
  period: number = 7;
  numberOfUnitsInOneDay: number = 24; //hours
  multiAssetSelectList: MultiAssetSelectList[] = [];
  cachedTableData: any[] = [];
  cachedChartData: any[] = [];
  selected: string = '';
  context!: Ref<ActiveContext>;
  requestPayload: KpiDataRequest = {
    metadata: {
      filter: {
        assetTypeCode: AssetType.TippingVehicle,
        // organizationIds filled in later
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_ASSET',
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET_TYPE',
        fields: [
          {
            code: KPI_FIELDS.TippingPayload,
            unit: KPI_UNIT.MetricTonne,
          },
          {
            code: KPI_FIELDS.TripCount,
            unit: KPI_UNIT.UnitCount,
          },
        ],
      },
    ],
  };
  requestPayloadForTripTime: KpiDataRequest = {
    metadata: {
      filter: {
        assetIds: [] as string[],
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_ASSET',
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET',
        fields: [
          {
            code: KPI_FIELDS.TripTime,
          },
        ],
      },
    ],
  };

  created() {
    this.context = useActiveContext();
    this.getRoute();
  }

  /**
   * Get remote data from API for kpi and trip time
   */
  async getData(): Promise<void> {
    this.loading = true;
    try {
      this.requestPayload.metadata.filter.organizationIds = unref(
        this.context
      ).organizationIds;

      /** kpi data response */
      const response = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );

      /**get assets for trip time request */
      this.requestPayloadForTripTime.metadata.filter.assetIds = this.getIds(
        response.data.details[0].fields
      );

      /** kpi response for trip time */
      const tripTimeResponse = await getKpiData(
        this.requestPayloadForTripTime,
        unref(this.context)
      );

      if (
        response.hasOwnProperty('data') &&
        response?.data?.details?.length > 0
      ) {
        this.handleChartData(response.data.details[0].fields);
      }
      this.tableData = this.generateTabletData(
        response.data.details[0].fields,
        tripTimeResponse.data.details[0].fields
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Handle fleet performance chart data
   */
  handleChartData(responseToBeProcessed: KpiDataField[]): void {
    const kpiDataValues = responseToBeProcessed.filter(
      (o: any) => o.code === KPI_FIELDS.TippingPayload
    );
    this.chartData = generateFleetPerformanceChartData({
      responseToBeProcessed: kpiDataValues,
      timePeriod: this.period,
      numberOfUnitsInOneDay: this.numberOfUnitsInOneDay,
      showAll: true,
    });
    this.cachedChartData = this.chartData;
    this.averageValue = getFleetPerformanceAverageValue(this.chartData);
  }

  /**
   * Generate table data
   * @param response
   * @param tripTimeResponse
   */
  generateTabletData(response: any, tripTimeResponse: any): TableData[] {
    let tippingPayload = response.filter(
      (o: any) => o.code === KPI_FIELDS.TippingPayload
    )[0].values;
    let tripCount = response.filter(
      (o: any) => o.code === KPI_FIELDS.TripCount
    )[0].values;
    let tripTime = tripTimeResponse.filter(
      (o: any) => o.code === KPI_FIELDS.TripTime
    )[0].values;
    let tableData: TableData[] = [];
    this.multiAssetSelectList = [];

    for (let asset in tippingPayload) {
      /** generate multi-asset-select data */
      let newAsset = {
        id: tippingPayload[asset].k,
        name: tippingPayload[asset].id,
        selected: true,
      };

      /** generate table-data */
      let newTableEntry = {
        id: tippingPayload[asset].k,
        assetId: tippingPayload[asset].id,
        loadPerHour:
          (
            Number(tippingPayload[asset].v) /
            (this.period * this.numberOfUnitsInOneDay)
          ).toFixed(2) || this.$t('ASSSTAT_INACTIVE_NULL').toString(),
        loadPrTrip:
          (
            Number(tippingPayload[asset].v) / Number(tripCount[asset].v)
          ).toFixed(2) || this.$t('ASSSTAT_INACTIVE_NULL').toString(),
        avgTripTime:
          (
            Number(tripTime[asset].v) /
            (parseInt(tripCount[asset].v, 10) * 60)
          ).toFixed(0) || this.$t('ASSSTAT_INACTIVE_NULL').toString(),
        trips: parseInt(tripCount[asset].v, 10),
      };
      this.multiAssetSelectList.push(newAsset);
      tableData.push(newTableEntry);
    }

    this.cachedTableData = tableData;
    return tableData;
  }

  /**
   * Check if it's selected
   */
  isSelected({ assetId }: any): boolean {
    return this.selected === assetId;
  }

  /**
   * Handle time filter
   */
  handleTimeFilter(dateRange: DateRange): void {
    this.period = Math.abs(
      moment(dateRange.endExclusive).diff(dateRange.start, 'days')
    );
    this.requestPayload.metadata.selection!.startDate = dateRange.start;
    this.requestPayload.metadata.selection!.endDate = dateRange.endExclusive;
    this.requestPayloadForTripTime.metadata.selection!.startDate =
      dateRange.start;
    this.requestPayloadForTripTime.metadata.selection!.endDate =
      dateRange.endExclusive;
    this.getData();
  }

  /**
   * Get specific route
   */
  getRoute(): void {
    if (this.$route.name === 'TippingVehiclesInfo') {
      this.route = `/assets/tipping-vehicles/expandkpi/${this.$route.params.id}/kpi-performance`;
      return;
    }
    this.route = '/assets/tipping-vehicles/expandkpi/kpi-performance';
  }

  /**
   * Filter table and chart data
   */
  filterData(assets: MultiAssetSelectList[]): void {
    this.loading = true;

    /** filter table data */
    this.tableData = this.cachedTableData;
    this.tableData = this.tableData.filter((tableAsset: any) =>
      assets.find(
        (asset: MultiAssetSelectList) =>
          tableAsset.id === asset.id && asset.selected
      )
    );

    /** filter chart data */
    this.chartData = this.cachedChartData;
    this.chartData = this.chartData.filter((chartAsset: any) =>
      assets.find(
        (asset: MultiAssetSelectList) =>
          chartAsset[0] === asset.name && asset.selected
      )
    );

    this.loading = false;
  }

  /**
   * Get asset ids
   * @param response
   */
  getIds(response: KpiDataField[]): any {
    return response
      ?.find((kpiField: KpiDataField) => kpiField.code === KPI_FIELDS.TripCount)
      ?.values.map((kpiValue: KpiDataValue) => kpiValue.k);
  }
}
