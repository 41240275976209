
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  promptSuccessBox,
  promptFailedBox,
  customFailedMessage,
} from '@/utils/prompt';
import {
  postConnectAssetOnBoarding,
  getAssetOnBoardingProgressById,
} from '@/api/assetsMgmt';
import { ASSET_ON_BOARDING_PROGRESS } from '@/utils/workData/lookuptable';

@Component({
  name: 'ConnectionProgress',
  components: {},
})
export default class extends Vue {
  @Prop({ required: true }) assetOnBoardingId!: string;
  @Prop({ required: true }) assetOnBoardingObj!: any;

  /** Local variables */
  isConnecting: boolean = false;
  isConnected: boolean = false;
  isConnectionFail: boolean = false;
  noProgressData: boolean = false;
  progressStatuses: any = [];
  assetOnBoardingProgress: any = ASSET_ON_BOARDING_PROGRESS;
  triggerSource: any = this.$route.name;
  assetOnBoardingCroneJob: any = '';
  ON_BOARDING_PROGRESS_INTERVAL: number = 2000;
  progressRequestCounting: number = 0;
  progressRequestFailCountingThreshold: number = 1;
  connectionMessage: string = this.$t(
    'assetMgmt.assetOnBoardingConnecting'
  ) as string;

  created() {
    if (
      this.triggerSource === 'assetMgmtConnectView' ||
      this.triggerSource === 'assetMgmtRegisterConnectingView'
    ) {
      // When connecting to an unassigned gateway -> GET /assetOnBoarding/{onBoardingId} endpoint
      // When view asset detailed page -> GET /assets/{assetId} -> different endpoint & object structure
      let assetOnBoardingId = this.assetOnBoardingObj.assetOnBoardingId
        ? this.assetOnBoardingObj.assetOnBoardingId
        : this.assetOnBoardingId;
      this.fetchAssetOnBoardingProgress(assetOnBoardingId);
      this.connectionMessage = this.$t(
        'assetMgmt.fetchingAssetOnBoardingStatuses'
      ) as string;
    }
  }

  beforeDestroy() {
    clearInterval(this.assetOnBoardingCroneJob);
  }

  /**
   * Connecting
   */
  async handleConnect() {
    if (!this.assetOnBoardingObj.gatewayId) {
      customFailedMessage(this.$t('assetMgmt.gatewayIdMandatory') as string);
      this.isConnecting = false;
      return;
    }

    let payloadData = {
      unassignedGatewayId: this.assetOnBoardingObj.gatewayId,
      companyAssetId: this.assetOnBoardingObj.companyAssetId
        ? this.assetOnBoardingObj.companyAssetId
        : this.assetOnBoardingObj.assetDataCollectionId,
    };

    await postConnectAssetOnBoarding(this.assetOnBoardingId, payloadData).then(
      (res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.fetchAssetOnBoardingProgress(this.assetOnBoardingId);
        }

        if (
          res.code === 400 &&
          res.data.errors[0].code === 'ApiErrorCannotBeAssinged'
        ) {
          customFailedMessage(
            this.$t('assetMgmt.gatewayIdIsAlreadyAssigned') as string
          );
          this.isConnected = false;
          return;
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          this.isConnected = false;
        }
      }
    );
  }

  /** Start API calling by interval -> stop when receiving CONNECTED status */
  fetchAssetOnBoardingProgress(assetOnBoardingId: string) {
    this.isConnecting = true;
    this.assetOnBoardingCroneJob = setInterval(() => {
      this.retrieveAssetOnBoardingProgress(assetOnBoardingId);
    }, this.ON_BOARDING_PROGRESS_INTERVAL);
  }

  /** Retrieve asset on boarding progress after connecting to the unassigned gateway */
  async retrieveAssetOnBoardingProgress(assetOnBoardingId: string) {
    await getAssetOnBoardingProgressById(assetOnBoardingId).then((res) => {
      if (!res) return;

      if (res.code === 200) {
        this.progressStatuses = res.data;

        res.data.forEach((progressStatus: any) => {
          if (
            progressStatus.assetConnectingSubStatus ===
            this.assetOnBoardingProgress.AssConOnboardingSuccessful
          ) {
            clearInterval(this.assetOnBoardingCroneJob);
            this.isConnected = true;
          }

          if (
            progressStatus.assetConnectingSubStatus ===
            this.assetOnBoardingProgress.AssConOnBoardingFailed
          ) {
            clearInterval(this.assetOnBoardingCroneJob);
            this.isConnected = false;
            this.isConnectionFail = true;
          }
        });
      }

      // if (res.code === 400 && this.progressRequestCounting <= this.progressRequestFailCountingThreshold) {
      //     this.progressRequestCounting++
      //     return
      // }

      // if (res.code === 400) {
      //     customFailedMessage(this.$t('assetMgmt.retrieveAssetOnBoardingProgressFailed') as string);
      //     clearInterval(this.assetOnBoardingCroneJob)
      //     this.noProgressData = true
      // }
    });
  }

  /** Go back to connected asset */
  handleDoneSelfCheck() {
    this.$router.push('/asset-mgmt/index?activeName=connectedAssets');
  }
}
