
import { Component, Vue, Prop } from 'vue-property-decorator';
import FeaturesTable from '@/components/table/FeaturesTable.vue';
import { FEATURES_TABLE_COLS } from '@/utils/workData/subscriptionPackages';

@Component({
  name: 'Features',
  components: {
    'features-table': FeaturesTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) featuresData!: any;
  @Prop({ required: true }) isEditable!: any;
  @Prop({ required: true }) featuresWithInputType!: any;
  @Prop({ required: true }) featuresWithDateType!: any;
  @Prop({ required: true }) featuresWithBooleanType!: any;

  /** Local variables */
  cols = FEATURES_TABLE_COLS;

  async handlePage(page: number, pageSize: number) {
    // const res = await getUsers(page, pageSize, UserModule.companyId);
    // this.tableList = res.data.users;
  }
}
