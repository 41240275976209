
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getTemplatesById,
  getModules,
  createTemplate,
  updateTemplate,
  DashboardTemplate,
} from '@/api/dashboardConf';
import {
  COMPANY_TYPE,
  COMPANY_TYPE_LIST,
  INDUSTRY_LIST,
} from '@/utils/workData/lookuptable';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { promptFailedBox, promptSuccessBox } from '@/utils/prompt';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import EditDashTemplate from '@/components/template/EditDashTemplate.vue';
import { PageModule } from '@/store/modules/page';

interface WIDGET_TYPE {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
  code: string;
  pageCode: string;
  name: string;
}

@Component({
  name: 'AddNewTemplate',
  components: {
    BaseCard,
    EditDashTemplate,
  },
})
export default class extends Vue {
  @Prop() id!: string;

  errorCode = ERROR_CODE_LIST;
  custTypes = COMPANY_TYPE_LIST;
  industries = INDUSTRY_LIST;
  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  action: any;
  editDashTemplateChild: any = {};
  title: string = '';
  templateForm: DashboardTemplate = {
    id: '',
    name: '',
    customerType: COMPANY_TYPE.Customer,
    industry: 'INDTYP_CONSTRUCTION',
    widgets: [],
    unSelectedModules: [],
    isDefault: false,
  };
  templateNameRegex = /^\S+/;

  validateTemplateNameLength = (rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(new Error(''));
      this.$message({
        type: 'error',
        message: `${this.$t('dashboardConf.tipTemplateNameForm')}`,
        duration: 2000,
      });
    } else {
      callback();
    }
  };

  get rules() {
    const tmpRules = {
      name: [
        { required: true, message: '', pattern: /^\S+/, trigger: 'change' },
        { validator: this.validateTemplateNameLength, trigger: 'blur' },
      ],
    };

    return tmpRules;
  }

  handleResetToDefault() {
    this.editDashTemplateChild.handleResetToDefault(
      this.templateForm.customerType,
      this.templateForm.industry
    );
  }

  showErrorInfo(errorCode: any, info: any) {
    this.errorInfos = errorCode;

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      this.$message({
        type: 'error',
        message: `${this.$t(info.msg, { action: info.action })}`,
      });
    }
  }

  handleCreate() {
    this.editDashTemplateChild.updateWidget();
    this.templateForm.unSelectedModules =
      this.editDashTemplateChild.filterModules();

    if (
      !this.editDashTemplateChild.isCheckedNodeListNull() &&
      !this.editDashTemplateChild.isPageNull()
    ) {
      (this.$refs.templateForm as any).validate(async (valid: any) => {
        if (valid) {
          this.templateForm.widgets =
            this.editDashTemplateChild.getAllWidgets();

          if (this.id && !this.action) {
            await updateTemplate(this.id, this.templateForm).then((res) => {
              if (res.code === 200) {
                promptSuccessBox(this.$t('common.save') as string);
                this.$router.push('/dashboard-conf/index');
              } else if (res.code === 400) {
                promptFailedBox(this.$t('common.save') as string);
              }
            });
          } else {
            await createTemplate(this.templateForm).then((res) => {
              if (res.code === 200) {
                promptSuccessBox(this.$t('common.create') as string);
                this.$router.push('/dashboard-conf/index');
              } else if (res.code === 400) {
                this.showErrorInfo(res.data.errors, {
                  msg: 'dashboardConf.duplicateName',
                  action: this.$t('common.create'),
                });
              }
            });
          }
        } else {
          this.$message({
            type: 'error',
            message: `${this.$t('dashboardConf.tipInputTemplateName')}`,
            duration: 2000,
          });
        }
      });
    }
  }

  async fetchTempalteById() {
    const res = await getTemplatesById(this.id);

    PageModule.setTitle(res.data.name);
    this.templateForm = res.data;

    if (this.action) {
      this.templateForm.name += ' - ' + this.action;
      this.templateForm.id = '';
    }

    this.editDashTemplateChild.updateWidgetsBuffer(this.templateForm.widgets);

    this.editDashTemplateChild.handleNodeClick({
      name: 'AUTHRSC_MOD_HOME',
      children: [],
    });

    this.templateForm.widgets = [];
  }

  async fetchCheckedModules() {
    const resCheckedModules = await getModules(this.id);
    this.editDashTemplateChild.setCheckedModules(resCheckedModules);
  }

  mounted() {
    this.editDashTemplateChild = this.$refs.editDashTemplate as any;
    this.id && this.fetchCheckedModules();
    this.id && this.fetchTempalteById();
  }

  created() {
    this.action = this.$route.query.action;
  }
}
