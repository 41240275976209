
import { Component, Vue } from 'vue-property-decorator';
import {
  getCustomizedAlarmsDataByCustomUrl,
  CustomizedKpiLimit,
} from '@/api/alarmConfig';
import {
  ALARM_SETTINGS_TABLE_COLUMNS_FOR_CUSTOMER,
  ALARM_CONFIG_SELECTION_OPTIONS,
} from '@/utils/workData/alarmConfig';
import { UserModule } from '@/store/modules/user';
import { ASSET_TYPE_LIST } from '@/utils/workData/lookuptable';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import AlarmConfigTable from '../components/AlarmConfigTable.vue';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { LoggedInUserRef, useLoggedInUser } from '@/composables/context';
import { unref } from 'vue';

@Component({
  name: 'AlarmConfigurationForCustomer',
  components: {
    'select-table-header': SelectTableHeader,
    AlarmConfigTable,
  },
})
export default class extends Vue {
  /** Local variables */
  alarmTableColumns: any = ALARM_SETTINGS_TABLE_COLUMNS_FOR_CUSTOMER;
  assetTypeList = ASSET_TYPE_LIST;
  searchFieldOptions = ALARM_CONFIG_SELECTION_OPTIONS;
  totalAlarms: number = 0;
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  alarmTableIsLoading: boolean = false;
  tableDataList: CustomizedKpiLimit[] = [];
  isColumnSelectionVisible: boolean = false;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';
  isAlarmTableDisplayed: boolean = true;
  loggedInUser!: LoggedInUserRef;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
  }

  /** Fetch when search is triggered */
  fetchAlarmDataBySearchParams() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchAlarmsDataBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchAlarmsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let finalUrl = '';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue: any = searchParams ? searchParams.value : null;

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    /** Scenarios covered: when value is boolean or when value is a string */
    if (searchFieldName === 'isLocked' && searchFieldValue) {
      switch (searchFieldValue) {
        case 'IS_LOCK_ACTIVATED':
          finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${true}`;
          break;
        case 'IS_LOCK_DEACTIVATED':
          finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${false}`;
          break;
      }
    } else if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}`;
    } else {
      finalUrl += `&sortBy=${this.defaultSort}&order=${this.defaultOrder}`; // default must be sorted by createdOn DESC !
    }

    return finalUrl;
  }

  /** Remote fetch alarms bata by custom URL */
  async fetchRemoteAlarmsDataByCustomUrl(customUrl: any) {
    try {
      this.alarmTableIsLoading = true;
      const user = unref(this.loggedInUser);
      if (!user) {
        // Should never happen
        throw new Error('No user logged in');
      }
      const response = await getCustomizedAlarmsDataByCustomUrl(
        user.organization.id,
        customUrl
      );
      this.totalAlarms = response.data.total;
      this.tableDataList = response.data.limits;
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.serverError').toString());
    } finally {
      this.alarmTableIsLoading = false;
    }
  }

  /** Switch between view mode and edit mode */
  goToEditPage() {
    this.$router.push('/administration/alarm-settings/edit');
  }
}
