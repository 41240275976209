
import { Component, Vue } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import Deployments from './Deployments.vue';
import History from './History.vue';
import PendingUpdates from './PendingUpdates.vue';
import { UserModule } from '@/store/modules/user';
import { PageModule } from '@/store/modules/page';

@Component({
  name: 'DeployManagement',
  components: {
    NavPage,
    deployments: Deployments,
    history: History,
    'pending-updates': PendingUpdates,
  },
})
export default class extends Vue {
  /** Local variables */
  userRole = UserModule.role;
  activeName: string = 'deployments';
  previousTabIndex: number = 0;

  menuAccess: any = {
    AUTHRSC_PAGE_PENDING_UPDATES: false,
    AUTHRSC_PAGE_DEPLOYMENTS: false,
    AUTHRSC_PAGE_DEPLOYMENT_HISTORY: false,
  };

  /**
   * Handle tab click
   */
  handleTabClick(): void {
    if (
      this.$route.query.activeName != this.activeName &&
      this.activeName === 'deployments'
    )
      this.$router.push('/deploy/index?activeName=deployments');
    if (
      this.$route.query.activeName != this.activeName &&
      this.activeName === 'history'
    )
      this.$router.push('/deploy/index?activeName=history');
    if (
      this.$route.query.activeName != this.activeName &&
      this.activeName === 'pending-updates'
    )
      this.$router.push('/deploy/index?activeName=pending-updates');
  }

  /**
   * Show default tab
   */
  showDefaultTab(): void {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
      return;
    }

    this.activeName = this.menuAccess.AUTHRSC_PAGE_DEPLOYMENTS
      ? 'deployments'
      : this.menuAccess.AUTHRSC_PAGE_PENDING_UPDATES
      ? 'pending-updates'
      : 'history';
  }

  async mounted(): Promise<void> {
    var keys = Object.keys(this.menuAccess);
    for (let i = 0; i < keys.length; i++) {
      this.menuAccess[keys[i]] = await UserModule.hasMenu(keys[i]);
    }
    this.showDefaultTab();
  }

  /**
   * Check if the logged user has auth resource code for tab view
   * @param menu
   */
  hasMenu(menu: string): boolean {
    return this.menuAccess[menu];
  }
}
