
import CreateNewReportSubscribtionModal from '@/views/report/components/CreateNewReportSubscribtionModal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  getAssetsOrganizationsHierarchy,
  HierarchyResponse,
} from '@/api/assets';
import { getReportTemplate, TemplateResponse } from '@/api/report';
import { createReportSubscription } from '@/api/reportSubscription';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import {
  ASSET_SCOPE_ORGANIZATION,
  ASSET_SCOPE_SINGLE_ASSET,
} from '@/utils/workData/reportMgmt';

import ViewReportHeader from '../components/ViewReportHeader.vue';
import { setReportAdditionalData } from '../report';
import ReportView from './next/ReportView.vue';

type ReportFilters = {
  reportScope: string;
  reportScopeValue: string;
  startDate: string;
  endDate: string;
};

@Component({
  name: 'ViewReportTemplate',
  components: {
    BaseCard,
    ViewReportHeader,
    ReportView,
    CreateNewReportSubscribtionModal,
  },
})
export default class extends Vue {
  @Prop() reportTemplateId!: string;

  template: TemplateResponse | null = null;
  organizations: HierarchyResponse[] = [];
  scope: string | null = null;
  filters: ReportFilters | null = null;
  subscribeVisible = false;
  generateReportTemplatePageIsLoading: boolean = false;
  isGeneratedReportVisible: boolean = false;

  get scopeOptions() {
    switch (this.scope) {
      case ASSET_SCOPE_SINGLE_ASSET:
        return this.organizations
          .flatMap(({ organization }) => organization.assets)
          .map((asset) => ({
            id: asset.id,
            name: asset.companyAssetId,
          }));
      case ASSET_SCOPE_ORGANIZATION:
        return this.organizations.map(({ organization }) => ({
          id: organization.id,
          name: organization.name,
        }));
    }
    return [];
  }

  created() {
    this.prepareDefaultInitialization();
  }

  /**
   * Prepare default initialization
   */
  async prepareDefaultInitialization(): Promise<void> {
    try {
      this.generateReportTemplatePageIsLoading = true;
      this.getData().then(([template, organizations]) => {
        this.template = template;
        this.organizations = organizations.filter(
          (entity: HierarchyResponse) =>
            entity.organization.activationStatus == ACTIVATION_STATUS.Activated
        );
        this.generateReportTemplatePageIsLoading = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Get report template data and organizations hierarchy
   */
  async getData(): Promise<readonly [TemplateResponse, HierarchyResponse[]]> {
    const { data: template } = await getReportTemplate(this.reportTemplateId);
    const { data: hierarchy } = await getAssetsOrganizationsHierarchy(
      template.assetType
    );

    return [
      template,
      this.flatHierarchy(hierarchy, (h) => h.organization.suborganizations),
    ] as const;
  }

  /**
   * Set filters for generated report
   * @param filters
   */
  setFilters(filters: ReportFilters): void {
    this.isGeneratedReportVisible = false;
    this.filters = filters;
    if (
      !Boolean(filters.reportScope) ||
      !Boolean(filters.reportScopeValue) ||
      !Boolean(filters.startDate) ||
      !Boolean(filters.endDate)
    ) {
      return;
    }

    this.$nextTick(() => {
      this.isGeneratedReportVisible = true;
    });
  }

  /**
   * Flat hierarchy
   * @param hierarchy
   * @param get
   */
  flatHierarchy<T, K>(hierarchy: T, get: (value: T) => T[]): T[] {
    return [
      hierarchy,
      ...get(hierarchy).flatMap((h) => this.flatHierarchy(h, get)),
    ];
  }

  /**
   * Create subscription report
   * @param data
   */
  async createSubscriptionReport(data: any): Promise<void> {
    try {
      setReportAdditionalData(data);
      const { code } = await createReportSubscription(data);

      if (code !== 200) {
        return customFailedMessage(this.$t('common.serverError') as string);
      }

      customSuccessMessage(
        this.$t('report.reportHasBeenSubscribedSuccessfully') as string
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.subscribeVisible = false;
    }
  }
}
