
import { Component, Vue } from 'vue-property-decorator';
import { REPORT_COL, REPORT_SEARCH_FIELDS } from '@/utils/workData/reportMgmt';
import { UserModule } from '@/store/modules/user';
import { getCompanySubscriptionsByCompanyId } from '@/api/subscriptionPackages';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import ReportTemplateTable from '../components/ReportTemplateTable.vue';
import {
  fetchReportTemplatesByCustomParams,
  ReportTemplate,
} from '@/api/report';
import { generateRequestPayload } from '@/utils/misc';
import {
  getActionCode,
  handleActionsForReportTemplateItem,
  handleDisabledItem,
} from './reportTemplate';
import { getAssetTypesFromCompanySubscription } from '../report';
import { getAssetTypesList, ReportAssetType } from '@/utils/assetTypes';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';

@Component({
  name: 'reportTemplate',
  components: {
    'pure-table': PureTable,
    'select-table-header': SelectTableHeader,
    'report-template-table': ReportTemplateTable,
  },
})
export default class extends Vue {
  /** Local variables */
  reportCols = REPORT_COL;
  reportSearchFields = REPORT_SEARCH_FIELDS;
  isReportTemplateLoading: boolean = false;
  hasAuthToCreateReportTemplate: boolean = false;
  createReportTemplateBtnIsVisible: boolean = false;
  reportTemplateList: any = [];
  reportTemplateTotal: number = 0;
  isColumnSelectionVisible: boolean = true;
  generalQueryData: any = {
    pageNumber: 1,
    pageSize: UserModule.gridPageSize,
    searchParams: [
      {
        reference: null,
        operator: null,
        value: null,
      },
    ],
    sortByAndOrder: [
      {
        sortBy: null,
        order: null,
      },
    ],
  };
  reportFilterAndSorterFields = {
    name: 'NAME',
    assetType: 'ASSET_TYPE',
    creatorUserName: 'CREATOR_USER_NAME',
  };
  companySystemFeatureCodeToCreateNewReportTemplate: string =
    'SYSFEAT_NEW_REPORT_TMPL';
  authorizationResourceCodeToCreateNewReportTemplate: string =
    'AUTHRSC_PAGE_REPORT_TEMPLATES_CREATE';
  assetTypeAvailable: string[] | undefined = [];
  tableHeaderIsVisible: boolean = false;

  async created() {
    this.hasAuthToCreateReportTemplate = await UserModule.claims.hasClaim(
      this.authorizationResourceCodeToCreateNewReportTemplate
    );
    this.prepareDefaultInitialization();
  }

  /**
   * Prepare default initialization
   */
  async prepareDefaultInitialization(): Promise<void> {
    /** Prepare table header > search options for asset types filtration
     * to be only what is found on report default template and
     * logged in user company subscription available asset types
     */
    this.isReportTemplateLoading = true;
    const availableSubscriptionAssetTypes =
      await getAssetTypesFromCompanySubscription();

    /**
     * Mandatory filter that should be exist always related to company subscription available asset types
     * When there are no asset type, send random string for the API to not retrieve anything
     */
    this.generalQueryData.searchParams.push({
      reference: 'ASSET_TYPE',
      operator: FilterOperator.IN,
      value:
        availableSubscriptionAssetTypes &&
        availableSubscriptionAssetTypes.length > 0
          ? availableSubscriptionAssetTypes
          : ['SUBSCRIPTION_WITH_NO_AVAILABLE_ASSET_TYPES'],
    });

    const reportTemplatesAvailableAssetTypes = await getAssetTypesList();
    this.assetTypeAvailable = reportTemplatesAvailableAssetTypes
      .filter((reportAssetItem: ReportAssetType) =>
        availableSubscriptionAssetTypes?.some(
          (item: string) => item == reportAssetItem.assetType
        )
      )
      .map((reportAssetItem: ReportAssetType) => reportAssetItem.assetType);
    this.tableHeaderIsVisible = true;

    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.mapFiltersAndSorters(finalUrlParamsForSearch);

    this.fetchReportTemplateRemotly(finalUrlParamsForSearch);
    this.fetchSystemFeature();
  }

  /**
   * Go to view detail page for row clock even
   */
  handleView(rowInfo: any): void {
    this.$router.push({
      path: `view/${rowInfo.id}`,
    });
  }

  /**
   * Go to the edit page for row click event
   * @param rowInfo
   */
  handleEdit(rowInfo: any): void {
    this.$router.push({
      path: `edit/${rowInfo.id}`,
    });
  }

  /**
   * Fetch by page selection
   * @param page
   * @param pageSize
   */
  fetchReportTemplateDataByPageSelection(page: number, pageSize: number): void {
    this.generalQueryData.pageNumber = page;
    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.mapFiltersAndSorters(finalUrlParamsForSearch);

    this.fetchReportTemplateRemotly(finalUrlParamsForSearch);
  }

  /**
   * Fetch by table sorting event
   * @param sortBy
   * @param order
   */
  fetchReportTemplateDataBySortEvent(sortBy: any, order: any): void {
    this.generalQueryData.sortByAndOrder[0].sortBy = !!order ? sortBy : null;
    this.generalQueryData.sortByAndOrder[0].order = !!order ? order : null;
    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.mapFiltersAndSorters(finalUrlParamsForSearch);

    this.fetchReportTemplateRemotly(finalUrlParamsForSearch);
  }

  /**
   * etch by search params
   */
  fetchReportTemplateDataBySearchParams(): void {
    let finalUrlParamsForSearch: any = generateRequestPayload(
      this.generalQueryData
    );
    this.mapFiltersAndSorters(finalUrlParamsForSearch);

    this.fetchReportTemplateRemotly(finalUrlParamsForSearch);
  }

  /**
   * Map filters and sorters
   * @param finalUrlParamsForSearch
   */
  mapFiltersAndSorters(finalUrlParamsForSearch: any): void {
    finalUrlParamsForSearch.sorters.forEach((sortItem: any) => {
      sortItem.field = this.mapFiltersAndSortersNames(sortItem.field);
    });

    finalUrlParamsForSearch.filters.forEach((filterItem: any) => {
      filterItem.name = this.mapFiltersAndSortersNames(filterItem.name);
    });
  }

  /**
   * Map filters and sorters names
   * @param name
   */
  mapFiltersAndSortersNames(name: string): string {
    switch (name) {
      case REPORT_COL[0].prop:
        return this.reportFilterAndSorterFields.name;
      case REPORT_COL[1].prop:
        return this.reportFilterAndSorterFields.assetType;
      case REPORT_COL[2].prop:
        return this.reportFilterAndSorterFields.creatorUserName;
    }

    return name;
  }

  /**
   * Fetch company subscriptions
   */
  async fetchSystemFeature(): Promise<void> {
    try {
      const response = await getCompanySubscriptionsByCompanyId(
        UserModule.companyId
      );
      if (response.code === 200) {
        const resData = response.data;
        const subscriptionPackagesLength: number = resData.length;
        for (let index = 0; index < subscriptionPackagesLength; ++index) {
          const systemFeatures = resData[index].systemFeatures;
          const systemFeaturesLength: number = systemFeatures.length;
          for (
            let funcIndex = 0;
            funcIndex < systemFeaturesLength;
            ++funcIndex
          ) {
            if (
              systemFeatures[funcIndex].code ===
              this.companySystemFeatureCodeToCreateNewReportTemplate
            ) {
              this.createReportTemplateBtnIsVisible = Boolean(
                this.hasAuthToCreateReportTemplate &&
                  JSON.parse(systemFeatures[funcIndex].value)
              );
              return;
            }
            this.createReportTemplateBtnIsVisible =
              this.hasAuthToCreateReportTemplate;
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Fetch remotly form API report template data
   * @param requestPayload
   */
  async fetchReportTemplateRemotly(requestPayload: any): Promise<void> {
    try {
      this.isReportTemplateLoading = true;
      const res = await fetchReportTemplatesByCustomParams(requestPayload);
      if (res.code === 200 && res.data.reportTemplates.length > 0) {
        let resData = res.data.reportTemplates;
        resData.map((item: ReportTemplate) => {
          item.action = handleActionsForReportTemplateItem(item?.userId);
          item.isDisabled = item.action.includes(getActionCode('edit'))
            ? handleDisabledItem(item?.userId, item.numberOfActiveSubscriptions)
            : false;
        });

        this.reportTemplateList = resData;
        this.reportTemplateTotal = res.data.total;
      } else {
        this.reportTemplateList = [];
        this.reportTemplateTotal = 0;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isReportTemplateLoading = false;
    }
  }
}
