
import { Component, Vue } from 'vue-property-decorator';
import { ElTree } from 'element-ui/types/tree';
import {
  OperationalStatus,
  KPI_FIELDS,
  AssetType,
} from '@/utils/workData/lookuptable';
import { getKpisForMultipleAssets, KpiParametersValues } from '@/api/assetsAll';
import { getAssetsOrganizationsHierarchy } from '@/api/assets';
import { ASSET_TYPE_LIST } from '@/utils/workData/lookuptable';
import moment from 'moment';
import { findAssets } from '@/utils/assets';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { formatTimer } from '@/utils/misc';

@Component({
  name: 'AssetsWidgetTable',
})
export default class extends Vue {
  orderCriteria: string = '';
  tree: any = [];
  oldConvertedList: any = [];
  convertedList: any = [];
  assetList: any = [];
  unwatch: any = null;
  filterText: string = '';
  orderType: number = 0;
  ordered: boolean = false;
  orderingList: any[] = [];
  previousOrderBy: string = '';
  chartData: any = [];
  kpiResponse: KpiParametersValues[] = [];
  data: any = [];
  initialChartData: boolean = false;
  assetType: any = '';
  routeName: string = '';

  statusFilters: any = null;

  context!: Ref<ActiveContext>;

  chartProperties = [
    {
      id: OperationalStatus.MotorOn,
      name: this.$t('kpiStatus.MOTOR_ON'),
    },
    {
      id: OperationalStatus.Stopped,
      name: this.$t('kpiStatus.STOP'),
    },
    {
      id: OperationalStatus.Offline,
      name: this.$t('kpiStatus.OFFLINE'),
    },
    {
      id: OperationalStatus.InTransition,
      name: this.$t('kpiStatus.IN_TRANSITION'),
    },
    {
      id: OperationalStatus.Active,
      name: this.$t('kpiStatus.ACTIVE'),
    },
    {
      id: OperationalStatus.Inactive,
      name: this.$t('kpiStatus.INACTIVE'),
    },
    {
      id: OperationalStatus.Parked,
      name: this.$t('kpiStatus.PARKED'),
    },
    {
      id: OperationalStatus.Maintenance,
      name: this.$t('kpiStatus.MAINTENANCE'),
    },
  ];
  iconDictionay = {
    ASSSTAT_OFFLINE: require('@/icons/svg/assetOffline.svg'),
    ASSSTAT_STOP: require('@/icons/svg/assetStop.svg'),
    ASSSTAT_MOTOR_ON: require('@/icons/svg/motorOn.svg'),
    ASSSTAT_IN_TRANSITION: require('@/icons/svg/inTransition.svg'),
    ASSSTAT_ACTIVE: require('@/icons/svg/active.svg'),
    ASSSTAT_INACTIVE: require('@/icons/svg/inactive.svg'),
    ASSSTAT_PARKED: require('@/icons/svg/parked.svg'),
    ASSSTAT_MAINTENANCE: require('@/icons/svg/maintenance.svg'),
  };

  tableCols = [
    {
      id: 'status',
      label: this.$t('operationalStatusWidget.table.status'),
    },
    {
      id: 'label',
      label: this.$t('operationalStatusWidget.table.assetId'),
    },
    {
      id: 'lastSync',
      label: this.$t('operationalStatusWidget.table.lastSyncTime'),
    },
  ];

  loading: boolean = false;

  created() {
    this.context = useActiveContext();
    this.unwatch = this.$watch('filterText', (newValue, oldValue) => {
      this.ordered = false;
      this.orderType = 0;
      if (newValue.length === 0) {
        this.tree = [];
        this.getData(this.statusFilters);
      }
      if (newValue.length >= 3) {
        const tree = this.$refs.tree as ElTree<HTMLElement, any>;
        tree.filter(newValue.toUpperCase());
      }
    });
    this.getData(this.statusFilters);
  }

  async setKpiIds(data: string[]) {
    if (!data || data.length === 0) {
      this.kpiResponse = [];
      return;
    }
    const requestData = {
      metadata: {
        filter: {
          assetIds: data,
        },
        timezone: unref(this.context)?.primaryOrgTimeZone,
      },
      details: [
        {
          fields: [
            { code: KPI_FIELDS.OperationalStatus },
            { code: KPI_FIELDS.LastCommunicationTime },
          ],
        },
      ],
    };
    try {
      const response = await getKpisForMultipleAssets(
        requestData,
        unref(this.context)
      );
      this.kpiResponse = response.data;
    } catch (err) {
      console.log(err);
    }
  }

  getChartNewData() {
    this.chartData = [];
    if (!this.$route.params.id && this.initialChartData === false) {
      this.transformTreeInList(this.convertedList);
      this.initialChartData = true;
    }
    for (let item in this.chartProperties) {
      let obj = {
        name: this.chartProperties[item].name,
        value: 0,
      };
      this.orderingList.filter((asset) => {
        if (
          asset.status.includes(this.chartProperties[item].id.toUpperCase())
        ) {
          obj.value++;
        }
      });
      this.chartData.push(obj);
    }
    this.$emit('getChartNewData', this.chartData);
  }

  filterNode(value: any, data: any) {
    if (!value) return;
    return data.label.toUpperCase().indexOf(value.toUpperCase()) !== -1;
  }

  updateFilters(orderBy: any) {
    this.orderCriteria = orderBy;
    if (orderBy !== this.previousOrderBy && this.previousOrderBy.length > 0) {
      this.orderType = 0;
    }
    this.previousOrderBy = orderBy;

    this.orderType =
      this.orderType < 2 ? this.orderType + 1 : (this.orderType = 0);
    this.orderType > 0 ? (this.ordered = true) : (this.ordered = false);

    //save initial converted list
    if (this.oldConvertedList.length === 0) {
      this.oldConvertedList = this.convertedList;
    }

    this.convertedList = this.oldConvertedList;

    if (this.orderType === 1) {
      this.orderList(orderBy, this.convertedList, 1);
      return;
    }

    if (this.orderType === 2) {
      this.orderList(orderBy, this.convertedList, -1);
    }
  }

  async refreshData(filter: any) {
    this.statusFilters = filter;
    this.data = [];
    this.tree = [];
    this.convertedList = [];
    this.oldConvertedList = [];
    await this.getData(filter);
  }

  async getData(filter: any) {
    this.loading = true;
    try {
      this.routeName = this.$route.name
        ? this.$route.name
            .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            .toUpperCase()
            .split(' ')[0]
        : '';
      this.assetType =
        ASSET_TYPE_LIST.find((asset: any) =>
          asset.id.includes(this.routeName)
        ) ?? AssetType.All;
      const response = await getAssetsOrganizationsHierarchy(
        this.assetType.value,
        unref(this.context).organization?.id,
        unref(this.context)
      );
      if (this.statusFilters !== filter) {
        // Filters changed while loading, ignore this response
        return;
      }
      const data =
        typeof response.data === 'object' ? [response.data] : response.data;
      let ids = this.$route.params.id
        ? [this.$route.params.id]
        : data.flatMap((data: any) => this.unpack(data));
      await this.setKpiIds(ids);
      if (this.$route.params.id) {
        this.findSelectedAssetOrg(data[0]);
      } else {
        for (let i in data) {
          let organisation = this.parseResponse(
            data[i].organization,
            {},
            this.kpiResponse
          );
          this.tree.push(organisation);
          this.convertTreeToList(data[i], this.kpiResponse);
        }
        this.getChartNewData();
      }
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
  }

  unpack(o: any): [] {
    let ids = o.organization.assets
      ? o.organization.assets.map((a: any) => a.id)
      : [];
    if (o.organization.suborganizations)
      ids = [
        ...ids,
        ...o.organization.suborganizations.flatMap((c: any) => this.unpack(c)),
      ];
    return ids;
  }

  findKpiAssetById(id: string, keyToFind: string, kpi: KpiParametersValues[]) {
    try {
      return kpi
        .find((kpiObject: any) => kpiObject.assetId === id)
        ?.values.find((kpi: any) => kpi.code === keyToFind)?.v;
    } catch (err) {
      console.log(err);
    }
  }

  parseResponse(node: any, treeNode: any, kpiResponse: KpiParametersValues[]) {
    const { status: filter } = this.$route.query;

    treeNode.parent = true;
    treeNode.id = node.id;
    treeNode.label = node.name;
    treeNode.children = [];

    for (let i in node.assets) {
      const status =
        this.findKpiAssetById(
          node.assets[i].id,
          KPI_FIELDS.OperationalStatus,
          kpiResponse
        ) ?? '';

      if (
        (this.statusFilters &&
          !this.statusFilters[this.$t(status) as string]) ||
        (!this.statusFilters && filter && status != filter)
      ) {
        continue;
      }

      let asset = {
        type: 'asset',
        status,
        lastSync:
          moment(
            this.findKpiAssetById(
              node.assets[i].id,
              KPI_FIELDS.LastCommunicationTime,
              kpiResponse
            )
          ).format('YYYY-MM-DD HH:mm:ss') ?? '',
        id: node.assets[i].id,
        label: node.assets[i].companyAssetId,
        children: [],
      };
      treeNode.children.push(asset);
    }
    for (let i in node.suborganizations) {
      let org = this.parseResponse(
        {
          type: 'organization',
          ...node.suborganizations[i].organization,
        },
        {},
        kpiResponse
      );
      treeNode.children.push(org);
    }
    return treeNode;
  }

  convertTreeToList(tree: any, kpiResponse: KpiParametersValues[]) {
    let listItem: { label: string; id: string; children: any[] } = {
      label: tree.organization.name,
      id: tree.organization.id,
      children: [],
    };
    for (let i in tree.organization.assets) {
      let asset = {
        status:
          this.findKpiAssetById(
            tree.organization.assets[i].id,
            KPI_FIELDS.OperationalStatus,
            kpiResponse
          ) ?? '',
        lastSync:
          this.findKpiAssetById(
            tree.organization.assets[i].id,
            KPI_FIELDS.LastCommunicationTime,
            kpiResponse
          ) ?? '',
        label: tree.organization.assets[i].companyAssetId,
        id: tree.organization.assets[i].id,
      };
      listItem.children.push(asset);
    }
    if (listItem.children.length >= 1) this.convertedList.push(listItem);
    for (let i in tree.organization.suborganizations) {
      this.convertTreeToList(
        tree.organization.suborganizations[i],
        kpiResponse
      );
    }
    return listItem;
  }

  constructTreeFromList(organizationAssetList: any) {
    let resultList = [];
    let lastOrg = {
      label: '',
      orgId: '',
      children: [{}],
    };
    let lastOrgId = '';

    for (let i in organizationAssetList) {
      if (organizationAssetList[i].orgId !== lastOrgId) {
        lastOrg = {
          label: '',
          orgId: '',
          children: [{}],
        };
        lastOrg.label = organizationAssetList[i].orgName;
        lastOrg.orgId = organizationAssetList[i].orgId;
        lastOrg.children = [];
        lastOrgId = lastOrg.orgId;
        resultList.push(lastOrg);
      }
      lastOrg.children.push({
        status: organizationAssetList[i].status,
        label: organizationAssetList[i].assetName,
        lastSync: organizationAssetList[i].lastSync,
        id: organizationAssetList[i].assetId,
      });
    }
    return resultList;
  }

  transformTreeInList(list: any) {
    this.orderingList = [];
    for (let i in list) {
      for (let j in list[i].children) {
        let listItem = {
          orgId: list[i].id,
          orgName: list[i].label,
          assetName: list[i].children[j].label,
          lastSync: list[i].children[j].lastSync,
          status: list[i].children[j].status,
          assetId: list[i].children[j].id,
        };
        this.orderingList.push(listItem);
      }
    }
  }

  orderList(orderBy: string, list: any, order: number) {
    this.transformTreeInList(list);
    const filteredList = this.orderingList.filter((listItem) =>
      listItem.assetName.includes(this.filterText)
    );
    let filter = orderBy === 'label' ? 'assetName' : orderBy;
    const ordered = filteredList.sort((left: any, right: any) =>
      left[filter] > right[filter] ? order : -order
    );
    this.convertedList = this.constructTreeFromList(ordered);
  }

  navigateToAsset(data: any) {
    if (data.type !== 'asset') {
      return;
    }

    if (this.$route.query.widget === 'AUTHRSC_WIDGET_MAP') {
      this.$emit('getSelectedAsset', data.id);
      return;
    }

    let assetType = '';
    switch (this.$route.name) {
      case 'StaticCompactors':
        assetType = 'static-compactors';
        break;
      case 'AlbaStaticCompactors':
        assetType = 'alba-static-compactors';
        break;
      case 'TippingVehicles':
        assetType = 'tipping-vehicles';
        break;
      case 'TableTopTissectors':
        assetType = 'table-top-tissectors';
        break;
      default:
        assetType = 'mobile-compactors';
    }

    let assetPath = `/assets/${assetType}/${data.id}`;
    this.$router.push(assetPath);
  }

  findSelectedAssetOrg(data: any) {
    this.orderingList = [];
    let assetList = findAssets(data.organization);
    let org = assetList.filter(
      (asset: any) => asset.id === this.$route.params.id
    );
    let asset = {
      id: org[0].id,
      label: org[0].companyAssetId,
      status: this.findKpiAssetById(
        org[0].id,
        KPI_FIELDS.OperationalStatus,
        this.kpiResponse
      ),
      lastSync: moment(
        this.findKpiAssetById(
          org[0].id,
          KPI_FIELDS.LastCommunicationTime,
          this.kpiResponse
        )
      ).format('YYYY-MM-DD HH:mm:ss'),
    };
    this.tree.push(asset);
    this.orderingList.push(asset);
    this.getChartNewData();
  }

  /**
   * Format time
   * @param value
   * @param includeTime
   */
  formatTime(value: string, includeTime: boolean = false): string {
    return formatTimer(value, includeTime ? 'datetime' : 'date');
  }
}
