
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';

@Component({
  name: 'UtilTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ default: true }) showCheckedColumn!: boolean;

  @Prop() tableList!: any[];
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() path!: string;
  @Prop() showPage!: boolean;

  value = '';
  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }
  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  tableRowClassName(row: any, rowIndex: number) {
    if (row.row.chosen) {
      return 'choosing-row';
    }
    return '';
  }

  handleSelectionChange(val: any) {
    this.$emit('count-chosen', val);
    this.tableList.forEach((item: any) => {
      item.chosen = false;
    });
    val.forEach((item: any) => {
      item.chosen = true;
    });
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  mounted() {}

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }
}
