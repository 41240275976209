import { render, staticRenderFns } from "./AssetPayload.vue?vue&type=template&id=19ff27d1&scoped=true&"
import script from "./AssetPayload.vue?vue&type=script&lang=ts&"
export * from "./AssetPayload.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19ff27d1",
  null
  
)

export default component.exports