
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { updateProductModel } from '@/api/products';
import {
  MODEL_ASSET_TYPE_LIST,
  PROD_ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import { promptSuccessBox, customFailedMessage } from '@/utils/prompt';
import { ErrorType } from '@/api/types';

@Component({
  name: 'GeneralForm',
  components: {
    'new-card': NewCard,
    'cus-form-item': CusFormItem,
  },
})
export default class extends Vue {
  @Prop({ required: false }) productModelInfo!: any;
  @Prop({ required: false }) state!: any;

  /** Local variables */
  assetTypeList = MODEL_ASSET_TYPE_LIST;
  originalLifeCycle = 'LCL_DRAFT';
  // errModelNameInfo = this.$t('maintConf.duplicateName')
  // errNumberInfo = this.$t('maintConf.duplicateName')
  errModelNameInfo = '';
  errNumberInfo = '';

  /**
   * Backend payload structure used in v-model bindings
   */
  productModelForm = {
    code: '',
    assetTypeCode: 'ASSTYP_TABLE_TOP_TISSECTOR',
    modelNumber: '',
    lifeCycle: 'LCL_DRAFT',
    picturePath: '',
  };
  fileSize: number = 0;
  fileName: string = '';
  productImgFile: any;
  fileType: string = '';
  showInfoLabel: boolean = true;
  productModelIsLoading: boolean = false;
  errorImgInfo: string = '';
  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  mounted() {
    if (this.state === 'edit') {
      //the form is in in editing state
      this.productModelForm.code = this.productModelInfo.code;
      this.productModelForm.assetTypeCode = this.productModelInfo.assetTypeCode;
      this.productModelForm.modelNumber = this.productModelInfo.modelNumber;
      this.productModelForm.lifeCycle = this.productModelInfo.lifeCycle;
      this.originalLifeCycle = this.productModelInfo.lifeCycle;
      this.productModelForm.picturePath = this.productModelInfo.picturePath;
    }
    this.getCustomer();
  }

  validateProdModelFormLength = (rule: any, value: any, callback: any) => {
    let newReplaceValue = value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    if (newReplaceValue.length < 5 || newReplaceValue.length > 50) {
      callback(
        new Error(`${this.$t('prodCata.tipInputProductModelFormCorrect')}`)
      );
    } else {
      callback();
    }
  };

  /** Get form rules */
  get rules() {
    const tmpRules = {
      code: [
        {
          required: true,
          message: this.$t('prodCata.tipInputProductModelName'),
          trigger: 'change',
        },
        { validator: this.validateProdModelFormLength, trigger: 'change' },
      ],
      modelNumber: [
        {
          required: true,
          message: this.$t('prodCata.tipInputModelNumber'),
          trigger: 'change',
        },
        { validator: this.validateProdModelFormLength, trigger: 'change' },
      ],
      picturePath: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
    };
    return tmpRules;
  }

  /**
   * Get error name
   */
  get errNameInfo(): string {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.unknown)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.notMatchError')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleNone')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleError')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorCannotBeUpdated'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleUpdateError')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetType)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.assetTypeNone')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  async getCustomer(): Promise<void> {
    this.fileType = 'image/jpeg';
    this.fileSize = 0.5 * 1024 * 1024;
    this.productImgFile = null;
  }

  /**
   * Check product model name, number and picture path
   */
  get twoFormProps(): {
    code: string;
    modelNumber: string;
    errorImgWarning: string;
    picturePath: string;
  } {
    const { code, modelNumber, picturePath } = this.productModelForm;
    const errorImgWarning = this.errorImgInfo;
    return {
      code,
      modelNumber,
      errorImgWarning,
      picturePath,
    };
  }

  /**
   * Handle cancel by rerouting to previous route
   */
  handleCancel(): void {
    this.$router.go(-1);
  }

  /**
   * Check if product model name with number with picture path are valid, then send event to parent to activate "Save" button
   * @param value
   * @param oldVal
   */
  @Watch('twoFormProps', { deep: true })
  private watchFormItemsChange(value: any, oldVal: any): void {
    (this.$refs.productModelForm as any).validate((valid: any) => {
      if (valid && this.errorImgInfo === '') {
        this.$emit('disabledCreate', false);
      } else {
        this.$emit('disabledCreate', true);
      }
    });
  }

  /**
   * Watch product model Info
   * @param value
   * @param oldVal
   */
  @Watch('productModelInfo', { immediate: true, deep: true })
  private watchproductModelInfo(value: any, oldVal: any): void {
    if (this.$route.params.action || this.$route.params.action === 'copy') {
      this.productModelForm.code = this.productModelInfo.code;
      this.productModelForm.assetTypeCode = this.productModelInfo.assetTypeCode;
      this.productModelForm.modelNumber = this.productModelInfo.modelNumber;
      this.productModelForm.picturePath = this.productModelInfo.picturePath;
    }
  }

  /**
   * Watch over asset type change to send code to the parent component
   * @param value
   * @param oldVal
   */
  @Watch('productModelForm.assetTypeCode')
  private watchproductModelAssetType(value: any, oldVal: any): void {
    this.sendChosenAssetType();
  }

  /** Validate product model picture before upload */
  beforeProductImgUpload(file: any): void {
    this.productImgFile = file;
    this.productModelForm.picturePath = URL.createObjectURL(file);
    this.fileSize = file.size;
    this.fileName = file.name;
    this.fileType = file.type;
    this.showInfoLabel = false;
    (this.$refs.productModelForm as any).validateField('picturePath');
    this.validateProductModelPictureExtensionAndSize();
  }

  /** Validate product model picture extension and size */
  validateProductModelPictureExtensionAndSize(): boolean {
    const imageFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    const imageExtensions = ['jpeg', 'jpg', 'png'];
    const extensionRegex = /(?:\.([^.]+))?$/;
    const isImgFormatValid =
      imageFormats.includes(this.fileType) &&
      imageExtensions.some(
        (extension) => extensionRegex.exec(this.fileName)?.[1] === extension
      );
    if (!isImgFormatValid) {
      this.errorImgInfo = this.$t('prodCata.ImageFormatUnsupport') as string;
      return false;
    } else {
      this.errorImgInfo = '';
    }
    const isImgSizeValid = this.fileSize / 1024 / 1024 < 1;
    if (!isImgSizeValid) {
      this.errorImgInfo = this.$t('prodCata.ImageSizeExceed') as string;
      return false;
    } else {
      this.errorImgInfo = '';
    }
    return true;
  }

  /**
   * Send input data from the user to the parent component
   * Triggered from parent component
   */
  @Emit('product-model-input-form-event')
  sendFormData() {
    return this.productModelForm;
  }

  /**
   * Send picture file to parent component for further processing
   * Triggered from parent
   */
  @Emit('product-model-picture-evenet')
  sendPicture(): string {
    return this.productImgFile;
  }

  /**
   * Emit selected asset type to parent component
   */
  @Emit('product-model-asset-type-event')
  sendChosenAssetType(): string {
    return this.productModelForm.assetTypeCode;
  }

  /** Edit existing product model */
  updateFormData(id: string): void {
    try {
      this.productModelIsLoading = true;
      (this.$refs.productModelForm as any).validate((valid: any) => {
        if (valid && this.errorImgInfo === '') {
          const requestPayload = new FormData();
          requestPayload.append('productModelPicture', this.productImgFile);
          requestPayload.append(
            'productModelRequestBody',
            new Blob([JSON.stringify(this.productModelForm)], {
              type: 'application/json',
            })
          );
          updateProductModel(id, requestPayload).then((res) => {
            if (res.code === 200) {
              promptSuccessBox(this.$t('common.updated') as string);
              this.errorInfos = [];
              this.$router.push('/prod-cata/index?activeName=productModels');
            } else if (res.code === 400) {
              // this.errorInfos = res.data.errors[0].message;
              this.errorInfos = [...res.data.errors[0]];
              // customFailedMessage(this.$t('common.edit') as string);
              customFailedMessage(this.errNameInfo as any);
            }
          });
        } else {
          customFailedMessage(
            this.$t('subscriptionPackages.generalInfoErrors') as string
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.productModelIsLoading = false;
    }
  }

  /**
   * Handle image upload > no behavior & do not request remotly call
   */
  imageUploadRequest(): void {}

  /**
   * Handle success upload > do not request remotly call
   */
  handleImageUploadSuccess(): void {}
}
