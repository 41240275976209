
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { Role } from '@/api/roles';
import GeneralInfo from '@/components/form/GeneralInfo.vue';

@Component({
  name: 'ViewRoleInfo',
  components: {
    'new-card': NewCard,
    'module-access': ModuleAccess,
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) roleForm!: Role;
  @Prop() claimsStructure: any = []; // tree structure model from where module access section is created
  @Prop() defaultChecked: string[] = [];
}
