
import {
  Component,
  Vue,
  ProvideReactive,
  Inject,
} from 'vue-property-decorator';
import KpiBarChart from '@/components/widget/KpiBarChart.vue';
import { getKpiData } from '@/api/assets';
import { customFailedMessage } from '@/utils/prompt';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { collateExpandTableData } from '@/utils/kpidata';
import { determineEntityType } from '@/utils/assetRouteChecker';
import { EntityType } from '@/utils/workData/lookuptable';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';

@Component({
  name: 'AssetPower',
  components: {
    KpiBarChart,
  },
})
export default class extends Vue {
  dateRange = DEFAULT_DATE_RANGE;
  kpiNumberList: any = [
    {
      name: this.$t('KPI.totalPowerConsumption'),
      value: 0,
      unit: this.$t('UNIT_KWH'),
      growth: '0%',
    },
    {
      name: this.$t('KPI.avePowerConsumption'),
      value: 0,
      unit: this.$t('UNIT_KWH'),
      growth: null,
    },
  ];
  totalNumber: number | string = 0;
  averageNumber: number | string = 0;
  growth: string | number | null = '0%';
  KpiChartIsLoading: boolean = false;
  chartData: any = [];
  unitCode = 'UNIT_KWH';
  routeAssetMap = ROUTE_ASSET_MAP;
  kpiTypeList = [
    {
      code: 'KPI.PowerConsumption',
      tableHeadLabel: 'KPI.powerConsumption',
      tableProp: 'poweiConsumption',
      unit: 'UNIT_KWH',
      needGrowthPercentage: 'true',
    },
  ];
  entityType: EntityType = determineEntityType(this.$route);

  @ProvideReactive('tableList') tableList: any = [];
  @ProvideReactive('cols') cols: any = [];

  @Inject() expanded!: boolean;
  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  dateRangeChange(dateRange: DateRange) {
    this.dateRange = dateRange;
    this.fecthKpi();
  }

  collateAndCalculate(responseData: any) {
    let totalValue: number = 0;
    let availableLength: number = 0;
    responseData.forEach((item: any) => {
      if (item.v != null) {
        availableLength++;
      }
      totalValue = totalValue + Number(item.v ? item.v : 0);
      this.chartData.push([
        item.ts,
        item.v != null ? Number(item.v).toFixed(2) : '#N/A',
      ]);
    });
    this.totalNumber =
      availableLength > 0 ? Number(totalValue.toFixed(2)) : '#N/A';
    this.averageNumber =
      availableLength > 0
        ? (Number(this.totalNumber) / availableLength).toFixed(2)
        : '#N/A';
    this.$set(this.kpiNumberList[0], `value`, this.totalNumber);
    this.$set(this.kpiNumberList[1], `value`, this.averageNumber);
  }

  async fecthKpi() {
    this.KpiChartIsLoading = true;
    let submitFields: any = [];
    this.kpiTypeList.forEach((item: any) => {
      submitFields.push({
        code: item.code,
        unit: item.unitCode,
        needGrowthPercentage: item.needGrowthPercentage,
      });
    });
    let realTimeKpiPostData = {
      metadata: {
        filter: {
          assetId: this.$route.params.id,
        },
        selection: {
          startDate: this.dateRange.start,
          endDate: this.dateRange.endExclusive,
          dataBucketSize: 'DBSIZE_DAY',
        },
      },
      details: [
        {
          entity: this.entityType,
          fields: submitFields,
        },
      ],
    };
    await getKpiData(realTimeKpiPostData, unref(this.context)).then((res) => {
      this.KpiChartIsLoading = false;
      if (!res) return;
      if (res.code === 200) {
        this.chartData = [];
        this.collateAndCalculate(
          res.data.details[0].fields[0] && res.data.details[0].fields[0].values
            ? res.data.details[0].fields[0].values
            : []
        );
        this.growth =
          res.data.details[0].fields[0] &&
          res.data.details[0].fields[0].growthPercentage != null
            ? res.data.details[0].fields[0].growthPercentage
            : null;
        this.$set(this.kpiNumberList[0], `growth`, this.growth);

        if (this.expanded) {
          //it's in the expanded page, FE should caculate the table data
          let { cols, tableList } = collateExpandTableData(
            res.data.details[0].fields,
            this.kpiTypeList,
            'date'
          );
          this.cols = cols;
          this.tableList = tableList;
        }
        return;
      } else {
        this.chartData = [];
      }
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    });
  }
}
