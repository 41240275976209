
import { Component, Prop, Vue } from 'vue-property-decorator';
import CusFormItem from '@/components/form/CusFormItem.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import {
  COMPANY_TYPE_LIST,
  ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import { Role } from '@/api/roles';
import { ErrorType } from '@/api/types';

@Component({
  name: 'ChangeRole',
  components: {
    'cus-form-item': CusFormItem,
    'module-access': ModuleAccess,
  },
})
export default class extends Vue {
  @Prop() roleForm!: Role;
  @Prop() claimsStructure: any = []; // tree structure model from where module access section is created
  @Prop() defaultChecked: string[] = [];

  companyTypes = COMPANY_TYPE_LIST;
  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  validateClaimsFtd = (_rule: any, _value: any, callback: any) => {
    const checkedNode = (this.$refs.moduleAccess as any).getCheckedNodes();
    if (checkedNode.length < 1) {
      callback(new Error(`${this.$t('userRoleModule.tipSelectModuleAccess')}`));
    } else {
      callback();
    }
  };

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userRoleModule.duplicateName')}`;
    }
    // return this.errorInfos.find(item => item.field === this.errorCode.name)?.message
    return errInfo;
  }

  handleInput() {
    this.errorInfos = [];
  }
}
