
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'fancy-info',
})
export default class extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) value!: string;
}
