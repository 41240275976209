
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';

@Component({
  name: 'generalPreview',
  components: {
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) productModelInfo!: any;
  @Prop({ required: false }) imgAddress: any | undefined;

  created() {}
}
