
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { resetPassword } from '@/api/users';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import CommonBtn from '@/components/button/CommonBtn.vue';

@Component({
  name: 'AccountSettings',
  components: {
    'general-info': GeneralInfo,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  disabled: boolean = true;

  accountSettingForm = {
    oldPassword: '',
    newPassword: '',
  };

  userEmail: string = UserModule.email;
  userName: string = UserModule.name;

  confirmPwd: string = '';
  errorInfoFlag: boolean = false;

  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  errorCode = ERROR_CODE_LIST;

  get errMessage() {
    let tmpErrInfo: string = '';
    // let errItemCode: string | undefined = this.errorInfos.find(item => item.field === this.errorCode.password)?.code

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.password)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      tmpErrInfo = `${this.$t('userSetting.errPassword')}`;
    } else if (
      this.errorInfos.find((item) => item.field === this.errorCode.password)
        ?.code === 'ApiErrorSamePassword'
    ) {
      tmpErrInfo = `${this.$t('userSetting.samePassword')}`;
    } else if (
      this.errorInfos.find((item) => item.field === this.errorCode.password)
        ?.code === 'ApiErrorPasswordSameAsLastThree'
    ) {
      tmpErrInfo = `${this.$t('userSetting.sameAsLastThreePwd')}`;
    } else if (
      this.errorInfos.find((item) => item.field === 'newPassword')?.code ===
      'ApiErrorFieldInvalid'
    ) {
      tmpErrInfo = `${this.$t('userSetting.newPasswordFormat')}`;
    }

    return tmpErrInfo;
  }

  async saveAccountSetting() {
    if (this.confirmPwd !== this.accountSettingForm.newPassword) {
      this.errorInfoFlag = true;
    } else {
      this.errorInfoFlag = false;
      await resetPassword(UserModule.id, this.accountSettingForm).then(
        (res) => {
          if (res.code === 200) {
            this.errorInfos = [];
            promptSuccessBox(this.$t('common.save') as string);
          } else if (res.code === 400) {
            this.errorInfoFlag = true;
            promptFailedBox(this.$t('common.save') as string);
            this.errorInfos = res.data.errors;
          }
        }
      );
    }
  }

  newPwdInput(value: string) {
    this.errorInfos = [];
    if (this.confirmPwd && value !== this.confirmPwd) {
      this.errorInfoFlag = true;
    } else {
      this.errorInfoFlag = false;
    }

    this.isDisableBtn();
  }

  comparePwd(value: string) {
    this.errorInfos = [];
    if (value !== this.accountSettingForm.newPassword) {
      this.errorInfoFlag = true;
    } else {
      this.errorInfoFlag = false;
    }
  }

  isDisableBtn() {
    if (
      this.accountSettingForm.oldPassword &&
      this.accountSettingForm.newPassword &&
      this.confirmPwd
    ) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  async handleConfirmBtn() {
    await UserModule.keycloakLogout();
  }
}
