import { render, staticRenderFns } from "./FleetPower.vue?vue&type=template&id=8aa6f5a2&scoped=true&"
import script from "./FleetPower.vue?vue&type=script&lang=ts&"
export * from "./FleetPower.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8aa6f5a2",
  null
  
)

export default component.exports