
import { Component, Prop, Vue, Watch, PropSync } from 'vue-property-decorator';
import AssignDialog from './AssignDialog.vue';

@Component({
  name: 'AssignNav',
  components: {
    AssignDialog,
  },
})
export default class extends Vue {
  @Prop({ default: 0 }) selectedNumber!: string | Number;
  @PropSync('operationState', { type: String }) syncedOperationState!: string;
  @Prop({ default: false }) disableBtn!: boolean;

  btnTextMap: any = {
    selecting: 'assetMgmt.next',
    filling: 'assetMgmt.assign',
    confirm: 'assetMgmt.confirm',
    succeed: 'assetMgmt.succeed',
  };
  visible: boolean = false;

  created() {}

  //the state goes next
  changeAssignState() {
    if (!this.disableBtn) {
      switch (this.syncedOperationState) {
        case 'selecting':
          this.syncedOperationState = 'filling';
          break;
        case 'filling':
          //send emit to parent to get formData
          this.$emit('validate-form-data');
          break;
        case 'confirm': //post api
          this.$emit('send-request');
          break;
        case 'succeed': //reset to main page
          this.$emit('reset-to-home');
          // this.$router.go(0)
          // window.location.reload()
          break;
        default:
          break;
      }
    }
  }

  updateState(newState: string) {
    this.syncedOperationState = newState;
  }

  //the state goes back
  backAssignState() {
    switch (this.syncedOperationState) {
      case 'selecting':
        break;
      case 'filling':
        this.syncedOperationState = 'selecting';
        //back to 'selelcting' state should recover the disabled btn
        this.$emit('reset-disable-assign', false);
        break;
      case 'confirm':
        this.syncedOperationState = 'filling';
        break;
      default:
        break;
    }
  }

  updateVisible(val: boolean) {
    this.visible = val;
  }

  resetPopupData() {
    this.visible = false;
  }

  submitPopupData() {
    this.visible = false;
    //go to router
    this.$router.push('/asset-mgmt/index?activeName=connectedAssets');
  }

  //the state goes home
  backToHome() {
    this.visible = true;
  }
}
