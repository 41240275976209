
import { Component, Vue } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import RegisterAssetForm from '../components/RegisterAssetForm.vue';
import { assetOnBoarding } from '@/api/assets';
import moment from 'moment';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { addCustomerAssets } from '@/api/accessibleAssets';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { LoggedInUserRef, useLoggedInUser } from '@/composables/context';
import { unref } from 'vue';
import { LOCALDATE_FORMAT } from '@/utils/time';
import { formatDateForServer } from '../helper';

@Component({
  name: 'RegisteringAsset',
  components: {
    NavPage,
    RegisterAssetForm,
  },
})
export default class extends Vue {
  /** Local variables */
  customersWithAccessOnNewAsset: COMPANY_TYPE[] = [
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
  ];
  isCurrentCustomerWithInstantAssetAccess: boolean = false;
  registerAssetForm = {
    assetType: '',
    productModelId: '',
    modelNumber: '',
    deploymentRegion: '',
    serviceRegion: '',
    serialNumber: '',
    manufactureDate: '',
    timeZone: '',
    controllerId: '',
  };
  validateFieldInputs: boolean = false;
  isRegisterFormValid: boolean = false;
  loggedInUser!: LoggedInUserRef;
  registeringAssetIsLoading: boolean = false;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.prepareDefaultInitialization();
  }

  mounted() {}

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    this.isCurrentCustomerWithInstantAssetAccess =
      this.customersWithAccessOnNewAsset.includes(user.companyType);
  }

  /** Trigger when validation are done from register form element */
  checkIsValidFormInput(isValid: boolean) {
    this.isRegisterFormValid = isValid;

    if (!this.isRegisterFormValid) return;

    this.handleRegisterAssetRemotely();
  }

  /** Trigger on Save btn event */
  validateForm() {
    this.validateFieldInputs = !this.validateFieldInputs;
  }

  /** Remotely register the new asset */
  async handleRegisterAssetRemotely() {
    try {
      this.registeringAssetIsLoading = true;
      let payloadObject = {
        assetType: this.registerAssetForm.assetType,
        productModelId: this.registerAssetForm.modelNumber,
        deploymentRegion: this.registerAssetForm.deploymentRegion,
        serviceRegion: this.registerAssetForm.serviceRegion,
        serialNumber: this.registerAssetForm.serialNumber,
        manufactureDate: formatDateForServer(
          this.registerAssetForm.manufactureDate
        ),
        timezone: this.registerAssetForm.timeZone,
        controllerId: this.registerAssetForm.controllerId,
      };

      await assetOnBoarding(payloadObject).then(async (res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          const registeredAssetUUID = res.data.id;
          if (this.isCurrentCustomerWithInstantAssetAccess) {
            const user = unref(this.loggedInUser);
            if (!user) {
              // Should never happen
              throw new Error('No user logged in');
            }
          }
          this.$router.push('/asset-mgmt/index');
          customSuccessMessage(
            this.$t('assetMgmt.assetRegistrationWasSuccessful') as string
          );
          return;
        }

        if (
          res.code === 400 &&
          res.data.errors[0].field === 'ApiFieldSerialNumber'
        ) {
          customFailedMessage(
            this.$t('assetMgmt.duplicateSerialNumber') as string
          );
          return;
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('assetMgmt.assetRegistrationWasNotSuccessful') as string
          );
          return;
        }

        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.registeringAssetIsLoading = false;
    }
  }
}
