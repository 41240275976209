
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { customFailedMessage } from '@/utils/prompt';
import { getAssetOnBoardingById } from '@/api/assetsMgmt';
import moment from 'moment';
import { PageModule } from '@/store/modules/page';
import { formatServerDate } from '../helper';

@Component({
  name: 'RegisterAssetView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;

  /** Local variables */
  registerAssetInfo: any = {};
  title = '';
  registerAssetViewIsLoading: boolean = false;

  mounted() {
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.registerAssetViewIsLoading = true;
    await getAssetOnBoardingById(this.assetId).then((res) => {
      if (res.code === 200) {
        this.registerAssetInfo = res.data;
        this.registerAssetInfo.manufactureDate = formatServerDate(
          res.data.manufactureDate
        );
        PageModule.setTitle(
          `${res.data.assetDataCollectionId}/${res.data.serialNumber}`
        );
      } else {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      this.registerAssetViewIsLoading = false;
    });
  }

  gotoEditRegisteredAsset() {
    this.$router.push(`/asset-mgmt/register/edit/${this.assetId}`);
  }
}
