
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getTemplatesById,
  getModules,
  createTemplate,
  updateTemplate,
  DashboardTemplate,
} from '@/api/dashboardConf';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import EditDashTemplate from '@/components/template/EditDashTemplate.vue';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { handleUpdateAPIErrors } from './dashboardTemplate';

interface WIDGET_TYPE {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
  code: string;
  pageCode: string;
  name: string;
}

@Component({
  name: 'DashboardTemplateEdit',
  components: {
    BaseCard,
    EditDashTemplate,
  },
})
export default class extends Vue {
  @Prop() templateId!: string;

  title: string = '';

  editDashTemplateChild: any = {};
  templateForm: DashboardTemplate = {
    id: '',
    companyId: '',
    name: '',
    parentTemplateId: '',
    customerType: '' as any,
    industry: '',
    widgets: [],
    unSelectedModules: [],
    isDefault: false,
  };

  handleRecover() {
    this.editDashTemplateChild.handleResetToDefault('', '', this.templateId);
  }

  async handleSave() {
    this.editDashTemplateChild.updateWidget();
    this.templateForm.unSelectedModules =
      this.editDashTemplateChild.filterModules();

    if (
      !this.editDashTemplateChild.isCheckedNodeListNull() &&
      !this.editDashTemplateChild.isPageNull()
    ) {
      this.templateForm.widgets = this.editDashTemplateChild.getAllWidgets();

      if (CustMgmtModule.customerId) {
        await updateTemplate(this.templateId, this.templateForm).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.save') as string);
            this.$router.push(
              `/customer-management/edit/${CustMgmtModule.customerId}?activeName=dashboardTemplateTab`
            );
            CustMgmtModule.setTemplateId('');
            CustMgmtModule.setCustomerId('');
          } else if (res.code === 400) {
            handleUpdateAPIErrors(res);
          }
        });
      } else {
        this.templateForm.companyId = '00000000-0000-0000-0000-000000000000';
        this.templateForm.parentTemplateId = CustMgmtModule.templateId;
        await createTemplate(this.templateForm).then((res) => {
          if (res.code === 200 && res.data) {
            CustMgmtModule.setIsSave(true);
            CustMgmtModule.setCustTemplateId(res.data.id);
            promptSuccessBox(this.$t('common.save') as string);
            this.$router.push(
              '/customer-management/create?activeName=dashboardTemplateTab'
            );
          } else if (res.code === 400) {
            handleUpdateAPIErrors(res);
          }
        });
      }
    }
  }

  async fetchTemplateById() {
    const res = await getTemplatesById(this.templateId);

    this.title = res.data.name;
    this.templateForm = res.data;

    this.editDashTemplateChild.updateWidgetsBuffer(this.templateForm.widgets);

    this.editDashTemplateChild.handleNodeClick({
      name: 'AUTHRSC_MOD_HOME',
      children: [],
    });

    this.templateForm.widgets = [];
  }

  async fetchCheckedModules() {
    const resCheckedModules = await getModules(this.templateId);
    this.editDashTemplateChild.setCheckedModules(resCheckedModules);
  }

  mounted() {
    this.editDashTemplateChild = this.$refs.custEditDashTemplate as any;
    this.templateId && this.fetchCheckedModules();
    this.templateId && this.fetchTemplateById();
  }
}
