
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'NavCardPage',
})
export default class extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ default: true }) visible!: boolean;
  @Prop({ default: true }) arrowBack!: boolean;
  @Prop({ default: true }) totalSubscriptions!: number;

  goBack() {
    //the arrow back doesn't always go back in some situations.
    if (!this.arrowBack) {
      this.$emit('arrow-click');
    } else this.$router.go(-1);
  }
}
