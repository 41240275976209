
import { getKpiData, KpiDataRequest, KpiDataField } from '@/api/assets';
import ScatterChart from '@/components/charts/ScatterChart.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { AssetType, KPI_FIELDS, KPI_UNIT } from '@/utils/workData/lookuptable';
import FleetPerformanceExpanded from '@/widgets/fleet/expanded/FleetPerformanceExpanded.vue';
import { Ref, unref } from 'vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import moment from 'moment';
import {
  generateFleetPerformanceChartData,
  getFleetPerformanceAverageValue,
} from '../utils/handleWidgetOperations';

interface seriesArray {
  symbolSize: number;
  data: number[][];
  type: string;
}

interface chartOptions {
  xAxis?: {};
  yAxis?: {};
  series: seriesArray[];
}

@Component({
  name: 'FleetPerformance',
  components: {
    FleetPerformanceExpanded,
    TimeSelect,
    WidgetCard,
    ScatterChart,
  },
})
export default class extends Vue {
  @Inject() expanded!: boolean;

  /** Local variables */
  chartData: any = [];
  isLoading: boolean = true;
  period: number = 7;
  averageValue: number = 0;
  numberOfUnitsInOneDay: number = 24; //hours
  context!: Ref<ActiveContext>;
  requestPayload: KpiDataRequest = {
    metadata: {
      filter: {
        assetTypeCode: AssetType.TippingVehicle,
        // organizationIds filled in later
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_ASSET',
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET_TYPE',
        fields: [
          {
            code: KPI_FIELDS.TippingPayload,
            unit: KPI_UNIT.MetricTonne,
          },
        ],
      },
    ],
  };

  created() {
    this.context = useActiveContext();
  }

  /**
   * Handle time selection
   * - set period as days difference between start and inclusive end date of the range
   */
  handleTimeFilter(dateRange: DateRange) {
    this.period = Math.abs(
      moment(dateRange.endExclusive).diff(dateRange.start, 'days')
    );
    this.requestPayload.metadata.selection!.startDate = dateRange.start;
    this.requestPayload.metadata.selection!.endDate = dateRange.endExclusive;
    this.getData();
  }

  /**
   * Get kpi-data remote from API
   */
  async getData() {
    try {
      this.isLoading = true;
      this.requestPayload.metadata.timezone = unref(
        this.context
      )?.primaryOrgTimeZone;
      this.requestPayload.metadata.filter.organizationIds = unref(
        this.context
      ).organizationIds;
      const response = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );
      if (
        response.hasOwnProperty('data') &&
        response?.data?.details?.length > 0
      ) {
        this.handleChartData(response.data.details[0].fields);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handle fleet performance chart data
   */
  handleChartData(responseToBeProcessed: KpiDataField[]): void {
    this.chartData = generateFleetPerformanceChartData({
      responseToBeProcessed,
      timePeriod: this.period,
      numberOfUnitsInOneDay: this.numberOfUnitsInOneDay,
      showAll: true,
    });
    this.averageValue = getFleetPerformanceAverageValue(this.chartData);
  }
}
