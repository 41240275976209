
import { Component, Vue, Prop } from 'vue-property-decorator';
import { THEME_LIST, CHART_TYPE_LIST } from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';
import {
  createReportTemplate,
  getReportTemplate,
  updateReportTemplate,
} from '@/api/report';
import { customFailedMessage } from '@/utils/prompt';
import { getRoles } from '@/api/roles';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import SelectCheckbox from '../components/SelectCheckbox.vue';
import {
  getAssetTypesList,
  ReportAssetType,
  ReportAssetTypeKpi,
} from '@/utils/assetTypes';
import QuitEditingTemplateModal from '../components/QuitEditingTemplateModal.vue';
import { PageModule } from '@/store/modules/page';
import { ErrorType } from '@/api/types';
import { getAssetTypesFromCompanySubscription } from '../report';

interface ReportKPI {
  id: string;
  code: string;
  unit?: string;
  method: string;
}

const allKpi = {
  id: 'KPI.All_All',
  code: 'KPI.All',
  method: 'All',
};

@Component({
  name: 'createReportTemplate',
  components: {
    'base-card': BaseCard,
    'common-btn': CommonBtn,
    'cus-form-item': CusFormItem,
    'select-checkbox': SelectCheckbox,
    'quit-editing-template-modal': QuitEditingTemplateModal,
  },
})
export default class extends Vue {
  @Prop() reportTemplateId!: string;

  /** Local variables */
  loadingCreatePage: boolean = false;
  assetTypeList: any = [];
  kpiListMap: Map<string, Array<ReportKPI>> = new Map<
    string,
    Array<ReportKPI>
  >();
  showDialogBeforeBack: boolean = false;
  isQuitEditingTemplateModalVisible: boolean = false;
  isformValuesChanged: boolean = false;

  originKpis: any = [];
  kpiList: any = [];

  originRoles: any = [];
  subscriberRoleList: any = [];

  chartTypes = CHART_TYPE_LIST;
  chartKpiList: any = [];
  defaultSubscriberRoles: string[] = ['All'];

  activeColor: string = '';
  templateName: string = '';
  selectedAssetType: string = '';
  selectedAssetTypeTotalKpis: number = 0;
  allKpiAreUsed: boolean = true;

  reportTemplateForm: any = {
    name: '',
    assetType: '',
    tempKpis: [],

    kpis: [],
    subscriberRoles: [],

    allowedSubscriberRoles: [],
    charts: [],
    displayedInTable: true,
    companyId: UserModule.companyId,
    organizationId: UserModule.organizationId,
    creatorUserName: UserModule.name,
    note: '',
  };

  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  subscriberRolesListIsLoading: boolean = false;

  created() {
    this.showDialogBeforeBack = !!this.reportTemplateId;
    this.getThemeColor();

    Promise.all([this.fetchAssetTypes(), this.fetchRoles()]).then(() => {
      if (this.reportTemplateId) {
        this.getReportTemplate();
      }
    });
  }

  /**
   * Get report template by id
   */
  async getReportTemplate(): Promise<void> {
    try {
      const response = await getReportTemplate(this.reportTemplateId);
      const template = response.data;
      PageModule.setTitle(template.name);
      this.showValuesInForm(template);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Display form values
   * @param template
   */
  showValuesInForm(template: any): void {
    this.handleSelectAssetType(template.assetType);

    this.reportTemplateForm.name = template.name;
    this.reportTemplateForm.assetType = template.assetType;
    this.reportTemplateForm.displayedInTable = template.displayedInTable;
    this.reportTemplateForm.note = template.note;

    let tempKpis: any = [];
    if (template.kpis.length == this.selectedAssetTypeTotalKpis) {
      tempKpis = [allKpi];
    } else {
      template.kpis.forEach((kpi: any) => {
        return kpi.statisticsMethods.forEach((method: string) => {
          tempKpis.push(this.mapKpi(kpi, method));
        });
      });
    }
    this.reportTemplateForm.tempKpis = tempKpis;

    if (this.originRoles.length == template.allowedSubscriberRoles.length) {
      (this.$refs.reportTemplateRolesRef as SelectCheckbox).setCheckedItems([
        'All',
      ]);
    } else {
      (this.$refs.reportTemplateRolesRef as SelectCheckbox).setCheckedItems(
        template.allowedSubscriberRoles
      );
    }

    this.reportTemplateForm.charts = template.charts;
    const kpiCodes = template.kpis.map((kpi: any) => kpi.code);
    this.chartKpiList = this.originKpis
      .find((originKpi: any) => originKpi.assetType == template.assetType)
      .kpis.filter((originKpi: any) => kpiCodes.indexOf(originKpi.code) > -1)
      .map((originKpi: any) => this.mapChartKpiListItem(originKpi));
  }

  /**
   * Get selected asset type
   */
  get isSelectAssetType(): boolean {
    return this.reportTemplateForm.assetType == '';
  }

  /**
   * Get roles
   */
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('report.tipInputName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      assetType: [
        {
          required: true,
          message: this.$t('report.tipSelectAssetType'),
          trigger: 'change',
        },
      ],
      tempKpis: [
        {
          required: true,
          message: this.$t('report.tipSelectKpis'),
          trigger: 'change',
        },
      ],
      kpiCode: [{ validator: this.validateKpis, trigger: 'change' }],
      chartType: [{ validator: this.validateChartType, trigger: 'change' }],
    };
    return tmpRules;
  }

  /**
   * Get error name message
   */
  get errNameInfo() {
    let errInfo: string = '';

    if (
      this.errorInfos.find(
        (item) => item.field === 'ApiFieldReportTemplateName'
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('report.errInfoName')}`;
    }
    return errInfo;
  }

  /**
   * Validate KPIs
   * @param _rule
   * @param value
   * @param callback
   */
  validateKpis = (_rule: any, value: any, callback: any) => {
    if (value) {
      callback();
    } else {
      callback(new Error(`${this.$t('report.tipSelectKpi')}`));
    }
  };

  /**
   * Validate chart types
   * @param _rule
   * @param value
   * @param callback
   */
  validateChartType = (_rule: any, value: any, callback: any) => {
    if (value) {
      callback();
    } else {
      callback(new Error(`${this.$t('report.tipSelectChartType')}`));
    }
  };

  /**
   * Validate name
   * @param _rule
   * @param value
   * @param callback
   */
  validateName = (_rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 50) {
      callback(
        new Error(`${this.$t('report.nameShouldBeBetweenOneAndFifteen')}`)
      );
    } else {
      callback();
    }
  };

  /**
   * Get asset types to be used for new report template creationg
   * Must be filtered by company available subscription asset types subscriptions
   */
  async fetchAssetTypes(): Promise<void> {
    try {
      this.loadingCreatePage = true;
      const companyAssetTypes: string[] | undefined =
        await getAssetTypesFromCompanySubscription();
      const res = await getAssetTypesList();
      this.originKpis = res;
      res.forEach((item: ReportAssetType) => {
        /** Set available asset types by report template default asset type and available company subscriptions */
        this.handleAvailableAssetTypes(companyAssetTypes, item);
        let tempKpiArray: ReportKPI[] = [allKpi];
        item.kpis.forEach((kpi: ReportAssetTypeKpi) => {
          kpi.statisticsMethods.forEach((method: string) => {
            tempKpiArray.push(this.mapKpi(kpi, method));
          });
        });

        this.kpiListMap.set(item.assetType, tempKpiArray);
      });

      if (this.reportTemplateForm.assetType) {
        this.handleSelectAssetType(this.reportTemplateForm.assetType);
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('maintenance.errorWithFetchingData').toString()
      );
    } finally {
      this.loadingCreatePage = false;
    }
  }

  /**
   * Handle available asset type for new report template creation by:
   * Retrieving report template default asset types and filter those by company subs available asset types subscriptions
   */
  handleAvailableAssetTypes(
    companyAssetTypes: string[] | undefined,
    item: any
  ) {
    if (!companyAssetTypes || companyAssetTypes?.length == 0) {
      this.assetTypeList = [];
    } else if (
      companyAssetTypes.some((assetType: string) => assetType == item.assetType)
    ) {
      this.assetTypeList.push(item.assetType);
    }
  }

  /**
   * Map KPI
   * @param kpi
   * @param method
   */
  mapKpi(kpi: any, method: string): ReportKPI {
    return {
      id: kpi.code + '_' + method,
      code: kpi.code,
      unit: kpi.unit,
      method: method,
    };
  }

  /**
   * Fetch roles
   */
  async fetchRoles(): Promise<void> {
    try {
      this.subscriberRolesListIsLoading = true;
      let finalUrl = `?page=1&size=1000&searchFieldName=companyType&searchFieldValues=${UserModule.companyType}`;
      const res = await getRoles(finalUrl);
      if (res.code === 200 && res.data.roles.length > 0) {
        this.originRoles = res.data.roles;
        res.data.roles.forEach((role: any) => {
          this.subscriberRoleList.push({
            id: role.id,
            code: role.code,
            label: role.name,
          });
        });
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.subscriberRolesListIsLoading = false;
    }
  }

  /**
   * Handle selected asset type
   * @param assetType
   */
  handleSelectAssetType(assetType: string): void {
    this.reportTemplateForm.tempKpis = [];
    this.reportTemplateForm.charts = [];
    this.kpiList = this.kpiListMap.get(assetType);

    this.chartKpiList = [];
    this.selectedAssetType = assetType;
    this.selectedAssetTypeTotalKpis = this.originKpis.find(
      (item: any) => item.assetType === this.selectedAssetType
    ).kpis.length;
  }

  /**
   * Select all kpis by asset type
   */
  selectAllKpisByAssetType(): void {
    this.chartKpiList = [];
    const allKpis = this.originKpis.find(
      (item: any) => item.assetType === this.selectedAssetType
    ).kpis;

    allKpis.forEach((kpi: any) => {
      this.chartKpiList.push({
        kpiCode: kpi.code,
        unit: kpi.unit,
        disabled: false,
      });
    });
  }

  /**
   * Check all kpis that are used
   */
  checkAllKpiAreUsed(): void {
    this.allKpiAreUsed =
      !!this.chartKpiList.length &&
      (this.chartKpiList.length === this.reportTemplateForm.charts.length ||
        !!this.chartKpiList.every((el: { kpiCode: string }) =>
          this.handleChartKpiStatus(el.kpiCode)
        ));
  }

  /**
   * Remove KPI tag
   * @param tagValue
   */
  removeKpiTag(tagValue: any): void {
    this.manageChartKpiList(tagValue);
  }

  /**
   * Handle selected item
   * @param kpi
   */
  handleSelectItem(kpi: any): void {
    if (kpi.id === allKpi.id && this.reportTemplateForm.tempKpis.length > 0) {
      this.reportTemplateForm.tempKpis = [allKpi];
    } else {
      let index = this.reportTemplateForm.tempKpis.findIndex(
        (item: any) => item.id === allKpi.id
      );

      if (index > -1) {
        this.reportTemplateForm.tempKpis.splice(index, 1);
      }
    }

    this.manageChartKpiList(kpi);
    this.formValuesChanged();
  }

  /**
   * Manage chart kpis list
   * @param kpi
   */
  manageChartKpiList(kpi: any): void {
    if (kpi.id === allKpi.id) {
      this.selectAllKpisByAssetType();
      return;
    } else if (this.chartKpiList.length == this.selectedAssetTypeTotalKpis) {
      this.chartKpiList = [];
      this.reportTemplateForm.charts.forEach((listItem: any) => {
        const isKPI = this.reportTemplateForm.tempKpis.find(
          (item: any) => item.code == listItem.kpiCode
        );

        if (!isKPI) {
          listItem.kpiCode = '';
        }
      });
    }

    const chartKpiListItem = this.chartKpiList.find(
      (item: any) => item.kpiCode == kpi.code
    );

    if (
      chartKpiListItem &&
      !this.reportTemplateForm.tempKpis.some(
        (item: any) => item.code == kpi.code
      )
    ) {
      const index = this.chartKpiList.findIndex(
        (item: any) => item.kpiCode == kpi.code
      );
      this.chartKpiList.splice(index, 1);

      this.removeKpiFromChart(kpi);
    } else if (!chartKpiListItem) {
      const originKpi: any = this.originKpis
        .find((item: any) => item.assetType === this.selectedAssetType)
        .kpis.find((item: any) => item.code == kpi.code);
      this.chartKpiList.push(this.mapChartKpiListItem(originKpi));
    }
  }

  /**
   * Map chart kpi list item
   * @param originKpi
   */
  mapChartKpiListItem(originKpi: any): {
    kpiCode: string;
    unit: string;
    disabled: boolean;
  } {
    return {
      kpiCode: originKpi.code,
      unit: originKpi.unit,
      disabled: false,
    };
  }

  /**
   * Remove kpi from chart
   * @param kpi
   */
  removeKpiFromChart(kpi: any): void {
    const chart = this.reportTemplateForm.charts.find(
      (item: any) => item.kpiCode == kpi.code
    );

    if (chart) {
      chart.kpiCode = '';
    }
  }

  /**
   * Handle push chart
   */
  handlePushChart(): void {
    this.reportTemplateForm.charts.push({
      kpiCode: '',
      chartType: '',
    });

    this.formValuesChanged();
  }

  /**
   * Handle chart kpi status
   * @param kpiCode
   */
  handleChartKpiStatus(kpiCode: string): boolean {
    let charts = this.reportTemplateForm.charts;
    for (let index = 0; index < charts.length; ++index) {
      if (charts[index].kpiCode === kpiCode) {
        return true;
      }
    }
    return false;
  }

  /**
   * Handle deletion of a chart
   * @param index
   * @param kpiCode
   */
  handleDeleteChart(index: number, kpiCode: string): void {
    this.reportTemplateForm.charts.splice(index, 1);

    this.chartKpiList.map((item: any) => {
      if (item.kpiCode === kpiCode) {
        item.disabled = false;
      }
    });

    this.formValuesChanged();
  }

  /**
   * Get theme color
   */
  getThemeColor(): void {
    const themes = THEME_LIST;
    themes.forEach((item: any) => {
      if (item.id === UserModule.theme) {
        this.activeColor = item.colorVlue;
      }
    });
  }

  /**
   * Handle value changes in the input form
   */
  formValuesChanged(): void {
    this.isformValuesChanged = true;
    this.checkAllKpiAreUsed();
  }

  /**
   * Show dialog to return back
   */
  showBackDialog(): void {
    if (this.isformValuesChanged) {
      this.isQuitEditingTemplateModalVisible = true;
    } else {
      (this.$refs.baseCardRef as BaseCard).goBack();
    }
  }

  /**
   * Cancel event from dialog to quit editing template
   */
  cancelQuitEditingTemplateModal(): void {
    this.isQuitEditingTemplateModalVisible = false;
  }

  /**
   * Cancel editing template
   */
  handleQuitEditingTemplate(): void {
    this.isQuitEditingTemplateModalVisible = false;
    (this.$refs.baseCardRef as BaseCard).goBack();
  }

  /**
   * Handle create or save
   */
  handleCreateAndSave(): void {
    (this.$refs.reportTemplateRef as any).validate(async (valid: any) => {
      if (valid) {
        this.reportTemplateForm.allowedSubscriberRoles = [];

        this.reportTemplateForm.subscriberRoles = (
          this.$refs.reportTemplateRolesRef as any
        ).getCheckedItems();
        if (this.reportTemplateForm.subscriberRoles[0] === 'All') {
          this.originRoles.forEach((role: any) => {
            this.reportTemplateForm.allowedSubscriberRoles.push(role.code);
          });
        } else {
          this.reportTemplateForm.allowedSubscriberRoles =
            this.reportTemplateForm.subscriberRoles;
        }

        this.reportTemplateForm.name = this.reportTemplateForm.name.trim();
        this.reportTemplateForm.charts.forEach((chart: any, index: number) => {
          chart.sequence = index;
        });

        // KPIs
        let tempReportKpis: any = [];
        if (this.reportTemplateForm.tempKpis[0].code === allKpi.code) {
          // Kpi selects 'all'
          this.originKpis.forEach((item: any) => {
            if (item.assetType === this.reportTemplateForm.assetType) {
              tempReportKpis = item.kpis;
              return;
            }
          });
        } else {
          // kpi selects others
          let tempKpisMap: Map<string, any> = new Map<string, any>();

          this.reportTemplateForm.tempKpis.forEach((kpi: any) => {
            if (tempKpisMap.has(kpi.code)) {
              tempKpisMap.get(kpi.code).method.push(kpi.method);
            } else {
              let unitAndMethod = {
                unit: kpi.unit,
                method: [kpi.method],
              };
              tempKpisMap.set(kpi.code, unitAndMethod);
            }
          });

          for (let [key, value] of tempKpisMap) {
            tempReportKpis.push({
              code: key,
              unit: value.unit,
              statisticsMethods: value.method,
            });
          }
        }

        this.reportTemplateForm.kpis = tempReportKpis;

        if (this.reportTemplateId) {
          // Update report template

          await updateReportTemplate(
            this.reportTemplateId,
            this.reportTemplateForm
          ).then((res) => {
            this.errorInfos = [];
            this.$router.push('/report/index?activeName=reportTemplate');
            if (res.code === 200) {
              promptSuccessBox(this.$t('common.save') as string);
            } else {
              customFailedMessage(
                this.$tc(`report.${res.data.errors[0].code}`)
              );
            }
          });
        } else {
          // Create new report template

          await createReportTemplate(this.reportTemplateForm).then((res) => {
            if (res.code === 200) {
              this.errorInfos = [];
              this.$router.push('index?activeName=reportTemplate');
              promptSuccessBox(this.$t('common.create') as string);
            } else if (res.code === 400) {
              if (
                res.data.errors.some(
                  (error: any) =>
                    error.field === 'ApiFieldReportTemplateName' &&
                    error.code === 'ApiErrorFieldDuplicate'
                )
              ) {
                customFailedMessage(
                  this.$t('report.duplicatedReportTemplateName') as string
                );
                this.errorInfos = [...res.data.errors[0]];
                return;
              }
              promptFailedBox(this.$t('common.creation') as string);
              this.errorInfos = [...res.data.errors[0]];
            }
          });
        }
      }
    });
  }
}
