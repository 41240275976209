
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';

@Component({
  name: 'SubscriptionPackagesTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() packageTypes!: any;
  @Prop() addBtnName!: string;
  @Prop() addPagePath!: string;
  @Prop() viewPagePath!: string;
  @Prop() searchContent!: string;
  @Prop() commonSelectA!: string;
  @Prop() commonSelectB!: string;
  @Prop() commonSelectC!: string;
  @Prop({ default: false }) selectDateFlag!: boolean;
  @Prop({ default: true }) addBtn!: boolean;

  options = [
    {
      value: 'Option1',
      label: 'Option1',
    },
    {
      value: 'Option2',
      label: 'Option2',
    },
    {
      value: 'Option3',
      label: 'Option3',
    },
    {
      value: 'Option4',
      label: 'Option4',
    },
    {
      value: 'Option5',
      label: 'Option5',
    },
  ];

  value = '';
  vehicleQuery = '';

  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  /** Local variables */
  selectedPackage = '';
  searchInputValue: string = '';

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  handleRowClick(row: any, column: any, event: any) {
    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + row.id);
    }
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  mounted() {
    this.columnDrop();
  }

  addNewPackages() {
    this.$router.push('/subscription-packages/create-package');
  }

  @Watch('searchInputValue')
  handleSearchField(value: any) {
    if (!this.searchInputValue && !this.selectedPackage) {
      this.selectedPackage = '';
      this.$emit('search-filter-pagination', 1, this.pageSize, null, null);
    }
  }

  handleSearchFilterAndPagination(val: any) {
    let page = 1;
    if (val.page) page = val.page;
    let searchValue: any = this.searchInputValue;

    if (
      ['^', '\\'].includes(searchValue) ||
      searchValue.indexOf('^') > -1 ||
      searchValue.indexOf('\\') > -1
    ) {
      this.searchInputValue = '';
      return;
    }

    if (['&', '#'].includes(searchValue))
      searchValue = encodeURIComponent(searchValue);
    if (!searchValue && !this.selectedPackage)
      this.$emit('search-filter-pagination', page, this.pageSize, null, null);
    if (searchValue && !this.selectedPackage)
      this.$emit(
        'search-filter-pagination',
        page,
        this.pageSize,
        searchValue,
        null
      );
    if (!searchValue && this.selectedPackage)
      this.$emit(
        'search-filter-pagination',
        page,
        this.pageSize,
        null,
        this.selectedPackage
      );
    if (searchValue && this.selectedPackage)
      this.$emit(
        'search-filter-pagination',
        page,
        this.pageSize,
        searchValue,
        this.selectedPackage
      );
  }
}
