import { KpiDataField, KpiDataValue } from '@/api/assets';

export function generateFleetPerformanceChartData({
  responseToBeProcessed,
  timePeriod,
  numberOfUnitsInOneDay,
  showAll = false,
}: {
  responseToBeProcessed: KpiDataField[];
  timePeriod: number;
  numberOfUnitsInOneDay: number;
  showAll?: boolean;
}): (string | null | undefined)[][] {
  const tripPayloadPerHour = responseToBeProcessed
    .flatMap((kpiResponse: KpiDataField) => kpiResponse.values)
    .map((value: KpiDataValue) => [
      value.id,
      (Number(value.v) / (timePeriod * numberOfUnitsInOneDay)).toFixed(2),
    ]);
  const sortedFleetPerformance =
    sortFleetPerformanceFromLowToHigh(tripPayloadPerHour);

  const fleetPerformanceResult =
    showAll || sortedFleetPerformance.length <= 10
      ? sortedFleetPerformance
      : [
          ...sortedFleetPerformance.slice(0, 5),
          ...sortedFleetPerformance.slice(
            sortedFleetPerformance.length - 5,
            sortedFleetPerformance.length
          ),
        ];

  return fleetPerformanceResult;
}

/**
 * Calculate fleet performance average value
 * @param array
 */
export function getFleetPerformanceAverageValue(
  array: (string | null | undefined)[][]
): number {
  let values = array.map((o: any) => Number(o[1]));
  return Number(
    (
      values.reduce((partialSum, a) => partialSum + a, 0) / values.length
    ).toFixed(2)
  );
}

/**
 * Sort fleet performance from low to high
 * @param assetsToBeSort
 * @returns
 */
export function sortFleetPerformanceFromLowToHigh(
  assetsToBeSort: (string | null | undefined)[][]
): (string | null | undefined)[][] {
  return assetsToBeSort.sort(
    (a: (string | null | undefined)[], b: (string | null | undefined)[]) =>
      Number(a[1]) > Number(b[1]) ? 1 : -1
  );
}
