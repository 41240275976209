
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import { getUserById, updateUser } from '@/api/users';
import { UserModule } from '@/store/modules/user';
import CusFormItem from '@/components/form/CusFormItem.vue';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CommFormInput from '@/components/form/CommFormInput.vue';
import { PageModule } from '@/store/modules/page';
import { computed, ref } from 'vue';
import { ErrorType } from '@/api/types';

interface UserForm {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  note: string;
}

@Component({
  name: 'UserProfile',
  components: {
    'cus-form-item': CusFormItem,
    'comm-form-input': CommFormInput,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  userId = UserModule.id;
  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  nameRegex = /^\S+/;
  userInfo: any = {};
  userSettingForm: UserForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    note: '',
  };
  userSettingsIsLoading: boolean = false;
  saveButtonIsDisabled: boolean = false;
  initialUserSettingsForm: UserForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    note: '',
  };

  /**
   * Check changes to input form compared with initial one to modify the state of the save button
   */
  @Watch('userSettingForm', { deep: true })
  handleUserInputChange() {
    this.saveButtonIsDisabled = !Object.keys(this.userSettingForm).some(
      (field) =>
        this.userSettingForm[field as keyof UserForm] !==
        this.initialUserSettingsForm[field as keyof UserForm]
    );
  }

  get inlineMsg() {
    return false;
  }

  get errNameInfo() {
    let errInfo: string = '';

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldUnique'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  validateName1 = (rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(new Error(`${this.$t('userSetting.tipName1Form')}`));
    } else {
      callback();
    }
  };

  validateName0 = (rule: any, value: any, callback: any) => {
    if (value && (!this.nameRegex.test(value) || value.length > 100)) {
      callback(new Error(`${this.$t('userSetting.tipName0Form')}`));
    } else {
      callback();
    }
  };

  get rules() {
    const tmpRules = {
      username: [
        {
          required: true,
          message: this.$t('userSetting.tipInputName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName1, trigger: 'change' },
      ],
      firstName: [{ validator: this.validateName0, trigger: 'change' }],
      lastName: [{ validator: this.validateName0, trigger: 'change' }],
      mobilePhone: [
        // { pattern: /^1[3-9]\d{9}$/, message: this.$t('customerModule.tipInputCorrectPhone') }
        {
          pattern: /^[, 0-9-#+]{1,20}$/,
          message: this.$t('customerModule.tipInputCorrectPhone'),
        },
      ],
    };

    return tmpRules;
  }

  handleInput() {
    this.errorInfos = [];
  }

  saveUserSetting() {
    (this.$refs.userSettingForm as any).validate((valid: any) => {
      if (valid) {
        updateUser(UserModule.id, this.userSettingForm).then((res) => {
          if (res.code === 200) {
            UserModule.UpdateUserName(this.userSettingForm.username);
            promptSuccessBox(this.$t('common.save') as string);

            if (typeof res.data.errors == 'undefined') {
              this.errorInfos = [];
            }
          } else if (res.code === 400) {
            this.errorInfos = res.data.errors;
            promptFailedBox(this.$t('common.save') as string);
          }
        });
      } else {
      }
    });
  }

  /**
   * Fetch user settings by id
   * - save initial fetched state for check changed in input form to disabled/enable save button
   */
  async fetch() {
    try {
      this.userSettingsIsLoading = true;
      const res = await getUserById(UserModule.id);
      let userForm: UserForm = {
        username: res.data.username ?? '',
        firstName: res.data.firstName ?? '',
        lastName: res.data.lastName ?? '',
        email: res.data.email ?? '',
        mobilePhone: res.data.mobilePhone ?? '',
        note: res.data.note ?? '',
      };

      this.userSettingForm = userForm;
      Object.assign(this.initialUserSettingsForm, this.userSettingForm);
    } catch (error) {
      console.log(error);
    } finally {
      this.userSettingsIsLoading = false;
    }
  }

  created() {
    this.fetch();
  }
}
