
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';
import { SUBSCRIPTION_MANAGEMENT_ASSETS_COLS } from '@/utils/workData/subscriptionManagement';
import { SUBSCRIPTION_MANAGEMENT_STATUS } from '@/utils/workData/lookuptable';
import moment from 'moment';

@Component({
  name: 'SubscriptionManagementTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() pageTotal!: number;
  @Prop() statisticsTips!: string;
  @Prop() createPagePath!: string;
  @Prop() createBtnName!: string;
  @Prop() viewPagePath!: string;
  @Prop({ default: true }) showTableHeaderOptions!: boolean;
  @Prop({ default: false }) isHeaderActionVisible!: boolean;

  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  subscriptionManagementAssetCols: any = SUBSCRIPTION_MANAGEMENT_ASSETS_COLS;
  subsMgmtStatus: any = SUBSCRIPTION_MANAGEMENT_STATUS;
  moment = moment;
  expendRowKeys: any = [];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }
  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  getRowKeys(row: any) {
    return row.id;
  }

  handleSortChange(val: any) {
    this.$emit('handle-sort-change', val.prop, val.order);
  }

  handleRowClick(row: any, column: any, event: any) {
    // Delete expanded element if exist in DOM
    let expandedTr = document.getElementsByClassName('el-table__expanded-cell');
    if (expandedTr[0]) expandedTr[0].remove();
    // Delete expanded icon class if exist in DOM
    let expandedIcon = document.getElementsByClassName(
      'el-table__expand-icon--expanded'
    );
    if (expandedIcon && expandedIcon[0]) expandedIcon[0].classList.remove();
    // Add id for new row clicked -> expand the row
    this.expendRowKeys = [];
    this.expendRowKeys.push(row.id);
  }

  handleActionBtnClick(actionName: any, rowInfo: any) {
    this.$emit(actionName, rowInfo);
  }

  getPageList(val: any) {
    this.$emit('handle-pagination', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  mounted() {
    this.columnDrop();
  }

  /**
   * Connection asset from table
   */
  handleConnectBtnClick(rowInfo: any) {
    this.$router.push('/asset-mgmt/connect/edit/' + rowInfo.id);
  }

  /**
   * Handle Renew btn click
   */
  handleRenewBtnClick(row: any) {
    this.$emit('handle-renew-btn-clicked', 'renew', row);
  }

  /**
   * Handle Auto renew btn click
   */
  handleTerminateBtnClick(row: any) {
    this.$emit('handle-terminate-btn-clicked', 'terminate', row);
  }
}
