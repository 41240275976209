
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { TemplateResponse } from '@/api/report';
import TimeSelect from '@/components/form/TimeSelect.vue';
import { KpisDataPayload } from '@/utils/kpidata';
import {
  DEFAULT_DATE_RANGE,
  getRangePeriod,
  REPORTDATE_FORMAT,
} from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import {
  ASSET_SCOPE_LIST,
  ASSET_SCOPE_SINGLE_ASSET,
} from '@/utils/workData/reportMgmt';

import { getKpisData } from '../helpers/getKpisData';
import { substractDaysFromData } from '../helpers/substractDaysFromData';
import ExportReportModalVue from './components/ExportReportModal.vue';

interface GENERATE_REPORT {
  reportScope: string;
  reportScopeValue: string;
  startDate?: string;
  endDate?: string;
}

@Component({
  name: 'ViewReportHeader',
  components: {
    TimeSelect,
    ExportReportModalVue,
  },
})
export default class extends Vue {
  @Prop({ required: true }) scope!: string;
  @Prop({ required: true }) scopeValue!: string;
  @Prop({ required: true }) startDate!: string;
  @Prop({ required: true }) endDate!: string;
  @Prop() reportScopedValueList!: any;
  @Prop({ required: true }) template!: TemplateResponse;
  @Prop({ default: false }) isGeneratedReportVisible!: boolean;

  assetScope = ASSET_SCOPE_LIST;
  dateRange = DEFAULT_DATE_RANGE;

  generateReportFilter: GENERATE_REPORT = {
    reportScope: '',
    reportScopeValue: '',
    startDate: '',
    endDate: '',
  };
  isExportReportModalVisible: boolean = false;
  kpisData: KpisDataPayload = {} as KpisDataPayload;

  mounted() {
    this.handleGenerateReport();
  }

  /**
   * Watch over report scope field and when is cleared triggered generate report event to parent
   */
  @Watch('generateReportFilter.reportScope')
  handleReportScopeInputChange() {
    if (!Boolean(this.generateReportFilter.reportScope)) {
      this.handleGenerateReport();
    }
  }

  /**
   * Watch over report scope value field and when is cleared triggered generate report event to parent
   */
  @Watch('generateReportFilter.reportScopeValue')
  handleReportScopeValueChange() {
    if (!Boolean(this.generateReportFilter.reportScopeValue)) {
      this.handleGenerateReport();
    }
  }

  /**
   * Watch over report time range field and when is changed triggered generate report event to parent
   * To be triggered only when generated report is visible
   */
  @Watch('dateRange', { deep: true })
  handleReportTimeRangeChange() {
    if (!this.isGeneratedReportVisible) {
      return;
    }
    this.isGeneratedReportVisible = false;
    this.handleGenerateReport();
  }

  /**
   * Handle report scope change
   */
  handleReportScopeChange(): void {
    this.$emit('asset-scope-changed', this.generateReportFilter.reportScope);
    this.generateReportFilter.reportScopeValue = '';
  }

  /**
   * Handle report scope value change
   */
  handleSelectedReportScopeValue(): void {
    this.$emit(
      'report-scope-value-changed',
      this.generateReportFilter.reportScopeValue
    );
  }

  /**
   * Handle date range change
   * To be triggered only when generated report is already visible
   * @param dateRange
   */
  dateRangeChange(dateRange: DateRange): void {
    this.dateRange = dateRange;
    this.selectedDays(getRangePeriod(dateRange));
    this.generateReportFilter.startDate = this.dateRange.start;
    this.generateReportFilter.endDate = this.dateRange.endExclusive;
    if (this.isGeneratedReportVisible) {
      this.handleGenerateReport();
    }
  }

  selectedDays(days: number | string) {
    const daysString = days.toString();

    if (isNaN(parseInt(daysString))) {
      this.$emit('selected-days');
      return;
    }

    this.$emit('selected-days', daysString);
  }

  /**
   * Handle generate report event
   */
  handleGenerateReport(): void {
    this.$emit('generate-report', this.generateReportFilter);
  }

  /**
   * Handle subscribe modal visible event
   */
  handleSubscribeModalVisible(): void {
    this.$emit('subscribe-modal-visible-event', true);
  }

  /**
   * Check if report scope field has data
   */
  get reportScopeHasData(): boolean {
    return !!this.generateReportFilter.reportScope;
  }

  /**
   * Check if report scope value field has data
   */
  get reportScopeVlueHasData(): boolean {
    return !!this.generateReportFilter.reportScopeValue;
  }

  /**
   * Check if there are all filters checked an can generate report
   */
  get canGenerateReport(): boolean {
    return (
      Boolean(this.generateReportFilter.reportScope) &&
      Boolean(this.generateReportFilter.reportScopeValue) &&
      Boolean(this.dateRange.start) &&
      Boolean(this.dateRange.endExclusive)
    );
  }

  /**
   * Get report scope value placeholder
   */
  get reportReportValuePlaceholder(): string {
    return this.generateReportFilter?.reportScope == ASSET_SCOPE_SINGLE_ASSET
      ? this.$tc('report.selectAsset')
      : this.$tc('report.selectOrganization');
  }

  exportReport(): void {
    this.isExportReportModalVisible = true;
    this.getReportData();
  }

  getReportData(): void {
    try {
      this.getKpisDataResponse().then((data) => {
        this.kpisData = data;
      });
    } catch (error) {
    } finally {
    }
  }

  /**
   * Fetch kpis data remotly from the API
   */
  async getKpisDataResponse(): Promise<KpisDataPayload> {
    return await getKpisData({
      assetScope: this.scope,
      template: this.template,
      assetId: this.scopeValue,
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  formatDateForReportTimestamp(date: string): string {
    return moment(date).format(REPORTDATE_FORMAT);
  }

  substractData(date: string): string {
    return substractDaysFromData({
      dateToBeSubstracted: date,
      dateFormat: REPORTDATE_FORMAT,
    });
  }
}
