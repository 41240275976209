
import TripTimeline from '@/components/trip/TripTimeline.vue';
import FancyInfo from '@/views/assets/components/FancyInfo.vue';
import moment from 'moment';

import { Component, Vue, Inject } from 'vue-property-decorator';

import {
  GENERAL_QUERY_OPERATORS,
  TripStatus,
  TripSubActivity,
  KPI_UNIT,
} from '@/utils/workData/lookuptable';
import { getTrips, TripResponse } from '@/api/trip';
import TripLegend from '@/components/trip/TripLegend.vue';
import { formatTimer } from '@/utils/misc';
import { formatDuration, formatTime, LOCALDATE_FORMAT } from '@/utils/time';
import { unitConverterUtils } from '@/utils/unitConvertUtil';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';

@Component({
  name: 'AssetTripLog',
  components: {
    WidgetCard,
    TripLegend,
    FancyInfo,
    TripTimeline,
  },
})
export default class extends Vue {
  @Inject() context!: Ref<ActiveContext>;

  trip: TripResponse | null = null;

  get properties(): Record<
    string,
    { value: string | number | boolean; unit: string }
  > {
    if (!this.trip || !this.trip.propertyDetails || !this.trip.propertyValues) {
      return {};
    }

    return this.trip.propertyDetails.reduce((acc, cur) => {
      const value =
        this.trip!.propertyValues[
          cur.code as keyof typeof this.trip.propertyValues
        ];
      if (value === null || value === undefined) {
        return acc;
      }

      return {
        ...acc,
        [cur.code]: {
          value: value,
          unit: cur.unit,
        },
      };
    }, {});
  }

  get start() {
    return formatTimer(moment(this.trip?.startPoint.timestamp), 'datetime');
  }

  get end() {
    return formatTimer(moment(this.trip?.endPoint.timestamp), 'datetime');
  }

  get time() {
    if (!this.trip) {
      return;
    }

    return formatDuration(
      this.trip.endPoint.timestamp,
      this.trip.startPoint.timestamp
    );
  }

  get tippingTime() {
    if (!this.trip) {
      return;
    }

    const activities = [...this.trip.tripSubActivities].sort((a, b) => {
      return (
        new Date(a.subActivityStartTimeStamp).getTime() -
        new Date(b.subActivityStartTimeStamp).getTime()
      );
    });

    const time = activities.reduce((acc, cur, i, arr) => {
      if (cur.subActivityKind !== TripSubActivity.Tipping) {
        return acc;
      }

      const next = arr[i + 1];
      const end = moment(
        next?.subActivityStartTimeStamp ?? this.trip?.endPoint.timestamp
      );
      const duration = moment.duration(end.diff(cur.subActivityStartTimeStamp));

      return acc + duration.asSeconds();
    }, 0);

    if (!time) {
      return;
    }

    return formatTime(time);
  }

  get timelineTrip() {
    if (!this.trip) {
      return;
    }

    return {
      startTime: new Date(this.trip.startPoint.timestamp),
      endTime: new Date(this.trip.endPoint.timestamp),
      summary: this.trip.tripSubActivities,
      events: this.trip.events,
    };
  }

  /**
   * View trip log more assets details by redirecting on trip live that will query trips only from last trip end date and -7 days as range
   */
  viewDetails(): void {
    if (this.trip) {
      let finalRoute = `/trip/index?assetType=${this.trip.assetType}&asset=${this.trip.companyAssetId}`;
      const tripEndDate = moment(this.trip.endPoint.timestamp).format(
        LOCALDATE_FORMAT
      );
      if (tripEndDate) finalRoute += `&tripEndDate=${tripEndDate}`;
      this.$router.push(finalRoute);
    }
  }

  created() {
    this.context = useActiveContext();
    this.getData();
  }

  async getData() {
    const {
      data: { trips },
    } = await this.getTrips();

    this.trip = trips[0];
  }

  getTrips() {
    return getTrips(
      {
        filters: [
          {
            name: 'assetId',
            operator: GENERAL_QUERY_OPERATORS.In,
            value: [this.$route.params.id],
          },
          {
            name: 'tripStatus',
            operator: GENERAL_QUERY_OPERATORS.Equal,
            value: [TripStatus.Completed],
          },
        ],
        sorters: [
          {
            field: 'startPoint.timestamp',
            order: 'DESC',
          },
        ],
        pagination: {
          page: '1',
          size: '1',
        },
      },
      unref(this.context)
    );
  }

  getConvertedProperty(property: string) {
    const prop = this.properties[property];

    if (!prop) {
      return `${this.$t('common.noData')}`;
    }

    // Property values are currently returned as strings from the backend, even if they are numbers.
    // So transparently parse them back into a number here for now.
    if (typeof prop.value !== 'number' && typeof prop.value !== 'string') {
      throw new Error(`invalid value for property '${property}'`);
    }

    const value =
      typeof prop.value === 'number' ? prop.value : parseFloat(prop.value);

    return `${unitConverterUtils.convertUnit(
      value,
      prop.unit as KPI_UNIT,
      KPI_UNIT.MetricTonne
    )} ${this.$t(KPI_UNIT.MetricTonne)}`;
  }
}
