
import { Component, Inject, Vue } from 'vue-property-decorator';
import { customFailedMessage } from '@/utils/prompt';
import UtilTable from '@/components/table/UtilTable.vue';
import { TableColumn } from '@/utils/types/columnCustomizationTypes';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';
import { getEvents, Event } from '@/api/event';
import { OPERATION_LOG_TABLE_CUSTOMIZATION } from '@/utils/workData/tableCustomization';
import { COMPANY_TYPE, UI_COLUMN_CODES } from '@/utils/workData/lookuptable';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { RouteAssetItem, ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import EventsExpanded from '@/widgets/home/expanded/EventsExpanded.vue';
import { Ref, unref } from 'vue';
import {
  ActiveContext,
  LoggedInUserRef,
  useActiveContext,
  useLoggedInUser,
} from '@/composables/context';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'Events',
  components: {
    EventsExpanded,
    WidgetCard,
    UtilTable,
    EventDetailsModal,
  },
})
export default class extends Vue {
  @Inject() expanded!: boolean;

  cols: TableColumn[] = [];
  eventsIsLoading: boolean = false;
  tableCustomization = OPERATION_LOG_TABLE_CUSTOMIZATION;
  tableList: Event[] = [];
  isEventDetailsModalVisible: boolean = false;
  selectedEventId: string = '';
  assetRelatedData: RouteAssetItem | null = null;
  sort: { field: string; order: string } | null = null;

  get detail() {
    return this.$route.name?.includes('Info') ?? false;
  }

  get rows() {
    if (!this.sort) {
      return this.tableList;
    }

    const { field, order } = this.sort;

    return [...this.tableList].sort((a, b) => {
      let result = String(a[field as keyof Event]).localeCompare(
        String(b[field as keyof Event])
      );

      if (order === 'desc') {
        result = -result;
      }

      return result;
    });
  }

  context!: Ref<ActiveContext>;
  loggedInUser!: LoggedInUserRef;

  created() {
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();
  }

  mounted() {
    if (this.$route.name) {
      this.assetRelatedData = ROUTE_ASSET_MAP.get(this.$route.name) || null;
    }

    this.cols = [
      this.getColumn(UI_COLUMN_CODES.UICOL_OPERATION_LOG_ICON, 'severityName'),
      ...(this.detail
        ? []
        : [
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_ASSET,
              'companyAssetId'
            ),
            this.getColumn(
              UI_COLUMN_CODES.UICOL_OPERATION_LOG_ORGANIZATION,
              'organizationName'
            ),
          ]),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_TIMESTAMP,
        'timestamp'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE,
        'eventTypeCategoryName'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_NAME,
        'eventName'
      ),
      this.getColumn(
        UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_DESCRIPTION,
        'eventDescription'
      ),
    ];

    this.getData();
  }

  async getData() {
    try {
      this.eventsIsLoading = true;
      const eventsResponse = await this.fetchEvents();
      this.tableList = eventsResponse.data.events;
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('operationLog.dataFetchError') as string);
    } finally {
      this.eventsIsLoading = false;
    }
  }

  private async fetchEvents() {
    const loggedInUser = unref(this.loggedInUser);
    const queryParameter: QueryParameter = {
      filters: this.getFilters(),
      sorters: [
        {
          field: 'timestamp',
          order: SorterOrder.DESC,
        },
      ],
      pagination: {
        page: 1,
        size: this.assetRelatedData ? 50 : 20,
      },
      timezone:
        loggedInUser!.companyType === COMPANY_TYPE.Helpdesk
          ? unref(this.context)?.primaryOrgTimeZone
          : UserModule.timeZone,
    };
    return loggedInUser && loggedInUser.companyType === COMPANY_TYPE.Helpdesk
      ? await getEvents(queryParameter, unref(this.context))
      : await getEvents(queryParameter);
  }

  getFilters(): Filter[] {
    if (this.detail) {
      return [
        {
          name: 'assetId',
          operator: FilterOperator.EQUAL,
          value: [this.$route.params.id],
        },
        {
          name: 'i18nCode',
          operator: FilterOperator.EQUAL,
          value: [UserModule.i18nCode],
        },
      ];
    }

    const orgIds = unref(this.context).organizationIds;
    return [
      ...(!orgIds
        ? []
        : [
            {
              name: 'organizationId',
              operator: FilterOperator.IN,
              value: orgIds,
            },
          ]),
      ...(!this.assetRelatedData
        ? []
        : [
            {
              name: 'assetTypeCode',
              operator: FilterOperator.EQUAL,
              value: [this.assetRelatedData.assetTypeCode],
            },
          ]),
      ...(!UserModule.i18nCode
        ? []
        : [
            {
              name: 'i18nCode',
              operator: FilterOperator.EQUAL,
              value: [UserModule.i18nCode],
            },
          ]),
    ];
  }

  rowClicked(row: any) {
    this.selectedEventId = row.id;
    this.isEventDetailsModalVisible = true;
  }

  handleSort(field: string, order: string) {
    this.sort = order ? { field, order } : null;
  }

  handleQuitEventDetailsDialog() {
    this.isEventDetailsModalVisible = false;
  }

  private getColumn(code: string, propertyName: string): TableColumn {
    return {
      label: this.$t(`operationLog.${code}`) as string,
      code: code,
      prop: propertyName,
      required: true,
      visible: true,
      order: 0,
      kpiUnitCode: '',
      isKpiData: false,
    };
  }
}
