
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { ACTIVATION_STATUS_LIST } from '@/utils/workData/lookuptable';

@Component({
  name: 'UserDetailPage',
  components: {
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userInfo!: any;

  activationStatus: any = [];

  @Watch('userInfo', { deep: true })
  handleLookupTypes(newValue: any) {
    if (newValue) {
      this.convert();
    }
    return newValue;
  }

  convert() {
    if (this.userInfo.activationStatus) {
      this.activationStatus.push(...ACTIVATION_STATUS_LIST);
      this.activationStatus = this.activationStatus.filter((item: any) => {
        this.userInfo.activationStatus.includes(item.id);
      });
    }

    this.activationStatus = ACTIVATION_STATUS_LIST.filter(
      (item) => item.id == this.userInfo.activationStatus
    )[0].id;
  }
}
