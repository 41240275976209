
import {
  getAssetOnBoardingById,
  getProductModelsByCustomParams,
} from '@/api/assetsMgmt';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import { ErrorType } from '@/api/types';
import CusFormItem from '@/components/form/CusFormItem.vue';
import i18n from '@/lang';
import { disabledFutureDate } from '@/utils/date';
import { customFailedMessage } from '@/utils/prompt';
import {
  getAllServiceRegionsList,
  ServiceRegion,
} from '@/utils/serviceRegions';
import {
  AssetType,
  ASSET_TYPE_LIST,
  DEPLOYMENT_REGION_LIST,
  ERROR_CODE_LIST,
  PRODUCT_MODEL_STATUS_ENUM,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { formatServerDate } from '../helper';
import { Asset, ProductModelNumber, SimpleProductModel } from './models/asset';

@Component({
  name: 'registerForm',
  components: { CusFormItem },
})
export default class extends Vue {
  @Prop({ required: true }) registerAssetForm!: any;
  @Prop({ required: false, default: false }) validateFieldInputs!: boolean;
  @Prop({ required: false, default: false }) isRegisterFormValid!: boolean;
  @Prop({ required: false, default: false }) editAssetId!: string;

  /** Local variables */
  assetList = ASSET_TYPE_LIST;
  assetTypeList = AssetType;
  prodModelList: ProductModelNumber[] | null = [];
  modelNumbers: SimpleProductModel[] | null = [];
  deploymentRegionList = DEPLOYMENT_REGION_LIST;
  serviceRegionList: ServiceRegion[] = [];
  productModelStatusEnum: typeof PRODUCT_MODEL_STATUS_ENUM =
    PRODUCT_MODEL_STATUS_ENUM;
  timezones: TimezoneResponse[] = [];
  page: string = '1';
  isEditAssetLoading: boolean = false;
  pageSize: string = '10000';
  defaultSortByReference: string = 'createdOn';
  defaultSortOrder: string = 'DESC';
  defaultPageSize: string = '1000';
  defaultLifeCycle: string = 'LCL_APPROVED';
  isProductModelChanged: boolean = false;
  editAssetModelNumber: string = '';
  initialProductModel: ProductModelNumber | undefined =
    {} as ProductModelNumber;

  pickerOptions: any = {
    disabledDate: disabledFutureDate,
  };

  created() {
    getTimezones().then(({ data }) => (this.timezones = data));
    if (!!this.editAssetId) {
      this.fetchAssetOnBoardingById(true);
    }
    this.getServiceRegion();
  }

  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  /**
   * Retrieve from API the assetOnBoarding by Id data
   */
  async fetchAssetOnBoardingById(
    shouldGetModelsAndSetInitialFormValues?: boolean
  ) {
    try {
      this.isEditAssetLoading = true;
      await getAssetOnBoardingById(this.editAssetId).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.registerAssetForm.assetDataCollectionId =
            res.data.assetDataCollectionId;
          this.registerAssetForm.assetType = res.data.assetType;
          this.registerAssetForm.productModelCode = res.data.productModelCode;

          this.registerAssetForm.productModelId = '';

          this.registerAssetForm.modelNumber = res.data.productModelNumber;
          this.editAssetModelNumber = res.data.productModelNumber;
          this.registerAssetForm.deploymentRegion = res.data.deploymentRegion;
          this.registerAssetForm.serviceRegion = res.data.serviceRegion;
          this.registerAssetForm.serialNumber = res.data.serialNumber;
          this.registerAssetForm.manufactureDate = formatServerDate(
            res.data.manufactureDate
          );
          this.registerAssetForm.timeZone = res.data.timezone;
          this.registerAssetForm.controllerId = res.data.controllerId;
          if (shouldGetModelsAndSetInitialFormValues) {
            this.getProductModels(true);
          }

          //send Up the title
          const title: string = i18n
            .t('common.editInterpolated', {
              name: `${res.data.assetDataCollectionId}/${res.data.serialNumber}`,
            })
            .toString();
          this.$emit('send-title', title);
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  handleAssetTypeChange() {
    this.prodModelList = null;
    this.registerAssetForm.productModelId = '';
    this.registerAssetForm.modelNumber = '';

    if (this.registerAssetForm.assetType === '') {
      this.prodModelList = [];
      return;
    }

    this.getProductModels();
  }

  get isSerialNumberVisible() {
    return (
      this.registerAssetForm.assetType !== this.assetTypeList.TippingVehicle
    );
  }

  @Watch('validateFieldInputs')
  private handleFieldValidation() {
    (this.$refs.registerAssetForm as any).validate((isValid: any) => {
      if (
        !!this.editAssetId &&
        this.registerAssetForm.productModelId === this.initialProductModel?.code
      ) {
        this.$emit('is-valid-event', isValid, this.initialProductModel?.id);
      } else {
        this.$emit('is-valid-event', isValid);
      }
    });
  }

  generateRequestUrlWithParams({
    pageNumber,
    pageSize,
    assetType,
    sortBy,
    order,
    lifeCycle,
  }: {
    pageNumber: string;
    pageSize: string;
    assetType: string;
    sortBy: string;
    order: string;
    lifeCycle: string;
  }) {
    let finalUrl = '';
    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.defaultPageSize}`);
    finalUrl += `&lifeCycle=${lifeCycle}`;
    if (assetType) {
      finalUrl += `&assetType=${assetType}`;
    }
    sortBy && order
      ? (finalUrl += `&sortBy=${sortBy}&order=${order}`)
      : (finalUrl += `&sortBy=${this.defaultSortByReference}&order=${this.defaultSortOrder}`);
    return finalUrl;
  }

  /** Retrieve product models only with lifecycle = LCL_APPROVED */
  async getProductModels(shouldSetInitialsFormValues: boolean = false) {
    try {
      const customParams = !!this.editAssetId
        ? this.generateRequestUrlWithParams({
            pageNumber: '1',
            pageSize: this.defaultPageSize,
            assetType: this.registerAssetForm.assetType,
            sortBy: this.defaultSortByReference,
            order: this.defaultSortOrder,
            lifeCycle: this.defaultLifeCycle,
          })
        : `?assetType=${this.registerAssetForm.assetType}&lifeCycle=${this.productModelStatusEnum.LclApproved}&sortBy=createdOn&order=DESC&page=${this.page}&size=${this.pageSize}`;
      await getProductModelsByCustomParams(customParams).then((res: any) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          const response = res.data;
          const uniqueModel = response
            .map((asset: Asset) => asset.code)
            .filter(
              (code: string, index: number, self: string) =>
                self.indexOf(code) === index
            );
          const uniqueModelList: ProductModelNumber[] = [];
          for (let model of uniqueModel) {
            const newUniqueModel = {
              id: response.filter((asset: Asset) => asset.code === model)[0].id,
              code: model,
              modelNumbers: response
                .filter((asset: Asset) => asset.code === model)
                .map((filteredAsset: Asset) => {
                  return {
                    code: filteredAsset.modelNumber,
                    id: filteredAsset.id,
                  };
                }),
            };
            uniqueModelList.push(newUniqueModel);
          }
          this.prodModelList = uniqueModelList;
          if (shouldSetInitialsFormValues) {
            this.initialProductModel = uniqueModelList?.find(
              (el) => el.code === this.registerAssetForm.productModelCode
            );
            this.registerAssetForm.productModelId =
              this.initialProductModel?.code || '';
            this.getModelNumbers();
            const productModelNumbers = uniqueModelList?.find((el) =>
              el.modelNumbers.some(
                (mn: SimpleProductModel) =>
                  mn.code === this.editAssetModelNumber
              )
            );
            this.registerAssetForm.modelNumber =
              productModelNumbers?.modelNumbers.find(
                (mn: SimpleProductModel) =>
                  mn.code === this.editAssetModelNumber
              )?.code;
          }

          return;
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isEditAssetLoading = false;
    }
  }

  getModelNumbers() {
    if (
      !this.isProductModelChanged &&
      this.registerAssetForm.productModelId !== this.initialProductModel?.code
    ) {
      this.isProductModelChanged = true;
    }
    const filteredProdModelList: ProductModelNumber[] | undefined =
      this.prodModelList?.filter(
        (pm: ProductModelNumber) =>
          pm.code === this.registerAssetForm.productModelId
      );
    this.modelNumbers =
      (filteredProdModelList && filteredProdModelList[0].modelNumbers) || null;
    if (
      !!this.registerAssetForm.modelNumber &&
      !this.modelNumbers?.some(
        (el: SimpleProductModel) =>
          el.code === this.registerAssetForm.modelNumber
      )
    ) {
      this.registerAssetForm.modelNumber = '';
    }
  }

  async getServiceRegion() {
    try {
      const serviceRegions: ServiceRegion[] = await getAllServiceRegionsList();
      this.serviceRegionList = serviceRegions;
    } catch (err) {
      console.error(err);
    } finally {
    }
  }

  //form validate rules
  get rules() {
    const tmpRules = {
      assetType: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      productModelId: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      modelNumber: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      deploymentRegion: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      serviceRegion: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      serialNumber: [
        {
          required: this.isSerialNumberVisible,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      timeZone: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
    };

    return tmpRules;
  }
}
