
import { getKpiData, KpiDataRequest, KpiDataResponse } from '@/api/assets';
import { getAssetList } from '@/api/assetsAll';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { findAssets } from '@/utils/assets';
import {
  DEFAULT_DATE_RANGE,
  getPreviousRange,
  getRangePeriod,
} from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { AssetType, KPI_FIELDS, KPI_UNIT } from '@/utils/workData/lookuptable';
import GrowthPercentage from '@/views/assets/components/GrowthPercentage.vue';
import FleetTargetExpanded from '@/widgets/fleet/expanded/FleetTargetExpanded.vue';
import { Ref, unref } from 'vue';
import { Component, Inject, Vue } from 'vue-property-decorator';

interface WidgetInfo {
  percentages: {
    currentPeriod: number;
    lastPeriod: number;
  };
  unit: string;
  currentPeriod: {
    start: string;
    end: string;
  };
  lastPeriod: {
    start: string;
    end: string;
  };
  currentCycle: number;
  lastCycle: number;
  growthPercentage: {
    averageTripsPerDay: number;
    averageLoadPerTrip: number;
    averageLoadPerHour: number;
  };
  averageTripsPerDay: {
    currentCycle: number;
    lastCycle: number;
  };
  averageLoadPerTrip: {
    currentCycle: number;
    lastCycle: number;
  };
  averageLoadPerHour: {
    currentCycle: number;
    lastCycle: number;
  };
}

@Component({
  name: 'FleetTarget',
  components: {
    FleetTargetExpanded,
    TimeSelect,
    WidgetCard,
    GrowthPercentage,
  },
})
export default class extends Vue {
  @Inject() expanded!: boolean;

  /** Local variables */
  isLoading: boolean = true;
  dateRange = DEFAULT_DATE_RANGE;
  arrowList = ['positive', 'negative'];
  firstLoad: boolean = true;
  currentCycle: KpiDataResponse['details'][number]['fields'] = [];
  lastCycle: KpiDataResponse['details'][number]['fields'] = [];
  widgetInfo: WidgetInfo = {
    percentages: {
      currentPeriod: 0,
      lastPeriod: 0,
    },
    unit: '',
    currentPeriod: {
      start: '',
      end: '',
    },
    lastPeriod: {
      start: '',
      end: '',
    },
    currentCycle: 0,
    lastCycle: 0,
    growthPercentage: {
      averageTripsPerDay: 0,
      averageLoadPerTrip: 0,
      averageLoadPerHour: 0,
    },
    averageTripsPerDay: {
      currentCycle: 0,
      lastCycle: 0,
    },
    averageLoadPerTrip: {
      currentCycle: 0,
      lastCycle: 0,
    },
    averageLoadPerHour: {
      currentCycle: 0,
      lastCycle: 0,
    },
  };
  requestPayload: KpiDataRequest = {
    metadata: {
      filter: {
        assetTypeCode: AssetType.TippingVehicle,
        // organizationIds filled in later
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_TIME',
      },
    },
    details: [
      {
        entity: this.$route.params.id ? 'ENTT_ASSET' : 'ENTT_ASSET_TYPE',
        fields: [
          {
            code: KPI_FIELDS.TippingPayload,
            unit: KPI_UNIT.MetricTonne,
          },
          {
            code: KPI_FIELDS.TripCount,
            unit: KPI_UNIT.UnitCount,
          },
          {
            code: KPI_FIELDS.TippingTime,
            unit: KPI_UNIT.Second,
          },
          {
            code: KPI_FIELDS.PTOWorkingHours,
            unit: KPI_UNIT.Hour,
          },
          {
            code: KPI_FIELDS.PTOWorkingHoursTipping,
            unit: KPI_UNIT.Hour,
          },
          {
            code: KPI_FIELDS.PTOWorkingHoursFiltering,
            unit: KPI_UNIT.Hour,
          },
        ],
      },
    ],
  };
  defaultKpiUnit: string = KPI_UNIT.MetricTonne;
  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
    this.getData();
  }

  handleTimeFilter(dateRange: DateRange) {
    this.dateRange = dateRange;
    if (!this.firstLoad) {
      this.getData();
    }
  }

  async getData() {
    try {
      this.isLoading = true;
      this.requestPayload.metadata.timezone = unref(
        this.context
      ).primaryOrgTimeZone;
      this.requestPayload.metadata.selection!.startDate = this.dateRange.start;
      this.requestPayload.metadata.selection!.endDate =
        this.dateRange.endExclusive;
      this.requestPayload.metadata.filter.organizationIds = unref(
        this.context
      ).organizationIds;
      if (this.$route.params.id) {
        this.requestPayload.metadata.filter = {
          assetIds: [this.$route.params.id],
        };
      } else {
        const organizationResponse = await getAssetList(
          AssetType.TippingVehicle
        );
        const organization = findAssets(organizationResponse.data.organization);
        for (let asset of organization) {
          this.requestPayload.metadata.filter.assetIds?.push(asset.id);
        }
      }
      const responseCurrentCycle = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );
      if (
        responseCurrentCycle.hasOwnProperty('data') &&
        responseCurrentCycle.data?.details?.length > 0
      ) {
        this.currentCycle = responseCurrentCycle.data.details[0].fields;
      }

      const previousRange = getPreviousRange(this.dateRange);
      this.requestPayload.metadata.selection!.startDate = previousRange.start;
      this.requestPayload.metadata.selection!.endDate =
        previousRange.endExclusive;
      const responseLastCycle = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );
      if (
        responseLastCycle.hasOwnProperty('data') &&
        responseCurrentCycle.data?.details?.length > 0
      ) {
        this.lastCycle = responseLastCycle.data.details[0].fields;
      }
      this.populateWidgetInfo();
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
      this.firstLoad = false;
    }
  }

  populateWidgetInfo() {
    const periodInDays = getRangePeriod(this.dateRange);

    const averageTripsPerDay = {
      currentCycle:
        this.sum(this.currentCycle, KPI_FIELDS.TripCount) / periodInDays,
      lastCycle: this.sum(this.lastCycle, KPI_FIELDS.TripCount) / periodInDays,
    };

    const averageLoadPerTrip = {
      currentCycle:
        this.sum(this.currentCycle, KPI_FIELDS.TripCount) !== 0
          ? this.sum(this.currentCycle, KPI_FIELDS.TippingPayload) /
            this.sum(this.currentCycle, KPI_FIELDS.TripCount)
          : 0,
      lastCycle:
        this.sum(this.lastCycle, KPI_FIELDS.TripCount) !== 0
          ? this.sum(this.lastCycle, KPI_FIELDS.TippingPayload) /
            this.sum(this.lastCycle, KPI_FIELDS.TripCount)
          : 0,
    };

    const averageLoadPerHour = {
      currentCycle:
        this.sum(this.currentCycle, KPI_FIELDS.TippingPayload) /
        (periodInDays * 24),
      lastCycle:
        this.sum(this.lastCycle, KPI_FIELDS.TippingPayload) /
        (periodInDays * 24),
    };

    const previousRange = getPreviousRange(this.dateRange);
    this.widgetInfo = {
      percentages: this.getPayloadPercentages(
        this.sum(this.currentCycle, KPI_FIELDS.TippingPayload),
        this.sum(this.lastCycle, KPI_FIELDS.TippingPayload)
      ),
      unit:
        this.currentCycle.find((kpi) => kpi.code === KPI_FIELDS.TippingPayload)
          ?.unit ?? 'UNIT_METRIC_TONNE', // Fallback because we currently don't get unit forwarded (AHMAPP-4556)
      currentPeriod: {
        start: this.dateRange.start,
        end: this.dateRange.end,
      },
      lastPeriod: {
        start: previousRange.start,
        end: previousRange.end,
      },
      currentCycle: this.sum(this.currentCycle, KPI_FIELDS.TippingPayload) ?? 0,
      lastCycle: this.sum(this.lastCycle, KPI_FIELDS.TippingPayload) ?? 0,
      growthPercentage: {
        averageTripsPerDay: this.calculatePercentage(
          averageTripsPerDay.lastCycle,
          averageTripsPerDay.currentCycle
        ),
        averageLoadPerTrip: this.calculatePercentage(
          averageLoadPerTrip.lastCycle,
          averageLoadPerTrip.currentCycle
        ),
        averageLoadPerHour: this.calculatePercentage(
          averageLoadPerHour.lastCycle,
          averageLoadPerHour.currentCycle
        ),
      },
      averageTripsPerDay: averageTripsPerDay,
      averageLoadPerTrip: averageLoadPerTrip,
      averageLoadPerHour: averageLoadPerHour,
    };
  }

  private calculatePercentage(lastCycle: number, currentCycle: number) {
    // in case of lastCycle and currentCycle values != 0 return growthPercentage with value: calculate percentage and growth +1 for positive growth and -1 for negative growth
    // in case of lastCycle != 0 and currentCycle = 0 return growthPercentage with value: null and growth +1 for positive growth and -1 for negative growth
    if (lastCycle !== 0) {
      if (currentCycle === 0) {
        return {
          value: undefined,
          growth: Math.sign(lastCycle) * -1,
        };
      }
      return {
        value: ((currentCycle - lastCycle) / Math.abs(lastCycle)) * 100,
        growth: Math.sign(currentCycle - lastCycle),
      };
    } else if (currentCycle !== 0) {
      // return growthPercentage with value: null and growth +1 for positive growth and -1 for negative growth
      return {
        value: undefined,
        growth: Math.sign(currentCycle),
      };
    } else {
      // return growthPercentage with value: 0 and growth: 0
      return {
        value: 0,
        growth: 0,
      };
    }
  }

  private sum(
    fields: KpiDataResponse['details'][number]['fields'],
    kpiCode: string
  ): number {
    const values =
      fields
        .find((field) => field.code === kpiCode)
        ?.values.map((value) => value.v) ?? [];
    // Note: original type marks these as string, but apparently, sometimes they can also be null.
    // TODO Probably fix the type of KpiDataResponse instead!
    const total = values.reduce(
      (a: string | number | null, b: string | number | null) => {
        a ??= 0;
        b ??= 0;
        return +a + +b; // cast each to number if needed
      },
      0
    );
    return total;
  }

  private getPayloadPercentages(
    currentPeriodPayload: number,
    lastPeriodPayload: number
  ) {
    if (isNaN(currentPeriodPayload)) currentPeriodPayload = 0;
    if (isNaN(lastPeriodPayload)) lastPeriodPayload = 0;
    if (currentPeriodPayload === 0 && lastPeriodPayload === 0)
      return { currentPeriod: 0, lastPeriod: 0 };
    return currentPeriodPayload >= lastPeriodPayload
      ? {
          currentPeriod: 100,
          lastPeriod:
            (100 * Math.min(currentPeriodPayload, lastPeriodPayload)) /
            Math.max(currentPeriodPayload, lastPeriodPayload),
        }
      : {
          currentPeriod:
            (100 * Math.min(currentPeriodPayload, lastPeriodPayload)) /
            Math.max(currentPeriodPayload, lastPeriodPayload),
          lastPeriod: 100,
        };
  }

  /** Get current unit */
  get currentUnit(): string {
    return this.widgetInfo.unit ? this.widgetInfo.unit : this.defaultKpiUnit;
  }
}
