
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AssignTable from '../components/AssignTable.vue';
import {
  CONNECTED_ASSETS_COLS,
  UNASSIGNED_ASSETS_SEARCH_OPTIONS,
} from '@/utils/workData/assetsMgmt';
import { getUnassignAssetsByCustomUrl } from '@/api/assetsMgmt';
import { UserModule } from '@/store/modules/user';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import moment from 'moment';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { Ref, unref } from 'vue';
import { fetchLastCommunicationTimes } from '@/utils/lastAssetCommunicationTime';
import { AssetType } from '@/utils/workData/lookuptable';

interface TableRowData {
  id: string;
  assetDataCollectionId: string;
  assetType: AssetType;
  companyAssetId: string;
  connectionHandlerCompanyName?: string;
  controllerId?: string;
  gatewayId: string;
  installTime: string;
  lastCommunicationTime?: string;
  manufactureDate?: string;
  productModelCode: string;
  productModelNumber: string;
  serialNumber?: string;
}

@Component({
  name: 'AssignSelectTable',
  components: {
    AssignTable,
    SelectTableHeader,
  },
})
export default class extends Vue {
  /** Local variables */
  assigningAssetsTableColumn = CONNECTED_ASSETS_COLS;
  searchFieldOptions: any = UNASSIGNED_ASSETS_SEARCH_OPTIONS;
  tableList: TableRowData[] = [];
  totalUnassignedAssets: number = 0;
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  chosenItems = 0;
  isColumnSelectionVisible: boolean = false;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';
  unnasignedAssetsTableIsLoading: boolean = false;

  context!: Ref<ActiveContext>;

  created() {
    this.context = useActiveContext();
  }

  mounted() {
    this.$nextTick(() => {
      this.prepareDefaultInitialization();
    });
  }

  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchUnassignAssetsData(finalUrlParamsForSearch);
  }

  updateChosenItem(data: any) {
    this.$emit('all-chosen', data);
  }

  /**
   * Get unassigned assets list
   */
  async fetchUnassignAssetsData(customUrl: string): Promise<void> {
    try {
      this.unnasignedAssetsTableIsLoading = true;
      const res = await getUnassignAssetsByCustomUrl(customUrl);
      if (res.code === 200) {
        this.totalUnassignedAssets = res.data.total;
        const lastCommunicationTimes = await fetchLastCommunicationTimes(
          res.data.assets.map((x) => x.id),
          unref(this.context)
        );
        this.tableList = res.data.assets.map(
          (x) =>
            ({
              id: x.id,
              assetDataCollectionId: x.assetDataCollectionId,
              assetType: x.assetType as string,
              companyAssetId: x.companyAssetId,
              connectionStatus: x.connectStatus,
              controllerId: x.controllerId,
              gatewayId: x.gatewayId,
              installTime: x.installTime,
              lastCommunicationTime: lastCommunicationTimes.get(x.id),
              manufactureDate: x.manufactureDate,
              productModelCode: x.productModelCode,
              productModelNumber: x.productModelNumber,
              connectionHandlerCompanyName: x.connectionHandlerCompanyName,
              serialNumber: x.serialNumber,
              timezone: x.timezone,
            } as TableRowData)
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.unnasignedAssetsTableIsLoading = false;
    }
  }

  /** Fetch when search is triggered */
  fetchUnassignedAssetsDataBySearchParams() {
    if (this.searchParams.reference === 'serialNumber')
      this.searchParams.reference = 'assetSerialNumber';
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUnassignAssetsData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ): string {
    let finalUrl: string = '';
    let searchFieldName: any = searchParams ? searchParams.reference : null;
    let searchFieldValue: any =
      searchParams.value && searchParams.reference != 'manufactureDate'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'manufactureDate'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${searchFieldValue}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  /** Filter by sort event */
  fetchUnassignedAssetsBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUnassignAssetsData(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchUnassignedAssetsByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUnassignAssetsData(finalUrlParamsForSearch);
  }
}
