
import { getAssetsOrganizationsHierarchy } from '@/api/assets';
import { CommonResult } from '@/api/commonResult';
import {
  GeofenceResponse,
  getAllGeofencesByOrganisationId,
  LifeCycle,
} from '@/api/geofence';
import { Geofence } from '@/api/geofenceTypes';
import {
  getAssetsWithTrips,
  getTripAssetTypes,
  getTrips,
  TripProperties,
} from '@/api/trip';
import TimeSelect from '@/components/form/TimeSelect.vue';
import Title from '@/components/layout/Title.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import UtilTable from '@/components/table/UtilTable.vue';
import TripDetails from '@/components/trip/TripDetails.vue';
import TripPlayer from '@/components/trip/TripPlayer.vue';
import { ActiveContext, useActiveContext } from '@/composables/context';
import {
  FilterOperator,
  Pagination,
  QueryParameter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { ColumnCustomizationModule } from '@/store/modules/tableColumnCustomization';
import { UserModule } from '@/store/modules/user';
import { findAssets } from '@/utils/assets';
import { customFailedMessage, customWarningMessage } from '@/utils/prompt';
import { DEFAULT_DATE_RANGE, LOCALDATE_FORMAT } from '@/utils/time';
import { KpiData, TableColumn } from '@/utils/types/columnCustomizationTypes';
import { DateRange } from '@/utils/types/date';
import { Position } from '@/utils/types/geoposition';
import { SearchType, Trip, TripActivity, TripEvent } from '@/utils/types/trip';
import { unitConverterUtils } from '@/utils/unitConvertUtil';
import {
  AssetType,
  KPI_UNIT,
  TripStatus,
  TRIP_PROPERTY_VALUES,
  UI_TABLE,
} from '@/utils/workData/lookuptable';
import { TRIPS_TABLE_CUSTOMIZATION } from '@/utils/workData/tableCustomization';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface filter {
  name: string;
}

interface DropdownAsset {
  label: string;
  id: string;
  selected: boolean;
  visible: boolean;
}

@Component({
  name: 'Trip',
  components: {
    Title,
    TripPlayer,
    LeafletMap,
    UtilTable,
    TripDetails,
    TimeSelect,
  },
})
export default class extends Vue {
  /** Local variables */
  /** Table properties */
  total: number = 0;
  cols: TableColumn[] = [];
  $refs!: {
    /** References for the multi-asset filter and the leaflet map */
    assetFilterRef: any;
    tripsTableRef: any;
  };
  firstLoad = true; /** true until created => getData() is finished */
  tripTableIsLoading: boolean = false; /** true if getData() is running */
  tripPlayerMapIsLoading: boolean =
    false; /** true if selectTrips() is triggered */
  assetsLoading = true; /** select asset dropdown is loading */
  tripsTableData: Trip[] = [];
  assetType: AssetType | null = null;
  assetTypes: AssetType[] = [];
  geofences: Geofence[] = [];
  searchFieldTypes: SearchType[] = Object.values(SearchType);
  searchField: { name: SearchType; value: string } = {
    name: this.searchFieldTypes[0],
    value: '',
  };
  dateRange = DEFAULT_DATE_RANGE;
  selectedTrip: Trip | null = null;
  pagination: { page: number; size: number } = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  tripTableRendering = TRIPS_TABLE_CUSTOMIZATION;
  tripStatuses = [
    TripStatus.Underway,
    TripStatus.Completed,
    TripStatus.Incomplete,
  ];
  noAssets: boolean = false;
  sortOrder: SorterOrder = SorterOrder.DESC;
  sortField: string = 'startPoint.timestamp';
  dropdownAssetList: DropdownAsset[] =
    []; /** asset dropdown data, used for el-switch options */
  assetDropdownSearchValue: string = '';
  allDropdownAssetsSelected: boolean = false;
  assetDropdownSelecteValues: any = []; /** v-model of el-select */
  context!: Ref<ActiveContext>;

  async created(): Promise<void> {
    this.tripTableIsLoading = true;
    this.tripPlayerMapIsLoading = true;
    this.context = useActiveContext();

    /** wait for the subscriptions to be updated before retrieving the assets and their data */
    await this.getSubscriptions();
    await this.getAssetOptions();
    await this.getData();

    /**
     * only set firstLoad to false after all data has been collected
     * otherwise the form will create a rerender and extra API calls
     */
    this.firstLoad = false;

    this.trackReadAllowed = unref(this.context).claims.hasClaim(
      'AUTHRSC_PAGE_TRIP_TRACKS_READ'
    );
  }

  mounted(): void {
    getAssetsOrganizationsHierarchy(
      undefined,
      undefined,
      unref(this.context)
    ).then((data) => {
      const assets = findAssets(data.data.organization);
      const tripTypes = this.assetTypes;
      const filtered = assets.reduce((acc, cur) => {
        if (!tripTypes.includes(cur.assetType)) {
          return acc;
        }

        const type = cur.assetType as AssetType;
        const count = acc.find((v) => v.type === type)?.count ?? 0;

        return [
          ...acc.filter((v) => v.type !== type),
          { type, count: count + 1 },
        ];
      }, [] as { type: AssetType; count: number }[]);

      filtered.sort((a, b) => a.type.localeCompare(b.type));

      this.assetTypes = filtered
        .sort((a, b) => b.count - a.count)
        .map((d) => d.type);
      this.assetType = this.assetTypes[0];

      this.getAssetOptions();
    });
  }
  trackReadAllowed: boolean = false;

  /** Change which assets are displayed in the asset dropdown filter  */
  handleAssetDropdownSearch(): void {
    let query = this.assetDropdownSearchValue.toLowerCase();
    this.dropdownAssetList.forEach(
      (item: DropdownAsset) =>
        (item.visible = item.label.toLowerCase().includes(query))
    );
    this.assetDropdownSelecteValues = this.dropdownAssetList
      .filter((item: DropdownAsset) => item.selected)
      .map((item: DropdownAsset) => item.label);
  }

  /** Toggle asset dropdown select all filter */
  async toggleAllDropdownAssetsSelected(): Promise<void> {
    this.allDropdownAssetsSelected = !this.allDropdownAssetsSelected;
    this.dropdownAssetList.forEach(
      (item: DropdownAsset) => (item.selected = this.allDropdownAssetsSelected)
    );
    this.assetDropdownSelecteValues = this.dropdownAssetList
      .filter((item: DropdownAsset) => item.selected)
      .map((item: DropdownAsset) => item.label);
    await this.getData();
  }

  /** When el select changes, map changes to inside el-switches */
  async assetDropdownChanged(): Promise<void> {
    if (this.firstLoad) return;
    this.dropdownAssetList.forEach(
      (item: DropdownAsset) =>
        (item.selected = this.assetDropdownSelecteValues.includes(item.label))
    );
    this.allDropdownAssetsSelected =
      this.assetDropdownSelecteValues.length == this.dropdownAssetList.length;
    await this.getData();
  }

  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    if (sortOrder) {
      this.sortField = sortField;
      this.sortOrder =
        sortOrder.toLowerCase() === 'asc' ? SorterOrder.ASC : SorterOrder.DESC;
    } else {
      /** default of sort is none */
      this.sortOrder = SorterOrder.DESC;
      this.sortField = 'startPoint.timestamp';
    }
    !this.firstLoad && (await this.getData());
  }

  async handleAssetsFilter(asset: AssetType): Promise<void | undefined> {
    if (!asset) return;
    this.tripPlayerMapIsLoading = true;
    this.selectedTrip = null;
    try {
      this.cols = await ColumnCustomizationModule.getTableColumns({
        code: UI_TABLE.Trip,
        assetType: asset,
      });
    } catch (e) {
      customFailedMessage(
        this.$t('tableColumnCustomization.getErrorMessage') as string
      );
      return;
    }

    /** Reset default sorters */
    this.sortOrder = SorterOrder.DESC;
    this.sortField = 'startPoint.timestamp';

    /** Reset pagination */
    this.pagination.page = 1;
    this.$nextTick(() => {
      this.$refs.tripsTableRef?.setPage(1);
    });

    if (this.firstLoad) {
      return;
    }
    await this.getAssets();
    await this.getData();
  }

  handleSearchFieldFilter(): void {
    this.searchField.value = '';
    this.handleSearchFieldForm();
  }

  async handleSearchFieldForm(): Promise<void> {
    if (this.firstLoad) return;
    await this.getAssets();
    await this.getData();
  }

  async handleTimeFilter(dateRange: DateRange): Promise<void> {
    this.dateRange = dateRange;

    if (this.firstLoad) return;

    await this.getAssets();
    await this.getData();
  }

  async getAssetOptions(): Promise<void> {
    const override: AssetType | undefined = this.$route.query.assetType as
      | AssetType
      | undefined;
    const overrideAsset: string | (string | null)[] | null =
      this.$route.query.asset || null;
    const tripEndDate: string | (string | null)[] | null =
      this.$route.query.tripEndDate || null;

    /**
     * When override: need to request between
     * end: endTripDate + 1 days to have end date inclusive in range
     * start: the end inslusive - 7 days as default date time picker required behavior
     */
    if (tripEndDate) {
      this.dateRange.start = moment(tripEndDate.toString())
        .subtract(7, 'day')
        .format(LOCALDATE_FORMAT);
      this.dateRange.endExclusive = moment(tripEndDate.toString())
        .add(1, 'day')
        .format(LOCALDATE_FORMAT);
    }

    if (override && this.assetTypes.includes(override)) {
      this.assetType = override;
    }

    const assetToBeOverridden: DropdownAsset = {
      selected: true,
      label: overrideAsset as string,
      id: moment()
        .unix()
        .toString() /** this will get replaced by actual ID if getAssets is called afterwards */,
      visible: true,
    };

    if (
      overrideAsset &&
      !this.dropdownAssetList.some(
        (el) => el.label === assetToBeOverridden.label
      )
    ) {
      this.dropdownAssetList.push(assetToBeOverridden);
    }
    if (this.assetType) {
      this.handleAssetsFilter(this.assetType);
    }
  }

  async handleQueryParameters(): Promise<QueryParameter> {
    let finalQueryParameters: QueryParameter = {
      filters: [
        {
          name: 'endPoint.timestamp',
          operator: FilterOperator.BETWEEN,
          value: [this.dateRange.start, this.dateRange.endExclusive],
        },
      ],
      sorters: [
        {
          field: this.sortField,
          order: this.sortOrder,
        },
      ],
      pagination: {
        page: 1,
        size: UserModule.gridPageSize,
      },
    };

    /** Handle pagination */
    finalQueryParameters.pagination!.page = this.pagination.page;
    finalQueryParameters.pagination!.size = this.pagination.size;

    this.$nextTick(() => {
      this.$refs.tripsTableRef?.setPage(this.pagination.page);
    });

    /** Subscription asset */
    finalQueryParameters.filters!.push({
      name: 'assetType',
      operator: FilterOperator.IN,
      value: [this.assetType!],
    });

    const orgIds = unref(this.context).organizationIds;
    if (orgIds) {
      finalQueryParameters.filters!.push({
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: orgIds,
      });
    }

    /** Multiple assets */
    let selectedAssetIds: string[] = this.dropdownAssetList
      .filter((a) => a.selected)
      .map((a) => a.id);

    if (selectedAssetIds.length > 0) {
      finalQueryParameters.filters!.push({
        name: 'assetId',
        operator: FilterOperator.IN,
        value: selectedAssetIds,
      });
    }

    /** Search field */
    if (this.searchField.value !== '') {
      finalQueryParameters.filters!.push({
        name: this.searchField.name,
        operator: FilterOperator.LIKE,
        value: [this.searchField.value],
      });
    }

    return finalQueryParameters;
  }

  async prepareTripAssetsQueryParameters(): Promise<QueryParameter> {
    let queryParameters = await this.handleQueryParameters();
    const filterName = 'assetId';
    queryParameters.filters = queryParameters.filters!.filter(
      (filter: filter) => filter.name !== filterName
    );
    delete queryParameters.sorters;
    const finalPagination: Pagination = { page: 1, size: 10000 };
    queryParameters.pagination = finalPagination;
    return queryParameters;
  }

  async getAssets(): Promise<void> {
    this.assetsLoading = true;
    this.tripTableIsLoading = true;
    try {
      const res = await getAssetsWithTrips(
        await this.prepareTripAssetsQueryParameters(),
        unref(this.context)
      );

      this.dropdownAssetList = res.data.assets.map(
        (item: any): DropdownAsset => {
          return {
            id: item.assetId,
            label: item.companyAssetId,
            visible: true,
            selected: this.dropdownAssetList
              .filter((e) => e.selected)
              .map((e) => e.label)
              .includes(item.companyAssetId),
          };
        }
      );

      let assetsSelected = this.dropdownAssetList.some(
        (el: DropdownAsset) => el.selected
      );

      if (!assetsSelected) {
        this.dropdownAssetList.forEach(
          (el: DropdownAsset) => (el.selected = true)
        );
      }

      this.assetDropdownSelecteValues = this.dropdownAssetList
        .filter((item: DropdownAsset) => item.selected)
        .map((item: DropdownAsset) => item.label);
      this.allDropdownAssetsSelected =
        this.assetDropdownSelecteValues.length == this.dropdownAssetList.length;
    } catch (e) {
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      console.error(e);
    } finally {
      this.assetsLoading = false;
      this.tripTableIsLoading = false;
    }
  }

  async getSubscriptions(): Promise<void | undefined> {
    try {
      const assetTypes = await getTripAssetTypes();

      for (let s in UserModule.subscriptions) {
        let subscription =
          UserModule.subscriptions[s].subscriptionPackageAssetType;
        if (subscription && assetTypes.data.includes(subscription)) {
          this.assetTypes.push(subscription);
        }
      }

      if (this.assetTypes.length == 0) {
        this.noAssets = true;
        return;
      }

      this.assetType = this.assetTypes[0];
    } catch (e) {
      console.error(e);
    }
  }

  async getData(): Promise<KpiData[] | undefined> {
    if (this.noAssets) return;

    if (this.assetDropdownSelecteValues.length == 0) {
      this.tripsTableData = [];
      return;
    }

    try {
      this.tripTableIsLoading = true;
      const {
        data: { total, trips },
      } = await getTrips(
        await this.handleQueryParameters(),
        unref(this.context)
      );
      this.total = total;

      if (
        this.pagination.page > 1 &&
        (this.pagination.page - 1) * UserModule.gridPageSize > total
      ) {
        this.handlePage(1);
        this.$nextTick(() => {
          this.$refs.tripsTableRef?.setPage(1);
        });
      }

      let data = trips.map((trip: any) => {
        const endTime =
          trip.tripStatus === 'TRPSTAT_UNDERWAY'
            ? undefined
            : trip.endPoint?.timestamp;
        const endPosition =
          trip.tripStatus === 'TRPSTAT_UNDERWAY'
            ? ''
            : (trip.endPoint as Position);

        const kpiData = this.getKpiData(trip);
        const payload =
          this.assetType === AssetType.TippingVehicle
            ? this.convertToTons(
                kpiData.find((k: KpiData) => k.code == 'OPER.TippedPayload')
              )
            : kpiData.find(
                (k: KpiData) => k.code === TRIP_PROPERTY_VALUES.PAYLOAD
              )?.value;

        return {
          id: trip.id,
          companyAssetId: trip.companyAssetId,
          assetType: this.assetType as any,
          organizationId: trip.organizationId,
          organizationName: trip.organizationName,
          payload: payload,
          tripId: trip.tripId,
          tripStatus: trip.tripStatus as TripStatus,
          startTime: new Date(trip.startPoint.timestamp),
          startPosition: trip.startPoint as Position,
          endTime: endTime ? new Date(endTime) : undefined,
          endPosition: endPosition,
          tripTime: trip.tripTime,
          summary: trip.tripSubActivities as TripActivity[],
          events: trip.events as TripEvent[],
          kpiData: kpiData,
          timeZone:
            trip.timezone /** AHMAPP-5285 Add time conversion to the asset time zone received for start/end */,
        };
      });

      data = data.filter((t) => {
        return data.filter((t2) => t.id == t2.id).length == 1;
      });

      this.tripsTableData = data;
      /** Default selection of first item from the table */
      this.selectTrip(data[0]);
      this.$nextTick(() => {
        this.$refs.tripsTableRef?.highlightRow(data[0]);
      });
    } catch (e) {
      this.tripsTableData = [];
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      console.error(e);
    } finally {
      this.tripTableIsLoading = false;
    }
  }

  getKpiData(trip: TripProperties): KpiData[] {
    let details = trip.propertyDetails || [];
    let values = trip.propertyValues || [];

    return details.map((detail: { code: string; unit: string }) => {
      return {
        code: detail.code,
        unit: detail.unit,
        value: values[detail.code as keyof typeof values] || null,
      };
    });
  }

  handlePage(page: number): void {
    this.pagination.page = page;
    !this.firstLoad && this.getData();
  }

  async selectTrip(row: Trip): Promise<void> {
    this.geofences = [];
    this.selectedTrip = row;

    if (!this.trackReadAllowed) {
      return;
    }

    try {
      this.tripPlayerMapIsLoading = true;
      const handleGeofences = (data: CommonResult<GeofenceResponse>) => {
        this.geofences = data.data.geofences.filter(
          (g) =>
            g.lifeCycle === LifeCycle.GFNLCL_ACTIVE &&
            g.associatedAssetTypes.includes(row.assetType)
        );
      };
      await getAllGeofencesByOrganisationId(
        row.organizationId,
        unref(this.context)
      ).then(handleGeofences);
    } catch (error) {
      console.log(error);
    } finally {
      this.tripPlayerMapIsLoading = false;
    }
  }

  convertToTons(kpiData?: KpiData): {
    value: number | string;
    unit?: string;
    code?: string;
  } {
    if (!kpiData) {
      return { value: '', unit: '' };
    }

    try {
      return {
        value: unitConverterUtils.convertUnit(
          kpiData.value,
          kpiData.unit as KPI_UNIT,
          KPI_UNIT.MetricTonne
        ),
        unit: this.$t(KPI_UNIT.MetricTonne).toString(),
      };
    } catch (exception) {
      console.error(exception);
      return {
        value: kpiData.value,
        code: this.$t(kpiData.unit).toString(),
      };
    }
  }

  async onColsChange(newCols: TableColumn[]): Promise<void> {
    try {
      await ColumnCustomizationModule.saveTable({
        code: UI_TABLE.Trip,
        assetType: this.assetType as AssetType,
        columns: newCols,
      });
    } catch (e) {
      customWarningMessage(
        this.$t('tableColumnCustomization.saveErrorMessage') as string
      );
    }
  }
}
