
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentContainer from './components/ListContent.vue';
import ContentTable from './components/TableContent.vue';
import TreeTransfer from '@/components/dialog/TreeTransfer.vue';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import {
  productSystemRelease,
  productSystemReleasesByID,
  getCustomers,
  activateSystemRelease,
  SystemRelease,
} from '@/api/systemReleaseManagement';
import {
  mapFirmwareReleaseData,
  getPartFirmwareInformation,
  getProductSystemInformation,
  getTreeData,
} from '@/utils/releaseInformationUtil';
import { GENERAL_QUERY_OPERATORS } from '@/utils/workData/lookuptable';
import { customFailedMessage } from '@/utils/prompt';

@Component({
  name: 'NewRelease',
  components: {
    'content-list': ContentContainer,
    'content-table': ContentTable,
    'tree-transfer': TreeTransfer,
    'confirm-dialog': WidgetDialog,
    'set-as-default': WidgetDialog,
  },
})
export default class extends Vue {
  treeContentData: { selectedData: Array<string>; tree: any } = {
    tree: {},
    selectedData: [],
  };
  setAsDefaultDialogVisible: boolean = false;
  customers: any = null;
  treeTransferVisible: boolean = false;
  productSystemReleaseID: string = '';
  productSystemReleasesIDs = [];
  partFirmwareInformation = [[]];
  productSystemReleaaseInformation: {
    title: string;
    content: any[];
    rawContent: any;
  } = {
    title: 'releaseDetails.productSystemReleaseInfo',
    content: [],
    rawContent: {},
  };
  checked: boolean = false;
  dialogVisible: boolean = false;
  id: string = '';
  defaultRelease: SystemRelease = {
    id: '',
    assetType: '',
    defaultRelease: true,
    deploymentDurationMinutes: 0,
    manifestCreationTime: '',
    maturityLevel: '',
    numberOfLinkedCustomers: 0,
    numberOfLinkedOrganizations: 0,
    productModelCode: '',
    productModelNumber: '',
    region: '',
    releaseStatus: '',
    systemReleaseId: '',
    systemReleaseName: '',
    systemReleaseVersion: '',
  };
  productID: string = '';
  createNewReleaseData: any = {
    productSystemReleaseId: '',
    productSystemReleaseName: '',
    productSystemReleaseVersion: '',
    assetType: '',
    productModel: '',
    modelNumber: '',
    deploymentDuration: 0,
    releaseStatus: '',
    defaultRelease: true,
    noOfCustomers: 0,
    systemReleaseNote: '',
    linkedOrganizations: [
      {
        customerId: '',
        customerName: '',
        organizations: [],
        numberOfAssets: 0,
      },
    ],
    partFirmwareInformation: [
      {
        partFirmwareName: '',
        containedPartName: '',
        partFirmwareVersion: '',
        partFirmwareReleaseNote: '',
      },
    ],
    createdTime: '',
  };

  customerListLoading: boolean = false;

  async created() {
    await this.getData();
  }

  async getData() {
    try {
      const response = await productSystemRelease({
        filters: [
          {
            name: 'releaseStatus',
            operator: GENERAL_QUERY_OPERATORS.Equal,
            value: ['RELSTAT_NEW'], // only new releases can be activated
          },
        ],
        //TODO remove pagination when backe-end accepts request body with no pagination
        pagination: {
          page: '1',
          size: '1000',
        },
      });
      this.productSystemReleasesIDs = response.data.systemReleases;
    } catch (err) {
      console.log(err);
    }
  }

  @Watch('productSystemReleaseID')
  async handleIdChange() {
    if (!this.productSystemReleaseID) {
      return;
    }
    this.customerListLoading = true;
    try {
      this.customers = (
        await getCustomers({
          filters: [
            {
              name: 'systemReleaseUuid',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [this.productSystemReleaseID],
            },
            {
              name: 'linkedCustomers',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [false],
            },
          ],
          pagination: {
            page: '1',
            size: '100',
          },
        })
      ).data.customers;
      this.customerListLoading = false;
    } catch (e) {
      console.error(e);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    }
    let treeData = getTreeData(this.customers);
    this.treeContentData = {
      tree: treeData,
      selectedData: [],
    };
  }

  handleSetAsDefault(isSetAsDefault: boolean) {
    this.productID =
      this.productSystemReleaaseInformation.rawContent['systemReleaseId'];
    this.setAsDefaultDialogVisible = isSetAsDefault;
  }

  editLinkedCustomer() {
    this.treeTransferVisible = true;
  }

  async handleProductSystemReleaseSelected(id: string) {
    this.productSystemReleaaseInformation.content = [];
    this.productSystemReleaaseInformation.rawContent = {};
    this.partFirmwareInformation = [[]];
    this.id = id;
    try {
      const response = await productSystemReleasesByID(id);
      this.checked = response.data.defaultRelease == true;
      if (!this.checked) {
        const defaultReleaseResponse = await productSystemRelease({
          filters: [
            {
              name: 'productModelCode',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [response.data.productModelCode],
            },
            {
              name: 'productModelNumber',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [response.data.productModelNumber],
            },
            {
              name: 'defaultRelease',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [true],
            },
          ],
          //TODO remove pagination when backe-end accepts request body with no pagination
          pagination: {
            page: '1',
            size: '1000',
          },
        });
        this.defaultRelease = !!defaultReleaseResponse.data.systemReleases
          .length
          ? defaultReleaseResponse.data.systemReleases[0]
          : null;
      } else {
        this.defaultRelease = response.data;
      }

      mapFirmwareReleaseData(response.data);
      this.productSystemReleaaseInformation.content =
        getProductSystemInformation(response.data);
      this.productSystemReleaaseInformation.rawContent = response.data;
      this.partFirmwareInformation = getPartFirmwareInformation(
        response.data.partFirmwareRelease
      );
    } catch (err) {
      console.log(err);
    }
  }

  async createRelease() {
    if (!this.productSystemReleaseID) return;
    const { data } = await activateSystemRelease(this.productSystemReleaseID, {
      defaultRelease: this.checked,
      ...(!this.checked && {
        linkedOrganizations: this.constructLinkedCustomers(),
      }),
    });
    if (data && !data.errors) {
      this.$router.go(-1);
      return;
    }
    console.error(data?.errors);
    customFailedMessage(this.$t('common.serverError') as string);
  }

  handleDialogConfirmSetDefault() {
    this.handleCancel(false);
  }

  constructLinkedCustomers() {
    let linekdCustomers = new Array<any>();
    for (let i = 0; i < this.customers.length; i++) {
      let customer = this.customers[i];

      let organizations = this.getLinkedOrganizations(customer);
      if (
        !this.treeContentData.selectedData.includes(customer.customerId) &&
        organizations.length == 0
      )
        continue;

      linekdCustomers.push(...organizations);
    }
    return linekdCustomers;
  }

  getLinkedOrganizations(parentOrg: any): any[] {
    let linkedOrgs = [];
    if (this.treeContentData.selectedData.includes(parentOrg.id))
      linkedOrgs.push(parentOrg.id);

    let children = parentOrg.children || parentOrg.organizations;

    if (children)
      for (let i = 0; i < children.length; i++) {
        linkedOrgs.push(...this.getLinkedOrganizations(children[i]));
      }
    return linkedOrgs;
  }

  handleDialogConfirm() {
    this.$router.push(`/release/index`);
  }

  handleBack() {
    this.dialogVisible = true;
  }

  handleConfirm() {
    this.treeTransferVisible = false;
  }

  handleCancel(setAsDefault: boolean = true) {
    if (this.treeTransferVisible) this.treeTransferVisible = false;
    if (this.dialogVisible) this.dialogVisible = false;
    if (this.setAsDefaultDialogVisible) {
      setAsDefault && (this.checked = false);
      this.setAsDefaultDialogVisible = false;
    }
  }
}
