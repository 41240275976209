
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Sortable from 'sortablejs';

@Component({
  name: 'UtilTable',
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() path!: string;

  value = '';
  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }
  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  tableRowClassName(row: any, rowIndex: number) {
    if (row.row.chosen) {
      return 'choosing-row';
    }
    return '';
  }

  handleSelectionChange(val: any) {
    this.$emit('count-chosen', val.length);
    this.tableList.forEach((item: any) => {
      item.chosen = false;
    });
    val.forEach((item: any) => {
      item.chosen = true;
    });
  }

  handleRowClick(row: any, column: any, event: any) {
    if (this.path) {
      this.$router.push(this.path + row.id);
    }
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  mounted() {
    this.columnDrop();
  }
}
