
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import {
  SPEC_UNIT_LIST,
  PROD_ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import {
  getProperties,
  getProductModelById,
  updateProductModel,
  ModelSpecsNewProperty,
  Property,
  CreateUpdateModalSpecsProperty,
} from '@/api/products';
import { promptSuccessBox, customFailedMessage } from '@/utils/prompt';
import { ErrorType } from '@/api/types';
import { checkNewPropertiesValues } from '../helpers/checkNewPropertiesValues';

@Component({
  name: 'modelSpec',
})
export default class extends Vue {
  @Prop({ required: false }) propertiesPrev!: any;
  @Prop({ required: false }) assetType!: string;
  @Prop({ required: false }) state!: any;

  staticSpecs: Property[] = [];

  newSpecs: Property[] = [];
  unitList = SPEC_UNIT_LIST;

  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  entityTypeProductModel = 'ENTT_PRODUCT_MODEL';

  mounted() {
    if (this.state === 'create' && this.$route.params.action != 'copy') {
      this.getAllProperties(this.entityTypeProductModel, this.assetType);
    }
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.property)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.propertyNotFound')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.units)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.unitsNotFound')}`;
    }

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetType)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.propertyDuplicate')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  async getAllProperties(entityType: string, assetType: string) {
    const res = await getProperties(entityType, assetType);
    if (res.code === 200) {
      this.staticSpecs = res.data;
      this.newSpecs = [];
    }
  }

  @Watch('assetType')
  private watchAssetTypeChange(value: string, oldVal: any) {
    if (this.state === 'create' && this.$route.params.action != 'copy') {
      this.getAllProperties(this.entityTypeProductModel, value);
    }
  }

  @Watch('propertiesPrev', { immediate: true, deep: true })
  watchSpecsInfo(value: any, oldVal: any) {
    if (this.$route.params.action || this.$route.params.action === 'copy') {
      //the specification form is in the copy state
      this.filterProperties(this.propertiesPrev.properties);
    }
  }

  //send specData to parent component
  @Emit('send-modal-spec')
  sendSpecData() {
    return this.filterMatchAllProperties('create');
  }

  async updateProperties(id: any) {
    const res = await getProductModelById(id);
    if (!res.data.properties || res.data.properties?.length === 0) {
      //the user has edited the assetType
      this.getAllProperties(
        this.entityTypeProductModel,
        res.data.assetTypeCode
      );
    } else {
      this.filterProperties(res.data.properties);
    }
  }

  filterProperties(oldProperties: Property[]) {
    if (oldProperties?.length > 0) {
      let oldStaticProperties = oldProperties.filter((item) => {
        return item.isPreDefined === true;
      });

      this.staticSpecs =
        oldStaticProperties.length > 0 ? oldStaticProperties : [];
      let oldButNotStaticProperties = oldProperties.filter((item) => {
        return !item.isPreDefined;
      });
      this.newSpecs =
        oldButNotStaticProperties.length > 0 ? oldButNotStaticProperties : [];
    } else {
      this.staticSpecs = [];
      this.newSpecs = [];
    }
  }

  filterMatchAllProperties(type: string): {
    properties: CreateUpdateModalSpecsProperty[];
    newProperties: ModelSpecsNewProperty[];
  } {
    const allProperties: Property[] = [...this.staticSpecs, ...this.newSpecs];
    const oldProperties: CreateUpdateModalSpecsProperty[] =
      allProperties.reduce(
        (accumulator: CreateUpdateModalSpecsProperty[], item: Property) => {
          if (item.keyId) {
            if (type === 'create') {
              accumulator.push({
                propertyKeyId: item.keyId,
                value: item.value,
              });
            }
            if (type === 'save') {
              if (!!item.isPreDefined) {
                accumulator.push({
                  id: item.keyId,
                  value: item.value,
                });
              } else {
                accumulator.push({
                  id: item.keyId,
                  value: item.value,
                  unit: item.unit,
                  name: item.name,
                });
              }
            }
          }

          return accumulator;
        },
        []
      );

    const newProperties: ModelSpecsNewProperty[] = this.newSpecs.filter(
      (item: any) => {
        return !item.keyId;
      }
    );

    let sendSpecs = {
      properties: oldProperties,
      newProperties,
    };

    return sendSpecs;
  }

  async updateModelSpec(productModelId: string) {
    try {
      const requestPayload = new FormData();
      requestPayload.append('productModelPicture', '');
      const specs = this.filterMatchAllProperties('save');
      if (specs.newProperties.length) {
        const errors = checkNewPropertiesValues(specs.newProperties);
        if (errors.length) {
          customFailedMessage(errors.join(', '));
          return;
        }
      }

      requestPayload.append(
        'productModelRequestBody',
        new Blob([JSON.stringify(specs)], {
          type: 'application/json',
        })
      );

      const res = await updateProductModel(productModelId, requestPayload);
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.updated') as string);
        this.errorInfos = [];
        this.$router.push('/prod-cata/index?activeName=productModels');
      } else if (res.code === 400) {
        // customFailedMessage(this.$t('common.save') as string);
        // this.errorInfos = res.data.errors[0].message;
        this.errorInfos = [...res.data.errors[0]];
        customFailedMessage(this.errNameInfo as any);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  addNewEmptySpec() {
    this.newSpecs.push({} as Property);
  }
}
