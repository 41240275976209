
import { getPolicies, Policies } from '@/api/policies';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import {
  getUserSettingById,
  updateUserSetting,
  UserSettings,
} from '@/api/users';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import i18n from '@/lang';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { promptFailedBox, promptSuccessBox } from '@/utils/prompt';
import {
  LANG_LIST,
  PAGE_SIZE_LIST,
  TIME_FORMAT_LIST,
} from '@/utils/workData/lookuptable';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';

interface SettingForm {
  userId: string;
  i18nCode: string;
  timeZone: string;
  datetimeFormat: string;
  gridPageSize: string;
}

@Component({
  name: 'ViewSettings',
  components: {
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  langOptions = LANG_LIST;
  timeZoneOptions: TimezoneResponse[] = [];
  timeFormatOptions = TIME_FORMAT_LIST;
  pageSizeOptions = PAGE_SIZE_LIST;

  viewSettingForm: UserSettings = {
    userId: UserModule.id,
    i18nCode: 'en-US',
    timeZone: moment.tz.guess(),
    datetimeFormat: 'YYYY-MM-DD HH:mm:ss',
    gridPageSize: '15',
  };
  saveButtonIsDisabled: boolean = true;
  initialSettingsForm: UserSettings = {
    userId: '',
    i18nCode: '',
    timeZone: '',
    datetimeFormat: '',
    gridPageSize: '',
  };
  viewSettingsIsLoading: boolean = false;
  reloadFlag: boolean = true;
  userSettingsUrls = {
    dppUrl: '',
    gtcUrl: '',
  };
  policies: Policies | undefined;

  /**
   * Check changes to input form compared with initial one to modify the state of the save button
   */
  @Watch('viewSettingForm', { deep: true })
  handleUserInputChange() {
    this.saveButtonIsDisabled = !Object.keys(this.viewSettingForm).some(
      (field) =>
        this.viewSettingForm[field as keyof SettingForm] !=
        this.initialSettingsForm[field as keyof SettingForm]
    );
  }

  async saveViewSettings() {
    try {
      this.viewSettingsIsLoading = true;
      this.handleTimeZoneChange();
      await updateUserSetting(UserModule.id, this.viewSettingForm);
      promptSuccessBox(this.$t('common.save') as string);
      var lang = this.viewSettingForm.i18nCode;
      i18n.locale = lang;
      UserModule.SetLang(lang);
      document.documentElement.lang = lang;
    } catch (error) {
      console.log(error);
      promptFailedBox(this.$t('common.save') as string);
    } finally {
      this.viewSettingsIsLoading = false;
      this.getUserSettingById();
      this.getPolicies();
    }
  }

  /** If user input has change the time zone from initial one, update current logged in session state */
  handleTimeZoneChange(): void {
    if (this.viewSettingForm.timeZone != UserModule.timeZone) {
      UserModule.UpdateTimeZone(this.viewSettingForm.timeZone!);
    }
  }

  async getUserSettingById() {
    try {
      this.viewSettingsIsLoading = true;
      const res = await getUserSettingById(UserModule.id);
      this.viewSettingForm = res.data;
      Object.assign(this.initialSettingsForm, this.viewSettingForm);
    } catch (error) {
      console.log(error);
    } finally {
      this.viewSettingsIsLoading = false;
    }
  }

  created() {
    getTimezones().then(({ data }) => (this.timeZoneOptions = data));
    this.getUserSettingById();
    this.getPolicies();
  }

  async getPolicies() {
    this.policies = await getPolicies({
      filters: [
        {
          name: 'policyRegionCode',
          operator: FilterOperator.EQUAL,
          value: [UserModule.policyRegion],
        },
        {
          name: 'i18nCode',
          operator: FilterOperator.EQUAL,
          value: [i18n.locale],
        },
      ],
    });
  }
}
