import { render, staticRenderFns } from "./PartsPrev.vue?vue&type=template&id=4c22889b&scoped=true&"
import script from "./PartsPrev.vue?vue&type=script&lang=ts&"
export * from "./PartsPrev.vue?vue&type=script&lang=ts&"
import style0 from "./PartsPrev.vue?vue&type=style&index=0&id=4c22889b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c22889b",
  null
  
)

export default component.exports