
import { AxiosInstance } from 'axios';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  KpiDataField,
  KpiDataValue,
  StatisticalData,
  StatisticalKpiSummary,
} from '@/api/assets';
import { TemplateChart, TemplateResponse } from '@/api/report';
import { KpisDataPayload } from '@/utils/kpidata';
import { LOCALDATE_FORMAT } from '@/utils/time';
import { KPI_STATISTICS_METHODS, KPI_UNIT } from '@/utils/workData/lookuptable';
import { REPORT_DATA_COL } from '@/utils/workData/reportMgmt';

import { getKpisData } from '../../helpers/getKpisData';
import Report from './Report.vue';
import {
  sortData,
  getDaysBetweenInterval,
  calculateProductivity,
  calculatePowerEfficency,
} from '../reportTemplate';

type Row = {
  assetId?: string | undefined;
  date?: string | undefined;
  fuelConsumption?: string | undefined;
  lifting?: string | undefined;
  powerConsumption?: string | undefined;
  productivity?: string | undefined;
  totalPayload?: string | undefined;
  workingHours?: string | undefined;
};

@Component({
  name: 'ReportView',
  components: {
    Report,
  },
})
export default class extends Vue {
  @Prop({ required: true }) template!: TemplateResponse;
  @Prop({ required: true }) scope!: string;
  @Prop({ required: true }) scopeValue!: string;
  @Prop({ required: true }) shownValue!: string;
  @Prop({ required: true }) start!: string;
  @Prop({ required: true }) end!: string;
  @Prop() request?: AxiosInstance;

  isGeneratedReportLoading: boolean = false;
  data: KpisDataPayload | null = null;

  created() {
    this.prepareDefaultInitializaiton();
  }

  get startDate() {
    return moment(this.start);
  }

  get endDate() {
    return moment(this.end);
  }

  /**
   * Fetch rows for all kpis assets retrieved from API
   * Splited by asset id column and date in the selected range
   */
  get rows() {
    const kpis = this.data?.kpis?.kpiData;
    if (!kpis || kpis.length == 0) {
      return [];
    }

    const intervalDays: string[] = getDaysBetweenInterval(
      this.startDate.format(LOCALDATE_FORMAT),
      moment(this.endDate).subtract(1, 'days').format(LOCALDATE_FORMAT)
    );

    const finalRows: Row[] = [];
    intervalDays.forEach((currentDay: string) => {
      kpis.forEach((assetKpis: StatisticalData) => {
        const entries = this.columns.map((column) => {
          const field = assetKpis.fields.find((f) => f.code === column.code);
          const value = field?.values.find((v) => v.ts === currentDay)?.v;

          return [column.prop, value ? Number(value).toFixed(2) : 'N/A'];
        });

        finalRows.push({
          date: currentDay,
          assetId: assetKpis.companyAssetId,
          ...Object.fromEntries(entries),
        });
      });
    });

    this.sortingByAssetId(finalRows);

    return finalRows;
  }

  /**
   * Default sorting by asset id column for generated report table
   * @param finalRows
   */
  sortingByAssetId(finalRows: Row[]): Row[] {
    return finalRows.sort((a: Row, b: Row) =>
      a?.assetId!.localeCompare(b?.assetId!)
    );
  }

  /**
   * Get columns accordingly with the payload data
   */
  get columns() {
    return REPORT_DATA_COL.filter((column) =>
      this.data?.template.kpis.some((kpi) => kpi.code === column.code)
    );
  }

  /**
   * Get all columns for generate report and add additional that are processed by response payload
   */
  get allColumns() {
    return [
      {
        label: 'report.assetId',
        prop: 'assetId',
        unit: null,
        require: true,
        visible: true,
        sortable: true,
      },
      {
        label: 'report.date',
        prop: 'date',
        unit: null,
        require: true,
        visible: true,
        sortable: true,
      },
      ...this.columns,
    ];
  }

  /**
   * Get kpis data that will be displayed on the top side section of the generated report
   */
  get kpis() {
    const summaryKPIsData = this.data?.kpis?.summary;

    if (!summaryKPIsData || summaryKPIsData.length === 0) {
      return [];
    }

    const intervalDays: string[] = getDaysBetweenInterval(
      this.startDate.format(LOCALDATE_FORMAT),
      moment(this.endDate).subtract(1, 'days').format(LOCALDATE_FORMAT)
    );

    return (
      this.data?.template.kpis.flatMap((templateKpi) => {
        const summaryForCurrentTemplateKpi = summaryKPIsData?.find(
          (summary) => summary.code === templateKpi.code
        );

        if (!summaryForCurrentTemplateKpi) {
          return [];
        }

        const values: Record<keyof typeof KPI_STATISTICS_METHODS, number> = {
          SUM: summaryForCurrentTemplateKpi.sum,
          AVERAGE_PER_DAY:
            intervalDays.length > 0
              ? summaryForCurrentTemplateKpi.sum / intervalDays.length
              : summaryForCurrentTemplateKpi.sum,
        };

        return templateKpi.statisticsMethods.map((method) => ({
          code: `${templateKpi.code}_${method}`,
          value: values[method].toFixed(2),
          unit: templateKpi.unit,
        }));
      }) ?? []
    );
  }

  /**
   * Get chart entity data sorted ASC by first key which is the date
   * Included scanarios with calculation for charts like: [Productivity, Efficiency]
   */
  get charts() {
    const kpisData = this.data?.kpis?.kpiData;

    if (!kpisData || kpisData.length === 0) {
      return [];
    }

    const intervalDays: string[] = getDaysBetweenInterval(
      this.startDate.format(LOCALDATE_FORMAT),
      moment(this.endDate).subtract(1, 'days').format(LOCALDATE_FORMAT)
    );

    const result = this.data!.template.charts.map(
      (reportTemplateChart: TemplateChart) => {
        const chartEntityLinesData: string[][] = [];

        intervalDays.forEach((daySelection: string) => {
          let sumPerDay: number = 0;

          let payloadSumPerDate: number = 0;
          let powerConsumptionSumPerDate: number = 0;
          let workingHoursSumPerDate: number = 0;
          kpisData.forEach((statisticsByAsset: StatisticalData) => {
            /** Get payload sum per date that will be used for productivity and efficency charts */
            payloadSumPerDate += this.getValueForGivenKpiCode(
              daySelection,
              'KPI.Payload',
              statisticsByAsset
            );

            /** Get working hours sum per date that will be used in productivity formula */
            if (reportTemplateChart.kpiCode === 'KPI.Productivity') {
              workingHoursSumPerDate += this.getValueForGivenKpiCode(
                daySelection,
                'KPI.WorkingHours',
                statisticsByAsset
              );
            } else if (reportTemplateChart.kpiCode === 'KPI.Efficiency') {
              /** Get power consumption sum per date that will be used for efficency formula */
              powerConsumptionSumPerDate += this.getValueForGivenKpiCode(
                daySelection,
                'KPI.PowerConsumption',
                statisticsByAsset
              );
            } else {
              /** Generic case used if it's report template type not in: [Productivity, Efficiency] */
              sumPerDay += this.getValueForGivenKpiCode(
                daySelection,
                reportTemplateChart.kpiCode,
                statisticsByAsset
              );
            }
          });

          let finalValuePerDate: number | undefined = 0;

          /**
           * Calculate final values for special cases: [Productivity, Efficiency]
           */
          if (reportTemplateChart.kpiCode === 'KPI.Productivity') {
            finalValuePerDate = calculateProductivity(
              payloadSumPerDate,
              workingHoursSumPerDate
            );
          } else if (reportTemplateChart.kpiCode === 'KPI.Efficiency') {
            finalValuePerDate = calculatePowerEfficency(
              payloadSumPerDate,
              powerConsumptionSumPerDate
            );
          } else {
            finalValuePerDate = sumPerDay;
          }

          chartEntityLinesData.push([
            daySelection,
            finalValuePerDate
              ? Number(finalValuePerDate).toFixed(2).toString()
              : '#N/A',
          ]);
        });

        return {
          lines: chartEntityLinesData,
          unit: kpisData![0]?.fields.find(
            (field: KpiDataField) => field.code === reportTemplateChart.kpiCode
          )!.unit,
          name: reportTemplateChart.kpiCode,
        };
      }
    );

    result.forEach((item) => {
      sortData(item?.lines);
    });

    return result;
  }

  /**
   * Get value from statistical field for given KPI Code
   * @param daySelection
   * @param kpiCodeToLookFor
   * @param statisticsByAsset
   * @return number
   */
  getValueForGivenKpiCode(
    daySelection: string,
    kpiCodeToLookFor: string,
    statisticsByAsset: StatisticalData
  ): number {
    const chartValuesByWorkingHoursField = statisticsByAsset.fields.find(
      (field: KpiDataField) => field.code === kpiCodeToLookFor
    )!;
    const workingHoursKpiValueOnDate =
      chartValuesByWorkingHoursField.values.find(
        (fieldValueItem: KpiDataValue) => fieldValueItem.ts === daySelection
      )?.v;

    return !isNaN(Number(workingHoursKpiValueOnDate))
      ? Number(workingHoursKpiValueOnDate)
      : 0;
  }

  /**
   * Prepare default initialization
   */
  prepareDefaultInitializaiton(): void {
    try {
      this.isGeneratedReportLoading = true;
      this.getData().then((data) => {
        this.data = data;
        this.isGeneratedReportLoading = false;
      });
    } catch (error) {
    } finally {
      this.isGeneratedReportLoading = false;
    }
  }

  /**
   * Fetch kpis data remotly from the API
   */
  async getData(): Promise<KpisDataPayload | null> {
    return await getKpisData({
      assetScope: this.scope,
      template: this.template,
      request: this.request,
      assetId: this.scopeValue,
      startDate: this.start,
      endDate: this.end,
    });
  }
}
