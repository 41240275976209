
import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import { formatTimer } from '@/utils/misc';
import { Moment } from 'moment';
import {
  getFirmwareReleaseNotes,
  getSystemReleaseNotes,
} from '@/api/systemReleaseManagement';

@Component({
  name: 'ContentContainer',
  components: {
    DefaultDialog,
  },
})
export default class extends Vue {
  @Prop() content!: any[];
  @Prop() title!: string;
  @Prop() showReleaseNotes?: string;
  @Prop() productSystemReleaseID?: string;

  systemNoteId: string = 'productSystemRelease.systemReleaseNotes';
  firmwareNoteId: string =
    'productSystemRelease.partFirmwareServiceReleaseNotes';
  private dialogTitle: string = '';
  private dialogVisible: boolean = false;
  private dialogContent: string = '';
  private dateTimeFields: string[] = [
    'productSystemRelease.manifestCreationTime',
  ];
  private systemReleaseRegionsReference: string = 'productSystemRelease.region';

  checkForTitle() {
    return this.title !== undefined;
  }

  isDateTimeField(rowId: string): boolean {
    return this.dateTimeFields.includes(rowId);
  }

  isSystemReleaseRegion(rowId: string): boolean {
    return this.systemReleaseRegionsReference === rowId;
  }

  async showNotes(title: string) {
    this.dialogTitle = title;
    const firmwareElement = this.content.find(
      (el) => el.id === 'productSystemRelease.partFirmwareReleaseUUID'
    );
    const response =
      (this.productSystemReleaseID &&
        (this.showReleaseNotes
          ? await getSystemReleaseNotes(this.productSystemReleaseID)
          : (firmwareElement &&
              (await getFirmwareReleaseNotes(
                this.productSystemReleaseID,
                firmwareElement.value
              ))) ||
            null)) ||
      null;
    if (response?.code === 200) {
      this.dialogContent = response.data;
    } else {
      this.dialogContent = this.$t(
        'releaseDetails.productSystemRelease.releaseNotesCouldNotBeFound'
      ).toString();
    }
    this.dialogVisible = true;
  }

  handleDialogClose(): void {
    this.dialogVisible = false;
  }

  formatDateAndTime(manifestCreationTime: string): string | Moment {
    return formatTimer(manifestCreationTime, 'datetime');
  }
}
