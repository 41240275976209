
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { REAL_TIME_KPIS_METRICS } from '@/utils/workData/lookuptable';
import LineChart from '@/components/kpiCharts/LineChart.vue';
import moment from 'moment';
import {
  oilLevelValueToTranslatedName,
  realTimeKPIChartData,
  realTimeKpiLimitAndWarningInfo,
  KpiChartDataItem,
  valueLimitCheckToColor,
  KpiLimitAndWarningInfo,
} from '@/utils/kpidata';
import Papa from 'papaparse';
import { KpiDataField } from '@/api/assets';
import { CustomizedKpiLimit } from '@/api/alarmConfig';

interface AllRealTimeKPIData {
  payload: KpiChartDataItem | undefined;
  oilTemperature: KpiChartDataItem | undefined;
  hydraulicPressure: KpiChartDataItem | undefined;
  filling: KpiChartDataItem | undefined;
  oilLevel: KpiChartDataItem | undefined;
}

@Component({
  name: 'LiveMonitoringLineCharts',
  components: {
    LineChart,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;
  @Prop({ required: true }) allChartData!: KpiDataField[];
  @Prop({ default: true }) isRendering!: boolean;
  @Prop({ default: 5 }) refreshSeconds!: number;
  @Prop({ required: true }) limitAlarmInfo!: CustomizedKpiLimit[];

  //current payload kpi
  payloadUnit: string = 'metricUnits.ton';
  payloadChartData: KpiChartDataItem[] = [];
  payloadLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  // oil Temperature kpi
  oilTemperatureUnit: string = 'metricUnits.celsius';
  oilTemperatureChartData: KpiChartDataItem[] = [];
  oilTemperatureLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  //hydraulic pressure
  hydraulicPressureUnit: string = 'metricUnits.bar';
  hydraulicPressureChartData: KpiChartDataItem[] = [];
  hydraulicPressureLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  //filling percentage
  fillingUnit: string = 'metricUnits.percentage';
  fillingChartData: KpiChartDataItem[] = [];
  fillingLimitWarningRange: KpiLimitAndWarningInfo =
    {} as KpiLimitAndWarningInfo;
  //oil level
  oilLevelChartData: KpiChartDataItem[] = [];
  oilLevelLimitWarningRange: KpiLimitAndWarningInfo = {
    lowLimit: 0.5,
  } as KpiLimitAndWarningInfo;

  allRealTimeKPILastestData: AllRealTimeKPIData = {} as AllRealTimeKPIData; //all the latest data in 5 kpis
  initDataLength: number = 100; //the init data length
  initNumberCount: number = 0; //the init number count

  created() {
    this.fillData(this.payloadChartData);
    this.fillData(this.oilTemperatureChartData);
    this.fillData(this.hydraulicPressureChartData);
    this.fillData(this.fillingChartData);
    this.fillData(this.oilLevelChartData);
  }

  mounted() {
    this.updateAlarmInfos(this.limitAlarmInfo);
    this.collateChartData(this.allChartData);
  }

  //collate the data
  @Watch('allChartData', { deep: true })
  collateChartData(handleData: KpiDataField[]) {
    if (handleData.length > 0) {
      let timeStamp: string = moment().format('YYYY-MM-DD hh:mm:ss');
      Object.entries(REAL_TIME_KPIS_METRICS).forEach(([_key, value]) => {
        let chartDataItem = realTimeKPIChartData(handleData, value, timeStamp);
        switch (value) {
          case REAL_TIME_KPIS_METRICS.CurrentPayload:
            this.addItemAndRemoveOverflow(this.payloadChartData, chartDataItem);
            break;
          case REAL_TIME_KPIS_METRICS.OilTemperature:
            this.addItemAndRemoveOverflow(
              this.oilTemperatureChartData,
              chartDataItem
            );
            break;
          case REAL_TIME_KPIS_METRICS.HydraulicPressure:
            this.addItemAndRemoveOverflow(
              this.hydraulicPressureChartData,
              chartDataItem
            );
            break;
          case REAL_TIME_KPIS_METRICS.CompactorFillingPercentage:
            this.addItemAndRemoveOverflow(this.fillingChartData, chartDataItem);
            break;
          case REAL_TIME_KPIS_METRICS.OilLevelLow:
            this.addItemAndRemoveOverflow(
              this.oilLevelChartData,
              chartDataItem
            );
            break;

          default:
            break;
        }
      });
      if (this.initNumberCount < this.initDataLength) this.initNumberCount++;
    }
    if (this.isRendering) {
      this.refreshLastestData();
    }
  }

  @Watch('isRendering')
  handleStopRender(newData: boolean, oldData: boolean) {
    if (!newData && oldData) {
      this.refreshLastestData();
    }
    return;
  }

  @Watch('limitAlarmInfo')
  updateAlarmInfos(newInfos: CustomizedKpiLimit[]) {
    this.payloadLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Current Payload'
    );
    this.oilTemperatureLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Oil Temperature'
    );
    this.fillingLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Compactor Filling Percentage'
    );
    this.hydraulicPressureLimitWarningRange = realTimeKpiLimitAndWarningInfo(
      newInfos,
      'Hydraulic Pressure'
    );
  }

  //the maximum number is one hour data
  get maximumLength() {
    let maximumNumber: number = 720;
    maximumNumber = parseInt((3600 / this.refreshSeconds).toString());
    return maximumNumber;
  }

  get realTimeKPIValues() {
    return {
      payload: this.allRealTimeKPILastestData.payload?.[1] ?? '',
      oilTemperature: this.allRealTimeKPILastestData.oilTemperature?.[1] ?? '',
      hydraulicPressure:
        this.allRealTimeKPILastestData.hydraulicPressure?.[1] ?? '',
      filling: this.allRealTimeKPILastestData.filling?.[1] ?? '',
      oilLevel: oilLevelValueToTranslatedName(
        Number(this.allRealTimeKPILastestData.oilLevel?.[1])
      ),
    };
  }

  realTimeKPIColor(value: KpiChartDataItem | undefined): string {
    return valueLimitCheckToColor(value?.[2]);
  }

  fillData(chartData: KpiChartDataItem[]) {
    for (let i = 1; i <= this.initDataLength; i++) {
      chartData.push(['test' + i, undefined, undefined]);
    }
  }

  addItemAndRemoveOverflow(
    chartData: KpiChartDataItem[],
    itemToAdd: KpiChartDataItem
  ) {
    if (chartData.length > this.maximumLength) {
      chartData.shift();
    }
    chartData.push(itemToAdd);
    if (this.initNumberCount < this.initDataLength) {
      chartData.shift();
    }
  }

  //refresh the RealTimeKPILastestData
  refreshLastestData() {
    this.allRealTimeKPILastestData = {
      payload: this.payloadChartData.at(-1),
      oilTemperature: this.oilTemperatureChartData.at(-1),
      hydraulicPressure: this.hydraulicPressureChartData.at(-1),
      filling: this.fillingChartData.at(-1),
      oilLevel: this.oilLevelChartData.at(-1),
    };
  }

  //trigger all the tooltips of the charts
  getNewXData(val: string) {
    (this.$refs.payloadKpi as any).triggerTooltip(val);
    (this.$refs.oilTemperatureKpi as any).triggerTooltip(val);
    (this.$refs.hydraulicPressureKpi as any).triggerTooltip(val);
    (this.$refs.fillingKpi as any).triggerTooltip(val);
    (this.$refs.oilLevelKpi as any).triggerTooltip(val);
  }

  //hide all the tooltips of the charts
  hideAllTooltip() {
    (this.$refs.payloadKpi as any).hideTooltip();
    (this.$refs.oilTemperatureKpi as any).hideTooltip();
    (this.$refs.hydraulicPressureKpi as any).hideTooltip();
    (this.$refs.fillingKpi as any).hideTooltip();
    (this.$refs.oilLevelKpi as any).hideTooltip();
  }

  //handle the data format before exporting to csv file
  generateCsvJson() {
    let result: CsvJsonEntry[] = [];
    let timeStamp: string = `${this.$t('KPI.timeStamp')}`;
    let currentPayload: string = `${this.$t('KPI.CurrentPayload')}(${this.$t(
      'metricUnits.ton'
    )})`;
    let compactorFillingPercentage: string = `${this.$t(
      'KPI.CompactorFillingPercentage'
    )}(${this.$t('metricUnits.percentage')})`;
    let oilTemperature: string = `${this.$t('KPI.OilTemperature')}(${this.$t(
      'metricUnits.celsius'
    )})`;
    let hydraulicPressure: string = `${this.$t(
      'KPI.HydraulicPressure'
    )}(${this.$t('metricUnits.bar')})`;
    let oilLevel: string = `${this.$t('KPI.OilLevel')}`;
    let handleDataStartPosition = this.initDataLength - this.initNumberCount;
    for (
      let i = handleDataStartPosition;
      i < this.payloadChartData.length;
      i++
    ) {
      const entry: CsvJsonEntry = {};
      entry[timeStamp] = this.payloadChartData[i][0];
      entry[currentPayload] = this.payloadChartData[i][1]?.toString();
      entry[compactorFillingPercentage] =
        this.fillingChartData[i][1]?.toString();
      entry[oilTemperature] = this.oilTemperatureChartData[i][1]?.toString();
      entry[hydraulicPressure] = this.hydraulicPressureChartData[i][1];
      entry[oilLevel] = oilLevelValueToTranslatedName(
        Number(this.oilLevelChartData[i][1])
      );
      result.push(entry);
    }
    return result;
  }

  //export to csv file
  exportCsvFile() {
    let toCsvDataArray = this.generateCsvJson();
    var csvData = Papa.unparse(toCsvDataArray);

    let content = new Blob([csvData]);
    let urlObject = window.URL || window.webkitURL || window;
    let url = urlObject.createObjectURL(content);

    let el = document.createElement('a');
    el.href = url;
    el.download = `${this.assetId} ${this.$t(
      'KPI.liveMonitoringData'
    )} ${new Date().toDateString()}.csv`;
    el.click();
    urlObject.revokeObjectURL(url);
  }
}

interface CsvJsonEntry {
  [name: string]: number | string | undefined;
}
