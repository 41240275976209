
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import AssignNav from '../components/AssignNav.vue';
import AssignSelectTable from '../components/AssignSelectTable.vue';
import AssignForm from '../components/AssignForm.vue';
import AssignFormPrev from '../components/AssignFormPrev.vue';
import PrevAssignTable from '../components/PrevAssignTable.vue';
import { postAssignAssets } from '@/api/assetsMgmt';
import { customFailedMessage } from '@/utils/prompt';
import { ASSIGN_ASSET_ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { finished } from 'stream';
import { ErrorType } from '@/api/types';

@Component({
  name: 'AssigningAsset',
  components: {
    NavPage,
    AssignNav,
    AssignSelectTable,
    AssignForm,
    AssignFormPrev,
    PrevAssignTable,
  },
})
export default class extends Vue {
  /** Local variables */
  state: string = 'selecting';
  selectedData: any = [];
  hasSameAssetType: boolean = false;
  disabledAssign: boolean = false; //determine whether user can go next step
  hasAssignedForm: any = {};
  refreshAllPage: boolean = true;
  assginFormTime: any = ''; //to update the sub-component DOM to reset Data
  isAssignmentFormLoading: boolean = false;

  errorCode = ASSIGN_ASSET_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  created() {}

  @Watch('state')
  private watchStateChange(value: any, oldVal: any) {
    if ((value = 'filling' && oldVal === 'selecting')) {
      //fill state back to selecting: the assign form should reset
      this.assginFormTime = +new Date();
    }
  }

  @Watch('hasSameAssetType')
  private watchHasSameAssetTypeChange(value: any, oldVal: any) {
    if (value === false && oldVal === true && this.selectedData.length > 0) {
      customFailedMessage(this.$t('assetMgmt.tipsDifferentAssets').toString());
    }
  }

  get chosenAssetTypeCode() {
    if (this.hasSameAssetType && this.selectedData.length > 0) {
      return this.selectedData[0].assetType;
    }
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.orderId)
        ?.code === 'ApiErrorFieldRequired'
    ) {
      errInfo = `${this.$t('prodCata.emptyOrderId')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.orderId)
        ?.code === 'ApiFieldOrderId'
    ) {
      errInfo = `${this.$t('prodCata.orderIdLength')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.connectStatus
      )?.code === 'ApiErrorCannotBeAssinged'
    ) {
      errInfo = `${this.$t('prodCata.wrongAssignStatus')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetTypes)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.duplicatedAssetTypes')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetIds)
        ?.code === 'ApiFieldAssignAssetsIds'
    ) {
      errInfo = `${this.$t('prodCata.emptyAssetsIds')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.organizition)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.invalidOrganization')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.subscriptionPackageId
      )?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.wrongMatchCompanyAndOrg')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  //get all the selected data
  getAllSelectedData(data: any) {
    this.selectedData = data;
    //determine whether the selected data share the same assetType
    if (data.length > 0) {
      this.hasSameAssetType = this.elementsAreEqual(this.selectedData);
    }
  }

  elementsAreEqual(list: Array<any>) {
    return list.every((el: any) => el.assetType === list[0].assetType);
  }

  //get Assign Step Info
  getAssignStepInfo(data: any) {
    this.disabledAssign = data;
  }

  //reset disabledAssign
  resetDisabledAssign(data: any) {
    this.disabledAssign = data;
  }

  //validate assign form
  validateFormData() {
    (this.$refs.assignForm as any).sendFormData();
  }

  //notify the nav to change state
  changeNavState() {
    (this.$refs.assignNav as any).updateState('confirm');
  }

  getAssignForm(data: any) {
    this.hasAssignedForm = data;
  }

  //request the AssignAsset API
  async requestAssignAsset() {
    let customerId = this.hasAssignedForm.customerId;
    let assignAssetsIds: any = [];

    this.selectedData.forEach((item: any) => {
      assignAssetsIds.push(item.id);
    });

    let submitData = {
      orderId: this.hasAssignedForm.orderId,
      cadId: this.hasAssignedForm.cadId,
      organizationId: this.hasAssignedForm.organizationId,
      subscriptionStartDate: this.hasAssignedForm.subscriptionStartDate,
      assignAssetIds: assignAssetsIds,
    };
    this.isAssignmentFormLoading = true;
    try {
      const res = await postAssignAssets(customerId, submitData);
      if (res.code === 200) {
        //notify the nav to change state to be successful
        (this.$refs.assignNav as any).updateState('succeed');
      } else if (res.code === 400) {
        customFailedMessage(res.data.errors[0].message as any);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isAssignmentFormLoading = false;
    }
  }

  //reset All data to continue another new assign asset workflow
  resetAllData() {
    this.refreshAllPage = false;
    this.$nextTick(() => {
      this.state = 'selecting';
      this.selectedData = [];
      this.hasSameAssetType = false;
      this.disabledAssign = false;
      this.hasAssignedForm = {};
      this.refreshAllPage = true;
    });
  }
}
