
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import GeneralInfomation from './components/GeneralInfomation.vue';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import DashboardTemplate from './components/DashboardTemplate.vue';
import { getCustomerById, updateActivationStatus } from '@/api/customer';
import { USER_COLS, USER_SEARCH_FIELDS } from '@/utils/workData/userMgmt';
import { ORG_COLS } from '@/utils/workData/orgMgmt';
import { ACTIVATION_STATUS, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import {
  ASSIGNED_ASSETS_COLS,
  CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS,
} from '@/utils/workData/assetsMgmt';
import { UserModule } from '@/store/modules/user';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import { customFailedMessage } from '@/utils/prompt';
import CustomerSubscriptionPackages from './components/CustomerSubscriptionPackages.vue';
import {
  getCompanySubscriptionsByCompanyId,
  getSubscriptionPackages,
  DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE,
} from '@/api/subscriptionPackages';
import { PACKAGE_TYPE } from '@/utils/workData/lookuptable';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  ASSIGNED_ASSETS_SEARCH_OPTION_COLS,
  ASSETS_SEARCH_OPTION_COLS,
} from '@/utils/workData/assetsMgmt';
import moment from 'moment';
import {
  getAccessibleAssetsByFlexibleFiltering,
  AccessibleAssetsObjectModel,
} from '@/api/accessibleAssets';
import { PageModule } from '@/store/modules/page';
import { ACCESS_TIME, CREATED_ON, ORGANIZATION_ID } from '@/utils/constants';
import { fetchAssetsByFlexibleFiltering, AssetObjectModel } from '@/api/assets';
import { getOrgById, Organization } from '@/api/organizations';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  Pagination,
  addFilter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import UserManagement from './components/userManagement/UserManagement.vue';
import OrganizationManagement from './components/organizationManagement/OrganizationManagement.vue';

@Component({
  name: 'CustomerInfo',
  components: {
    'new-card': NewCard,
    'pure-table': PureTable,
    'general-information': GeneralInfomation,
    'deactivate-dialog': DeactivateDialog,
    'subscription-packages': CustomerSubscriptionPackages,
    DashboardTemplate,
    'select-table-header': SelectTableHeader,
    'user-management': UserManagement,
    'organization-management': OrganizationManagement,
  },
})
export default class extends Vue {
  @Prop({ required: true }) customerId!: string;

  customerInfo: any = {};
  activateOrDeactivate: string = '';
  logo: any = '';
  title: string = '';
  selectedAssetes: string[] = [];
  customerType: string = '';
  totalUsers: number = 0;
  activeName: string = 'generalInfoTab';
  visiable: boolean = false;
  dialogContent: any = '';
  currentPage = 1;
  orgNum: number = 0;
  userCols = USER_COLS;
  orgCols = ORG_COLS;
  assetCols: any = ASSIGNED_ASSETS_COLS;
  assetList: AssetObjectModel[] | AccessibleAssetsObjectModel[] = [];
  userNum: number = 0;
  totalOrganizations: number = 0;
  assetTotal: number = 0;
  companySubscriptions: any | null = null;
  subscriptionPackages: any = {
    companyType: [],
    assetType: [],
  };
  packageType: any = PACKAGE_TYPE;
  source: string = 'view';
  subscriptionTabIsLoading: boolean = false;
  isViewModeLoading: boolean = false;
  userSearchFields: any = USER_SEARCH_FIELDS;
  templateId: string = '';
  pageSize: number = UserModule.gridPageSize;
  isAssetManagementLoading: boolean = false;
  userSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  assetSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  assetSearchParams: any = {
    reference: null,
    value: null,
  };
  messageTotalStatistics: string = '';
  totalStatistics: number = 0;
  isTotalStatisticsInfoVisible: boolean = false;
  assignedAssetsSearchFields: any = ASSETS_SEARCH_OPTION_COLS;
  queryParameter: QueryParameter = {};
  companyTypesWithImpersonation: string[] = [
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
    COMPANY_TYPE.Helpdesk,
  ];
  companyTypesThatRequiresAccessibleAssets: string[] = [
    COMPANY_TYPE.BodyBuilder,
    COMPANY_TYPE.Dealer,
  ];
  companyTypesThatRequiresAssetsByServiceRegion: string[] = [
    COMPANY_TYPE.Helpdesk,
  ];
  useAccessibleAssets: boolean = false;
  requestAssetsForHelpdesk: boolean = false;
  helpdeskServiceRegions: string[] = [];
  selectedUserCanImpersonate: boolean = false;

  created() {
    if (this.$route.query.tab === 'assets') {
      this.activeName = 'assetsTab';
      this.$router.replace({ query: null });
    }
    this.prepareDefaultInitialization();
  }

  async prepareDefaultInitialization() {
    this.customerId && (await this.fetchCustomerInfo());
    await this.retrieveSubscriptionPackages(
      1,
      DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE
    );
    await this.retrieveCompanySubscriptionsByCompanyId(this.customerId);

    this.useAccessibleAssets =
      this.companyTypesThatRequiresAccessibleAssets.includes(
        this.customerInfo.companyType
      );
    this.requestAssetsForHelpdesk =
      this.companyTypesThatRequiresAssetsByServiceRegion.includes(
        this.customerInfo.companyType
      );
    this.selectedUserCanImpersonate =
      this.companyTypesWithImpersonation.includes(
        this.customerInfo.companyType
      );

    if (this.selectedUserCanImpersonate) {
      this.assignedAssetsSearchFields = ASSIGNED_ASSETS_SEARCH_OPTION_COLS;
    }

    if (this.useAccessibleAssets) {
      this.assetCols = CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS;
    }

    await this.handleFetchAssets();
  }

  async fetchCustomerInfo() {
    this.isViewModeLoading = true;
    await getCustomerById(this.customerId).then((res) => {
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }
      if (res.code === 200 && Object.keys(res.data).length > 0) {
        this.customerInfo = res.data;
        PageModule.setTitle(this.customerInfo.name);
        this.logo = `${Vue.prototype.$envConfig.VUE_APP_BASE_API}/companies/${this.customerId}/logo`;

        if (
          this.customerInfo.activationStatus === ACTIVATION_STATUS.Deactivated
        ) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }
        this.customerType = res.data.companyType;
        this.isViewModeLoading = false;
        return;
      }

      this.isViewModeLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Retrieve from BE company subscriptions by company id
   * @param company id
   */
  async retrieveCompanySubscriptionsByCompanyId(
    companyId: string
  ): Promise<void> {
    try {
      await getCompanySubscriptionsByCompanyId(companyId).then((res: any) => {
        let allCompanySubscriptions: any = [];
        if (res.code === 200 && res.data.length > 0) {
          /** If package of company type is not the first in array, move it to 0 position */
          if (
            res.data[0].subscriptionPackageType != this.packageType.CompanyType
          ) {
            let packageOfCompanyType = res.data.find((item: any) => {
              return (
                item.subscriptionPackageType === this.packageType.CompanyType
              );
            });

            var index = res.data.indexOf(packageOfCompanyType);
            allCompanySubscriptions = res.data.filter((item: any) => {
              return (
                item.subscriptionPackageType != this.packageType.CompanyType
              );
            });
            res.data.splice(index, 1);
            allCompanySubscriptions.unshift(packageOfCompanyType);
          }
        }
        this.companySubscriptions =
          allCompanySubscriptions.length > 0
            ? allCompanySubscriptions
            : res.data;
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
    }
  }

  /**
   * Retrieve from BE subscription packages
   * @param page
   * @param size
   */
  async retrieveSubscriptionPackages(page: number, size: number) {
    this.subscriptionTabIsLoading = true;
    await getSubscriptionPackages(page, size).then((res) => {
      if (!res) return;
      if (res.code && res.code === 200) {
        res.data.subscriptionPackages.forEach((item: any) => {
          if (item.type === this.packageType.CompanyType)
            this.subscriptionPackages.companyType.push(item);
          if (item.type === this.packageType.AssetType)
            this.subscriptionPackages.assetType.push(item);
        });

        this.subscriptionTabIsLoading = false;
      }
    });
  }

  showDeactive() {
    this.visiable = true;
    if (this.customerInfo.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.title,
      });
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.title,
      });
    }
  }

  handleCancel() {
    this.visiable = false;
  }

  async handleDeactive() {
    await updateActivationStatus(this.customerId, {
      activationStatus:
        this.activateOrDeactivate === 'deactivate'
          ? ACTIVATION_STATUS.Deactivated
          : ACTIVATION_STATUS.Activated,
    }).then((res) => {
      let infoMsg = (
        this.activateOrDeactivate === 'deactivate'
          ? this.$t('common.deactivate')
          : this.$t('common.activate')
      ) as string;
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      if (res.code === 200) {
        this.$router.push('/home');
        promptSuccessBox(infoMsg);
      } else if (res.code === 400) {
        promptFailedBox(infoMsg);
      }
    });

    this.visiable = false;
  }

  handleTabClick(tab: any, event: any): void {
    if (this.activeName === 'assetsTab') {
      this.messageTotalStatistics = this.$t(
        'assetsModule.assetsTotalInfo'
      ) as string;
      this.totalStatistics = this.assetTotal;
      this.isTotalStatisticsInfoVisible = true;
      return;
    }

    if (this.activeName === 'usersTab') {
      this.messageTotalStatistics = this.$t(
        'userModule.userTotalInfo'
      ) as string;
      this.$nextTick(() => {
        (this.$refs.userManagement as any).sendTotalUsers();
      });
      return;
    }

    if (this.activeName === 'organizationsTab') {
      this.messageTotalStatistics = this.$t(
        'organizationModule.organizationTotalInfo'
      ) as string;
      this.$nextTick(() => {
        (this.$refs.organizationManagement as any).sendTotalOrganizations();
      });
      return;
    }

    this.isTotalStatisticsInfoVisible = false;
    this.totalStatistics = 0;
    this.messageTotalStatistics = '';
  }

  handleEdit() {
    this.$router.push(`/customer-management/edit/${this.customerId}`);
  }

  /** Filter by sort event */
  async fetchAssignedAssetsDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'organization') sortBy = 'organizationName';
    order != ''
      ? (this.assetSortAndOrderData.sortBy = sortBy)
      : (this.assetSortAndOrderData.sortBy = null);
    order != ''
      ? (this.assetSortAndOrderData.order = order)
      : (this.assetSortAndOrderData.order = null);
    await this.handleFetchAssets();
  }

  /**
   * Fetch new assets by pagination
   */
  fetchAssignedAssetsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    this.pageSize = pageSize;
    this.handleFetchAssets();
  }

  /**
   * Handle fetch assets data for selected customer, coveres the following company types:
   * - when normal customer: trigger flow for POST /assets with companyId
   * - when helpdesk: trigger flow for POST /assets with service regions
   * - when body builder / dealer: trigger flow for POST /accessible-assets by primaryOrgId of selected custoemr
   */
  async handleFetchAssets() {
    if (this.requestAssetsForHelpdesk) {
      this.fetchAssetsForHelpDeskCustomer();
      return;
    }

    if (this.useAccessibleAssets) {
      this.fetchAssetsForBodyBuilderAndDealer();
      return;
    }

    await this.fetchAssetsForNormalCustomers();
  }

  /**
   * Fetch for body builder and dealer customer types accessible assets by adding in filter all children and current org ids
   */
  async fetchAssetsForBodyBuilderAndDealer() {
    try {
      this.isAssetManagementLoading = true;
      const orgByIdResponse = await getOrgById(
        this.customerInfo.primaryOrganizationId
      );
      const flattenOrgIds: string[] = this.flattenOrganizationsIds([
        orgByIdResponse.data,
      ]);
      const filter: Filter = {
        name: ORGANIZATION_ID,
        operator: FilterOperator.IN,
        value: flattenOrgIds,
      };
      const queryParameter: QueryParameter = this.prepareQueryParameter(
        filter,
        true
      );
      const response = await getAccessibleAssetsByFlexibleFiltering(
        queryParameter
      );
      this.assetTotal = response.data.total;
      this.totalStatistics = this.assetTotal;
      this.assetList = response.data.assets;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isAssetManagementLoading = false;
    }
  }

  /** Flat current org level id and children */
  flattenOrganizationsIds(
    organizations: Organization | Organization[]
  ): string[] {
    if (!Array.isArray(organizations)) {
      organizations = [organizations];
    }
    return organizations.flatMap((org) => [
      org.id,
      ...this.flattenOrganizationsIds(org.children),
    ]);
  }

  /**
   * Fetch POST /assets by service regions for helpdesk customer
   */
  async fetchAssetsForHelpDeskCustomer() {
    try {
      this.isAssetManagementLoading = true;
      const queryParameter: QueryParameter = this.prepareQueryParameter();
      const response = await fetchAssetsByFlexibleFiltering(
        queryParameter,
        this.customerInfo.id
      );
      this.assetTotal = response.data.total;
      this.totalStatistics = this.assetTotal;
      this.assetList = response.data.assets;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isAssetManagementLoading = false;
    }
  }

  /**
   * Fetch assets for normal customer by add in query params to POST /assets: current selected customer UUID
   */
  async fetchAssetsForNormalCustomers() {
    try {
      this.isAssetManagementLoading = true;
      const queryParameter: QueryParameter = this.prepareQueryParameter();
      const response = await fetchAssetsByFlexibleFiltering(
        queryParameter,
        this.customerId
      );
      this.assetTotal = response.data.total;
      this.totalStatistics = this.assetTotal;
      this.assetList = response.data.assets;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isAssetManagementLoading = false;
    }
  }

  /**
   * Handle update selected asset
   * @param assets
   */
  handleUpdateSelectedAssets(assets: string[]) {
    this.selectedAssetes = assets;
  }

  /**
   * Prepare query parameter
   * @param filter
   * @return QueryParameter
   */
  prepareQueryParameter(
    filter?: Filter,
    isAccessTime?: boolean
  ): QueryParameter {
    const sorter: Sorter =
      this.assetSortAndOrderData.sortBy != null
        ? {
            field: this.assetSortAndOrderData.sortBy,
            order: this.assetSortAndOrderData.order,
          }
        : {
            field: isAccessTime ? ACCESS_TIME : CREATED_ON,
            order: SorterOrder.DESC,
          };
    const pagination: Pagination = {
      page: this.currentPage,
      size: this.pageSize,
    };
    let queryParameter: QueryParameter = {
      filters: [],
      sorters: [sorter],
      pagination: pagination,
    };
    if (
      this.assetSearchParams.value != null &&
      this.assetSearchParams.value != ''
    ) {
      const operator: FilterOperator = this.assetSearchParams.operator
        ? this.assetSearchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: this.assetSearchParams.reference,
        operator: operator,
        value: [this.assetSearchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    if (filter) {
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }

  /** Update user count from child event */
  updateTotalUsers(usersCount: number) {
    this.isTotalStatisticsInfoVisible = false;
    this.totalUsers = usersCount;
    this.totalStatistics = usersCount;
    this.isTotalStatisticsInfoVisible = true;
  }

  /** Update orgs count from child event */
  updateTotalOrganizations(orgsCount: number) {
    this.isTotalStatisticsInfoVisible = false;
    this.totalOrganizations = orgsCount;
    this.totalStatistics = orgsCount;
    this.isTotalStatisticsInfoVisible = true;
  }
}
