
import { Component, Vue } from 'vue-property-decorator';
import NewCard from '@/components/cusCard/NewCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { getCustomerById } from '@/api/customer';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { getOrganizations } from '@/api/organizations';
import {
  CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS,
  ASSIGNED_ASSETS_SEARCH_OPTION_COLS,
} from '@/utils/workData/assetsMgmt';
import {
  addCustomerAssets,
  getInaccessibleAssets,
} from '@/api/accessibleAssets';
import { PageModule } from '@/store/modules/page';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  QueryParameter,
  Filter,
  FilterOperator,
  Sorter,
  Pagination,
  addFilter,
  setFilters,
  setSorters,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { CREATED_ON } from '@/utils/constants';

@Component({
  name: 'AddAccess',
  components: {
    NewCard,
    PureTable,
    SelectTableHeader,
  },
})
export default class extends Vue {
  isViewModeLoading: boolean = false;
  assetTotal: number = 0;
  assetList: any = [];
  pageSize: number = UserModule.gridPageSize;
  page: number = 1;
  customerId: string = this.$route.params.customerId;
  assetSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  assetSearchParams: any = {
    reference: null,
    value: null,
  };
  customerInfo: any = {};
  title: string = '';
  logo: any = '';
  activateOrDeactivate: string = '';
  orgTotal: number = 0;
  orgList: any[] = [];
  selectedAssetes: string[] = [];
  selectedOrganizationId: string = '';
  currentPage = 1;
  assetCols = CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS;
  organization: string = '';
  assetsSearchFields: any = ASSIGNED_ASSETS_SEARCH_OPTION_COLS;
  customerType: string = '';
  assetsLoadingText: any = this.$t('assetsModule.assetsAreLoading');
  isAssetTableLoading: boolean = false;

  created() {
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    this.customerId && this.fetchCustomerInfo();
    this.fetchCustomerOrganizationsInfos();
  }

  /** Handle fetch assets */
  handleFetchAssets() {
    if (this.assetSearchParams.value == null) {
      this.currentPage = 1;
      this.pageSize = UserModule.gridPageSize;
      this.assetSortAndOrderData.sortBy = null;
    }
    this.fetchAssets(
      this.selectedOrganizationId,
      this.currentPage,
      this.pageSize
    );
  }

  /** Fetch inaccesible assets */
  async fetchAssets(organizationId: string, page: number, pageSize: number) {
    if (!this.selectedOrganizationId) {
      return;
    }
    try {
      this.isAssetTableLoading = true;
      const queryParameter: QueryParameter =
        this.prepareQueryParameterForAccessibleAssets(page, pageSize);
      const res = await getInaccessibleAssets(queryParameter, organizationId);

      if (!res || res.code !== 200) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      this.assetTotal = res.data.total;
      this.assetList = res.data.assets;
    } catch (error) {
      console.log(error);
    } finally {
      this.isAssetTableLoading = false;
    }
  }

  /** Prepare  stateless query parameters */
  prepareQueryParameterForAccessibleAssets(
    page: number,
    pageSize: number
  ): QueryParameter {
    const sorter: Sorter =
      this.assetSortAndOrderData.sortBy != null
        ? {
            field: this.assetSortAndOrderData.sortBy,
            order: this.assetSortAndOrderData.order,
          }
        : { field: CREATED_ON, order: SorterOrder.DESC };
    const pagination: Pagination = { page: page, size: pageSize };
    let queryParameter: QueryParameter = {
      filters: [],
      sorters: [sorter],
      pagination: pagination,
    };
    if (
      this.assetSearchParams.value != null &&
      this.assetSearchParams.value != ''
    ) {
      const operator: FilterOperator = this.assetSearchParams.operator
        ? this.assetSearchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: this.assetSearchParams.reference,
        operator: operator,
        value: [this.assetSearchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }

  /** Fetch customer info */
  async fetchCustomerInfo() {
    this.isViewModeLoading = true;
    await getCustomerById(this.customerId).then((res) => {
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      if (res.code === 200 && Object.keys(res.data).length > 0) {
        this.customerInfo = res.data;
        PageModule.setTitle(
          `${this.$t('customerModule.addAccess')} ${this.customerInfo.name}`
        );
        this.logo = `${Vue.prototype.$envConfig.VUE_APP_BASE_API}/companies/${this.customerId}/logo`;

        if (
          this.customerInfo.activationStatus === ACTIVATION_STATUS.Deactivated
        ) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }

        this.isViewModeLoading = false;
        return;
      }

      this.isViewModeLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /** Handle organization change */
  async organizationChange(organizationId: string) {
    this.selectedOrganizationId = organizationId;
    await this.fetchAssets(organizationId, this.currentPage, this.pageSize);
  }

  /** Fetch customer organizations info */
  async fetchCustomerOrganizationsInfos() {
    await getOrganizations(this.customerId).then((res) => {
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      if (res.code === 200) {
        this.orgTotal = res.data.length;
        this.orgList = this.findOrganizations(res.data[0]);
      }
    });
  }

  /** Find organizations */
  findOrganizations(organization: any): any[] {
    return [
      organization,
      ...organization.children.flatMap((sub: any) =>
        this.findOrganizations(sub)
      ),
    ];
  }

  /** Handle table pagination */
  handleTablePagination(page: number, pageSize: number) {
    this.page = page;
    this.pageSize = pageSize;
    this.fetchAssets(this.selectedOrganizationId, page, pageSize);
  }

  /** Filter by sort event */
  fetchAssignedAssetsDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'organization') sortBy = 'organizationName';
    order != ''
      ? (this.assetSortAndOrderData.sortBy = sortBy)
      : (this.assetSortAndOrderData.sortBy = null);
    order != ''
      ? (this.assetSortAndOrderData.order = order)
      : (this.assetSortAndOrderData.order = null);
    this.fetchAssets(this.selectedOrganizationId, this.page, this.pageSize);
  }

  /** Handle update selected assets */
  handleUpdateSelectedAssets(assets: string[]) {
    this.selectedAssetes = assets;
  }

  /** Handle checked assets */
  async handleAddAssets() {
    if (!this.organization) {
      customFailedMessage(
        this.$t('customerModule.selectOrgAndAssets').toString()
      );
      return;
    }
    if (!this.selectedAssetes.length) {
      customFailedMessage(
        this.$t('customerModule.addAccessibleAssets').toString()
      );
      return;
    }
    const res = await addCustomerAssets(
      this.selectedAssetes,
      this.organization
    );

    if (!res || res.code !== 200) {
      customFailedMessage(this.$t('customerModule.addAccessError').toString());
      return;
    }
    customSuccessMessage(
      this.$t('customerModule.operationSuccessful').toString()
    );
    this.$router.push({
      name: 'CustomerManagementView',
      query: { tab: 'assets' },
    });
    await this.fetchAssets(
      this.selectedOrganizationId,
      this.currentPage,
      this.pageSize
    );
  }
}
