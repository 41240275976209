
import { Component, Provide, Vue } from 'vue-property-decorator';
import Chart, { PieChartData } from '@/views/assets/components/Chart.vue';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import AssetsWidgetTable from '@/views/assets/components/AssetsWidgetTable.vue';
import moment from 'moment';
import {
  ActiveContext,
  LoggedInUserRef,
  useActiveContext,
  useLoggedInUser,
} from '@/composables/context';
import { Ref } from 'vue';

export enum StatusType {
  Active,
  Maintenance,
  InActive,
  Offline,
  Installed,
}

@Component({
  name: 'FleetStatus',
  components: {
    WidgetCard,
    Chart,
    AssetsWidgetTable,
  },
})
export default class extends Vue {
  private list: any = [];
  private total: number = 0;
  @Provide() chartData: PieChartData = { categories: [], data: [], colors: [] };
  @Provide() listLoading = true;
  @Provide() vehicleQuery = '';

  newChartData: any = [];
  routeAssetMap = ROUTE_ASSET_MAP;
  assetRelatedData: any = {};
  title: string = 'Status';
  assetTypeCode: string = '';

  widgetLoading: boolean | null = true;

  loggedInUser!: LoggedInUserRef;
  context!: Ref<ActiveContext>;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.context = useActiveContext();
  }

  getChartNewData(chartData: any) {
    this.total = 0;
    this.chartData.data = [];
    this.chartData.data.push(...chartData);
    this.chartData.data.forEach((element: any) => {
      this.total = this.total + element.value;
    });
    this.getChartCategories(this.$route.name as string);
    this.chartData.events = [
      {
        name: 'legendselectchanged',
        callback: (event: any) => {
          (this.$refs.assetTable as any).refreshData(event.selected);
          this.widgetLoading = true;
        },
      },
    ];
    this.widgetLoading = false;
  }

  sort() {
    var that: any = this.$refs.sortTable;
    that.sort('name', 'descending');
  }

  handleRowClick(row: any, column: any, event: any) {
    this.$router.push(`${this.assetRelatedData.route}/${row.id}`);
  }

  getChartCategories(route: string) {
    const chartCategoriesMapping: Record<string, string[]> = {
      MobileCompactor: [
        'kpiStatus.MOTOR_ON',
        'kpiStatus.STOP',
        'kpiStatus.IN_TRANSITION',
        'kpiStatus.OFFLINE',
      ],
      StaticCompactor: [
        'kpiStatus.MOTOR_ON',
        'kpiStatus.STOP',
        'kpiStatus.OFFLINE',
      ],
      TippingVehicles: [
        'kpiStatus.ACTIVE',
        'kpiStatus.INACTIVE',
        'kpiStatus.PARKED',
        'kpiStatus.MAINTENANCE',
        'kpiStatus.OFFLINE',
      ],
      All: [
        'kpiStatus.ACTIVE',
        'kpiStatus.INACTIVE',
        'kpiStatus.PARKED',
        'kpiStatus.MAINTENANCE',
        'kpiStatus.OFFLINE',
        'kpiStatus.MOTOR_ON',
        'kpiStatus.STOP',
        'kpiStatus.IN_TRANSITION',
        'kpiStatus.OFFLINE',
      ],
    };

    let category = Object.keys(chartCategoriesMapping).find((category: any) =>
      route.includes(category)
    );

    let categories =
      chartCategoriesMapping[category as keyof typeof chartCategoriesMapping] ||
      chartCategoriesMapping.All;

    if (!categories) {
      return;
    }

    const { status: filter } = this.$route.query;

    this.chartData.colors.push(
      ...categories.map((category: string) => this.getPiechartColor(category))
    );
    this.chartData.categories.push(
      ...categories.map((category: string) => this.$t(category) as string)
    );
    this.chartData.data = this.chartData.categories.map((category: string) =>
      this.chartData.data.find((data: any) => data.name === category)
    ) as object[];
    this.chartData.selectedCategories = this.chartData.categories.reduce(
      (acc: any, category) => {
        acc[category] =
          !filter || category == (this.$t(filter as string) as string);
        return acc;
      },
      {}
    );
  }

  getPiechartColor(category: string) {
    const chartColorsMapping: any = {
      MOTOR_ON: '#6494E2',
      STOP: '#E8BA53',
      OFFLINE: '#A7A7A7',
      IN_TRANSITION: '#E89253',
      ACTIVE: '#6494E2',
      INACTIVE: '#E8BA53',
      PARKED: '#E89253',
      MAINTENANCE: '#CE6666',
    };
    const status = category.split('.')[1];
    return chartColorsMapping[status];
  }

  dateFormatter(row: any, column: any) {
    let dateStr = moment(String(row.lastCommunicationTime)).format(
      'hh:mm:ss YYYY/MM/DD'
    );
    return dateStr;
  }

  getSelectedAsset(id: string) {
    this.$emit('getSelectedAsset', id);
  }
}
